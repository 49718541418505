// Props summary:
// className: well, obvious isn't it ?
// sortOrder: can be "asc" or "desc", also null if sortAttribute is null
// sortAttribute: attribute contained in headers that is currently being ordered
// requestResults(sortOrder, sortAttribute): callback to request the results
// headers: array of headers, a header can have two different shapes:
//        SHAPE1
//        checkbox: shows a checkbox with a toggle callback
//        checked: true/false for the checkbox
//        onChange: callback to trigger on the checkbox onChange
//        SHAPE2
//        sortable: defines if the item is sortable
//        attribute (must be unique): defines the attribute the header sorts
//        label (optional): defines the copy to be shown in the header, fallbacks to capitalized attribute
//        style (option): style of the cell, mainly there to set custom widths

import React from "react";

export class Header extends React.Component {
  orderIcon(attribute) {
    if (attribute != this.props.sortAttribute) return <span className="ticon-up-down ml-3"></span>;
    if (this.props.sortOrder == "desc") return <span className="ticon-down ml-3"></span>;
    return <span className="ticon-up ml-3"></span>;
  }

  selectOrder(e, attribute) {
    e.preventDefault();

    if (attribute == this.props.sortAttribute && this.props.sortOrder == "desc") {
      this.props.requestResults("asc", attribute);
    } else {
      this.props.requestResults("desc", attribute);
    }
  }

  render() {
    const { headers, className } = this.props;

    return (
      <thead className={className}>
        <tr>
          {headers.map(header => {
            if (header.checkbox) return (
              <th key={`header-${header.attribute}`} >
                <input type="checkbox" checked={header.checked} onChange={header.onChange} />
              </th>
            );

            if (!header.sortable) return (
              <th key={`header-${header.attribute}`} className="text-capitalize">
                {header.label || header.attribute}
              </th>
            );

            return (
              <th
                key={`header-${header.attribute}`}
                onClick={e => this.selectOrder(e, header.attribute)}
                style={header.style}
              >
                <a href="#" className="text-dark text-capitalize" style={{ textDecoration: "none" }}>
                  {header.label || header.attribute}
                  {this.orderIcon(header.attribute)}
                </a>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

export default Header;
