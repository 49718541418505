import React from "react";
import { connect } from "react-redux";

import { changePage, changePageAndSize, removeMessage } from "../_common/filtered_lists/_actions";
import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import Loader from "../_common/Loader";
import Alerts from "../_common/Alerts";
import Headers from "./Headers";
import TranslatorsList from "./TranslatorsList";

export class Results extends React.Component {
  render() {
    const {
      className,
      changePage,
      changePageAndSize,
      removeMessage,
      messages,
      loading,
      totalPages,
      page,
      size,
    } = this.props;

    return (
      <div className={className}>
        <Alerts messages={messages} removeCallback={removeMessage} />
        <div className="card">
          <div className="card-body">
            <TopPagination {...{ changePageAndSize, totalPages, page, size }} />
            <div className="loader_wrapper">
              {loading ? <Loader /> : null}
              <table className="table mb-3 table-striped whitelist__table" style={{tableLayout: "fixed"}}>
                <Headers />
                <TranslatorsList />
              </table>
            </div>
            <Pagination className="justify-content-end" {...{ changePage, totalPages, page }} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ messages, totalPages, loading, filters: {page, size} }) => ({
    messages,
    loading,
    totalPages,
    page,
    size
  }),
  { changePage, changePageAndSize, removeMessage }
)(Results);
