import PropTypes from "prop-types";
import React from "react";

function AllNumbers({ page, totalPages, changePage }) {
  const allPages = Array(totalPages).fill().map((_, i) => i + 1);

  return (
    <>
      {allPages.map(p => (
        <li className={`page-item ${p == page ? "active" : ""}`} key={`pagination-page-${p}`}>
          <a href="#" className="page-link" onClick={e => changePage(p, e)}>{p}</a>
        </li>
      ))}
    </>
  );
}

export default function Numbers(props) {
  const { totalPages, page, windowSize, changePage } = props;


  if (totalPages <= windowSize) return <AllNumbers {...props} />;


  let realPages = Array(windowSize).fill(1),
      changeablePages = windowSize - 3, // defines how many positions can vary since showing first, last and current is a must
      endRangeStart = totalPages - changeablePages, // if the current page is >= than this will show all pages
      extraMiddleItems = Math.floor((changeablePages - 2) / 2); // if page in the middle the ellipsis take 2 spaces, so we can show half of the remaining to left and right

  const rangeAtStart = page <= changeablePages,
        rangeAtEnd = page >= endRangeStart,
        rangeAtMiddle = !rangeAtEnd && !rangeAtStart;

  var newPage = page - extraMiddleItems;

  realPages = realPages.map((_, i) => {
    const iterator = i + 1;

    if (iterator === 1) return { type: "link", pageToRender: 1 };
    if (iterator === windowSize) return { type: "link", pageToRender: totalPages };
    if (rangeAtStart && iterator <= windowSize - 2) return { type: "link", pageToRender: iterator };
    if (rangeAtEnd && iterator > 2) return { type: "link", pageToRender: totalPages - windowSize + iterator };
    if (rangeAtMiddle && iterator > 2 && iterator <= windowSize - 2) {
      const realPage = { type: "link", pageToRender: newPage };
      newPage = newPage + 1;
      return realPage;
    }
    return { type: "ellipsis" };
  });

  return (
    <>
      {realPages.map(({ pageToRender, type }, i) => {
        if (type == "ellipsis") return <li className="page-item disabled" key={`ellipsis-${i}`}><span className="page-link">...</span></li>;

        return (
          <li className={`page-item ${pageToRender == page ? "active" : ""}`} key={`pagination-page-${pageToRender}`}>
            <a href="#" className="page-link" onClick={e => changePage(pageToRender, e)}>{pageToRender}</a>
          </li>
        );
      })}
    </>
  );
}

Numbers.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  windowSize: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};