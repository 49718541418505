import React from "react";
import { connect } from "react-redux";
import { post } from "../_utils/humpyAjax";

import DateTimePicker from "../_common/DateTimePicker";
import { setNewDeadline, closeDeadlineChanger } from "./_actions";

export class ChangeOfficialDeadline extends React.Component {
  state = {
    deadline: ""
  }

  changeOfficialDeadline(e) {
    const { deadline, changeDeadlinePath } = this.props;

    e.preventDefault();

    post(changeDeadlinePath, { deadline })
      .then(() => location.reload());
  }

  render() {
    const {
      changeDeadlineOpen,
      deadline,
      setNewDeadline,
      closeDeadlineChanger,
    } = this.props;

    if (!changeDeadlineOpen) return null;

    return (
      <div>
        <br/>
        <div className="mt-2">
          <DateTimePicker
            id="dealine-calendar"
            callback={(date) => setNewDeadline(date)}
            date={deadline}
            minDate={new Date()}
          />
          <button className="btn btn-primary mr-2" onClick={e => this.changeOfficialDeadline(e)}>Set custom deadline</button>
          <span className="pseudo-link cancel js-cancel-change-deadline" onClick={() => closeDeadlineChanger()}>Cancel</span>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    changeDeadlinePath,
    changeDeadlineOpen,
    deadline,
  }) => ({
    changeDeadlinePath,
    changeDeadlineOpen,
    deadline,
  }),
  { setNewDeadline, closeDeadlineChanger }
)(ChangeOfficialDeadline);
