import React, { createContext, useReducer } from 'react';
import {
  INITIALIZE_STORE,
  UPDATE_FILTERS,
  REQUEST_WITHDRAWALS,
  RECEIVE_WITHDRAWALS,
  MARK_AS_PAID,
  UPDATE_WITHDRAWALS,
} from './_actions';

export const Store = createContext();

const initialState = {
  filters: { page: 1, size: 25, },
  withdrawals: [],
  withdrawalsCount: 0,
  totalPages: 1,
  totalValue: 0,
  totalBalance: 0,
  currencies: [],
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case INITIALIZE_STORE:
      return {
        ...state,
        totalValue:   action.payload.totalValue,
        totalBalance: action.payload.totalBalance,
        currencies:   action.payload.currencies,
        filters:      { ...state.filters, ...action.payload.filters, },
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload, },
      };
    case REQUEST_WITHDRAWALS:
      return {
        ...state, loading: true,
      };
    case RECEIVE_WITHDRAWALS:
      return {
        ...state,
        withdrawals:      action.payload.records,
        withdrawalsCount: action.payload.totalCount,
        totalPages:       action.payload.totalPages,
        loading:          false,
      };
    case UPDATE_WITHDRAWALS:
      return {
        ...state,
        withdrawals: state.withdrawals.map((withdrawal) => {
          const updatedWithdrawal = action.payload.find((updatedWithdrawal) =>
            withdrawal.id == updatedWithdrawal.id);

          return updatedWithdrawal
            ? { ...withdrawal, ...updatedWithdrawal }
            : withdrawal;
        }),
      };
    case MARK_AS_PAID:
      return {
        ...state,
        withdrawals: state.withdrawals.map((withdrawal) =>
          withdrawal.id == action.payload
            ? { ...withdrawal, paid: true }
            : withdrawal
        ),
      }
    default:
      return state;
  }
}

export function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Store.Provider value={value}>{children}</Store.Provider>;
}
