import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import PriceField from "./PriceField";
import SalesMarginField from "./SalesMarginField";
import { fixed, percentage } from "./ClientLanguagePairPricing";

import {
  updateClientWorkflowLanguagePairPricing,
  saveClientWorkflowLanguagePairPricing,
  removeClientWorkflowLanguagePairPricing,
} from './_actions';

export default function ClientWorkflowLanguagePairPricing(props) {
  const {
    clientLanguagePairPricing: clientPricing,
    workflowLanguagePairPricing: pricing,
  } = props;

  const dispatch = useDispatch();

  const handleChange = useCallback((changes) => {
    dispatch(updateClientWorkflowLanguagePairPricing(clientPricing.id, changes));
  }, [pricing]);

  const handleSave = useCallback((e) => {
    e.preventDefault();
    dispatch(saveClientWorkflowLanguagePairPricing(clientPricing.id, pricing));
  }, [pricing]);

  const handleRemove = useCallback((e) => {
    e.preventDefault();
    dispatch(removeClientWorkflowLanguagePairPricing(clientPricing.id, pricing.id));
  }, []);

  return (
    <div className="row mb-5">
      <div className="col">
        <div className="row">
          <div className="col">
            <h5>
              {I18n.t("languages")[pricing.sourceLanguageCode]} - {I18n.t("languages")[pricing.targetLanguageCode]} (qualities using postediting workflow)
            </h5>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <form>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <table className="table table__center">
                      <thead>
                        <tr>
                          <th className="th__left">Quality</th>
                          <th>Sales margin</th>
                          <th>Price $</th>
                          <th>Tolq $</th>
                          <th>Translator $</th>
                          <th>Price €</th>
                          <th>Tolq €</th>
                          <th>Translator €</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="td__left">Translation</td>
                          <td>
                            <SalesMarginField
                              name="salesMarginTranslation"
                              value={pricing.salesMarginTranslation || ""}
                              onChange={handleChange}
                            />
                            {!pricing.salesMarginTranslation && <>({percentage(pricing.usedSalesMargin)})</>}
                          </td>
                          <td>${fixed(pricing.usedPricePerWordTranslationUsd, 3)}</td>
                          <td>${fixed(pricing.tolqPricePerWordTranslationUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordTranslationUsd, 3)}</td>
                          <td>€{fixed(pricing.usedPricePerWordTranslationEur, 3)}</td>
                          <td>€{fixed(pricing.tolqPricePerWordTranslationEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordTranslationEur, 3)}</td>
                        </tr>

                        <tr>
                          <td className="td__left">Localization</td>
                          <td>
                            <SalesMarginField
                              name="salesMarginLocalization"
                              value={pricing.salesMarginLocalization || ""}
                              onChange={handleChange}
                            />
                            {!pricing.salesMarginLocalization && <>({percentage(pricing.usedSalesMargin)})</>}
                          </td>
                          <td>${fixed(pricing.usedPricePerWordLocalizationUsd, 3)}</td>
                          <td>${fixed(pricing.tolqPricePerWordLocalizationUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordLocalizationUsd, 3)}</td>
                          <td>€{fixed(pricing.usedPricePerWordLocalizationEur, 3)}</td>
                          <td>€{fixed(pricing.tolqPricePerWordLocalizationEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordLocalizationEur, 3)}</td>
                        </tr>

                        <tr>
                          <td className="td__left">Expert</td>
                          <td>
                            <SalesMarginField
                              name="salesMarginExpert"
                              value={pricing.salesMarginExpert || ""}
                              onChange={handleChange}
                            />
                            {!pricing.salesMarginExpert && <>({percentage(pricing.usedSalesMargin)})</>}
                          </td>
                          <td>${fixed(pricing.usedPricePerWordExpertUsd, 3)}</td>
                          <td>${fixed(pricing.tolqPricePerWordExpertUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordExpertUsd, 3)}</td>
                          <td>€{fixed(pricing.usedPricePerWordExpertEur, 3)}</td>
                          <td>€{fixed(pricing.tolqPricePerWordExpertEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordExpertEur, 3)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="submit"
                        name="commit"
                        value="Save"
                        className="btn btn-sm btn-primary mr-1"
                        data-disable-with="Save"
                        onClick={handleSave}
                      />
                      <button
                        className="btn btn-sm btn-danger mr-1"
                        onClick={handleRemove}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-italic">
                        Hint: Empty fields when setting prices or sales margin will use the default sales margin.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
