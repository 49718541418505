import React from "react";

export default function ErrorsAlert({errors}) {
  if (!errors || !errors.length) return null;

  return (
    <div className="alert alert-danger">
      <ul className="m-0">
        {errors.map(error => <li key={error}>{error}</li>)}
      </ul>
    </div>
  );
}
