import React from "react";

import { useMarkInvoiceAsPaidMutation } from "./_api";

export default function Paid({paid, id}){
  const [ pay, {isLoading, error}] = useMarkInvoiceAsPaidMutation();

  const handlePayClick = e => {
    e.preventDefault();
    pay({input: {invoiceId: parseInt(id)}});
  };

  if (error) console.error(error);

  if (isLoading) return (
    <span className="text-muted">Loading...</span>
  );

  if (paid) return (
    <span className="ticon-check text-success" />
  );

  return (
    <a
      href="#mark-as-paid"
      className="ticon-cancel text-danger"
      onClick={handlePayClick}
    />
  );
}
