import React from "react";

export class Popup extends React.Component {
  render() {
    const { children, wrapperCallback, className, visibleOverflow } = this.props;

    return (
      <div className={`popup_wrapper ${className || ""}`} onClick={() => wrapperCallback ? wrapperCallback() : null}>
        <div className="popup" onClick={e => e.stopPropagation()}>
          <div className={`popup__content ${visibleOverflow ? "popup--with-visible-overflow" : ""}`}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;