import React from "react";

function createChart(data, config = { excluded: [], stacked: true, groupSize: 1 }, context) {
  let chart = new Chart(context, {
    type: "bar",
    data: filteredData(data, config),
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        xAxes: [{
          stacked: config.stacked,
          ticks: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {beginAtZero: true}
        }]
      }
    }
  });

  return chart;
}

function filteredData(data, config) {
  let {groupSize, excluded} = config,
      includedDatasets = data.datasets.filter(set => !excluded.includes( set.label) ),
      dataSum = [],
      includedIndexes,
      newData;

  includedDatasets.forEach(set =>{
    set.data.forEach( (value,index) =>{
      dataSum[index] = value + (dataSum[index] || 0);
    });
  });

  includedIndexes = dataSum.map( (value,index) => ({value, index}) )
    .filter( ({value}) => value > 0 )
    .map(({index})=>index);

  newData = {
    labels: data.labels.filter((value, index) => includedIndexes.includes(index)),
    datasets: includedDatasets.map(set => ({
      ...set,
      data: set.data.filter( (value, index) => includedIndexes.includes(index) )
    }))
  };

  if (groupSize == 1) return newData;

  return {
    labels: newData.labels.reduce( (acc, label, index) => {
      if (index % groupSize == 0) acc.push(label);
      if ((index+1) % groupSize == 0) acc[acc.length - 1] += ` - ${label}`;
      if (index == (newData.labels.length - 1) && (index + 1) % groupSize != 0) acc[acc.length - 1] += ` - ${label}`;
      return acc;
    }, []),
    datasets: newData.datasets.map(set => ({
      ...set,
      data: set.data.reduce((acc, value, index) => {
        if (index % groupSize == 0) {
          acc.push(value);
        } else {
          acc[acc.length - 1] += value;
        }
        return acc;
      }, [])
    }))
  };
}

export class GroupedBarsChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  componentDidMount() {
    let { values, excluded } = this.props,
        valuesToShow = values;

    if (valuesToShow.length) this.chart = createChart(valuesToShow, excluded, this.canvas.current);
  }

  componentDidUpdate() {
    let { values, excluded, stacked, groupSize } = this.props;

    if (!this.chart) {
      this.chart = createChart(values, { excluded, stacked, groupSize }, this.canvas.current);
    } else {
      this.chart.data = filteredData(values, { excluded, groupSize });
      this.chart.options.scales.xAxes[0].stacked = stacked;
      this.chart.update();
    }
  }

  shouldComponentUpdate(oldProps) {
    return oldProps != this.props;
  }

  componentWillUnmount() {
    if (this.chart) this.chart.destroy();
  }

  render() {
    const { height } = this.props;

    return (
      <div className="chart__line_chart__wraper" style={{ height }}>
        <canvas ref={this.canvas} ></canvas>
      </div>
    );
  }
}

export default GroupedBarsChart;