import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";

import Transactions from './Transactions';
import { StoreProvider } from './Store';

ReactDOM.render(
  <StoreProvider>
    <Transactions initialData={TRANSACTIONS_DATA} />
  </StoreProvider>,
  document.getElementById("transactions"),
);
