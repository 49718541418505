
export const QUALITY_HIGHLIGHT_COLOR_CODES = {
  spellingHuman: "#E74C3C",
  punctuation: "#B22ECC",
  grammar: "#f39c12",
  terminology: "#4A90E2",
  fluency: "#7DE0E3",
  accuracy: "#27AE60",
  wrong: "#2ecc71",
};

export const QUALITY_HIGHLIGHT_KEYS = [
  "spellingHuman", "punctuation", "grammar", "terminology", "fluency",
  "accuracy", "wrong",
];

export const ROLE_ICONS = {
  translation: "ticon-one-person",
  proofreading: "ticon-proofreading",
  both: "ticon-two-people",
};

// Matches enum in whitelisted_skill.rb
export const ROLES = ["translation", "proofreading", "both"];

export const SUPPORTED_HTML_ENTITIES_HASH = {
  "&gt;": ">",
  "&lt;": "<",
};

export const SUPPORTED_HTML_ENTITIES = Object.keys(SUPPORTED_HTML_ENTITIES_HASH);

export const CHARS_HTML_ENTITY_ENCODABLE_HASH = SUPPORTED_HTML_ENTITIES.reduce((acc, htmlEntity) => {
  const entityChar = SUPPORTED_HTML_ENTITIES_HASH[htmlEntity];

  acc[entityChar] = htmlEntity;
  return acc;
}, {});

export const CHARS_HTML_ENTITY_ENCODABLE = Object.values(SUPPORTED_HTML_ENTITIES_HASH);
