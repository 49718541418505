export const clientsUrl = () => {
  if (window.location.hostname == "localhost") {
    return "http://clients.tolq.dev";
  } else {
    return window.location.origin.replace("admin", "clients");
  }
};

export const translatorsUrl = () => {
  if (window.location.hostname == "localhost") {
    return "http://translators.tolq.dev";
  } else {
    return window.location.origin.replace("admin", "translators");
  }
};