import React from "react";
import { connect } from "react-redux";

import Card from "../_common/Card";
import QualityDistributionChart from "./QualityDistributionChart";
import TaskTypesChart from "./TaskTypesChart";
import VerdictsChart from "./VerdictsChart";
import Verdicts from "./Verdicts";
import QualityDimensions from "./QualityDimensions";
import ProofreadingOverlapScore from "./ProofreadingOverlapScore";

export class TranslatorMetrics extends React.Component {
  render() {
    if (!this.props.tasksCount) return null;

    return (
      <React.Fragment>
        <Card className="col-4 mt-2">
          <QualityDistributionChart />
        </Card>
        <Card className="col-4 mt-2">
          <TaskTypesChart />
        </Card>
        <Card className="col-4 mt-2">
          <VerdictsChart />
        </Card>

        <h3 className="col-12 mt-4">Verdicts</h3>
        <Verdicts />

        <h3 className="col-12 mt-4">Quality Dimensions</h3>
        <QualityDimensions />

        <h3 className="col-12 mt-4">Similarity between translation and proofreading</h3>
        <ProofreadingOverlapScore />
      </React.Fragment>
    );
  }
}

export default connect(
  ({staticData: {tasksCount}}) => ({tasksCount})
)(TranslatorMetrics);
