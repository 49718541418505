import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useFiltersUrlSync from "../../_hooks/useFiltersUrlSync";
import useConfigureVisibleAttributes from "../../_hooks/useConfigureVisibleAttributes";
import useDragTable from "../../_hooks/useDragTable";
import { Pagination, THead, TBody, ConfigureAttributes } from "../all";

export default function DataTable(props) {
  const {
    // Required
    name,
    keys,
    getQuery,
    updateFilters,
    updatePage,
    // Optional
    header,
    sortableKeys,
    sortAttribute,
    sortOrder,
    filterUrlSync,
  } = props;

  const columnWidths = props.columnWidths || {},
        components = props.components || {},
        hiddenKeys = props.hiddenKeys || [],
        labels = props.labels || ( k => k ),
        sortableKeysLabels = props.sortableKeysLabels || (k => k),
        Filters = props.Filters || (()=>null);

  const filters = useSelector(state => state.filters),
        dispatch = useDispatch(),
        { data, isLoading, error } = getQuery(filters),
        { items, totalPages, totalItems } = data || {},
        [ showConfigurator, setShowConfigurator ] = useState(false),
        { visibleAttributes, setVisibleAttributes } = useConfigureVisibleAttributes(name, keys, hiddenKeys),
        shownKeys = keys.filter(k => visibleAttributes[k]),
        { sliderRef, draggable,} = useDragTable([visibleAttributes]);

  // Reminder: this is an antipattern if the condition changes over execution
  if (filterUrlSync) useFiltersUrlSync(updateFilters);

  return (
    <>
      {!!header &&
        <h1>
          {header}
          {Number.isInteger(totalItems) && `(${totalItems})`}
        </h1>
      }
      <Filters />
      {showConfigurator &&
        <ConfigureAttributes
          filters={filters}
          keys={keys}
          labels={labels}
          setShowConfigurator={setShowConfigurator}
          setVisibleAttributes={setVisibleAttributes}
          sortableKeys={sortableKeys}
          sortableKeysLabels={sortableKeysLabels}
          updateFilters={ f => dispatch(updateFilters(f)) }
          visibleAttributes={visibleAttributes}
        />
      }
      <div style={{width: "100%", overflow: "auto"}} ref={sliderRef}>
        <table className={`table table--auto ${draggable && "table--draggable"}`}>
          <THead
            className={"thead--hoverable"}
            columnWidths={columnWidths}
            keys={shownKeys}
            labels={labels}
            attributeCb={()=>setShowConfigurator(true)}
            sortableKeys={sortableKeys}
            sortAttribute={sortAttribute}
            sortOrder={sortOrder}
          />
          <TBody
            items={items}
            isLoading={isLoading}
            keys={shownKeys}
            components={components}
            error={error}
          />
        </table>
      </div>
      <Pagination page={filters.page} totalPages={totalPages || 0} changePage={p =>dispatch(updatePage(p))} />
    </>
  );
}