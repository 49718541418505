import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PriceField from "./PriceField";
import LanguageField from "./LanguageField";
import SalesMarginField from "./SalesMarginField";

import {
  updateNewClientLanguagePairPricing,
  saveNewClientLanguagePairPricing,
} from './_actions';

export default function NewClientLanguagePairPricing(props) {
  const pricing = useSelector(state => state.newClientLanguagePairPricing);

  const dispatch = useDispatch();

  const handleChange = (changes) => dispatch(updateNewClientLanguagePairPricing(changes));

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    dispatch(saveNewClientLanguagePairPricing());
  }, []);

  return (
    <React.Fragment>
      <h3 className="flex-dont-grow">New language pair pricing</h3>
      <div className="card flex-grow new-client-language-pair-pricing">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <form className="new_client_language_pair_pricing" id="new_client_language_pair_pricing">
                    <div className="create-client-language-pair-pricing-wrapper">
                      <div className="client-language-pair-pricing-header">Source language</div>
                      <div className="client-language-pair-pricing-header">Target language</div>
                      <div className="client-language-pair-pricing-header">Sales margin</div>

                      <div className="client-language-pair-pricing-value">
                        <LanguageField
                          name="sourceLanguageCode"
                          invalid={!pricing.validSourceLanguageCode}
                          options={props.sourceLanguageCodes}
                          value={pricing.sourceLanguageCode}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <LanguageField
                          name="targetLanguageCode"
                          invalid={!pricing.validTargetLanguageCode}
                          options={props.targetLanguageCodes}
                          value={pricing.targetLanguageCode}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <SalesMarginField
                          name="salesMargin"
                          value={pricing.salesMargin}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-header">Quality</div>
                      <div className="client-language-pair-pricing-header">Price $</div>
                      <div className="client-language-pair-pricing-header">Price €</div>

                      <div className="client-language-pair-pricing-label">Postediting</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingUsd"
                          value={pricing.pricePerWordPosteditingUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingEur"
                          value={pricing.pricePerWordPosteditingEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Postediting AI</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingAiUsd"
                          value={pricing.pricePerWordPosteditingAiUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingAiEur"
                          value={pricing.pricePerWordPosteditingAiEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Translation</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordTranslationUsd"
                          value={pricing.pricePerWordTranslationUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordTranslationEur"
                          value={pricing.pricePerWordTranslationEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Localization</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordLocalizationUsd"
                          value={pricing.pricePerWordLocalizationUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordLocalizationEur"
                          value={pricing.pricePerWordLocalizationEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Expert</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordExpertUsd"
                          value={pricing.pricePerWordExpertUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordExpertEur"
                          value={pricing.pricePerWordExpertEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Machine</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordMachineUsd"
                          value={pricing.pricePerWordMachineUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordMachineEur"
                          value={pricing.pricePerWordMachineEur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <input
                          type="submit"
                          name="commit"
                          value="Create"
                          className="btn btn-primary"
                          data-disable-with="Create"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p className="font-italic">
                          Hint: To create a Qualities using Postediting workflow pricing, please first create a client pricing.
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
