import React from "react";

export class NoMergePossible extends React.Component {
  render() {
    return (
      <div>
        <h2>This order phrases cannot be merged</h2>
        <p>This order is already available for translators to translate, any further merging will have to be done via their translation UI.</p>
      </div>
    );
  }
}

export default NoMergePossible;
