import React from "react";
import { connect } from "react-redux";

import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { requestFiltersUpdate } from "./_actions";

export class ResultsTable extends React.Component {
  render() {
    const { requestFiltersUpdate, totalPages, page, size } = this.props,
          changePageAndSize = (page, size) => requestFiltersUpdate({page,size}),
          changePage = (page) => requestFiltersUpdate({ page });

    return (
      <div className="card">
        <div className="card-body">
          <TopPagination {...{ changePageAndSize, totalPages, page, size}} />
          <table className="table">
            <TableHead />
            <TableBody />
          </table>
          <Pagination className="justify-content-end" {...{ changePage, totalPages, page }} />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ totalPages, filters: { page, size } }) => ({ totalPages, page, size }),
  { requestFiltersUpdate }
)(ResultsTable);