import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PriceField from "./PriceField";
import SalesMarginField from "./SalesMarginField";

import {
  getClientDefaultPricing,
  updateClientDefaultPricing,
  saveClientDefaultPricing,
} from './_actions';

export const fixed = (x, precision) => x && Number.parseFloat(x).toFixed(precision);
export const percentage = (x, precision = 2) => x && `${Number.parseFloat(x).toFixed(precision)}%`;

export default function ClientDefaultPricing( ) {
  const [loading, setLoading] = useState(true);

  const clientAccount = useSelector(state => state.clientAccount);
  const clientDefaultPricing = useSelector(state => state.clientDefaultPricing);

  useEffect(() => {
    const loadData = async () => {
      await dispatch(getClientDefaultPricing(clientAccount.id));
      setLoading(false);
    };

    loadData();
  }, [clientAccount]);

  const dispatch = useDispatch();

  const handleSave = useCallback((e) => {
    e.preventDefault();
    dispatch(saveClientDefaultPricing(clientAccount.id, clientDefaultPricing));
  }, [clientAccount, clientDefaultPricing]);

  const handleChange = (changes) => dispatch(updateClientDefaultPricing(changes));

  return (
    <React.Fragment>
      <h3 className="flex-dont-grow">Default pricing</h3>
      {!loading && <div className="card flex-grow new-client-language-pair-pricing">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <form className="new_client_language_pair_pricing" id="new_client_language_pair_pricing">
                    <div className="create-client-language-pair-pricing-wrapper">
                      <div className="client-language-pair-pricing-header" />
                      <div className="client-language-pair-pricing-header" />
                      <div className="client-language-pair-pricing-header">Sales margin</div>

                      <div className="client-language-pair-pricing-value" />
                      <div className="client-language-pair-pricing-value" />

                      <div className="client-language-pair-pricing-value">
                        <SalesMarginField
                          name="salesMargin"
                          value={clientDefaultPricing.salesMargin}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-header">Quality</div>
                      <div className="client-language-pair-pricing-header">Price $</div>
                      <div className="client-language-pair-pricing-header">Price €</div>

                      <div className="client-language-pair-pricing-label">Postediting</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingUsd"
                          value={clientDefaultPricing.pricePerWordPosteditingUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingEur"
                          value={clientDefaultPricing.pricePerWordPosteditingEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Postediting AI</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingAiUsd"
                          value={clientDefaultPricing.pricePerWordPosteditingAiUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordPosteditingAiEur"
                          value={clientDefaultPricing.pricePerWordPosteditingAiEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Translation</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordTranslationUsd"
                          value={clientDefaultPricing.pricePerWordTranslationUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordTranslationEur"
                          value={clientDefaultPricing.pricePerWordTranslationEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Localization</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordLocalizationUsd"
                          value={clientDefaultPricing.pricePerWordLocalizationUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordLocalizationEur"
                          value={clientDefaultPricing.pricePerWordLocalizationEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Expert</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordExpertUsd"
                          value={clientDefaultPricing.pricePerWordExpertUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordExpertEur"
                          value={clientDefaultPricing.pricePerWordExpertEur}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-label">Machine</div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordMachineUsd"
                          value={clientDefaultPricing.pricePerWordMachineUsd}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="client-language-pair-pricing-value">
                        <PriceField
                          name="pricePerWordMachineEur"
                          value={clientDefaultPricing.pricePerWordMachineEur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <input
                          type="submit"
                          name="commit"
                          value="Save"
                          className="btn btn-primary"
                          data-disable-with="Save"
                          onClick={handleSave}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </React.Fragment>
  );
}
