// Props summary
// title: What the header containing the count is going to say
// count: count of selected items
// buttonCopy: copy of the button
// callback: what happens on button click

import React from "react";

export class FloatingCornerPopup extends React.Component {
  render() {
    const { children, hidden } = this.props;

    return (
      <div className={`popups__floating_selector ${hidden ? "popups__floating_selector--hidden" : ""}`}>
        <div className="card">
          <div className="card-body">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default FloatingCornerPopup;
