import { gql } from "graphql-request";

export const clientAccountCreateMutation = gql`
  mutation clientAccountCreate($input: ClientAccountCreateInput!) {
    clientAccountCreate(input: $input) {
      clientAccount{
        id
      }
      errors
      success
    }
  }
`;
