import React from "react";
import { connect } from "react-redux";

import AugmentedMultiSelect from "../_common/AugmentedMultiSelect";
import SimpleSelect from "../_common/SimpleSelect";
import { postDialectsUpdate } from "./_actionsAndReducers";

export class DialectsHandler extends React.Component {
  constructor(props) {
    super(props);

    let advancedOptionsLanguages = {};

    Object.entries(props.clientDialects).forEach(([masterCode,language]) => {
      if (typeof language.default === "string") {
        advancedOptionsLanguages[masterCode] = language.default;
        return;
      }
      advancedOptionsLanguages[masterCode] = language.allowed[0];
    });

    this.state ={
      showAdvancedOptions: false,
      advancedOptionsLanguages
    };
  }

  changeAdvancedOptionsLanguage(masterCode, value) {
    this.setState({advancedOptionsLanguages: { ...this.state.advancedOptionsLanguages, [masterCode]: value }});

    if (typeof this.props.clientDialects[masterCode].default == "string") {
      this.selectAdvancedOptionConfiguration(masterCode, value);
    }
  }

  selectAdvancedOptionConfiguration(masterCode, newDefault){
    const clientDialects = {
      ...this.props.clientDialects,
      [masterCode]: {
        ...this.props.clientDialects[masterCode],
        default: newDefault,
      }
    };

    this.props.postDialectsUpdate(clientDialects);
  }

  selectDialects(dialectCodes) {
    const oldClientDialects = this.props.clientDialects;
    let advancedOptionsLanguages = { ...this.state.advancedOptionsLanguages };

    let clientDialects = dialectCodes.reduce((acc, fullDialectCode)=>{
      const [masterCode] = fullDialectCode.split("_");

      if (!acc[masterCode]) {
        acc[masterCode] = {
          default: oldClientDialects[masterCode] ? oldClientDialects[masterCode].default : false,
          allowed: [],
        };
      }

      if (!advancedOptionsLanguages[masterCode]) {
        advancedOptionsLanguages[masterCode] = fullDialectCode;
      }

      acc[masterCode].allowed.push(fullDialectCode);

      return acc;
    } ,{});

    this.setState({advancedOptionsLanguages});

    // Correct default when removed from allowed
    Object.values(clientDialects).forEach(language=> {
      if (typeof language.default == "string" && !language.allowed.includes(language.default) ) language.default = false;
    });

    this.props.postDialectsUpdate(clientDialects);
  }

  render() {
    const {
      dialects,
      flattenedAllowedFullCodeDialects,
      clientDialects,
      clientMasterLanguages,
    } = this.props;

    const {
      showAdvancedOptions,
      advancedOptionsLanguages,
    } = this.state;

    return (
      <>
        <label className="col-3 mb-1 font-weight-bold">Allowed dialects:</label>
        <div className="col-9">
          <AugmentedMultiSelect
            name="masterLanguage"
            options={dialects.map(({code, name})=>[name, code])}
            value={flattenedAllowedFullCodeDialects}
            onChange={dialectCodes=>this.selectDialects(dialectCodes)}
          />
          {flattenedAllowedFullCodeDialects.length > 0 && !showAdvancedOptions &&
            <a href="#" onClick={()=>this.setState({showAdvancedOptions: true})}>
              <small className="mb-1"><span className="ticon-plus"/> Show advanced settings for master languages</small>
            </a>
          }

          {flattenedAllowedFullCodeDialects.length > 0 && showAdvancedOptions &&
            <>
              {
                clientMasterLanguages.map(masterCode => {
                  return (
                    <small className="form-check form-check-inline form-inline font-size-small mb-3" key={masterCode}>
                      <label className="form-check-label font-weight-bold mr-3">{masterCode.toUpperCase()} code:</label>
                      <input onChange={()=>this.selectAdvancedOptionConfiguration(masterCode, false)} type="radio" className="form-check-input" id={`forbid-${masterCode}`} name={`forbid-${masterCode}`} checked={clientDialects[masterCode].default === false}/>
                      <label htmlFor={`forbid-${masterCode}`} className="form-check-label mr-4">
                        Forbid
                      </label>
                      <input onChange={() => this.selectAdvancedOptionConfiguration(masterCode, advancedOptionsLanguages[masterCode])} type="radio" className="form-check-input" id={`forbid-${masterCode}-3`} name={`forbid-${masterCode}`} checked={typeof clientDialects[masterCode].default === "string"}/>
                      <label htmlFor={`forbid-${masterCode}-3`} className="form-check-label mr-2">
                        Interpret as
                      </label>
                      <SimpleSelect
                        name={`forbid-${masterCode}-select-language`}
                        value={advancedOptionsLanguages[masterCode]}
                        onChange={e=>this.changeAdvancedOptionsLanguage(masterCode, e.target.value)}
                        className="form-control form-control-sm"
                        options={
                          clientDialects[masterCode].allowed
                            .sort((a, b) => I18n.t(`languages.${a}`).localeCompare(I18n.t(`languages.${b}`)) )
                            .map(code => [I18n.t(`languages.${code}`),code])
                        }
                      />
                    </small>
                  );
                })
              }
              <a href="#" onClick={() => this.setState({ showAdvancedOptions: false })} className="clearfix">
                <small className="mb-1"><span className="ticon-minus" /> Hide advanced settings for master languages</small>
              </a>
            </>
          }
        </div>
      </>
    );
  }
}

export default connect(
  ({ staticData: { dialects }, clientDialects }) => {
    const clientMasterLanguages = Object.keys(clientDialects).sort();

    const flattenedAllowedFullCodeDialects = clientMasterLanguages.reduce((acc, masterLanguageCode) => {
      acc = [...acc, ...clientDialects[masterLanguageCode].allowed];

      return acc;
    }, []);

    return {
      dialects,
      flattenedAllowedFullCodeDialects,
      clientDialects,
      clientMasterLanguages,
    };
  },
  { postDialectsUpdate }
)(DialectsHandler);
