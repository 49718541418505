import React from "react";
import { connect } from "react-redux";

import Card from "../_common/Card";
import { camelizeKeys } from "humps";
import { clientAccountInviteMutation } from "./_gql";
import gqlClient from "../_utils/graphQlRequests";

export class Invitation extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      showInvitation: false,
      message: "",
      clientInvitation: props.initialInvitation,
      sendingInvitation: false,
    };
  }

  sendEmail(e){
    e.preventDefault();

    const {
      userIdToInvite,
      clientAccount,
    } = this.props;

    const input = {
      userId: userIdToInvite,
      clientAccountId: clientAccount.id,
      clientInvitationInput: { message: this.state.message, },
    };

    this.setState({ sendingInvitation: true});

    gqlClient.request(clientAccountInviteMutation, { input })
      .then(({ clientAccountInvite: { clientInvitation } }) => {
        console.log(clientInvitation);
        this.setState({clientInvitation});
      })
      .catch( () => alert("Something went wrong. Sorry :("))
      .finally(() => this.setState({ showInvitation: false, message: "", sendingInvitation: false}));
  }

  render() {
    const {
      showInvitation,
      message,
      clientInvitation,
      sendingInvitation,
    } = this.state;

    const {
      clientEmail
    } = this.props;

    if (!showInvitation) return (
      <div>
        <h5>
          Client onboarding

          <button className="btn btn-sm btn-primary ml-3" onClick={()=>this.setState({showInvitation: true})}>
            {clientInvitation ? "Examine invitation" : "Send invitation"}
          </button>
        </h5>
        {clientInvitation?.acceptedAt &&
          <p><em>This invitation has already been accepted by the client</em></p>
        }
        {clientInvitation && !clientInvitation.acceptedAt &&
          <p><em>Waiting for client invitation acceptance</em></p>
        }
      </div>
    );

    return (
      <form onSubmit={e=>this.sendEmail(e)}>
        <h5>Client onboarding</h5>
        <Card>
          <p>Using this functionality you will send the following email to the main user of this client account so that they can set their password and complete their account settings.</p>
          <p>Have in mind that even though this functionality can be used at any point in time it is meant to be used with new clients</p>
          <div className="d-flex justify-content-center">
            <div className="w-50 font-italic m-4">
              {/* Must replicate: core/.../client_account_mailer/invite.erb */}
              <h3>{I18n.t("mailers.admin_invite.welcome", { email: clientEmail })}</h3>
              <p>{I18n.t("mailers.admin_invite.details1")}</p>
              <p>{I18n.t("mailers.admin_invite.details2")}</p>
              {clientInvitation ?
                <p>{clientInvitation.message}</p>
                :
                <textarea
                  className="w-100 form-control"
                  rows="6"
                  placeholder="This textarea is here so that you can include custom messages for a more personal message.&#10;Please have in mind that it will be shown as a single paragraph between the rest of the copy and that you can choose to leave it blank."
                  onChange={e=>this.setState({message: e.target.value})}
                  value={message}
                ></textarea>
              }
              <button className="btn btn-success btn-lg w-100 mt-3 mb-3" disabled>{I18n.t("mailers.admin_invite.button")}</button>
              <p>{ I18n.t("mailers.common.the_tolq_team") }</p>
            </div>
          </div>

          {!clientInvitation &&
            <button className="btn btn-primary float-right ml-4" disabled={sendingInvitation}>Send onboarding email</button>
          }
          <button type="button" className="btn btn-link float-right" onClick={() => this.setState({ showInvitation: false })}>
            {clientInvitation ? "Close invitation view" : "Cancel"}
          </button>
        </Card>
      </form>
    );
  }
}

export default connect(
  ({ staticData: { clientEmail, initialInvitation, userIdToInvite }, clientAccount }) => ({
    clientEmail,
    userIdToInvite,
    initialInvitation: initialInvitation ? camelizeKeys(initialInvitation) : null,
    clientAccount,
  })
)(Invitation);
