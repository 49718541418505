import React, { useContext } from "react";
import FormCalendar from "../_common/FormCalendar";
import FormSelect from "../_common/FormSelect";
import { Store } from "./Store";
import { updateFilters } from "./_actions";

const types = [
  "currency_change",
  "manual_credit",
  "manual_debit",
  "payment",
  "payment_refund",
  "payout",
  "payout_judge",
  "subscription_payment",
  "unknown",
  "withdrawal",
  "withdrawal_fee",
];

export default function Sidebar() {
  const { state: { filters, account }, dispatch } = useContext(Store);

  return (
    <div className="col-3">
      <div className="card">
        <div className="card-body">
          <FormSelect
            label="Type"
            name="type"
            onChange={e => updateFilters(dispatch, { page: 1, type: e.target.value })}
            value={filters.type || ""}
            options={types}
            includeBlank={true}
            blankOptionLabel="All"
          />

          <FormCalendar
            id="calendar"
            label="Created after"
            callback={(date) => updateFilters(dispatch, { page: 1, createdAfter: date })}
            value={filters.createdAfter || ""}
          />

          <FormCalendar
            id="calendar"
            label="Created before"
            callback={(date) => updateFilters(dispatch, { page: 1, createdBefore: date })}
            value={filters.createdBefore || ""}
          />
        </div>
      </div>
    </div>
  );
}
