import React from "react";

import SimpleSelect from "./SimpleSelect";

const Result = ({ type, options, attribute, value, valueLabel, onChange, placeholder, rows }) => {
  if (type === "checkbox") return (
    <div className="form-check form-check-inline mr-4">
      <input
        className="form-check-input"
        type="radio"
        id={`${attribute}-radio`}
        checked={value}
        onChange={() => onChange(!value)}
      />
      <label className="form-check-label" htmlFor={`${attribute}-radio`}>Yes</label>

      <input
        className="ml-4 form-check-input"
        type="radio"
        id={`${attribute}-radio-2`}
        checked={!value}
        onChange={() => onChange(!value)}
      />
      <label className="form-check-label" htmlFor={`${attribute}-radio-2`}>No</label>
    </div>
  );

  if (type === "select") return (
    <SimpleSelect
      name={`${attribute}-select`}
      value={value}
      options={options}
      onChange={e => {
        if (typeof value === "boolean") {
          onChange(e.target.value === "true");
        } else {
          onChange(e.target.value);
        }
      }}
    />
  );

  if (type == "textarea") return (
    <textarea
      className="form-control"
      type={type}
      value={value}
      id={`${attribute}-text`}
      placeholder={placeholder}
      rows={rows}
      onChange={(e) => onChange(e.target.value)}
    />
  )

  return (
    <input
      className="form-control"
      type={type}
      value={value}
      id={`${attribute}-text`}
      onChange={(e) => onChange(e.target.value)}
    />
  )
};

export class Field extends React.Component {
  render() {
    const {
      label,
    } = this.props;

    const width = this.props.width || 3;

    return (
      <>
        <label className={`col-${width} mb-3 font-weight-bold`}>{label}:</label>
        <div className={`col-${width} mb-3`}>
          <Result {...this.props} />
        </div>
      </>
    );
  }
}

export default Field;
