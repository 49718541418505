import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "./_slices";
import BulkActions from "./BulkActions";
import FormSelect from "../_common/FormSelect";

const paymentMethods = [
  ["Bank transfer", "bank_transfer"],
  ["Credit card", "credit_card"],
];

const paymentStatuses = [
  ["Paid", "true"],
  ["Unpaid", "false"],
];

export default function Sidebar() {
  const filters = useSelector(s => s.filters);
  const dispatch = useDispatch();

  return (
    <div className="col-3">
      <div className="card">
        <div className="card-body">
          <BulkActions />
          <hr />
          <FormSelect
            label="Payment method"
            name="paymentMethod"
            onChange={({ target: { value } }) => dispatch(updateFilters({ paymentMethod: value || null, page: 1 }))}
            value={filters.paymentMethod || ""}
            options={paymentMethods}
            includeBlank={true}
            blankOptionLabel="All"
          />

          <FormSelect
            label="Payment status"
            name="paid"
            onChange={({ target: { value } }) => dispatch(updateFilters({ paid: value ? (value === "true") : null, page: 1 }))}
            value={filters.paid ?? ""}
            options={paymentStatuses}
            includeBlank={true}
            blankOptionLabel="All"
          />
        </div>
      </div>
    </div>
  );
}
