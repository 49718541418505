import React from "react";
import { useSelector } from "react-redux";

import { paymentsSelector } from "./_selectors";
import { purchaseOrderSelector } from "./_reducers";


export default function CreditCardPayments(){
  const payments = useSelector(paymentsSelector);

  if (!payments) return null;

  const transferredPayment = payments.find(payment => (
    ["settled", "partially_refunded", "refunded"].includes(payment.status))
  );

  const { currency } = useSelector(purchaseOrderSelector);

  return (
    <div className="col-4">
      <div className="card">
        <div className="card-body">
          <h5>Credit card payment attempts</h5>
          <p className="text-muted">
            <small><span className="ticon-info mr-1"></span> Credit card payments usually include taxes</small>
          </p>

          <table className="table">
            <thead>
              <tr>
                <th>Stripe ID</th>
                <th>Status</th>
              </tr>
              {payments.map( ({
                status,
                paymentIntentId,
              }) =>(
                <tr key={paymentIntentId} className={status == "failed" ? "text-muted" : ""}>
                  <td>{paymentIntentId}</td>
                  <td>{status}</td>
                </tr>
              ))}
              {!!transferredPayment && (
                <tr>
                  <th>Amount transferred</th>
                  <th>
                    {(transferredPayment.amountInCents / 100).toLocaleString(navigator.language, { currency, style: "currency" })}
                  </th>
                </tr>
              )}
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
}