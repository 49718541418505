import { gql } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/query/react";

import { graphqlBaseQuery } from "../_utils/graphqlBaseQuery";

export const gqlApi = createApi({
  reducerPath: "gql",
  baseQuery:   graphqlBaseQuery(),
  tagTypes:    [ "PromptTemplates" ],
  endpoints:   (builder) => ({
    getPromptTemplates: builder.query({
      transformResponse: response => response?.machineTranslatorPrompts,
      query:             input => ({
        input,
        body: gql`
          query promptTemplates {
            machineTranslatorPrompts {
              id
              default
              translationContext
              currencyString
              formality
              hyphensAndQuotes
              keepHtmlIntact
              punctuation
              targetAudience
              toneOfVoice
              unitOfMeasure
              general
              properNamesTitles
              prompt
              defaultPrompt
              refinementPrompt
              defaultRefinementPrompt
              refinementPrompt
              name
            }
          }
        `
      })
    }),
    createMachineTranslatorPromptTemplate: builder.mutation({
      invalidatesTags: [ "PromptTemplates" ],
      query:           input => ({
        input,
        body: gql`
          mutation machineTranslatorPromptCreate($input: PromptTemplateCreateInput!) {
            machineTranslatorPromptTemplateCreate(input: $input) {
              errors
              success
            }
          }
        `
      })
    }),
    updateMachineTranslatorPromptTemplate: builder.mutation({
      invalidatesTags: [ "PromptTemplates" ],
      query:           input => ({
        input,
        body: gql`
          mutation machineTranslatorPromptUpdate($input: PromptTemplateUpdateInput!) {
            machineTranslatorPromptTemplateUpdate(input: $input) {
              errors
              success
            }
          }
        `
      })
    }),
    defaultMachineTranslatorPromptTemplate: builder.mutation({
      invalidatesTags: [ "PromptTemplates" ],
      query:           input => ({
        input,
        body: gql`
          mutation machineTranslatorPromptDefault($input: PromptDefaultInput!) {
            machineTranslatorPromptDefault(input: $input) {
              errors
              success
            }
          }
        `
      })
    }),
  })})

export const {
  useGetPromptTemplatesQuery,
  useCreateMachineTranslatorPromptTemplateMutation,
  useUpdateMachineTranslatorPromptTemplateMutation,
  useDefaultMachineTranslatorPromptTemplateMutation,
} = gqlApi;
