import React from "react";
import { connect } from "react-redux";

import TableQualityReview from "./TableQualityReview";
import { MODES } from "./_constants";

export class TableList extends React.Component {
  render() {
    const { qualityReviews, mode } = this.props;

    return (
      <table className="table thead-dark table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th>Task</th>
            {MODES.emitted_quality_reviews == mode ?
              null :
              <th>Judge</th>
            }
            <th>Tr. verdict</th>
            <th>Pr. verdict</th>
            <th>Justification</th>
            <th>Updated</th>
            <th>Created</th>
            <th className="text-center">Confirmed</th>
            <th className="text-center">Admin Reviewed</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {qualityReviews.map(qualityReview => <TableQualityReview key={qualityReview.id} qualityReview={qualityReview} />)}
        </tbody>
      </table>
    );
  }
}

export default connect(
  ({ qualityReviews, mode }) => ({ qualityReviews, mode }),
)(TableList);
