import React from "react";

export default function Breadcrumbs({ links }) {
  return (
    <div className="top_controls mt-3">
      <ol className="breadcrumb ml-3">
        <li className="breadcrumb-item">
          <a href="/">Dashboard</a>
        </li>
        {links.map((link) =>
          <li key={link.url} className="breadcrumb-item">
            <a href={link.url}>{link.name}</a>
          </li>
        )}
      </ol>
    </div>
  );
}
