import React from "react";
import { useDispatch, useSelector } from "react-redux";

import EditableField from "./EditableField";
import gqlClient from "../_utils/graphQlRequests";
import {
  userDeleteMutation,
  userResetOtpLimitMutation,
} from "./_gql";
import {
  confirmEmail,
  updateEmail,
} from "./_actions";
import { userSelector } from "./_reducers";
import ResetPassword from "./ResetPassword";

const clientsUrl = () => {
  if (window.location.host.endsWith(".com")) {
    return "https://clients.tolq.com";
  } else if (window.location.host.endsWith(".co")) {
    return "https://clients.tolq.co";
  } else {
    return "";
  }
};

export default function User() {
  const user = useSelector(userSelector);

  const dispatch = useDispatch();

  const deleteUser = async () => {
    if (confirm(`Are you sure you want to remove ${user.email} from the database?`)) {
      await gqlClient.request(userDeleteMutation, { input: { userId: user.id } });
      window.location.replace("/users");
    }
  };

  const resetOtpLimit = () =>
    gqlClient.request(userResetOtpLimitMutation, { input: { userId: user.id } });



  return (
    <div className="User">
      <div className="container-fluid">
        <div className="card mb-4">
          <div className="card-body">
            <div className="d-flex">
              {user.email && <div className="mr-4">
                <img src={`//www.gravatar.com/avatar/${user.gravatarEmailHash}?d=mm&s=150`} />
              </div>}

              <div className="w-100">
                <a
                  href={`${clientsUrl()}/become/user/${user.id}`}
                  className="btn btn-primary btn-lg pull-right"
                  target="_blank"
                  rel="noreferrer"
                >
                  Become user
                </a>

                <h1>
                  {`${user.firstName || ""} ${user.lastName || ""}`} <small
                    className="text-muted">{user.name}</small>
                </h1>

                <EditableField
                  name="Email"
                  value={user.email}
                  onSave={email => dispatch(updateEmail(user, email))}
                />

                {!user.unconfirmedEmail && user.confirmedAt ?
                  <p className="text-muted font-italic"> This email is <strong>confirmed</strong>. </p>
                  : user.unconfirmedEmail ?
                    <p className="text-muted font-italic">
                      The user is trying to change their email to <strong>{user.unconfirmedEmail}</strong>.
                      <button className="btn btn-sm btn-success ml-3" onClick={() => dispatch(confirmEmail(user))} >
                        Confirm email change
                      </button>
                    </p>
                    :
                    <p className="text-muted font-italic">
                      This email is <strong>not confimed</strong>.
                      <button className="btn btn-sm btn-success ml-3" onClick={() => dispatch(confirmEmail(user))} >
                        Confirm email
                      </button>
                    </p>
                }
              </div>
            </div>
          </div>
        </div>

        {user.maxLoginAttemptsReached && <div className="row">
          <div className="col">
            <div className="alert alert-danger">
              <a
                className="text-error btn btn-danger pull-right mt-4"
                onClick={resetOtpLimit}
              >
                reset 2FA limit
              </a>

              <h3 className="alert-heading">Reset 2FA limit</h3>

              <p>The user exceeded the limit of 2FA attempts. Use the button to reset the limit.</p>
            </div>
          </div>
        </div>}

        <ResetPassword />

        <div className="row">
          <div className="col">
            <div className="alert alert-danger">
              <a
                className="text-error btn btn-danger pull-right mt-4"
                onClick={deleteUser}
              >
                delete user
              </a>

              <h3 className="alert-heading">Delete user</h3>

              <p>Use the button to delete the user <strong>forever</strong>. You will get a confirmation dialog first. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
