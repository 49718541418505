import { useState } from "react";

export default function useLSAttributesConfiguration(name, initialValue) {
  const unparsedLSAttributes = localStorage.getItem(`manual_operations__${name}`),
        localStorageAttributes = unparsedLSAttributes ? JSON.parse(unparsedLSAttributes) : undefined;

  const [ state, setState ] = useState(localStorageAttributes || initialValue);

  function setStateWithLS(visibleAttributes) {
    localStorage.setItem(`datatable_visible_attrs__${name}`, JSON.stringify(visibleAttributes));
    setState(visibleAttributes);
  }

  return {
    visibleAttributes:    state,
    setVisibleAttributes: setStateWithLS,
  };
}