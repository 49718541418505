import {
  userConfirmEmailMutation,
  userDeleteMutation,
  userResetOtpLimitMutation,
  userResetPasswordMutation,
  userUpdateMutation,
} from "./_gql";
import gqlClient from "../_utils/graphQlRequests";

export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

export const setUser = (user) => ({type: SET_USER, user});

export const updateUser = (changes) => ({ type: UPDATE_USER, changes });

export const confirmEmail = (user) => async (dispatch) => {
  const {
    userConfirmEmail: {
      success,
      user: {
        confirmedAt,
        email,
        unconfirmedEmail,
      },
    }
  } = await gqlClient.request(userConfirmEmailMutation, { input: { userId: user.id } });

  if (success) {
    dispatch(
      updateUser({ confirmedAt, email, unconfirmedEmail })
    );
  }
};

export const updateEmail = (user, email) => async (dispatch) => {
  const {
    userUpdate: {
      success,
      errors,
    }
  } = await gqlClient.request(userUpdateMutation, {
    input: {
      userId: user.id,
      userInput: { email }
    }
  });

  if (success) dispatch(updateUser({ email }));

  return { success, errors };
};