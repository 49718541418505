import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducers from "./_reducers";
import ClientDefaultPricing from "./ClientDefaultPricing";
import ClientLanguagePairPricingList from "./ClientLanguagePairPricingList";

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  reducers,
  CLIENT_LANGUAGE_PAIR_PRICINGS_DATA,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <ClientDefaultPricing />
    <ClientLanguagePairPricingList
      sourceLanguageCodes={STATIC_DATA.sourceLanguageCodes}
      targetLanguageCodes={STATIC_DATA.targetLanguageCodes}
    />
  </Provider>,
  document.getElementById("client-language-language-pair-pricings"),
);
