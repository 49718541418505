import React, { useContext } from "react";
import { Store } from './Store';
import TableSortableHeader from "../_common/TableSortableHeader";
import { updateFilters } from "./_actions";

const headers = [
  { attribute: "id",                sortable: true,  label: "ID" },
  { attribute: "email",             sortable: true },
  { attribute: "amount",            sortable: true, label: "Amount" },
  { attribute: "currency",          sortable: true },
  { attribute: "created_at",        sortable: true, label: "Withdrawal Date" },
  { attribute: "paid",              sortable: true, label: "Withdrawal Completed" },
  { attribute: "withdrawal_method", sortable: false, label: "Withdrawal Method" }
];

export default function Headers(props) {
  const {
    state: {
      filters: { sortAttribute, sortOrder }
    },
    dispatch
  } = useContext(Store);

  const requestResults = (sortOrder, sortAttribute) =>
    updateFilters(dispatch, { page: 1, sortOrder, sortAttribute });

  return (
    <TableSortableHeader  {...{ sortAttribute, sortOrder, headers, requestResults }} />
  );
}
