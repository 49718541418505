import React from "react";

import { useRecreateInvoiceMutation } from "./_api";

export default function Regenerate({ id }){
  const [recreate, { isLoading, error }] = useRecreateInvoiceMutation();

  const handleRegenerateClick = e => {
    e.preventDefault();
    recreate({ input: { invoiceId: parseInt(id) } });
  };

  if (error) console.error(error);

  if (isLoading) return (
    <span className="text-muted">Loading...</span>
  );

  return (
    <button
      className="btn btn-outline-primary btn-sm"
      onClick={handleRegenerateClick}
    >
      Regenerate
    </button>
  );
}
