import React from "react";
import { connect } from "react-redux";

import { requestFiltersUpdateAndResetPage } from "../_common/filtered_lists/_actions";
import FormSelect from "../_common/FormSelect";
import SimpleSelect from "../_common/SimpleSelect";
import FormCalendar from "../_common/FormCalendar";

export class Sidebar extends React.Component {
  render() {
    const {
      className,
      filters,
      clientsWithOrders,
      sourceLanguageCodes,
      targetLanguageCodes,
      statuses,
      deadlines,
      requestFiltersUpdateAndResetPage
    } = this.props;

    return (
      <div className={className}>
        <div className="card">
          <div className="card-body">

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Search</label>
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type in your search"
                  name="search"
                  value={filters.search || ""}
                  onChange={(e) => requestFiltersUpdateAndResetPage({ search: e.target.value })}
                />
                <div className="input-group-append"><span className="input-group-text ticon-search"></span></div>
              </div>
              <small id="emailHelp" className="form-text text-muted">Exact matches ids and partial matches project name.</small>
              {!filters.status || filters.status == "completed" ?
                <small className="form-text text-danger"><span className="ticon-warning" /> Searching names for completed tasks is too expensive, only id search will work.</small>
                : null
              }
            </div>

            <FormSelect
              label="Status"
              name="status"
              onChange={e => requestFiltersUpdateAndResetPage({ status: e.target.value })}
              value={filters.status || ""}
              options={statuses}
              includeBlank={true}
              blankOptionLabel="All"
            />

            <FormSelect
              label="Client"
              name="clientId"
              onChange={e => requestFiltersUpdateAndResetPage({ clientId: e.target.value })}
              value={filters.clientId || ""}
              options={clientsWithOrders}
              includeBlank={true}
              blankOptionLabel="All"
            />

            <div className="form-group">
              <label htmlFor="">Language pair</label>
              <div className="container-fluid">
                <div className="row">
                  <SimpleSelect
                    className="col form-control"
                    name="sourceLanguageCode"
                    onChange={e => requestFiltersUpdateAndResetPage({ sourceLanguageCode: e.target.value })}
                    value={filters.sourceLanguageCode || ""}
                    options={sourceLanguageCodes}
                    includeBlank={true}
                    blankOptionLabel="Any"
                  />
                  <SimpleSelect
                    className="col form-control"
                    name="targetLanguageCode"
                    onChange={e => requestFiltersUpdateAndResetPage({ targetLanguageCode: e.target.value })}
                    value={filters.targetLanguageCode || ""}
                    options={targetLanguageCodes}
                    includeBlank={true}
                    blankOptionLabel="Any"
                  />
                </div>
              </div>
            </div>

            <FormCalendar
              id="calendar"
              label="Created after"
              callback={(date) => requestFiltersUpdateAndResetPage({ createdAfter: date })}
              value={filters.createdAfter || ""}
            />

            <FormCalendar
              id="calendar"
              label="Completed after"
              callback={(date) => requestFiltersUpdateAndResetPage({ completedAfter: date })}
              value={filters.completedAfter || ""}
            />

            <FormSelect
              label="Deadlines"
              name="deadlineSet"
              onChange={e => requestFiltersUpdateAndResetPage({ deadlineSet: e.target.value })}
              value={filters.deadlineSet || ""}
              options={deadlines}
              includeBlank={true}
              blankOptionLabel="Any"
            />

          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ filters, staticData: { clientsWithOrders, sourceLanguageCodes, targetLanguageCodes, statuses, deadlines } }) => ({
    filters,
    clientsWithOrders,
    sourceLanguageCodes,
    targetLanguageCodes,
    statuses,
    deadlines
  }),
  { requestFiltersUpdateAndResetPage }
)(Sidebar);
