import React from "react";
import { camelize } from "humps";

function sortingIcon(key, cSortAttribute, sortOrder) {
  if (key != cSortAttribute) return "ticon-up-down";
  if (sortOrder == "desc") return "ticon-down";
  return "ticon-up";
}

export default function THead(props) {
  const { 
    attributeCb,
    children,
    className,  
    keys,
    labels,
    sortableKeys,
    sortAttribute,
    sortOrder ,
  } = props;

  const columnWidths = props.columnWidths || {};

  if (!keys) return null;        

  const cSortableKeys = sortableKeys?.map(k => camelize(k)),
        cSortAttribute = sortAttribute ? camelize(sortAttribute) : null;  

  return (
    <thead className={className}>
      <tr>
        {keys.map((key) => (
          <th key={`thead-th-${key}`} onClick={attributeCb}>
            <div style={{ width: columnWidths[key] }} />
            <div className="d-flex justify-content-between align-items-center">
              <span className="truncate_line">{labels(key)}</span>
              {sortableKeys && cSortAttribute && sortOrder && cSortableKeys.includes(key) ?
                <span className={`ml-2 ${sortingIcon(key, cSortAttribute, sortOrder)}`} />
                : null
              }
            </div>
          </th>
        ))}
        {children}
      </tr>
    </thead>
  );
}