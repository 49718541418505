import {
  SUPPORTED_HTML_ENTITIES_HASH,
  SUPPORTED_HTML_ENTITIES,
  CHARS_HTML_ENTITY_ENCODABLE_HASH,
  CHARS_HTML_ENTITY_ENCODABLE,
} from "./constants";

// String conversion
// ====================================================================================
export const decodeHtmlEntities = (text) => text.replace(
  RegExp(SUPPORTED_HTML_ENTITIES.join("|"), "gi"),
  (match) => SUPPORTED_HTML_ENTITIES_HASH[match] || match
);

export const encodeHtmlEntities = (text) => text.replace(
  RegExp(CHARS_HTML_ENTITY_ENCODABLE.join("|"), "gi"),
  (match) => CHARS_HTML_ENTITY_ENCODABLE_HASH[match] || match
);
