import React, { useState } from "react";

export default function EditableShortText({ name, value, onSave, emptyValue = "N/A" }) {
  const [newValue, setNewValue] = useState(value || "");
  const [editMode, setEditMode] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    onSave(newValue);
    setEditMode(false);
  };

  const onCancel = e => {
    e.preventDefault();
    setEditMode(false);
    setNewValue(value || "");
  };

  return (
    <>
      <dt>
        {name}
        {!editMode &&
          <a
            href="#"
            className="my-0 ml-2 ticon-edit"
            onClick={() => setEditMode(true)}
          />
        }
      </dt>
      {editMode ?
        <dd>
          <form className="form-group" onSubmit={onSubmit}>
            <input
              className="form-control form-control-sm my-2"
              type="text"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
            <button type="submit" className="btn btn-sm btn-success pull-right ml-2" >
              Save
            </button>
            <button className="btn btn-sm btn-outline-primary pull-right" onClick={onCancel} >
              Cancel
            </button>
          </form>
        </dd>
        :
        <dd>
          {newValue || <span className="text-muted">{emptyValue}</span>}
        </dd>}
    </>
  );
}
