import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";

import gqlClient from "../_utils/graphQlRequests";
import Breadcrumbs from "../_common/Breadcrumbs";
import User from "./User";
import { getUserQuery } from "./_gql";
import { setUser } from "./_actions";

export default function UserPage() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  // NOTE: this could be migrated to some generic and global solution
  const userId = useMemo(() => {
    const urlMatches = document.URL.match(/users\/([0-9]+)/);
    return urlMatches[1];
  }, [document.URL]);

  useEffect(() => {
    const loadData = async () => {
      const { user } = await gqlClient.request(getUserQuery, { id: userId });
      dispatch(setUser( user));
      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <div className="UserPage">
      <Breadcrumbs
        links={[
          { name: "Users", url: "/users" },
          { name: userId, url: `/users/${userId}` }
        ]}
      />
      {!loading && <User />}
    </div>
  );
}
