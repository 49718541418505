import { combineReducers } from "redux";

import { UPDATE_TRAINING_TEXTS, UPDATE_FILTERS } from "./_actions";

export const trainingTexts = (state = {}, action) => {
  if (action.type == UPDATE_TRAINING_TEXTS) return action.trainingTexts;
  return state;
};

export const withTrainingTotalCount =  (state = {}, action) => {
  if (action.type == UPDATE_TRAINING_TEXTS) return action.withTrainingTotalCount;
  return state;
};

export const withoutTrainingTotalCount =  (state = {}, action) => {
  if (action.type == UPDATE_TRAINING_TEXTS) return action.withoutTrainingTotalCount;
  return state;
};

export const totalPages = (state = {}, action) => {
  if (action.type == UPDATE_TRAINING_TEXTS) return action.totalPages;
  return state;
};


export const staticData = (state = {}) => state;

export const defaultFiltersValue = { number: 0, size: 25, slc: "", tlc: "", trainingEnabled: "", tab: "" };

export const filters = (state = defaultFiltersValue, action) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

export default combineReducers({
  trainingTexts,
  withTrainingTotalCount,
  withoutTrainingTotalCount,
  totalPages,
  filters,
  staticData
});