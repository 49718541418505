import React from "react";
import moment from "moment";

const unitFor = {
  year: "day",
  month: "day",
  week: "day"
};

const stepSizeFor = {
  year: 30,
  month: 2,
  week: 1
};

function createChart(values, context, withLegends, timeRange = "week", ticks = {}) {
  let chart = new Chart(context, {
    type: "line",
    data: {
      datasets: values.map(v => ({
        label: v.label,
        data: v.data.map(({ x, y }) => ({ x: moment(x), y })),
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: v.color,
        lineTension: 0,
        borderWidth: 2,
        radius: 1,
      }))
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: withLegends,
        position: "bottom",
      },
      scales: {
        yAxes: [{
          ticks: ticks
        }],
        xAxes: [{
          type: "time",
          distribution: "linear",
          time: {
            min: moment().subtract(1, timeRange),
            max: moment(),
            unit: unitFor[timeRange],
            stepSize: stepSizeFor[timeRange],
          }
        }],
      }
    }
  });

  return chart;
}

export class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  componentDidMount() {
    let { values, withLegends, timeRange, ticks } = this.props;

    if (values.length) this.chart = createChart(values, this.canvas.current, withLegends, timeRange, ticks);
  }

  componentDidUpdate({ values: oldValues }) {
    let { values, withLegends, timeRange, ticks } = this.props;

    // Chart.js update() method cannot be used in this instance because it only updates data changes,
    // and not options changes
    if (oldValues != values) {
      if (this.chart) this.chart.destroy();
      this.chart = createChart(values, this.canvas.current, withLegends, timeRange, ticks);
    }
  }

  componentWillUnmount() {
    if (this.chart) this.chart.destroy();
  }

  render() {
    const { height } = this.props;

    return (
      <div className="chart__line_chart__wraper" style={{ height }}>
        <canvas ref={this.canvas} ></canvas>
      </div>
    );
  }
}

export default LineChart;
