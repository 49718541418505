import React from "react";
import { debounce } from "underscore";

import { get } from "../_utils/humpyAjax";
import PropTypes from "prop-types";
import { KEYCODES, getKeyCodeName } from "../_utils/keycode";

// REQUIRED PROPS DETAILS
// url (string): url where the component will make a debounced "get" to get the possible matches
// searchParamKey (string): name of the param that will be sent with the text introduced in the input
// responseConverter (function): method to ensure that the response array elements have at least {id, name} attributes
//                               can also be used to filter out data if needed
// selectionCallback (function): callback to call once one of the results is selected.

export class AutocompleteInput extends React.Component {
  state = {
    results: [],
    text: "",
    canShowResults: true,
    activeResult: 0,
  };

  debouncedSearch = debounce(this.search, 200)

  search(text) {
    const {responseConverter, url, searchParamKey} = this.props;

    if (!text.length) return this.setState({results: []});

    get(url, { [searchParamKey]: text } )
      .then(responseConverter)
      .then(results => this.setState({results}));
  }

  selectResult(result) {
    this.setState({text: result.name, canShowResults: false});
    this.props.selectionCallback(result);
  }

  changeText = (e) => {
    const text = e.target.value;

    this.setState({ text, canShowResults: true });
    this.debouncedSearch(text);
  }

  handleShortcuts = (e) => {
    const code = getKeyCodeName(e),
          { enter, up, down } = KEYCODES,
          codesPreventingDefault = [enter, up, down];

    const {results, activeResult} = this.state;

    if (codesPreventingDefault.includes(code)) e.preventDefault();

    switch (code) {
      case enter:
        return this.selectResult(results[activeResult]);
      case up:
        return activeResult - 1 >= 0 ?
          this.setState({ activeResult: activeResult - 1 }) : null;
      case down:
        return activeResult + 1 < results.length ?
          this.setState({ activeResult: activeResult + 1 }) : null;
    }
  }

  render() {
    const { results, text, canShowResults, activeResult } = this.state;
    const { className, placeholder } = this.props;

    return (
      <div className={className} style={{position: "relative"}}>
        <input
          className="form-control"
          type="text"
          value={text}
          onChange={this.changeText}
          onKeyDown={this.handleShortcuts}
          placeholder={placeholder}
        />
        {canShowResults && results.length ?
          <ul className="list-group" style={{position:"absolute", width: "100%", zIndex: "10000"}}>
            {results.map((result, index) => (
              <li
                className={`list-group-item ${index == activeResult ? "active" : ""}`}
                style={{cursor: "pointer"}}
                key={result.id}
                onClick={()=>this.selectResult(result)}
                onMouseEnter={()=>this.setState({activeResult: index})}
              >
                {result.name}
              </li>
            ))}
          </ul>
          : null
        }
      </div>
    );
  }
}

AutocompleteInput.proptypes = {
  url: PropTypes.string.isRequired,
  searchParamKey: PropTypes.string.isRequired,
  responseConverter: PropTypes.func.isRequired,
  selectionCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default AutocompleteInput;


