// lpString format: "en-es"
export const languagePairDescriptionFromCodes = (lpString) => {
  return lpString.split("-")
                 .map( languageCode => I18n.t("languages")[languageCode] )
                 .join(" to ");
};

export const languagePairFromModel = ({ sourceLanguageCode, targetLanguageCode }) => {
  if (!sourceLanguageCode || !targetLanguageCode) throw ("Missing codes to form the language pair");
  return languagePair(sourceLanguageCode, targetLanguageCode);
};

export const languagePairFromArray = ([ sourceLanguageCode, targetLanguageCode ]) => {
  if (!sourceLanguageCode || !targetLanguageCode) throw ("Missing codes to form the language pair");
  return languagePair(sourceLanguageCode, targetLanguageCode);
};

export const languagePairFromString = (str) => {
  const [sourceLanguageCode, targetLanguageCode] = str.split("-");

  if (!sourceLanguageCode || !targetLanguageCode) throw ("Missing codes to form the language pair");
  return languagePair(sourceLanguageCode, targetLanguageCode);
};

export function languagePair(sourceLanguageCode, targetLanguageCode) {
  return {
    toString: `${sourceLanguageCode}-${targetLanguageCode}`,
    toArray: [sourceLanguageCode, targetLanguageCode],
    toHash: { sourceLanguageCode, targetLanguageCode },
    toHuman: `${I18n.t(`languages.${sourceLanguageCode}`)} - ${I18n.t(`languages.${targetLanguageCode}`)}`
  };
}