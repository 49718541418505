export const REQUEST_FILTERS_UPDATE = "REQUEST_FILTERS_UPDATE";
export const UPDATE_RESULTS = "UPDATE_RESULTS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const LOADING = "LOADING";
export const NOT_LOADING = "NOT_LOADING";
export const SELECT_ALL_RESULTS = "SELECT_ALL_RESULTS";
export const DESELECT_ALL_RESULTS = "DESELECT_ALL_RESULTS";
export const TOGGLE_RESULT_SELECTION = "TOGGLE_RESULT_SELECTION";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const requestFiltersUpdate = (filters) => (dispatch, getState) => {
  let state = getState();
  dispatch({ type: REQUEST_FILTERS_UPDATE, filters: { ...state.filters, ...filters } });
};

export const requestFiltersUpdateAndResetPage = (filters) => requestFiltersUpdate({...filters, page: 1});

export const refreshResults = () => requestFiltersUpdate({});

export const changePage = (page) => requestFiltersUpdate({ page });

export const changePageAndSize = (page, size) => requestFiltersUpdate({ page, size });

// otherChanges is normally undefined or nil and exists to allow filtered lists extension
// by just adding new reducers that access other variable states from there.
export const updateResults = (results, totalCount, totalPages, otherChanges) => ({
  type: UPDATE_RESULTS, results, totalCount, totalPages, otherChanges
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS, filters
});

export const loading = () => ({ type: LOADING });

export const notLoading = () => ({ type: NOT_LOADING });

export const selectAllResults = (selectedResults) => ({type: SELECT_ALL_RESULTS, selectedResults});

export const deselectAllResults = () => ({ type: DESELECT_ALL_RESULTS });

export const toggleResultSelection = (result) => (dipatch, getState) => {
  let currentSelectedResults = getState().selectedResults,
      selectedResults;

  if (currentSelectedResults.includes(result)) {
    selectedResults = currentSelectedResults.filter( r => r != result);
  } else {
    selectedResults = [...currentSelectedResults, result];
  }

  dipatch({type: TOGGLE_RESULT_SELECTION, selectedResults });
};

// message = {type, message}
export const addMessage = (message) => ({
  type: ADD_MESSAGE, message
});

// message = {type, message}
export const removeMessage = (index) => ({
  type: REMOVE_MESSAGE, index
});