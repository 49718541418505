import React from "react";
import { connect } from "react-redux";
import PieChart from "./PieChart";

const colors = {
  proofreading: "#4A90E2",
  translation: "#2ECC71"
};

export class TaskTypesChart extends React.Component {
  render() {
    const { taskTypesDistribution, tasksCount } = this.props;

    return (
      <PieChart
        id="task-types"
        title="Task types"
        count={tasksCount}
        values={Object.keys(taskTypesDistribution).map(key => ({
          label: key,
          data: taskTypesDistribution[key],
          color: colors[key]
        }))}
      />
    );
  }
}

export default connect(
  ({ staticData: { taskTypesDistribution, tasksCount } }) => ({ taskTypesDistribution, tasksCount })
)(TaskTypesChart);
