import React from "react";

import { useDefaultMachineTranslatorPromptTemplateMutation } from "./_api";

const AiTemplate = ({ template, refetch }) => {
  const [defaultMachineTranslatorPromptTemplate] = useDefaultMachineTranslatorPromptTemplateMutation();

  const prompt = () => {
    const prompt = template.propmt || template.defaultPrompt;
    if (prompt?.length > 100) {
      return `${prompt.slice(0, 100)}...`;
    }
    return prompt;
  }

  const makeDefault = () => {
    if (confirm(`Are you sure you want to make template ${template.id} default`)) {
      defaultMachineTranslatorPromptTemplate({ input: { promptId: template.id }}).then(() => {
        refetch();
      })
    }
  }

  return (
    <tr>
      <td>
        { template.id }
      </td>
      <td>
        { template.name }
      </td>
      <td>
        { prompt() }
      </td>
      <td>
        { template.default ? 'True' : 'False' }
      </td>
      <td>
        <a href={`/ai/${template.id}/edit`}>
          <button className="btn btn-success mr-2">Edit</button>
        </a>
        <a href={`/ai/${template.id}`}>
          <button className="btn btn-primary mr-2">Metrics</button>
        </a>
        {
          !template.default && <button onClick={() => makeDefault()} className="btn btn-danger">Make default</button>
        }
      </td>
    </tr>
  );
}

export default AiTemplate;
