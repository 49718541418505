import React, { useContext } from "react";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";
import { Store } from './Store';
import { markAsPaid } from "./_actions";

const amountClass = (amount) => {
  if (amount == 0)
    return "text-secondary"
  else if (amount < 0)
    return "text-danger"
  else
    return "text-success";
}

export default function WithdrawalsList(props) {
  const { state: { withdrawals }, dispatch } = useContext(Store);

  return (
    <tbody>
      {withdrawals.map((withdrawal) =>
        <tr key={`withdrawal-${withdrawal.id}`}>
          <td>{withdrawal.id}</td>
          <td><a href={`/translators/${withdrawal.translatorAccount.id}`}>{withdrawal.translatorAccount.email}</a></td>
          <td className={amountClass(withdrawal.amount)}>{withdrawal.amount}</td>
          <td>{withdrawal.currency}</td>
          <td><PrettyLocalDateTime date={withdrawal.createdAt} /></td>
          <td>
            {withdrawal.paid ? 'Paid' : 'Not paid'}
            {!withdrawal.paid &&
              <div
                className='btn btn-primary'
                onClick={(e) => markAsPaid(dispatch, withdrawal.id)}
              >
                Mark as paid
              </div>}
          </td>
          <td>
            {withdrawal.withdrawalMethod.paypalPreferred ?
              <>
                Paypal: {withdrawal.withdrawalMethod.paypalHandle}
              </>
            :
              <ul>
               <li>Bank account: {withdrawal.withdrawalMethod.accountNumber}</li>
               <li>BSB: {withdrawal.withdrawalMethod.bsb}</li>
               <li>SWIFT: {withdrawal.withdrawalMethod.swiftCode}</li>
               <li>SEPA: {withdrawal.withdrawalMethod.sepaSupported ? 'Yes' : 'No'}</li>
              </ul>
            }
          </td>
        </tr>
      )}
    </tbody>
  );
}
