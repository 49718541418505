import React, { createContext, useReducer } from 'react';
import {
  INITIALIZE_STORE,
  UPDATE_FILTERS,
  REQUEST_TRANSACTIONS,
  RECEIVE_TRANSACTIONS
} from './_actions';

export const Store = createContext();

const initialState = {
  filters: { page: 1, size: 25, },
  transactions: [],
  transactionsCount: 0,
  totalPages: 1,
  account: null,
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case INITIALIZE_STORE:
      return {
        ...state,
        account: action.payload.account,
        filters: { ...state.filters, ...action.payload.filters, },
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload, },
      };
    case REQUEST_TRANSACTIONS:
      return {
        ...state, loading: true,
      };
    case RECEIVE_TRANSACTIONS:
      return {
        ...state,
        transactions:      action.payload.records,
        transactionsCount: action.payload.totalCount,
        totalPages:        action.payload.totalPages,
        loading:           false,
      };
    default:
      return state;
  }
}

export function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Store.Provider value={value}>{children}</Store.Provider>;
}
