import { gql } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/query/react";

import { graphqlBaseQuery } from "../_utils/graphqlBaseQuery";

export const gqlApi = createApi({
  reducerPath: "gql",
  baseQuery:   graphqlBaseQuery(),
  tagTypes:    [ "PromptTemplates" ],
  endpoints:   (builder) => ({
    getPromptTemplate: builder.query({
      transformResponse: response => response?.machineTranslatorPrompt,
      query:             input => ({
        input,
        body: gql`
          query machineTranslatorPrompt($promptId: ID!) {
            machineTranslatorPrompt(promptId: $promptId) {
              id
              default
              type
              translationContext
              currencyString
              formality
              hyphensAndQuotes
              keepHtmlIntact
              punctuation
              targetAudience
              toneOfVoice
              unitOfMeasure
              general
              properNamesTitles
              prompt
              defaultPrompt
              refinementPrompt
              defaultRefinementPrompt
              refinementPrompt
              proofreadingPrompt
            }
          }
        `
      })
    }),
    createMachineTranslatorPrompt: builder.mutation({
      invalidatesTags: [ "PromptTemplates" ],
      query:           input => ({
        input,
        body: gql`
          mutation machineTranslatorPromptCreate($input: PromptCreateInput!) {
            machineTranslatorPromptCreate(input: $input) {
              errors
              success
            }
          }
        `
      })
    }),
    updateMachineTranslatorPrompt: builder.mutation({
      invalidatesTags: [ "PromptTemplates" ],
      query:           input => ({
        input,
        body: gql`
          mutation machineTranslatorPromptUpdate($input: PromptUpdateInput!) {
            machineTranslatorPromptUpdate(input: $input) {
              errors
              success
            }
          }
        `
      })
    }),
    previewMachineTranslatorPrompt: builder.mutation({
      invalidatesTags: [ "PromptTemplates" ],
      query:           input => ({
        input,
        body: gql`
          mutation machineTranslatorPromptPreview($input: PromptPreviewInput!) {
            machineTranslatorPromptPreview(input: $input) {
              errors
              success

              prompt {
                default
                type
                translationContext
                currencyString
                formality
                hyphensAndQuotes
                keepHtmlIntact
                punctuation
                targetAudience
                toneOfVoice
                unitOfMeasure
                general
                properNamesTitles
                prompt
                defaultPrompt
                refinementPrompt
                defaultRefinementPrompt
                refinementPrompt
                proofreadingPrompt
                name
              }
            }
          }
        `
      })
    }),
  })})

export const {
  useGetPromptTemplateQuery,
  useCreateMachineTranslatorPromptMutation,
  useUpdateMachineTranslatorPromptMutation,
  usePreviewMachineTranslatorPromptMutation,
} = gqlApi;
