import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";

import Withdrawals from './Withdrawals';
import { StoreProvider } from './Store';

ReactDOM.render(
  <StoreProvider>
    <Withdrawals initialData={WITHDRAWALS_DATA} />
  </StoreProvider>,
  document.getElementById("withdrawals"),
);
