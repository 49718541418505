import { addMessage } from "../_common/filtered_lists/_actions";
import { post, handleJsonResponse } from "../_utils/ajax";

export const UPDATE_TRANSLATOR_TO_EMAIL_TO = "UPDATE_TRANSLATOR_TO_EMAIL_TO";
export const OPEN_EMAIL_POPUP = "OPEN_EMAIL_POPUP";
export const CLOSE_EMAIL_POPUP = "CLOSE_EMAIL_POPUP";
export const UPDATE_EMAIL_SUBJECT = "UPDATE_EMAIL_SUBJECT";
export const UPDATE_EMAIL_BODY = "UPDATE_EMAIL_BODY";
export const REQUEST_EMAIL_SUBMISSION = "REQUEST_EMAIL_SUBMISSION";
export const EMAIL_REQUEST_SUCCESS = "EMAIL_REQUEST_SUCCESS";

export const updateTranslatorToEmailTo = (translatorToEmailTo) => ({
  type: UPDATE_TRANSLATOR_TO_EMAIL_TO, translatorToEmailTo
});

export const openEmailPopup = () => ({ type: OPEN_EMAIL_POPUP });

export const closeEmailPopup = () => ({ type: CLOSE_EMAIL_POPUP });

export const updateEmailSubject = (emailSubject) => ({
  type: UPDATE_EMAIL_SUBJECT, emailSubject
});

export const updateEmailBody = (emailBody) => ({
  type: UPDATE_EMAIL_BODY, emailBody
});

export const requestEmailSubmission = () => ({ type: REQUEST_EMAIL_SUBMISSION });

export const emailRequestSuccess = () => ({ type: EMAIL_REQUEST_SUCCESS });

export const sendEmails = () => (dispatch, getState) => {
  let {emailBody: body, emailSubject: subject, allResultsSelected, selectedResults, translatorToEmailTo, filters } = getState(),
      ids;

  if (translatorToEmailTo !== null) {
    ids = [translatorToEmailTo];
  } else if (allResultsSelected) {
    ids = "all";
  } else {
    ids = selectedResults;
  }

  post("/translators/send_emails", { ...filters, ids, body, subject }, handleJsonResponse )
      .then( ({message, type}) => {
        dispatch(addMessage({ message, type }));
        dispatch(closeEmailPopup());
        dispatch(updateEmailSubject(""));
        dispatch(updateEmailBody(""));
      })
      .catch( ({error, response, json}) => {
        if (json) {
          dispatch(addMessage({ message: json.message, type: json.type }));
        } else if (!response.ok) {
          dispatch(addMessage({ message: response.statusText, type: "danger" }));
        } else {
          dispatch(addMessage({ message: error.message, type: "danger" }));
        }
        dispatch(closeEmailPopup());
      });
};