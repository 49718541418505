import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import PriceField from "./PriceField";
import SalesMarginField from "./SalesMarginField";

import {
  updateClientLanguagePairPricing,
  saveClientLanguagePairPricing,
  removeClientLanguagePairPricing,
  addClientWorkflowLanguagePairPricing,
} from './_actions';

export const fixed = (x, precision) => x && Number.parseFloat(x).toFixed(precision);
export const percentage = (x, precision = 2) => x && `${Number.parseFloat(x).toFixed(precision)}%`;

export default function ClientLanguagePairPricing(props) {
  const { clientLanguagePairPricing: pricing } = props;

  const dispatch = useDispatch();

  const handleSave = useCallback((e) => {
    e.preventDefault();
    dispatch(saveClientLanguagePairPricing(pricing));
  }, [pricing]);

  const handleRemove = useCallback((e) => {
    e.preventDefault();
    dispatch(removeClientLanguagePairPricing(pricing.id));
  }, []);

  const handleAddWorkflowPricing = useCallback((e) => {
    e.preventDefault();
    dispatch(addClientWorkflowLanguagePairPricing(pricing.id));
  }, []);

  const handleChange = (changes) => dispatch(updateClientLanguagePairPricing(pricing.id, changes));

  return (
    <div className="row mb-5">
      <div className="col">
        <div className="row">
          <div className="col">
            <h5>
              {I18n.t("languages")[pricing.sourceLanguageCode]} - {I18n.t("languages")[pricing.targetLanguageCode]} ({pricing.type})
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <form>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <table className="table table__center">
                      <thead>
                        <tr>
                          <th className="th__left">Quality</th>
                          <th>Sales margin</th>
                          <th>Price $</th>
                          <th>Tolq $</th>
                          <th>Translator $</th>
                          <th>Proofreader $</th>
                          <th>Price €</th>
                          <th>Tolq €</th>
                          <th>Translator €</th>
                          <th>Proofreader €</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className="td__left">Postediting</td>
                          <td>{percentage(pricing.usedSalesMargin)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordPosteditingUsd"
                              value={pricing.pricePerWordPosteditingUsd || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordPosteditingUsd &&
                              <>(${fixed(pricing.usedPricePerWordPosteditingUsd, 3)})</>}
                          </td>
                          <td>${fixed(pricing.tolqPricePerWordPosteditingUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordPosteditingUsd, 3)}</td>
                          <td>-</td>
                          <td>
                            <PriceField
                              name="pricePerWordPosteditingEur"
                              value={pricing.pricePerWordPosteditingEur || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordPosteditingEur &&
                              <>(€{fixed(pricing.usedPricePerWordPosteditingEur, 3)})</>}
                          </td>
                          <td>€{fixed(pricing.tolqPricePerWordPosteditingEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordPosteditingEur, 3)}</td>
                          <td>-</td>
                        </tr>

                        <tr>
                          <td className="td__left">Postediting AI</td>
                          <td>{percentage(pricing.usedSalesMargin)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordPosteditingAiUsd"
                              value={pricing.pricePerWordPosteditingAiUsd || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordPosteditingAiUsd &&
                              <>(${fixed(pricing.usedPricePerWordPosteditingAiUsd, 3)})</>}
                          </td>
                          <td>${fixed(pricing.tolqPricePerWordPosteditingAiUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordPosteditingAiUsd, 3)}</td>
                          <td>-</td>
                          <td>
                            <PriceField
                              name="pricePerWordPosteditingAiEur"
                              value={pricing.pricePerWordPosteditingAiEur || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordPosteditingAiEur &&
                              <>(€{fixed(pricing.usedPricePerWordPosteditingAiEur, 3)})</>}
                          </td>
                          <td>€{fixed(pricing.tolqPricePerWordPosteditingAiEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordPosteditingAiEur, 3)}</td>
                          <td>-</td>
                        </tr>

                        <tr>
                          <td className="td__left">Translation</td>
                          <td>{percentage(pricing.usedSalesMargin)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordTranslationUsd"
                              value={pricing.pricePerWordTranslationUsd || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordTranslationUsd &&
                              <>(${fixed(pricing.usedPricePerWordTranslationUsd, 3)})</>}
                          </td>
                          <td>${fixed(pricing.tolqPricePerWordTranslationUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordTranslationUsd, 3)}</td>
                          <td>-</td>
                          <td>
                            <PriceField
                              name="pricePerWordTranslationEur"
                              value={pricing.pricePerWordTranslationEur || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordTranslationEur &&
                              <>(€{fixed(pricing.usedPricePerWordTranslationEur, 3)})</>}
                          </td>
                          <td>€{fixed(pricing.tolqPricePerWordTranslationEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordTranslationEur, 3)}</td>
                          <td>-</td>
                        </tr>

                        <tr>
                          <td className="td__left">Localization</td>
                          <td>{percentage(pricing.usedSalesMargin)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordLocalizationUsd"
                              value={pricing.pricePerWordLocalizationUsd || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordLocalizationUsd &&
                              <>(${fixed(pricing.usedPricePerWordLocalizationUsd, 3)})</>}
                          </td>
                          <td>${fixed(pricing.tolqPricePerWordLocalizationUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordLocalizationUsd, 3)}</td>
                          <td>${fixed(pricing.proofreaderPricePerWordLocalizationUsd, 3)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordLocalizationEur"
                              value={pricing.pricePerWordLocalizationEur || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordLocalizationEur &&
                              <>(€{fixed(pricing.usedPricePerWordLocalizationEur, 3)})</>}
                          </td>
                          <td>€{fixed(pricing.tolqPricePerWordLocalizationEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordLocalizationEur, 3)}</td>
                          <td>€{fixed(pricing.proofreaderPricePerWordLocalizationEur, 3)}</td>
                        </tr>

                        <tr>
                          <td className="td__left">Expert</td>
                          <td>{percentage(pricing.usedSalesMargin)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordExpertUsd"
                              value={pricing.pricePerWordExpertUsd || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordExpertUsd &&
                              <>(${fixed(pricing.usedPricePerWordExpertUsd, 3)})</>}
                          </td>
                          <td>${fixed(pricing.tolqPricePerWordExpertUsd, 3)}</td>
                          <td>${fixed(pricing.translatorPricePerWordExpertUsd, 3)}</td>
                          <td>${fixed(pricing.proofreaderPricePerWordExpertUsd, 3)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordExpertEur"
                              value={pricing.pricePerWordExpertEur || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordExpertEur &&
                              <>(€{fixed(pricing.usedPricePerWordExpertEur, 3)})</>}
                          </td>
                          <td>€{fixed(pricing.tolqPricePerWordExpertEur, 3)}</td>
                          <td>€{fixed(pricing.translatorPricePerWordExpertEur, 3)}</td>
                          <td>€{fixed(pricing.proofreaderPricePerWordExpertEur, 3)}</td>
                        </tr>

                        <tr>
                          <td className="td__left">Machine</td>
                          <td>{percentage(100)}</td>
                          <td>
                            <PriceField
                              name="pricePerWordMachineUsd"
                              value={pricing.pricePerWordMachineUsd || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordMachineUsd &&
                              <>(${fixed(pricing.usedPricePerWordMachineUsd, 4)})</>}
                          </td>
                          <td>${fixed(pricing.tolqPricePerWordMachineUsd, 4)}</td>
                          <td>-</td>
                          <td>-</td>
                          <td>
                            <PriceField
                              name="pricePerWordMachineEur"
                              value={pricing.pricePerWordMachineEur || ""}
                              onChange={handleChange}
                            />
                            {!pricing.pricePerWordMachineEur &&
                              <>(€{fixed(pricing.usedPricePerWordMachineEur, 4)})</>}
                          </td>
                          <td>€{fixed(pricing.tolqPricePerWordMachineEur, 4)}</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>

                        <tr>
                          <td></td>
                          <td>
                            <SalesMarginField
                              name="salesMargin"
                              value={pricing.salesMargin || ""}
                              onChange={handleChange}
                            />
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="submit"
                        name="commit"
                        value="Save"
                        className="btn btn-sm btn-primary mr-1"
                        data-disable-with="Save"
                        onClick={handleSave}
                      />
                      <button
                        className="btn btn-sm btn-danger mr-1"
                        onClick={handleRemove}
                      >
                        Remove
                      </button>
                      {!pricing.workflowPricing && (<button
                        className="btn btn-sm btn-success"
                        onClick={handleAddWorkflowPricing}
                      >
                        Add workflow pricing
                      </button>)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="font-italic">
                        Hint: Empty fields when setting prices or sales margin will use the default pricing or sales margin.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
