import React from "react";
import { connect } from "react-redux";
import PieChart from "./PieChart";

const colors = {
  expertQuality: "#2ECC71",
  premiumQuality: "#4AE2D5",
  standardQuality: "#4A90E2",
  basicQuality: "#FF9119",
  badQuality: "#E74C3C",
};

const labels = {
  expertQuality: "Expert",
  premiumQuality: "Premium",
  standardQuality: "Standard",
  basicQuality: "Basic",
  badQuality: "Bad",
};

export class VerdictsChart extends React.Component {
  render() {
    const { verdictsDistribution, verdictsCount } = this.props;

    return (
      <PieChart
        id="verdicts"
        title="Verdicts"
        count={verdictsCount}
        values={Object.keys(verdictsDistribution).map(key => ({
          label: labels[key],
          data: verdictsDistribution[key],
          color: colors[key]
        }))}
      />
    );
  }
}

export default connect(
  ({ staticData: { verdictsDistribution, verdictsCount } }) => ({ verdictsDistribution, verdictsCount })
)(VerdictsChart);
