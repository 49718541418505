import React from "react";

import Card from "../_common/Card";
import AiTemplate from "./AiTemplate";

import { useGetPromptTemplatesQuery } from './_api';

const AiTemplates = ({ }) => {
  const { data: promptTemplates, isLoading, refetch } = useGetPromptTemplatesQuery();

  if (isLoading) return null;

  return (
    <>
      <h2>AI Templates</h2>
      <Card>
        <a href="/ai/new">
          <button className="btn btn-primary mb-3">Create AI template</button>
        </a>

        <table className="table table-striped">
          <thead>
            <tr>
              <th>Version</th>
              <th>Name</th>
              <th>Prompt</th>
              <th>Default</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { promptTemplates.map((template) => <AiTemplate template={template} refetch={refetch} />)}
          </tbody>
        </table>
      </Card>
    </>
  );
}

export default AiTemplates;
