import { camelizeKeys } from "humps";
import { flatten, uniq } from "underscore";

import { QUALITY_HIGHLIGHT_COLOR_CODES, QUALITY_HIGHLIGHT_KEYS } from "../_utils/constants";

export const hashedQualityHighlights = (qualityHighlights, qualityTypes, eventMode) => {
  return camelizeKeys(qualityHighlights).reduce( (acc, highlight) =>  {
    const id = eventMode ? highlight.ref : highlight.sourceChunkId;

    if (acc[id]) {
      acc[id].push(highlight);
    } else {
      acc[id] = [highlight];
    }

    return acc;
  },{});
};

// Returns each one of the fragments the text is going to be divided into
// some of them will be highlighted and some won't
// In order to allow popup interaction selectedText creates
// a highlight that mimics browser native selection
export const getFragments = (highlights, text) => {
  const highlightStarts = highlights.map(h => h.rangeStart),
        highlightEnds = highlights.map(h => h.rangeEnd),
        allDivisions = [...highlightStarts, ...highlightEnds ].sort((a, b) => a - b);

  if (typeof (text) !== "string") throw (`text parameter must be a string and it is: ${typeof (text)}`);
  if (allDivisions[0] != 0) allDivisions.unshift(0);
  if (allDivisions[allDivisions.length - 1] != text.length) allDivisions.push(text.length);

  return allDivisions.reduce((acc, rangeStart, index) => {
    if (index == (allDivisions.length - 1)) return acc;

    const rangeEnd = allDivisions[index + 1];

    return [...acc, { rangeStart, rangeEnd }];
  }, []);
};

export const isSameRange = (range1, range2) => {
  if (!range1 || !range2) return false;
  return (range1.rangeStart == range2.rangeStart && range1.rangeEnd == range2.rangeEnd);
};

export const fragmentStyle = (fragment, highlights) => {
  let highlight = highlights.find(h => isSameRange(fragment, h));

  if (!highlight) return undefined;

  let typeKeys = QUALITY_HIGHLIGHT_KEYS.filter(k => highlight[k]),
      style = { lineHeight: "normal", boxShadow: "" },
      underlineHeight = 2,
      underlinePadding = 2;

  if (typeKeys.length > 1) {
    style.lineHeight = `calc(110% + ${(typeKeys.length - 1) * 2 * (underlineHeight + underlinePadding)}px)`;
  }

  typeKeys.forEach((key, index) => {
    if (index === 0) {
      style.boxShadow += `inset 0 -2px ${QUALITY_HIGHLIGHT_COLOR_CODES[key]}, 0 ${underlinePadding}px 0 0 white`;
    } else {
      let linesBeforeHeight = (index - 1) * (underlineHeight + underlinePadding) + underlinePadding;

      style.boxShadow += `,0 ${linesBeforeHeight + underlineHeight}px 0 0 ${QUALITY_HIGHLIGHT_COLOR_CODES[key]},
                          0 ${linesBeforeHeight + underlineHeight + underlinePadding}px 0 0 white`;
    }
  });

  return style;
};


export const segmentHighlightTypes = (highlights)  => {
  return uniq(
    flatten(
      highlights.map(h => QUALITY_HIGHLIGHT_KEYS.filter(k => h[k]))
    )
  );
};

export const getTaskId = () => {
  const matchData = window.location.href.match(/\d+/g);

  return matchData[matchData.length - 1];
}
