import { combineReducers } from "redux";

import { UPDATE_RESULTS } from "../_common/filtered_lists/_actions";
import {
  totalCount,
  totalPages,
  filters,
  results,
  loading,
  staticData,
  messages,
} from "../_common/filtered_lists/_reducers";


export default combineReducers({
  totalCount,
  totalPages,
  filters,
  translators: results,
  loading,
  staticData,
  messages,
});