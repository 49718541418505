import React from "react";
import { connect } from "react-redux";

import { updateQualityReview } from "./_actions";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";
import RemoveQualityReview from "./RemoveQualityReview";
import { MODES } from "./_constants";

export class TableQualityReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props.qualityReview };
  }

  updateQualityReview(e, id, changes) {
    e.preventDefault();
    this.setState({...changes});
    this.props.updateQualityReview(id, changes);
  }

  render() {
    const {
      id,
      taskId,
      translatorVerdict,
      proofreaderVerdict,
      verdictText,
      updatedAt,
      createdAt,
      confirmed,
      task,
      translatorAccount,
    } = this.props.qualityReview;

    const {
      mode
    } = this.props;

    const {
      adminConfirmed,
    } = this.state;

    return (
      <tr key={id}>
        <td>{id}</td>
        <td>
          <a className="ml-1" href={`/tasks/${taskId}`} target="_blank" rel="noopener noreferrer">
            {taskId}
          </a>
        </td>
        {MODES.emitted_quality_reviews == mode ?
          null :
          <td>
            <a href={`/translators/${translatorAccount.id}`} target="blank">{translatorAccount.email}</a>
          </td>
        }
        <td>
          {translatorVerdict ?
            <div>
              {I18n.t(`qualities.${translatorVerdict}`) }
              <br/>
              <a href={`/translators/${task.translatorAccount.id}`} target="blank">{task.translatorAccount.email}</a>
            </div>
            :
            <span className="text-muted">N/A</span>
          }
        </td>
        <td>
          {proofreaderVerdict ?
            <div>
              {I18n.t(`qualities.${proofreaderVerdict}`) }
              <br />
              <a href={`/translators/${task.proofreaderAccount.id}`} target="blank">{task.proofreaderAccount.email}</a>
            </div>
            :
            <span className="text-muted">N/A</span>
          }
        </td>
        <td>
          {verdictText ? 
            <div className="text-wrap" style={{maxWidth: "450px"}}>
              {verdictText}
            </div>
            :
            <span className="text-muted">N/A</span>
          }
        </td>
        <td><PrettyLocalDateTime date={updatedAt} /></td>
        <td><PrettyLocalDateTime date={createdAt} /></td>
        <td className="text-center">{confirmed ? <small className="ticon-check text-success"></small> : <small className="ticon-cancel text-danger"></small>}</td>
        <td className="text-center">
          {adminConfirmed ?
            <small
              onClick={ e => this.updateQualityReview(e, id, {adminConfirmed: false}) }
              className="ticon-check text-success btn btn-sm"
            />
            :
            <small
              onClick={ e => this.updateQualityReview(e, id, { adminConfirmed: true }) }
              className="ticon-cancel text-danger btn btn-sm"
            />
          }
        </td>
        <td><RemoveQualityReview id={id} /></td>
      </tr>
    );
  }
}

export default connect(
  ({ mode }) => ({ mode }),
  { updateQualityReview }
)(TableQualityReview);
