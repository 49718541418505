import React from "react";

export default function PrettyCurrency({
  currency,
  amount,
  maximumFractionDigits = 3,
  className = "currency_amount"
}) {
  if (isNaN(amount)) return <span className="dulled">N/A</span>;
  if (!currency) return <>?? {amount}</>;

  let formatParts,
      config = { style: "currency", maximumFractionDigits, currency };

  try {
    formatParts = Intl.NumberFormat("en", config).formatToParts(amount);
  } catch (error) {
    formatParts = Intl.NumberFormat(undefined, config).formatToParts(amount);
  }

  // Possible types: currency, integer, group, decimal, fraction
  // currency is the money symbol or series of characters like € or EUR
  // group and decimal are the symbols separating digits like the comma (,) and dot (.) in 1,235.12
  // integer and fraction are the digits of the amount, with fraction corresponding to decimals
  return (
    <span className={`${className}__wrapper`}>
      {formatParts.map(({ type, value }, index) => (
        <span
          key={`currency-part-${index}`}
          className={`${className}__${type}`}
        >
          {value}
        </span>
      ))}
    </span>
  );
}