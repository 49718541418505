import { useState, useEffect, useRef } from "react";
import { urlParams } from "../_utils/humpyAjax";
import { useSelector, useDispatch } from "react-redux";

function shouldPushState(mode, filters, prevFilters) {
  // The first time a page loads creates a history item on its own, but no state is stored,
  // so we need this to replace said history item with one with state
  if (!prevFilters.current.page) return false;
  if (mode == "pushState") return true;
  if (mode == "replaceState") return false;
  if (!Array.isArray(mode)) throw ("Invalid mode type:", mode);
  // If any the keys contained in the array changed it should pushState
  return mode.findIndex(key => filters[key] != prevFilters.current[key]) == -1;
}

// Available modes "pushState", "replaceState", and ["key1", "key2", .....]
// The key array does pushState when the keys change and replaceState in the rest of cases
export default function useFiltersUrlSync(updateFilters, mode = "pushState") {
  const [search, setSearch] = useState(""), // helps define if the last change should change the url
        dispatch = useDispatch(),
        filters = useSelector(state => state.filters),
        prevFilters = useRef({});

  // Handles clicking the back button which should update the url and trigger correct queries via filter update
  // Please be aware it doesn't affect other states
  useEffect(() => {
    function handleChangeFilters(e) {
      if (!e?.state) return;
      setSearch(urlParams(e.state));
      dispatch(updateFilters(e.state));
    }

    window.addEventListener("popstate", handleChangeFilters);

    return () => window.removeEventListener("popstate", handleChangeFilters);
  }, []);

  // if the filters configuration changed (search) manipulate history
  useEffect(() => {
    const { origin, pathname } = window.location,
          newSearch = urlParams(filters);

    if (search != newSearch) {
      setSearch(newSearch);

      if (shouldPushState(mode, filters, prevFilters)) {
        history.pushState(filters, null, `${origin}${pathname}?${newSearch}`);
        return;
      }

      history.replaceState(filters, null, `${origin}${pathname}?${newSearch}`);
    }
  }, [filters]);

  // The effect needs to happen AFTER any comparisons relying on prevFilters to get the old value
  useEffect(() => {
    prevFilters.current = filters;
  });
}