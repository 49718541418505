import gqlClient from "../_utils/graphQlRequests";

import { createTrainingTextMutation } from "./_gql";

export const CHANGE_TARGET_LANGUAGE = "CHANGE_TARGET_LANGUAGE";
export const CHANGE_SOURCE_LANGUAGE = "CHANGE_SOURCE_LANGUAGE";
export const CHANGE_TEXT = "CHANGE_TEXT";

export const changeTargetLanguage = (targetLanguageCode) => ({
  type: CHANGE_TARGET_LANGUAGE, targetLanguageCode
});

export const changeSourceLanguage = (sourceLanguageCode) => ({
  type: CHANGE_SOURCE_LANGUAGE, sourceLanguageCode
});

export const changeText = (text) => ({
  type: CHANGE_TEXT, text
});

export const createTrainingText = () => async (_, getState) => {
  const { sourceLanguageCode, targetLanguageCode, text } = getState();

  const {
    trainingTextCreate: {
      success,
      errors,
    }
  } = await gqlClient.request(createTrainingTextMutation, {
    input: {
      trainingTextInput: {
        sourceLanguageCode,
        targetLanguageCode,
        text,
      }
    }
  });

  if (success) {
    window.location = `/language_pairs/${sourceLanguageCode}-${targetLanguageCode}`;
  }

  return { success, errors };
};
