import React from "react";
import { connect } from "react-redux";

import { changePage, changePageAndSize } from "../_common/filtered_lists/_actions";
import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import Loader from "../_common/Loader";
import Headers from "./Headers";
import OrdersList from "./OrdersList";
import MultiselectionPopup from "./MultiselectionPopup";
import DeadlinePopup from "./DeadlinePopup";
import ConfirmReadyPopup from "./ConfirmReadyPopup";
import SetAsReviewedPopup from "./SetAsReviewedPopup";

export class Results extends React.Component {
  render() {
    const { totalPages, page, size, changePage, changePageAndSize, loading, className } = this.props;

    return (
      <div className={className}>
        <div className="card">
          <div className="card-body">
            <TopPagination {...{ changePageAndSize, totalPages, page, size }} />
            <div className="loader_wrapper">
              {loading ? <Loader /> : null}
              <Headers />
              <OrdersList />
            </div>
            <Pagination className="justify-content-end" {...{ changePage, totalPages, page }} />
            <MultiselectionPopup />
            <DeadlinePopup />
            <ConfirmReadyPopup />
            <SetAsReviewedPopup />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ totalPages, filters: { page, size }, loading, allResultsSelected, totalCount, selectedResults }) => ({
    totalPages,
    page,
    size,
    loading,
    count: allResultsSelected ? totalCount : selectedResults.length,
  }),
  { changePage, changePageAndSize }
)(Results);
