import React from "react";
import { connect } from "react-redux";
import { post, expectNoContent } from "../_utils/humpyAjax";
import { cleanParams } from "../_utils/ajax";

import FormPopup from "../_common/FormPopup";
import { closeConfirmReadyPopup } from "./_actions";

export class ConfirmReadyPopup extends React.Component {
  state = {
    postingSetReadyToTranslate: false,
  }

  setReadyToTranslate(){
    const { allResultsSelected, cleanFilters, selectedResults } = this.props,
          ids = allResultsSelected ? "all" : selectedResults;

    this.setState({ postingSetReadyToTranslate: true });

    post("/translation-requests/bulk_start_postprocessing", { ids, ...cleanFilters, }, expectNoContent)
      .then(()=>location.reload())
      .catch(()=>{
        alert("Something went wrong");
        this.setState({ postingSetReadyToTranslate: false });
      });
  }

  render() {
    const {
      confirmReadyPopupOpen,
      closeConfirmReadyPopup,
    } = this.props;

    const {
      postingSetReadyToTranslate
    } = this.state;

    if (!confirmReadyPopupOpen) return null;

    return (
      <FormPopup wrapperCallback={() => closeConfirmReadyPopup()}>
        <h3>Set as ready for translation ?</h3>
        <p>Are you sure you want to make ready for translation all pending orders within the current selection?</p>

        <button onClick={() => closeConfirmReadyPopup()} className="btn btn-outline-primary float-left">Cancel</button>
        <button
          onClick={()=> this.setReadyToTranslate()}
          className="btn btn-primary float-right"
          disabled={postingSetReadyToTranslate}
        >
          Set as ready for translation
        </button>
      </FormPopup>
    );
  }
}

export default connect(
  ({ confirmReadyPopupOpen, allResultsSelected, filters, selectedResults }) => ({
    confirmReadyPopupOpen,
    allResultsSelected,
    cleanFilters: cleanParams(filters),
    selectedResults,
  }),
  { closeConfirmReadyPopup }
)(ConfirmReadyPopup);
