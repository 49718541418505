import { urlParams } from "../_utils/ajax";
import { get, urlParams as humpyUrlParams } from "../_utils/humpyAjax";

export const INITIALIZE_STORE     = "INITIALIZE_STORE";
export const UPDATE_FILTERS       = "UPDATE_FILTERS";
export const REQUEST_TRANSACTIONS = "REQUEST_TRANSACTIONS";
export const RECEIVE_TRANSACTIONS = "RECEIVE_TRANSACTIONS";

export const initializeStore = (dispatch, data) =>
  dispatch({ type: INITIALIZE_STORE, payload: data });

export const updateFilters = (dispatch, filters) =>
  dispatch({ type: UPDATE_FILTERS, payload: filters });

export const requestFilteredTransactions = async (dispatch, filters) => {
  dispatch({ type: REQUEST_TRANSACTIONS });

  const params = humpyUrlParams(filters);
  const transactions = await get(`transactions.json?${params}`);

  dispatch({ type: RECEIVE_TRANSACTIONS, payload: transactions });

  history.replaceState(null, null, `transactions?${params}`);
};
