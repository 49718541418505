import React from "react";

export class ChartLegends extends React.Component {
  render() {
    const { dataSets, colors, excluded, keyNameGenerator, toggleExcludedItem} = this.props;

    return (
      <ul className="chart__legends chart__legends--centered mt-2">
        {Object.keys(dataSets).map((key) => {
          const label = keyNameGenerator(key);

          return (
            <li
              className="chart__legend mb-2"
              key={`quality-dimension-total-${key}`}
              onClick={() => toggleExcludedItem(label)}
              style={{ textDecoration: excluded.includes(label) ? "line-through" : "none" }}
            >
              <div className="chart__legend__box" style={{ backgroundColor: colors[key] }}></div>
              {keyNameGenerator(key)}
            </li>
          );
        })}
      </ul>
    );
  }
}

export default ChartLegends;
