import React from "react";
import { connect } from "react-redux";

export class PreviewTable extends React.Component {
  render() {
    const {text} = this.props;
    return (
      <div className="col d-flex">
        <div className="card flex-grow-1">
          <div className="card-body">
            <table className="table">
              <tbody>
                {text.split("\n").filter( p => p.length ).map( (paragraph, index) => (
                  <tr key={`paragraph-${index}`} >
                    <td>{paragraph}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ text }) => ({ text })
)(PreviewTable);
