import React from "react";
import PropTypes from "prop-types";

export class Prev extends React.Component {
  render() {
    const { totalPages, page, changePage } = this.props;

    if (totalPages == 1) return null;

    return (
      <li className={`page-item ${page == 1 ? "disabled" : ""}`}>
        <span className="page-link" onClick={e => changePage(page - 1, e)}>&laquo;</span>
      </li>
    );
  }
}

export default Prev;

Prev.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};