import React from "react";
import { connect } from "react-redux";

import Header from "./Header";
import PreviewTable from "./PreviewTable";
import { changeText, createTrainingText } from "./_actions";

export class TrainingTextForm extends React.Component {
  createTrainingText(e) {
    e.preventDefault();
    this.props.createTrainingText();
  }

  render() {
    const { text, changeText, disabledButton, editing } = this.props;

    return (
      <form className="container-fluid">
        <div className="row">
          <div className="col">
            <Header />
          </div>
        </div>

        <div className="row">
          <h5 className="col">Text editor</h5>
          <h5 className="col">Preview</h5>
        </div>

        <div className="row d-flex align-items-stretch">
          <div className="col d-flex">
            <div className="card flex-grow-1">
              <div className="card-body d-flex">
                <textarea
                  className="flex-grow-1 p-3"
                  rows="15"
                  placeholder="Write or paste the text that should be used to evaluate translators here"
                  value={text}
                  onChange={e => changeText(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <PreviewTable />
        </div>

        <div className="row mt-4">
          <div className="col">
            <button
              className="btn btn-primary btn-lg float-right"
              disabled={disabledButton}
              onClick={ e => this.createTrainingText(e) }
            >
              {editing ? "Update Training Text" : "Create Training Text"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default connect(
  ({ text, sourceLanguageCode, targetLanguageCode, staticData: { editing } }) => ({
    text,
    disabledButton: !text.length || !sourceLanguageCode.length || !targetLanguageCode.length || targetLanguageCode == sourceLanguageCode,
    editing
  }),
  { changeText, createTrainingText }
)(TrainingTextForm);