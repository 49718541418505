import { Socket } from "phoenix";

var TranslationLive = function(){
  var d = Date.now(); // date in miliseconds

  d = d - d%60000; // We remove seconds and miliseconds so the starting date is the begining of the current minute

  const MARK_DISTANCE = 75,
        SECOND_SIZE = MARK_DISTANCE / 15, //The chart has marks (the gray vertical lines) every 15 seconds
        PX_TO_SECONDS_CENTER = 5,
        PX_TO_MINUTES_CENTER = 10,
        MINUTES_TO_PRELOAD = 5,
        MINUTES_TO_RESTART = 20,
        START_DATE = d - MINUTES_TO_PRELOAD * 60 * 1000,
        INITIAL_DRAWN_MINUTES = 10; // Seconds must be divisable by 15

  var obj = {
    $displayArea: $("#translations-live-display-area"),
    endDate: START_DATE, // defines where the markers and the width currently reach after executing drawAllMarkers
    translators: {},  // each translator stores their last update date
    grabbing: false,
    grabbingScroll: false,
    handlerWidth: $(".tl__scrollbar__handler").width(),
    autoScrollEnabled: true,
    limit:  $(".tl__display_area__wrapper").width() - $("#translations-live-display-area").width(),
    curLeft: 0,
    scrollLimits: {
      start: $(".tl__scrollbar").offset().left,
      width: $(".tl__scrollbar").width()
    },
  }

  obj.initialize = function() {
    console.log("Executing TranslationLive...");
    this.drawAllMarkers(INITIAL_DRAWN_MINUTES + MINUTES_TO_PRELOAD +  1); // +1 to make up for the initial offset cause by the removed seconds
    this.adaptWidthToEndDate();
    this.subscribeToTranslationsLive();
    this.expandPeriodically();
    this.enableDisplayGrabbing();
    this.enableDisplayScroll();
    this.getInitialData();
    setTimeout( () => location.reload(), MINUTES_TO_RESTART * 60 * 1000 ); // reload page every MINUTES_TO_RESTART to cleanup views and remove outdated
  }

  obj.expandPeriodically = function() {
    setInterval(
      () => {
        var left = - this.dateToPosition(this.endDate);
        console.log("Expanding to:", left, `each ${INITIAL_DRAWN_MINUTES} minutes`);

        this.drawAllMarkers(INITIAL_DRAWN_MINUTES);
        this.adaptWidthToEndDate();
      }, INITIAL_DRAWN_MINUTES * 60 * 1000
    )
  }

  obj.enableDisplayGrabbing = function() {
    var {$displayArea} = this,
        self = this,
        windowXPos, left;

    $displayArea.mousemove(function(e){
      if(self.grabbing === true){
        self.setLeftTo(self.curLeft - (windowXPos - e.pageX));
        windowXPos = e.pageX;
      }
    });

    $displayArea.mousedown(function(e){
      self.grabbing = true;
      windowXPos = e.pageX;
    });

    $(window).mouseup(() => self.grabbing = false );
  }

  obj.enableDisplayScroll = function() {
    var {$displayArea} = this,
        self = this,
        windowXPos;

    $(window).mousemove(function(e){
      if(self.grabbingScroll){
        var percentage = e.pageX - self.scrollLimits.start - self.handlerWidth/2,
            rightLimit = self.scrollLimits.width - self.handlerWidth;;

        if (percentage < 0) percentage = 0;
        if (percentage > rightLimit) percentage = rightLimit;

        self.setLeftTo(self.limit * percentage / rightLimit);
      }
    });

    $(".tl__scrollbar__handler").mousedown(function(e){
      self.grabbingScroll = true;
      windowXPos = e.pageX;
    });

    $(window).mouseup(() => self.grabbingScroll = false );
  }

  obj.setLeftTo = function(position, animate) {
    var left = position,
        scrollRightLimit = this.scrollLimits.width - this.handlerWidth;

    if (left > 0 ) left = 0;
    if (left < this.limit) left = this.limit;

    this.curLeft = left;
    $(".tl__scrollbar__handler").css({left: left * scrollRightLimit / this.limit });
    animate ? this.$displayArea.animate({left}) : this.$displayArea.css({left});
  }

  // Markers Drawing:
  // ====================================================================================
  obj.drawAllMarkers = function(minutesToPaint){
    var {$displayArea, drawMarkers, drawMarkersLabels, endDate } = this,
        markCount = minutesToPaint * 4,
        currentSecond = (endDate / 1000) % 60,
        startingPosition = this.dateToPosition(endDate);

    if (markCount < 1) return;
    console.log("drawing markers:", startingPosition, "currentSecond:", currentSecond);

    for (let i = 0; i < markCount; i++) {
      var position = startingPosition + MARK_DISTANCE * i;

      this.drawMarker( position, currentSecond );
      this.drawMarkerLabel( position, currentSecond, endDate + 15 * 1000 * i, $(".tl__timeline--top, .tl__timeline--bottom") );
      currentSecond = (currentSecond + 15)%60
    }

    this.endDate += minutesToPaint*60*1000;
  }

  obj.drawMarker = function(position, second) {
    if (second) {
      this.$displayArea.append(`<div class="tl__timeline__bar_seconds" style="left: ${position}px"></div>`)
    } else {
      this.$displayArea.append(`<div class="tl__timeline__bar_minutes" style="left: ${position}px"></div>`);
    }
  }

  obj.drawMarkerLabel = function(position, second, date, $timelines) {
    if (second) {
      $timelines.append(`<div class="tl__timeline__second" style="left: ${position - PX_TO_SECONDS_CENTER}px">${second}</div>`)
    } else {
      $timelines.append(`<div class="tl__timeline__minute" style="left: ${position - PX_TO_MINUTES_CENTER}px">${moment(date).format("HH:mm")}</div>`);
    }
  }

  obj.dateToPosition = function(date, startDate = START_DATE) {
    return (date - startDate) / 1000 * SECOND_SIZE;
  }

  obj.adaptWidthToEndDate = function() {
    var width = this.dateToPosition(this.endDate);
    this.$displayArea.width(width);
    this.limit = $(".tl__display_area__wrapper").width() - width;
    console.log("Current limit:", this.limit)
  }

  // Translations/Autocompletes subsciption + drawing process:
  // ====================================================================================
  obj.subscribeToTranslationsLive = function() {
    const realtimeToken = document.querySelector("meta[name='realtime-token']").getAttribute("content");
    const realtimeUserId = document.querySelector("meta[name='realtime-user-id']").getAttribute("content");

    const socket = new Socket(`${REALTIME_SOCKET_URL}/socket`, { params: { token: realtimeToken }});
    socket.connect();

    const channel = socket.channel("translations", {})

    channel.join()
      .receive("ok", resp => { console.log("Joined successfully", resp) })
      .receive("error", resp => { console.log("Unable to join", resp) });

    channel.on("new:translation", drawData.bind(this));
  }

  function drawData(data) {
    console.log(data);
    var translator = data.translator_account_id,
        isNew = !this.translators[translator];

    if (isNew) this.addTranslator(translator, data.translator_name);
    if (data.autocompletion) this.drawAutocompletion(data.autocompletion, translator);
    if (data.translation) this.drawTranslation(data.translation, translator, isNew);
    if (this.autoScrollEnabled && !this.grabbing) this.moveTo(translator, Date.now());
  }

  obj.moveTo = function(translator, date) {
    var translatorHeight = $(`#translator-portrait-${translator}`).offset().top - 150;

    this.setLeftTo(500 - this.dateToPosition(date), true)
    $("html, body").animate( { scrollTop: translatorHeight } );
  }

  obj.addTranslator = function(translator, name){
    var translatorHeight = 6.5,
        index;

    this.translators[translator] = Date.now(); // TODO use created_at
    index = Object.keys(this.translators).length - 1;

    // 1. expand canvas
    $(".tl__display_area__wrapper").height(`${15 + index*translatorHeight}rem`);
    // 2. draw icon part
    $(".tl__container").append(`
      <div id="translator-portrait-${translator}" class="tl__translator_portrait" style="top: ${5 + index*translatorHeight}rem">
        <p class="ticon-person tl__translator_portrait__icon"></p>
        <p class="tl__translator_portrait__name">${name}</p>
      </div>
    `)
    // // 3. draw timeline
    $("#translations-live-display-area").append(`
      <div id="translator-timeline-${translator}" class="tl__translator_timeline" style="top: ${5 + index*translatorHeight}rem"></div>
    `)
  }

  obj.drawAutocompletion = function(data, translator) {
    var date = new Date(data.created_at).getTime(),
        position = this.dateToPosition(date);


    this.$displayArea.children(`#translator-timeline-${translator}`).append(
      `<div class="tl__translator_timeline__autocomplete" style="left: ${position}px">
        <span class="tl__translator_timeline__autocomplete__sphere"></span>
        <div class="tl__translator_timeline__autocomplete__popup">
          <p class="tl__translator_timeline__autocomplete__main_copy"><strong>Completion:</strong> ${data.completion} </p>
          <p class="tl__translator_timeline__autocomplete__copy"><strong>Typed:</strong> ${data.typed_text} </p>
          <p class="tl__translator_timeline__autocomplete__copy"><strong>Completed:</strong> ${data.completed_text} </p>
        </div>
      </div>`
    );
  }

  obj.drawTranslation = function(data, translator, isNew) {
    var date = new Date(data.created_at).getTime(),
        end = this.dateToPosition(date),
        start = this.dateToPosition(this.translators[translator]),
        width = start >= end ? 10 : end - start;

    // It is unknown when the first translation started so we assume the max width equivalent to 30 secs
    // we add a second for padding
    if (isNew) {
      start-= MARK_DISTANCE * 2 + MARK_DISTANCE / 15;
      width = MARK_DISTANCE * 2;
    }

    // Calculate the starting date of the next translation as one second after the end of the last one
    this.translators[translator] = date + 1000;

    this.$displayArea.children(`#translator-timeline-${translator}`).append(
      `<div class="tl__translator_timeline__translation"  style="left: ${start}px; width: ${width}px">
        <p class="tl__translator_timeline__translation__timestamp">${moment(date).format("HH:mm:ss")}</p>
        <p class="tl__translator_timeline__translation__copy">${data.translation}</p>
      </div>`
    );
  }

  obj.getInitialData = function() {
    var autocompletions = this.$displayArea.data("autocompletions"),
        translations = this.$displayArea.data("translations"),
        { autoScrollEnabled } = this;

    // order by translator, then time
    translations =  _.sortBy( translations, t => {
      var tid = t.translator_account_id * 1000,
          time = Math.log( new Date(t.translation.created_at).getTime() );

      return tid + time;
    });

    this.autoScrollEnabled = false;
    autocompletions.forEach( drawData.bind(this) )
    translations.forEach( drawData.bind(this) )
    this.autoScrollEnabled = autoScrollEnabled;
  }

  $("#toggle-auto-scroll").change( function(){ obj.autoScrollEnabled = this.checked } );

  $(window).resize( _.debounce(() => {
    obj.limit =  $(".tl__display_area__wrapper").width() - $("#translations-live-display-area").width();
    obj.scrollLimits = {
      start: $(".tl__scrollbar").offset().left,
      width: $(".tl__scrollbar").width()
    }
    console.log("After resize limit:", obj.limit);
  },1000 ) );

  return obj;
}

if ($("#translations-live-display-area").length) {
  var translationLive = TranslationLive();
  $().ready(() => translationLive.initialize());
}
