import React from "react";

export class PrettyLocalDateTime extends React.Component {
  render() {
    if (this.props.date == null) return <span className="text-muted">N/A</span>;

    let date = new Date(this.props.date),
        zeroPaddedMinutes = date.getMinutes();

    if (zeroPaddedMinutes<10) zeroPaddedMinutes = `0${zeroPaddedMinutes}`;

    return (
      <span className={this.props.className || ""}>
        {date.toLocaleDateString()}
        {!this.props.hideTime &&
          <small className="text-muted ml-1">
            {date.getHours()}:{zeroPaddedMinutes}
          </small>
        }
      </span>
    );
  }
}

export default PrettyLocalDateTime;
