import React from "react";
import { connect } from "react-redux";
import { post, expectNoContent } from "../_utils/humpyAjax";
import { cleanParams } from "../_utils/ajax";

import FormPopup from "../_common/FormPopup";
import { closeSetAsReviewed } from "./_actions";

export class SetAsReviewedPopup extends React.Component {
  state = {
    postingSetAsReviewed: false,
  }

  setAsReviewed() {
    const { allResultsSelected, cleanFilters, selectedResults } = this.props,
          ids = allResultsSelected ? "all" : selectedResults;

    this.setState({ postingSetAsReviewed: true });

    post("/translation-requests/bulk_set_as_reviewed", { ids, ...cleanFilters, }, expectNoContent)
      .then(() => location.reload())
      .catch(() => {
        alert("Something went wrong");
        this.setState({ postingSetAsReviewed: false });
      });
  }

  render() {
    const {
      setAsReviewedPopupOpen,
      closeSetAsReviewed,
    } = this.props;

    const {
      postingSetAsReviewed
    } = this.state;

    if (!setAsReviewedPopupOpen) return null;

    return (
      <FormPopup wrapperCallback={() => closeSetAsReviewed()}>
        <h3>Accept the tasks in the selected translation requests ?</h3>
        <p>Are you sure you want to accept all those translation requests?</p>

        <button onClick={() => closeSetAsReviewed()} className="btn btn-outline-primary float-left">Cancel</button>
        <button
          onClick={() => this.setAsReviewed()}
          className="btn btn-primary float-right"
          disabled={postingSetAsReviewed}
        >
          Accept selected Ready to Review TRs
        </button>
      </FormPopup>
    );
  }
}

export default connect(
  ({ setAsReviewedPopupOpen, allResultsSelected, filters, selectedResults }) => ({
    setAsReviewedPopupOpen,
    allResultsSelected,
    cleanFilters: cleanParams(filters),
    selectedResults,
  }),
  { closeSetAsReviewed }
)(SetAsReviewedPopup);
