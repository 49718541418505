import React from "react";
import { connect } from "react-redux";

import TableSortableHeader from "../_common/TableSortableHeader";
import { requestFiltersUpdate, selectAllResults, deselectAllResults } from "../_common/filtered_lists/_actions";


export class Headers extends React.Component {
  toggleSelectAll(e) {
    const { selectableResults, deselectAllResults, selectAllResults } = this.props;

    if (e.target.checked) {
      selectAllResults(selectableResults);
    } else {
      deselectAllResults();
    }
  }

  render() {
    const { requestResults, sortAttribute, sortOrder, allResultsSelected } = this.props;

    const headers = [
      { checkbox: true, checked: allResultsSelected, onChange: e => this.toggleSelectAll(e) },
      { attribute: "id", sortable: true },
      { attribute: "email", sortable: true },
      { attribute: "full_name", sortable: true, label: "Full name" },
      { attribute: "native_language_code", sortable: true, label: "Native language code" },
    ];

    return (
      <table className="table mb-0">
        <TableSortableHeader  {...{ sortAttribute, sortOrder, headers, requestResults }} />
      </table>
    );
  }
}

export default connect(
  ({ filters: { sortAttribute, sortOrder }, allResultsSelected, translators }) => ({
    sortAttribute,
    sortOrder,
    allResultsSelected,
    selectableResults: translators.map(t => t.id)
  }),
  {
    requestResults: (sortOrder, sortAttribute) => requestFiltersUpdate({ page: 1, sortOrder, sortAttribute }),
    selectAllResults,
    deselectAllResults
  }
)(Headers);
