import React from "react";

export class FormPopup extends React.Component {
  render() {
    const { children, wrapperCallback, className} = this.props;

    return (
      <div className={`popup_wrapper ${className}`} onClick={() => wrapperCallback ? wrapperCallback() : null}>
        <div className="popup" onClick={ e => e.stopPropagation() }>
          <div className="card">
            <div className="card-body">
              <div className="popup__content">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormPopup;
