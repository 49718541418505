import React from "react";

function createChart(values, context) {
  let chart = new Chart(context, {
    type: "bar",
    data: valuesToData(values),
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        xAxes: [{
          ticks: {
            display: false
          }
        }],
        yAxes: [{
          ticks: { beginAtZero: true }
        }]
      }
    }
  });

  return chart;
}

function valuesToData(values) {
  return {
    labels: values.map(v => v.label),
    datasets: [{
      data: values.map(v => v.data),
      backgroundColor: values.map(v => v.color),
    }]
  };
}

export class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  componentDidMount() {
    let { values, excluded } = this.props,
        valuesToShow = values;

    if (excluded) valuesToShow = values.filter(v => !excluded.includes(v.label));
    if (valuesToShow.length) this.chart = createChart(valuesToShow, this.canvas.current);
  }

  componentDidUpdate({ values: oldValues, excluded: oldExcluded }) {
    let { values, excluded } = this.props,
        shouldUpdateChart = oldValues != values || excluded != oldExcluded,
        valuesToShow = values.filter(v => !excluded.includes(v.label));

    if (shouldUpdateChart && !this.chart) {
      this.chart = createChart(valuesToShow, this.canvas.current);
    } else if (shouldUpdateChart) {
      this.chart.data = valuesToData(valuesToShow);
      this.chart.update();
    }
  }

  componentWillUnmount() {
    if (this.chart) this.chart.destroy();
  }

  render() {
    return <canvas ref={this.canvas} ></canvas>;
  }
}

export default BarChart;