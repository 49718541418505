import React from "react";
import { connect } from "react-redux";

import FormSelect from "../_common/FormSelect";
import { requestFiltersUpdate } from "./_actions";

export class Sidebar extends React.Component {
  render() {
    const {
      slc,
      tlc,
      trainingEnabled,
      customPricing,
      sourceLanguages,
      targetLanguages,
      trainingEnabledOptions,
      customPricingOptions,
      requestFiltersUpdate
    } = this.props;

    return (
      <div className="col-3">
        <div className="card">
          <div className="card-body">
            <FormSelect
              className="form-control"
              label="Source language"
              options={sourceLanguages}
              name="slc"
              value={slc}
              onChange={e => requestFiltersUpdate({slc: e.target.value})}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />
            <FormSelect
              className="form-control"
              label="Target language"
              options={targetLanguages}
              name="tlc"
              value={tlc}
              onChange={e => requestFiltersUpdate({tlc: e.target.value})}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />
            <FormSelect
              className="form-control"
              label="Training enabled"
              options={trainingEnabledOptions}
              name="trainingEnabled"
              value={trainingEnabled}
              onChange={e => requestFiltersUpdate({trainingEnabled: e.target.value})}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />
            <FormSelect
              className="form-control"
              label="Custom pricing"
              options={customPricingOptions}
              name="customPricing"
              value={customPricing}
              onChange={e => requestFiltersUpdate({customPricing: e.target.value})}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
     filters: { slc, tlc, trainingEnabled, customPricing },
     staticData: { sourceLanguages, targetLanguages, trainingEnabledOptions, customPricingOptions }
  }) => ({
    slc, tlc, trainingEnabled, customPricing, sourceLanguages, targetLanguages, trainingEnabledOptions, customPricingOptions
  }),
  { requestFiltersUpdate: filters => requestFiltersUpdate({...filters, page: 1})}
)(Sidebar);
