import param from "jquery-param";
import humps from "humps";
import { cleanParams } from "./ajax";

import {
  get as oldGet,
  post as oldPost,
  patch as oldPatch,
  deleteRequest as oldDelete,
} from "./ajax";

export const handleJsonResponseAndCamelize = (response) => {
  return response.json()
    .catch(error => {
      // In general we expect valid JSON responses but it is frequent for certain requests to return empty responses
      // To avoid making basic AJAX functions verbose and unergonomic to use we support them here

      // 204 is the only response we can consistently expect to be empty, the rest we log in case there is an actual error
      if (response.status !== 204) console.warn(error);

      return response;
    })
    .then( json => humps.camelizeKeys(json) )
    .then(json => ({ json, ok: response.ok }))
    .then(({ json, ok }) => {
      if (!ok) throw { json };
      return json;
    });
};

export const expectNoContent = response => {
  if (!response.ok) throw(response);
  return response;
};

export const get = (url, params = {}, handleResponse = handleJsonResponseAndCamelize) => {
  if (!Object.keys(params).length) return oldGet(`${url}`, handleResponse);
  let urlParams = param(humps.decamelizeKeys(params));
  return oldGet(`${url}?${urlParams}`, handleResponse);
};

export const post = (url, params, handleResponse = handleJsonResponseAndCamelize) => {
  let snakeParams = humps.decamelizeKeys(params);
  return oldPost(url, snakeParams, handleResponse);
};

export const patch = (url, params, handleResponse = handleJsonResponseAndCamelize) => {
  let snakeParams = humps.decamelizeKeys(params);
  return oldPatch(url, snakeParams, handleResponse);
};


export const deleteRequest = (url, params, handleResponse = handleJsonResponseAndCamelize) => {
  let snakeParams = humps.decamelizeKeys(params);
  return oldDelete(url, snakeParams, handleResponse);
};

// Gets url search params from a params hash
// WARNING: removes attributes with a falsy value
export const urlParams = (params) => {
  return param(humps.decamelizeKeys(cleanParams(params)));
};

// Returns a hash made of what is present in the url search params
// If permit key array is included it only returns an object with the keys present in it
export const urlSearchToHash = (permit) => {
  const { search } = window.location,
        params = new URLSearchParams(search).entries();

  var searchHash = {};

  // A url search might contain the same param more than once like /x?a=1&a=2
  // With this loop we get the latest
  for (const param of params) {
    const key = humps.camelize(param[0]),
          value = param[1];

    if (permit && !permit.includes(key)) continue;

    try {
      searchHash[key] = JSON.parse(value);
    } catch (e) {
      searchHash[key] = value;
    }
  }

  return searchHash;
};

// WARNING: initial filters are used as reference to get rid of irrelevant parameters
export const urlSearchToInitialState = (initialFilters) => {
  return {
    ...initialFilters,
    ...urlSearchToHash(Object.keys(initialFilters)),
  };
};

// To be consumed bt redux api baseQuery
// TODO: Probably needs to reconsider how errors should be handled (for example differentiating data parsing errors from validation)
// The approach in graphqlBaseQuery.js to differentiate errors seems it could be adapted here
// urlParams is defined by what builder.query().query returns
export const baseQuery = ({ baseUrl }) => async (urlParams) => {
  try {
    const data = await get(baseUrl + urlParams + ".json");
    return { data };
  } catch (error) {
    return { error };
  }
};