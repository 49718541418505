import { useRef, useEffect, useState } from "react";
import useDragScroll from "use-drag-scroll";

export default function useDragTable(reliants) {
  const sliderRef = useRef(null),
        [draggable, setDraggable] = useState(false);

  useDragScroll({ sliderRef, reliants });
  useEffect(() => {
    const { scrollWidth, clientWidth } = sliderRef.current;
    setDraggable(scrollWidth != clientWidth);
  });

  return {
    sliderRef,
    draggable,
  };
}