export const UPDATE_EDITABLE_FIELDS = "UPDATE_EDITABLE_FIELDS";
export const RESET_FIELDS = "RESET_FIELDS";
export const REMOVE_GLOSSARY = "REMOVE_GLOSSARY";

export const updateEditableFields = (field,value) => ({
  type: UPDATE_EDITABLE_FIELDS,
  field, value,
});

export const resetFields = (editableFields) => ({ type: RESET_FIELDS, editableFields });

export const removeGlossary = (glossaryId) => ({ type: REMOVE_GLOSSARY, glossaryId });
