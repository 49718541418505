import React from "react";

import { useGetInvoicesQuery } from "./_api";
import { useSelector } from "react-redux";
import InvoicesData from "./InvoicesData";
import Sidebar from "./Sidebar";

export default function Invoices() {
  const filters = useSelector(s => s.filters),
        {data: invoices} = useGetInvoicesQuery(filters);

  return (
    <>
      <h1 className="mb-3">
        Client invoices
        {invoices && <span className="text-muted ml-2">({invoices.totalItems})</span>}
      </h1>

      <div className="row">
        <Sidebar className="col-3" />
        <div className="col-9">
          <InvoicesData />
        </div>
      </div>
    </>
  );
}
