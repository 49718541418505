export const REQUEST_TRANSLATORS_PAGE = "REQUEST_TRANSLATORS_PAGE";
export const UPDATE_TRANSLATORS = "UPDATE_TRANSLATORS";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_TOTAL_PAGES = "UPDATE_TOTAL_PAGES";
export const UPDATE_ORDER = "UPDATE_ORDER";

export const requestTranslatorsPage = (page, order) => (dispatch, getState) => {
  const url = getState().staticData.url;

  dispatch({ type: REQUEST_TRANSLATORS_PAGE, page, order, url});
};

export const updateTranslators = (translators) => ({
  type: UPDATE_TRANSLATORS, translators
});

export const updatePage = (page) => ({
  type: UPDATE_PAGE, page
});

export const updateTotalPages = (totalPages) => ({
  type: UPDATE_TOTAL_PAGES, totalPages
});

export const updateOrder = ({type, order}) => ({
  type: UPDATE_ORDER, order: {type, order}
});