import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import sliceReducers from "./_slices";
import { gqlApi, ajaxApi } from "./_api";
import ManualOperations from "./ManualOperations";

export const store = configureStore({
  reducer: {
    ...sliceReducers,
    [ajaxApi.reducerPath]: ajaxApi.reducer,
    [gqlApi.reducerPath]:  gqlApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(gqlApi.middleware)
});

ReactDOM.render(
  <Provider store={store}>
    <ManualOperations />
  </Provider>,
  document.getElementById("manual-operations")
);
