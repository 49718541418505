import React from "react";

import { SimpleSelect } from "../all";
import { sortByLocale } from "../../_utils/sortingMethods";

export default function ConfigureSorting({
  sorting,
  setSorting,
  sortableKeys,
  sortableKeysLabels,
}) {
  if (!sortableKeys) return null;

  const sortingOptions = sortableKeys.map((value) => ([sortableKeysLabels(value), value] ))
    .sort(([a], [b]) => sortByLocale(a, b));

  return (
    <div className="mb-3">
      <h2>Sort by</h2>
      <div className="grid mb-4">
        <div className="gcol-6">
          <SimpleSelect
            name={"sortAttribute"}
            value={sorting.sortAttribute}
            options={sortingOptions}
            onChange={e => setSorting({ ...sorting, sortAttribute: e.target.value })}
          />
        </div>
        <div className="gcol-6">
          <SimpleSelect
            name={"sortOrder"}
            value={sorting.sortOrder}
            options={[["Ascending", "asc"], ["Descending", "desc"]]}
            onChange={e => setSorting({ ...sorting, sortOrder: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
}