import { combineReducers } from "redux";

import {
  UPDATE_WHITELISTED_SKILLS,
  ADD_WHITELISTED_SKILL,
  UPDATE_CLIENTS,
  ADD_CLIENT,
  UPDATE_SKILLS,
  UPDATE_CONSTANT_DATA,
} from "./_actions";

export const whitelistedSkills = (state = [], action) => {
  switch (action.type) {
    case UPDATE_WHITELISTED_SKILLS:
      return action.whitelistedSkills;
    case ADD_WHITELISTED_SKILL:
      return [...state, action.whitelistedSkill];
    default:
      return state;
  }
};

export const clients = (state = [], action) => {
  if (action.type == UPDATE_CLIENTS) return action.clients;
  if (action.type == ADD_CLIENT) return [...state, action.client];
  return state;
};

export const translatorAccountId = (state = null) => state;

export const humanQualities =  (state = [], action) => {
  if (action.type == UPDATE_CONSTANT_DATA) return action.humanQualities;
  return state;
};

export const twoHumansQualities = (state = [], action) => {
  if (action.type == UPDATE_CONSTANT_DATA) return action.twoHumansQualities;
  return state;
};

export const skills = (state = [], action) => {
  if (action.type == UPDATE_SKILLS) return action.skills;
  return state;
};

export default combineReducers({
  clients,
  humanQualities,
  skills,
  translatorAccountId,
  twoHumansQualities,
  whitelistedSkills,
});