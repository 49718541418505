import React from "react";

import Tabs from "./Tabs";
import ResultsTable from "./ResultsTable";

export class Results extends React.Component {
  render() {
    return (
      <div className="col-9">
        <Tabs />
        <ResultsTable />
      </div>
    );
  }
}

export default Results;
