import React from "react";
import { connect } from "react-redux";

import { get } from "../_utils/humpyAjax";
import Pagination from "../_common/pagination/Pagination";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";

const verdictColorClass = {
  "bad_quality":"text-danger",
  "basic_quality":"text-warning",
  "standard_quality":"text-primary",
  "premium_quality":"text-info",
  "expert_quality":"text-success",
};

export class VerdictsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verdicts: [],
      page: 1,
      totalPages: 1
    };
  }

  componentDidMount() {
    this.getPage(1);
  }

  getPage(page) {
    const { machineTranslatorPromptId } = this.props;
    get(this.props.paths.verdicts, { page, machineTranslatorPromptId })
      .then(({ verdicts, page, totalPages }) => {
        this.setState({ verdicts, page, totalPages });
      });
  }

  render() {
    const {verdicts, page, totalPages} = this.state,
          { verdictsCount } = this.props;

    return (
      <div>
        <h6>Verdicts <small className="text-muted">({verdictsCount})</small></h6>

        {verdicts.map(({ id, proofreaderVerdict, proofreaderVerdictText, taskPath, completedAt }) => (
          <div key={`task-verdict-${id}`} className="mb-2">
            <small>
              <nav>
                <strong>
                  <a className={verdictColorClass[proofreaderVerdict]} href={taskPath}>Task #{id} {proofreaderVerdict}</a>
                </strong>
                <PrettyLocalDateTime className="text-muted float-right" date={completedAt} />
              </nav>
              {proofreaderVerdictText}
            </small>
          </div>
        ))}

        {totalPages > 1 ?
          <Pagination
            className="pagination pagination-sm"
            totalPages={totalPages}
            page={page}
            changePage={(page)=>this.getPage(page)}
          />
          : null
        }
      </div>
    );
  }
}

export default connect(
  ({ staticData: { verdictsCount, paths }, machineTranslatorPromptId }) => ({ verdictsCount, paths, machineTranslatorPromptId })
)(VerdictsList);
