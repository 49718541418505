import gqlClient from "../_utils/graphQlRequests";
import { getPurchaseOrderQuery } from "./_gql";

export const SET_PURCHASE_ORDER = "SET_PURCHASE_ORDER";
export const UPDATE_PURCHASE_ORDER = "UPDATE_PURCHASE_ORDER";

export const setPurchaseOrder = (purchaseOrder) => ({
  type: SET_PURCHASE_ORDER, purchaseOrder
});

export const updatePurchaseOrder = (changes) => ({
  type: UPDATE_PURCHASE_ORDER, changes
});

export const loadPurchaseOrder = (id) => (dispatch) => {
  gqlClient.request(getPurchaseOrderQuery, { id })
    .then( data => dispatch(setPurchaseOrder(data.purchaseOrder)) );
};