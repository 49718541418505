import React from "react";
import { connect } from "react-redux";

import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";
import RemoveQualityReview from "./RemoveQualityReview";

export class List extends React.Component {
  render() {
    const { qualityReviews } = this.props;

    return (
      <div>
        {qualityReviews.map(qualityReview => {
          const {
            translatorAccountId,
            translatorAccount,
            translatorVerdict,
            proofreaderVerdict,
            verdictText,
            updatedAt,
            confirmed,
            id,
          } = qualityReview;

          if (!confirmed) return(
            <React.Fragment key={qualityReview.id}>
              <hr/>
              <RemoveQualityReview id={id} />
              <p>
                Unconfirmed Quality Review by
                <a className="ml-1" href={`/translators/${translatorAccountId}`} target="_blank" rel="noopener noreferrer">
                  {translatorAccount.name}
                </a>
                <small className="text-muted">
                  <span className="ml-2 mr-2">-</span>
                  Last updated: <PrettyLocalDateTime date={updatedAt} />
                </small>
              </p>
            </React.Fragment>
          );

          return (
            <div key={qualityReview.id}>
              <hr />
              <RemoveQualityReview id={id} />
              <p className="text-muted">
                <small>
                  Reviewed by:
                  <a className="ml-1" href={`/translators/${translatorAccountId}`} target="_blank" rel="noopener noreferrer">
                    {translatorAccount.name}
                  </a>
                  <span className="ml-2 mr-2">-</span>
                  Last updated: <PrettyLocalDateTime date={updatedAt} />
                </small>
              </p>

              <ul className="list-unstyled">
                {translatorVerdict &&
                  <li>
                    <strong className="mr-1">Translator:</strong>
                    {I18n.t(`qualities.${translatorVerdict}`)}
                  </li>
                }
                {proofreaderVerdict &&
                  <li>
                    <strong className="mr-1">{translatorVerdict ? "Proofreader" : "Posteditor"}:</strong>
                    {I18n.t(`qualities.${proofreaderVerdict}`)}
                  </li>
                }
              </ul>

              <p>
                <strong>Justification:</strong>
                <br />
                {verdictText}
              </p>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(
  ({ qualityReviews, mode  }) => ({ qualityReviews , mode }),
)(List);
