import React from "react";
import { connect } from "react-redux";

import WhitelistedQualities from "./WhitelistedQualities";

const stopPropagation = e => e.stopPropagation();

export class TranslatorsList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      expandedRow: {}
    };
  }

  toggleRowExpansion(id){
    const { expandedRow } = this.state;

    this.setState({ expandedRow: { ...expandedRow, [id]: !expandedRow[id] } });
  }

  render() {
    const { translators, languagePair } = this.props,
          { expandedRow } = this.state;

    return (
      <tbody>
        {translators.map(({
          id,
          fullName,
          url,
          email,
          translationBackground: {
            nativeLanguageCode,
            resume,
            prozUrl,
            linkedInUrl,
            selectedExperienceFieldKeys
          },
          qualityLevel,
          activity,
          verdictCounts,
          tasksCount,
          whitelistedQualities,
        }) => {
          return (
            <tr key={`translator-row-${id}`} className={expandedRow[id] ? "whitelist__collapsable--open" : ""} style={{ width: "30vw" }}>
              <td onClick={()=> this.toggleRowExpansion(id)} >
                <div className="whitelist__collapsable">
                  <b>{fullName}</b>
                  <br/>
                  <a href={url} target="_blank" rel="noreferrer noopener" onClick={stopPropagation} >{email}</a>
                  <br/>
                  <small className="text-muted">
                    Native {nativeLanguageCode ?
                      I18n.t("languages")[nativeLanguageCode]
                      : "undefined"
                    }
                  </small>
                  <br/>
                  <small className="text-muted">
                    {qualityLevel} {languagePair}
                  </small>
                  <br/>
                  <small className="text-muted">
                    Activity: {activity || "undefined"}
                  </small>
                  <br/>
                  {Object.keys(verdictCounts).map( verdict => (
                    <React.Fragment key={`translator-row-${id}-verdict-${verdict}`}>
                      <small className="text-muted">
                        {verdict} {verdictCounts[verdict]}
                      </small>
                      <br/>
                    </React.Fragment>
                  ))}
                </div>
              </td>

              <td onClick={()=> this.toggleRowExpansion(id)} >
                {linkedInUrl ?
                  <a className="mr-3" href={linkedInUrl} target="_blank" rel="noreferrer noopener" onClick={stopPropagation} >LinkedIn profile</a>
                  : <small className="text-muted mr-3">No LinkedIn </small>
                }

                {prozUrl ?
                  <a className="mr-3" href={prozUrl} target="_blank" rel="noreferrer noopener" onClick={stopPropagation} >Proz profile</a>
                  : <small className="text-muted mr-3">No LinkedIn </small>
                }

                {resume ?
                  <div className="whitelist__collapsable resume">{resume}</div>
                  : <small className="text-muted">No CV</small>
                }
              </td>

              <td onClick={()=> this.toggleRowExpansion(id)} >
                <div className="whitelist__collapsable">
                  {(selectedExperienceFieldKeys || []).map( expKey => (
                    <small className="whitelist__experience_field" key={`translator-row-${id}-expKey-${expKey}`}>
                      {I18n.t(`experience_fields.${expKey}`)}
                    </small>
                  ))}
                </div>
              </td>

              <td onClick={()=> this.toggleRowExpansion(id)} >{tasksCount}</td>

              <td className="whitelist__table__form">
                <WhitelistedQualities initialWhitelistedQualities={whitelistedQualities}  id={id} email={email} />
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default connect(
  ({ translators, filters: { languagePair } }) => ({ translators, languagePair })
)(TranslatorsList);
