import { gql } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/query/react";
import humps from "humps";

import { graphqlBaseQuery } from "../_utils/graphqlBaseQuery";
import { baseQuery } from "../_utils/humpyAjax";


export const ajaxApi = createApi({
  reducerPath: "staticData",
  baseQuery:   baseQuery({baseUrl: window.location.pathname}),
  endpoints:   (builder) => ({
    getStaticData: builder.query({
      query:             ()=>"",
      transformResponse: r => humps.camelizeKeys(r)
    })
  })
});

export const { useGetStaticDataQuery } = ajaxApi;

const manualOperation = `
  amount
  createdAt
  creator {
    email
    id
    name
  }
  billingAccount {
    clientAccount {
      name
      accountManagerEmail
    }
  }
  payment {
    refundable
  }
  currency
  description
  id
  rateUsed
  template
  title
  type
  updatedAt
`;


export const gqlApi = createApi({
  reducerPath: "gql",
  baseQuery:   graphqlBaseQuery(),
  tagTypes:    ["ManualOperations"],
  endpoints:   (builder) => ({
    getManualOperations: builder.query({
      providesTags:      ["ManualOperations"],
      transformResponse: response => {
        if (!response?.manualOperations) return;

        return {
          items:      response.manualOperations.nodes,
          totalItems: response.manualOperations.nodesCount,
          totalPages: response.manualOperations.pagesCount,
        };
      },
      query: (input) => ({
        input,
        body: gql`
        query ManualOperations(
            $billingAccountId: ID!,
            $sortAttribute: ManualOperationSortAttributeEnum,
            $sortOrder: SortOrderEnum,
            $page: Int,
            $perPage: Int,
          ){
          manualOperations(
            billingAccountId: $billingAccountId,
            sortAttribute: $sortAttribute,
            sortOrder: $sortOrder,
            page: $page,
            perPage: $perPage,
          ){
            nodes {
              ${manualOperation}
            }
            nodesCount
            pagesCount
          }
        }
        `
      })
    }),
    createManualOperation: builder.mutation({
      invalidatesTags: ["ManualOperations"],
      query:           input => ({
        input,
        body: gql`
          mutation manualOperationCreate( $input: ManualOperationCreateInput! ) {
            manualOperationCreate( input: $input ) {
              success
              errors
              manualOperation {
                ${manualOperation}
              }
            }
          }
        `
      })
    }),
    updateManualOperation: builder.mutation({
      invalidatesTags: ["ManualOperations"],
      query:           input => ({
        input,
        body: gql`
          mutation manualOperationUpdate( $input: ManualOperationUpdateInput! ) {
            manualOperationUpdate( input: $input ) {
              success
              errors
              manualOperation {
                ${manualOperation}
              }
            }
          }
        `
      })
    }),
    refundManualOperation: builder.mutation({
      invalidatesTags: ["ManualOperations"],
      query:           input => ({
        input,
        body: gql`
          mutation manualOperationRefund( $input: ManualOperationRefundInput! ) {
            manualOperationRefund( input: $input) {
              success
              errors
              manualOperation {
                ${manualOperation}
              }
            }
          }
        `
      })
    })
  })
});

export const {
  useGetManualOperationsQuery,
  useCreateManualOperationMutation,
  useUpdateManualOperationMutation,
  useRefundManualOperationMutation,
} = gqlApi;
