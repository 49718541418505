import React from "react";

import { hashedQualityHighlights } from "./_utils";
import HighlightedTranslation from "./HighlightedTranslation";
import TextWithTags from "./TextWithTags";
import History from "./History";

import { getTaskId } from './_utils';
import { post } from "../_utils/humpyAjax";
export class TaskTranslations extends React.Component {
  constructor(props) {
    const { qualityHighlights, qualityTypes, eventMode } = props;

    super(props);

    this.hashedQualityHighlights = hashedQualityHighlights(qualityHighlights, qualityTypes, eventMode);
  }

  releaseAiLock = (pretranslation) => {
    const taskId = getTaskId();
    const ref = pretranslation.originalRef || pretranslation.ref

    post(`/tasks/${taskId}/release_proofreading_lock`, { ref })
      .then(() => window.location.reload())
      .catch((error) => console.error(error));
  }

  render() {
    const {
      pretranslations,
      emailById,
      translatorPathById,
      eventMode,
      nmtName,
      isXliff,
    } = this.props;

    return (
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th width="60"> Id</th>
            <th width="60"> Ref</th>
            <th width="120">Type</th>
            <th>Text</th>
            <th width="250">Translators</th>
            <th width="150">Actions</th>
          </tr>
        </thead>

        <tbody>
          {pretranslations.map( (pretranslation,index) => {
            const id = eventMode ? pretranslation.ref :pretranslation.sourceChunkId,
                  translationHighlights = this.hashedQualityHighlights[id] &&
                    this.hashedQualityHighlights[id].filter(qh => qh.translatorAccountId == pretranslation.firstTranslator),
                  proofreaderHighlights = this.hashedQualityHighlights[id] &&
                    this.hashedQualityHighlights[id].filter(qh => qh.translatorAccountId == pretranslation.proofreader);
            return (
              <React.Fragment key={`pretranslation-${pretranslation.sourceChunkId}-${index}`}>
                <tr className="table-primary">
                  <td>{ pretranslation.sourceChunkId }</td>
                  <td>{ pretranslation.ref }</td>
                  <td className="text-muted"><small>Source</small></td>
                  <td><TextWithTags text={pretranslation.originalText} isXliff={isXliff} index={index} /></td>
                  <td></td>
                  <td></td>
                </tr>

                { pretranslation.googleTranslation &&
                  <tr className="text-muted">
                    <td></td>
                    <td></td>
                    <td><small>{nmtName}</small></td>
                    <td><TextWithTags text={pretranslation.googleTranslation} isXliff={isXliff} index={index} /></td>
                    <td></td>
                    <td></td>
                  </tr>
                }

                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-muted"><small>First translation</small></td>
                  <td>
                    {translationHighlights ?
                      <HighlightedTranslation
                        id={id}
                        text={pretranslation.firstTranslation}
                        highlights={translationHighlights}
                      />
                      :
                      <TextWithTags text={pretranslation.firstTranslation} isXliff={isXliff} index={index} />
                    }
                  </td>
                  <td>
                    {pretranslation.firstTranslator ?
                      <a className="hide_for_printers" href={translatorPathById[pretranslation.firstTranslator]}>{emailById[pretranslation.firstTranslator]}</a>
                      : null
                    }
                  </td>
                  <td>
                    { pretranslation.proofreadingLock && <>
                      <button className="btn btn-primary btn-sm pull-right" onClick={() => this.releaseAiLock(pretranslation)}>Release AI lock (AI confidence: { pretranslation.confidencePercentage }%)</button>
                    </>}
                  </td>
                </tr>

                {pretranslation.proofread ?
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-muted"><small>Corrected translation</small></td>
                    <td>
                      {pretranslation.correctedTranslation && proofreaderHighlights ?
                        <HighlightedTranslation
                          id={id}
                          text={pretranslation.correctedTranslation}
                          highlights={proofreaderHighlights}
                          isXliff={isXliff}
                        />
                        :
                        (
                          pretranslation.correctedTranslation ?
                            <TextWithTags text={pretranslation.correctedTranslation} isXliff={isXliff} index={index} />
                            :
                            <span className="text-muted">accepted</span>
                        )
                      }
                    </td>
                    <td>
                      <a className="hide_for_printers" href={translatorPathById[pretranslation.proofreader]}>{emailById[pretranslation.proofreader]}</a>
                    </td>
                    <td></td>
                  </tr>
                  : null
                }

                {pretranslation.rejectMessage && pretranslation.rejectMessage.map((msg,index2) => {
                  return (
                    <tr key={`pretranslation-msg-${pretranslation.sourceChunkId}-${index}-${index2}`}>
                      <td></td>
                      <td></td>
                      <td className="text-muted"><small>Correction</small></td>
                      <td className="text-danger">{ msg }</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }) }

                <History {...{pretranslation, translatorPathById, emailById}} />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default TaskTranslations;
