import React from "react";
import { connect } from "react-redux";

import { potentialMergeablesList } from "./_selectors";
import { changeMergeableIndex } from "./_actionsAndReducers";

let SIDEBAR_TOP = 20;

export class Sidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      left: null,
      width: null,
      position: "relative",
    };
  }

  componentDidMount() {
    let { left, width, top } = this.sidebar.getBoundingClientRect(),
        scrollLimit = top + window.scrollY - SIDEBAR_TOP;

    this.setState({ left, width });

    window.addEventListener("scroll", () => {
      if (window.scrollY > scrollLimit && this.state.position == "relative") this.setState({position: "fixed"});
      if (window.scrollY < scrollLimit && this.state.position == "fixed") this.setState({ position: "relative" });
    });
  }

  moveTo({name, chunkId, index}) {
    document.getElementById(`${name}-${chunkId}-${index}`).scrollIntoView({ block: "start", behavior: "smooth" });
  }

  moveToNext = (e) => {
    let { potentialMergeablesList, currentMergeableIndex, changeMergeableIndex } = this.props;

    e.preventDefault();
    if (!potentialMergeablesList.length) return;

    if (currentMergeableIndex !== null && currentMergeableIndex < potentialMergeablesList.length - 1) {
      currentMergeableIndex++;
    } else {
      currentMergeableIndex = 0;
    }

    this.moveTo(potentialMergeablesList[currentMergeableIndex]);
    changeMergeableIndex(currentMergeableIndex);
  }

  moveToPrev = (e) => {
    let { potentialMergeablesList, currentMergeableIndex, changeMergeableIndex } = this.props;

    e.preventDefault();
    if (!potentialMergeablesList.length) return;

    if (currentMergeableIndex !== null && currentMergeableIndex > 0) {
      currentMergeableIndex--;
    } else {
      currentMergeableIndex = potentialMergeablesList.length - 1;
    }

    this.moveTo(potentialMergeablesList[currentMergeableIndex]);
    changeMergeableIndex(currentMergeableIndex);
  }

  render() {
    let { width, left, position} = this.state,
        relative = position == "relative",
        { potentialMergeablesList } = this.props;

    return (
      <div
        className="col-4"
        ref={sidebar => this.sidebar = sidebar}
        style={{
          width: relative ? "auto" : width,
          left: relative ? "auto" : left,
          top: relative ? "auto" : SIDEBAR_TOP,
          position
        }}
      >
        <h2>Merge UI</h2>
        <p>You can merge phrase by clicking on the first and last of the group you want to merge.</p>
        <p>You cannot merge phrases belonging to different tasks and/or keys</p>
        <p>You can navigate over sentences starting with lower case characters, which will potentially need merging, that doesn't mean all of them mergeable nor that all those needing merge will be hihglighted:</p>
        <button
          className="btn btn-outline-primary mr-2"
          disabled={!potentialMergeablesList.length}
          onClick={this.moveToPrev}
        >
          <small className="ticon-left"></small> Previous
        </button>
        <button
          className="btn btn-outline-primary"
          disabled={!potentialMergeablesList.length}
          onClick={this.moveToNext}
        >
          Next <small className="ticon-right"></small>
        </button>
      </div>
    );
  }
}

export default connect(
  (state)=>({
    potentialMergeablesList: potentialMergeablesList(state),
    currentMergeableIndex: state.currentMergeableIndex
  }),
  { changeMergeableIndex }
)(Sidebar);
