// Props summary
// id: MANDATORY Id of the calendar
// label: optional
// callback: function to execute on day selection
// value: selected input value

import React from "react";
import Calendar from "react-calendar";

import { onClickOutside } from "../_utils/customClickEvents";

export class FormCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      value: this.props.value,
    };
  }

  componentDidMount() {
    this.onClickOutsideCalendar = onClickOutside(`#${this.props.id}`, () => this.hideCalendar());
  }

  componentWillUnmount() {
    this.onClickOutsideCalendar.stop();
  }

  componentDidUpdate(oldProps){
    if (oldProps.value != this.props.value) {
      this.setState({ value: this.props.value});
    }
  }

  handleInputChange(value){
    this.setState({value});
    if (!value) this.props.callback(null);
    if (Date.parse(value)) this.props.callback(value);
  }

  showCalendar() {
    this.setState({ showCalendar: true });
  }

  hideCalendar() {
    this.setState({ showCalendar: false });
  }

  selectDate(date) {
    this.props.callback( moment(date).format("LL") );
    this.hideCalendar();
  }

  render() {
    const { id, label } = this.props,
          { value } = this.state,
          { showCalendar } = this.state,
          date = !value || isNaN(value) ? null : new Date(value);

    return (
      <div id={id}>
        <div className="form-group">
          {label ? <label htmlFor="exampleInputEmail1">{label}</label> : null}
          <div className="input-group mb-2 mr-sm-2 mb-sm-0">
            <input
              type="text"
              className="form-control"
              placeholder="Select your date"
              name="search"
              value={value || ""}
              onChange={e => this.handleInputChange(e.target.value)}
              onFocus={() => this.showCalendar()}
              autoComplete="off"
            />
            <div className="input-group-append">
              <span className="input-group-text ticon-calendar" onClick={() => this.showCalendar()} ></span>
            </div>
          </div>
        </div>
        {showCalendar ?
          <Calendar
            className="calendar"
            activeStartDate={date}
            value={date}
            onClickDay={(date) => this.selectDate(date)}
            maxDate={new Date()}
          />
          : null
        }
      </div>
    );
  }
}

export default FormCalendar;
