import React from "react";
import { connect } from "react-redux";

import { get } from "../_utils/humpyAjax";
import BarChart from "./BarChart";
import { QUALITY_DIMENSIONS_COLORS as colors } from "./_constants";

export class QualityDimensionsTotals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totals: {},
      excluded: [
        I18n.t("metrics.spellingAutomated")
      ],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { machineTranslatorPromptId } = this.props;

    get(this.props.paths.qualityDimensionsTotals, { machineTranslatorPromptId })
      .then(totals => {
        this.setState({ totals });
      });
  }

  toggleExcludedItem(item) {
    let { excluded } = this.state;

    if (excluded.includes(item)) {
      excluded = excluded.filter(l => l != item);
    } else {
      excluded = [...excluded, item];
    }

    this.setState({ excluded });
  }

  render() {
    const { totals, excluded } = this.state;

    let values = [];

    Object.keys(totals).forEach(key => {
      values.push({
        label: I18n.t(`metrics.${key}`),
        data: totals[key],
        color: colors[key]
      });
    });

    return (
      <div>
        <div className="mt-3 mb-4" style={{ height: "230px" }}>
          <BarChart values={values} excluded={excluded} />
        </div>
        <ul className="chart__legends chart__legends--2-columns">
          {Object.keys(totals).map((key) => {
            const label = I18n.t(`metrics.${key}`);

            return (
              <li
                className="chart__legend mb-2"
                key={`quality-dimension-total-${key}`}
                onClick={() => this.toggleExcludedItem(label)}
                style={{ textDecoration: excluded.includes(label) ? "line-through" : "none" }}
              >
                <div className="chart__legend__box" style={{ backgroundColor: colors[key] }}></div>
                {I18n.t(`metrics.${key}`)}: <strong>{totals[key]}</strong>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default connect(
  ({ staticData: { paths }, machineTranslatorPromptId }) => ({ paths, machineTranslatorPromptId })
)(QualityDimensionsTotals);
