import { delay } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { get as oldGet, urlParams as oldUrlParams } from "../../_utils/ajax";
import { get, urlParams } from "../../_utils/humpyAjax";

import { updateResults, updateFilters, loading, notLoading } from "./_actions";

export const hocGetResults = (resultsName, url) => function*({ filters }) {
  yield put(updateFilters(filters));
  yield put(loading());
  let params = oldUrlParams(filters);
  yield call(delay, 200);
  try {
    let response = yield call(oldGet, `${url}.json?${params}`);
    let parsedResponse = yield call([response, "json"]);
    let { totalCount, totalPages, ...otherChanges} = parsedResponse;
    yield put(updateResults(parsedResponse[resultsName], totalCount, totalPages, otherChanges));
    history.replaceState(null, null, `${url}?${params}`);
  }
  catch (error) {
    alert("The request failed");
  }
  yield put(notLoading());
};

export const hocGetHumpedResults = (resultsName, url) => function* ({ filters }) {
  yield put(updateFilters(filters));
  yield put(loading());
  yield call(delay, 200);
  let params = urlParams(filters);

  try {
    let response = yield call(get, `${url}.json?${params}`);
    let { totalCount, totalPages, ...otherChanges } = response;
    yield put(updateResults(response[resultsName], totalCount, totalPages, otherChanges));
    history.replaceState(null, null, `${url}?${params}`);
  }
  catch (error) {
    alert("The request failed");
  }
  yield put(notLoading());
};