import { combineReducers } from "redux";

import {
  UPDATE_NEW_CLIENT_LANGUAGE_PAIR_PRICING,
  CLEAN_NEW_CLIENT_LANGUAGE_PAIR_PRICING,
  ADD_CLIENT_LANGUAGE_PAIR_PRICING,
  UPDATE_CLIENT_LANGUAGE_PAIR_PRICING,
  REMOVE_CLIENT_LANGUAGE_PAIR_PRICING,
  ADD_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING,
  UPDATE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING,
  REMOVE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING,
  SET_CLIENT_DEFAULT_PRICING,
  UPDATE_CLIENT_DEFAULT_PRICING,
  UPDATE_CLIENT_LANGUAGE_PAIR_PRICINGS,
  CHANGE_CLIENT_LANGUAGE_PAIR_PRICING_SCOPE,
} from "./_actions";

const newClientLanguagePairPricingInitState = {
  type: "client",
  sourceLanguageCode: "",
  targetLanguageCode: "",
  salesMargin: "",
  pricePerWordPosteditingUsd: "",
  pricePerWordPosteditingEur: "",
  pricePerWordTranslationUsd: "",
  pricePerWordTranslationEur: "",
  pricePerWordLocalizationUsd: "",
  pricePerWordLocalizationEur: "",
  pricePerWordExpertUsd: "",
  pricePerWordExpertEur: "",
  pricePerWordMachineUsd: "",
  pricePerWordMachineEur: "",
  validSourceLanguageCode: true,
  validTargetLanguageCode: true,
};

const clientDefaultPricingInitState = {
  salesMargin: "",
  pricePerWordPosteditingUsd: "",
  pricePerWordPosteditingEur: "",
  pricePerWordTranslationUsd: "",
  pricePerWordTranslationEur: "",
  pricePerWordLocalizationUsd: "",
  pricePerWordLocalizationEur: "",
  pricePerWordExpertUsd: "",
  pricePerWordExpertEur: "",
  pricePerWordMachineUsd: "",
  pricePerWordMachineEur: "",
};

export const newClientLanguagePairPricing = (state = newClientLanguagePairPricingInitState, action) => {
  switch (action.type) {
    case UPDATE_NEW_CLIENT_LANGUAGE_PAIR_PRICING:
      return {
        ...state,
        ...action.changes
      };
    case CLEAN_NEW_CLIENT_LANGUAGE_PAIR_PRICING:
      return {
        ...newClientLanguagePairPricingInitState
      };
    default:
      return state;
  }
};

export const clientLanguagePairPricings = (state = [], action) => {
  switch (action.type) {
    case ADD_CLIENT_LANGUAGE_PAIR_PRICING:
      return [
        action.clientLanguagePairPricing,
        ...state,
      ];

    case UPDATE_CLIENT_LANGUAGE_PAIR_PRICING:
      return state.map(pricing => {
        if (pricing.id === action.clientLanguagePairPricingId) {
          return {
            ...pricing,
            ...action.changes,
          };
        } else {
          return pricing;
        }
      });
    case REMOVE_CLIENT_LANGUAGE_PAIR_PRICING:
      return state.filter(pricing => pricing.id !== action.clientLanguagePairPricingId);
    case ADD_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING:
      return state.map(pricing => {
        if (pricing.id === action.clientLanguagePairPricingId) {
          return {
            ...pricing,
            workflowPricing: action.clientWorkflowLanguagePairPricing,
          };
        } else {
          return pricing;
        }
      });
    case UPDATE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING:
      return state.map(pricing => {
        if (pricing.id === action.clientLanguagePairPricingId) {
          return {
            ...pricing,
            workflowPricing: {
              ...pricing.workflowPricing,
              ...action.changes,
            }
          };
        } else {
          return pricing;
        }
      });
    case REMOVE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING:
      return state.map(pricing => {
        if (pricing.id === action.clientLanguagePairPricingId) {
          return {
            ...pricing,
            workflowPricing: null,
          };
        } else {
          return pricing;
        }
      });
    case UPDATE_CLIENT_LANGUAGE_PAIR_PRICINGS:
      return action.clientLanguagePairPricings;
    default:
      return state;
  }
};

export const clientDefaultPricing = (state = clientDefaultPricingInitState, action) => {
  switch (action.type) {
    case SET_CLIENT_DEFAULT_PRICING:
      return {
        ...state,
        ...action.clientDefaultPricing
      };
    case UPDATE_CLIENT_DEFAULT_PRICING:
      return {
        ...state,
        ...action.changes
      };
    default:
      return state;
  }
};
const clientLanguagePairPricingsSearchParams = new URLSearchParams(window.location.search)
const clientLanguagePairPricingsInitialScope = {
  page: clientLanguagePairPricingsSearchParams.get('page') || 1,
  sourceLanguageCode: clientLanguagePairPricingsSearchParams.get('source_language_code') || '',
  targetLanguageCode: clientLanguagePairPricingsSearchParams.get('target_language_code') || ''
}
export const clientLanguagePairPricingsScope = (state = clientLanguagePairPricingsInitialScope, action) => {
  switch (action.type) {
    case CHANGE_CLIENT_LANGUAGE_PAIR_PRICING_SCOPE:
      return {
        ...state,
        ...action.changes
      };
    default:
      return state;
  }
}

export const clientAccount = (state = null) => state;

export default combineReducers({
  newClientLanguagePairPricing,
  clientLanguagePairPricings,
  clientLanguagePairPricingsScope,
  clientDefaultPricing,
  clientAccount,
});
