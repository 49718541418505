import React from "react";
import { useSelector } from "react-redux";

import CreateManualOperation from "./CreateManualOperation";
import UpdateManualOperation from "./UpdateManualOperation";
import RefundManualOperation from "./RefundManualOperation";

export default function Popup(){
  const currentPopup = useSelector(s=>s.currentPopup),
        currentManualOperation = useSelector(s=>s.currentManualOperation);

  switch (currentPopup) {
    case "create":
      return <CreateManualOperation />;
    case "edit":
      return <UpdateManualOperation manualOperation={currentManualOperation} />;
    case "refund":
      return <RefundManualOperation manualOperation={currentManualOperation} />;
    default:
      return null;
  }
}
