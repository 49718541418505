import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { get } from "../_utils/humpyAjax";
import { TIME_RANGES } from "./_constants";
import { QUALITY_DIMENSIONS_COLORS as colors } from "./_constants";
import Card from "../_common/Card";
import GroupedBarsChart from "./GroupedBarsChart";
import TimeRangeSelector from "./TimeRangeSelector";
import QualityDimensionsTotals from "./QualityDimensionsTotals";
import TaskGrouping from "./TaskGrouping";
import ChartFile from "./ChartFile";
import ChartLegends from "./ChartLegends";

export class QualityDimensions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeRange: TIME_RANGES[0],
      values: [],
      dataSets: [],
      excluded: [
        I18n.t("metrics.spellingAutomated")
      ],
      stacked: true,
      groupSize: 1,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { machineTranslatorPromptId } = this.props;
    const { timeRange } = this.state;

    get(this.props.paths.qualityDimensions, { timeRange, machineTranslatorPromptId })
      .then(dataSets => {
        let keys = Object.keys(dataSets);

        const sortByDate = (a, b) => a.date.localeCompare(b.date);

        let values = {
          // every item has the same set of dates, so we just use the ones from the first
          // to get the dates that will label the data
          labels: dataSets[keys[0]].sort(sortByDate).map(set => `Task #${set.taskId}\n ${moment(set.date).format("LLL")}`),
          datasets:  keys.map( key => {
            return {
              label: I18n.t(`metrics.${key}`),
              backgroundColor: colors[key],
              data: dataSets[key].sort(sortByDate).map(set => set.value)
            };
          })
        };

        this.setState({ values, dataSets });
      });
  }

  changeTimeRange(timeRange) {
    this.setState({ timeRange }, () => this.getData());
  }

  toggleExcludedItem(item) {
    let { excluded } = this.state;

    if (excluded.includes(item)) {
      excluded = excluded.filter(l => l != item);
    } else {
      excluded = [...excluded, item];
    }

    this.setState({ excluded });
  }

  render() {
    const { timeRange, values, dataSets, excluded, stacked, groupSize } = this.state;

    return (
      <div className="col-12">
        <div className="row">
          <Card className="col-8">
            <ul className="nav nav-pills justify-content-end mb-3">
              <TaskGrouping changeSize={groupSize => this.setState({ groupSize })} groupSize={groupSize} />
              <ChartFile stacked={stacked} changeStacked={stacked=>this.setState({stacked})} />
              <TimeRangeSelector callback={(timeRange) => this.changeTimeRange(timeRange)} currentTimeRange={timeRange} />
            </ul>
            <GroupedBarsChart
              excluded={excluded}
              values={values}
              height="300px"
              stacked={stacked}
              groupSize={groupSize}
            />
            <ChartLegends
              dataSets={dataSets}
              colors={colors}
              excluded={excluded}
              keyNameGenerator={key => I18n.t(`metrics.${key}`)}
              toggleExcludedItem={item => this.toggleExcludedItem(item)}
            />
          </Card>

          <Card className="col-4" h100={true}>
            <QualityDimensionsTotals />
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ staticData: { paths }, machineTranslatorPromptId }) => ({ paths, machineTranslatorPromptId })
)(QualityDimensions);
