import { decodeHtmlEntities } from "../_utils/regexp";

import React from "react";

export class PlainTextFragment extends React.Component {
  render() {
    const { text } = this.props;

    if (!text) return "";

    return (
      <React.Fragment>
        {text.split(" ").map((textFragment, i, a) => (
          <React.Fragment key={`${textFragment}-${i}`}>
            {decodeHtmlEntities(textFragment)}
            {i < a.length - 1 ?
              <React.Fragment>
                <span className="visible_space"></span>{" "}
              </React.Fragment>
              : null
            }
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }
}

export default PlainTextFragment;