import React from "react";
import { connect } from "react-redux";

import Loader from "../_common/Loader";
import TableList from "./TableList";
import Pagination from "../_common/pagination/Pagination";
import { getBadVerdicts } from "./_actionsAndReducers";

export class BadVerdicts extends React.Component {
  componentDidMount() {
    this.props.getBadVerdicts();
  }

  render() {
    const { loading, error, badVerdicts, page, getBadVerdicts } = this.props;

    return (
      <div className="container-fluid my-3">
        <h2 className="my-3">Bad verdicts</h2>
        <div className="card">
          <div className="card-body">
            <div className="loader_wrapper">
              {loading ? <Loader/> : null}
              {badVerdicts.nodes.length == 0 && !loading &&
                <div className="jumbotron my-4">
                  <h1 className="display-4 text-center">There are no bad verdicts</h1>
                </div>
              }
              {badVerdicts.nodes.length > 0 &&
                <React.Fragment>
                  <TableList />
                  <Pagination
                    className="pull-right"
                    changePage={page => getBadVerdicts(page)}
                    page={page}
                    totalPages={badVerdicts.pagesCount}
                  />
                </React.Fragment>
              }
              {error &&
                <div className="jumbotron my-4">
                  <h1 className="display-4 text-center">Something went wrong loading the verdicts</h1>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ loading, error, badVerdicts, page }) => ({ loading, error, badVerdicts, page }),
  { getBadVerdicts }
)(BadVerdicts);
