import { gql } from "graphql-request";

export const updateTaskMutation = gql`
  mutation taskUpdate($input: TaskUpdateInput!) {
    taskUpdate(input: $input) {
      success
      errors
      task {
        proposedAction
        actionTaken
        translatorAnswered
        opsHandled
      }
    }
  }
`;

export const paginationDefaults = {
  page: 1,
  perPage: 20,
};

export const tasksQuery = gql`
  query getTasks($page: Int, $perPage: Int) {
    tasks(
      proofreaderVerdict: bad_quality,
      sortAttribute: proofreader_confirmed_at,
      sortOrder: desc,
      page: $page,
      perPage: $perPage
    ) {
      nodes {
        id
        proofreaderVerdict
        proofreaderVerdictText
        translatorAnswered
        opsHandled
        proposedAction
        actionTaken
        translatorAccount {
          id
          ... on TranslatorAccount {
            __typename
            user {
              email
            }
          }
          ... on MachineTranslator {
            __typename
            name
          }
        }
        proofreaderAccount {
          id
          user {
            email
          }
        }
        invitations(proofreading: true) {
          confirmedAt
        }
      }
      hasNextPage
      nodesCount
      pagesCount
    }
  }
`;
