import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { changeDueDate } from "./_actionsAndReducers";

export default function DueDate(){
  const clientAccount = useSelector(s=>s.clientAccount),
        dispatch = useDispatch();

  const onChange = e => dispatch(
    changeDueDate(parseInt(e.target.value))
  );

  if (!clientAccount) return null;

  return (
    <>
      <label className="col-3 mb-3 font-weight-bold">
        DueDate
      </label>
      <div className="col-3">
        <select 
          name="due-date" 
          id="due-date" 
          className="form-control"
          value={clientAccount.billingAccount.invoiceDueDays || 14}
          onChange={onChange}
        >
          <option value="7">7 days</option>
          <option value="14">14 days</option>
          <option value="21">21 days</option>
          <option value="30">30 days</option>
          <option value="60">60 days</option>
        </select>
      </div>
    </>
  );
}