import React from "react";
import { connect } from "react-redux";
import { post, get, expectNoContent } from "../_utils/humpyAjax";
import { cleanParams } from "../_utils/ajax";

import FormPopup from "../_common/FormPopup";
import DateTimePicker from "../_common/DateTimePicker";
import { closeDeadlinePopup, } from "./_actions";

export class DeadlinePopup extends React.Component {
  state = {
    proposedDeadlines: [],
    date: null,
  }

  componentDidUpdate({deadlinePopupOpen: oldDeadlinePopupOpen}) {
    const { allResultsSelected, selectedResults, deadlinePopupOpen, cleanFilters } = this.props,
          ids = allResultsSelected ? "all" : selectedResults;

    if (!deadlinePopupOpen || oldDeadlinePopupOpen == deadlinePopupOpen) return;

    get("/translation-requests/proposed_deadlines_variability", { ids, ...cleanFilters })
      .then(({ proposedDeadlines}) => this.setState({proposedDeadlines}));
  }

  header() {
    const {
      allResultsSelected,
      selectedResults,
      totalCount
    } = this.props;

    if (selectedResults.length == 1) {
      return <h3>Setting deadline for order <span className="text-muted">#{selectedResults[0]}</span></h3>;
    } else if (allResultsSelected) {
      return <h3>Setting deadlines for <span className="text-muted">{totalCount} orders</span></h3>;
    } else {
      return <h3>Setting deadlines for <span className="text-muted">{selectedResults.length} orders</span></h3>;
    }
  }

  proposedDeadlines() {
    const { proposedDeadlines } = this.state,
          nonNullDeadlines = proposedDeadlines.filter(d => d);

    if (nonNullDeadlines.length == 1 && nonNullDeadlines[0]) return moment(nonNullDeadlines[0]).format("LLL");
    if (proposedDeadlines.length <= 1 && !proposedDeadlines[0]) return "No proposed deadlines";
    return "Several different proposals";
  }

  proposedDeadlinesAlert() {
    const { proposedDeadlines } = this.state,
          nullDeadlines = proposedDeadlines.filter(d => !d);

    if (proposedDeadlines.length == 1) return;
    if (nullDeadlines.length) return "Some orders have no proposed date. Accepting will only effect those that do, using custom will affect everything.";
  }

  useCustomDeadline(){
    const { allResultsSelected, cleanFilters, selectedResults } = this.props,
          { date } = this.state,
          ids = allResultsSelected ? "all" : selectedResults;

    post("/translation-requests/change_deadlines", { ids, ...cleanFilters, date }, expectNoContent)
      .then( () => location.reload());
  }

  acceptProposedDeadlines(){
    const { allResultsSelected, cleanFilters, selectedResults } = this.props,
          ids = allResultsSelected ? "all" : selectedResults;

    post("/translation-requests/accept_deadlines", { ids, ...cleanFilters, }, expectNoContent)
      .then(()=>location.reload());
  }

  render() {
    const {
      closeDeadlinePopup,
      deadlinePopupOpen,
    } = this.props;

    const {
      date,
      proposedDeadlines,
    } = this.state;

    const noDeadlines = proposedDeadlines.filter(d => d).length == 0;

    const proposedDeadlinesAlert = this.proposedDeadlinesAlert();

    if (!deadlinePopupOpen) return null;

    return (
      <FormPopup wrapperCallback={() => closeDeadlinePopup()}>
        {this.header()}
        <div className="mb-2">
          <label className="font-weight-bold m-0 mr-2">Proposed deadline:</label>
          {this.proposedDeadlines()}
          {proposedDeadlinesAlert ?
            <div className="text-muted font-italic">
              <small><small className="ticon-warning mr-1"></small> {proposedDeadlinesAlert}</small>
            </div>
            : null
          }
        </div>

        <div className="mb-5">
          <label className="font-weight-bold mr-2">Custom deadline:</label>
          <DateTimePicker
            id="dealine-calendar"
            callback={(date) => this.setState({ date })}
            date={date}
            minDate={new Date()}
          />
        </div>

        <button
          className="btn btn-outline-primary float-left"
          onClick={() => closeDeadlinePopup()}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary float-right mr-2"
          onClick={()=>this.useCustomDeadline()}
          disabled={!date}
        >
          Use custom
        </button>
        <button
          className="btn btn-success float-right mr-2"
          disabled={noDeadlines}
          onClick={() => this.acceptProposedDeadlines()}
        >
          Accept proposed
        </button>
      </FormPopup>
    );
  }
}

export default connect(
  ({
    deadlinePopupOpen,
    allResultsSelected,
    selectedResults,
    totalCount,
    filters,
  }) => ({
    deadlinePopupOpen,
    allResultsSelected,
    selectedResults,
    totalCount,
    cleanFilters: cleanParams(filters),
  }),
  { closeDeadlinePopup }
)(DeadlinePopup);
