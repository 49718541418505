import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducers from "./_reducers";
import TrEditableFields from "./TrEditableFields";
import { get } from "../_utils/humpyAjax";

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const url = window.location.pathname + ".json";

get(url)
  .then(initialData => {
    window.EDITABLE_FIELDS_INITIAL_STATE = initialData.editableFields;
    let store = createStore(
      reducers,
      initialData,
      composeEnhancers(applyMiddleware(thunk))
    );

    ReactDOM.render(
      <Provider store={store}>
        <TrEditableFields />
      </Provider>,
      document.getElementById("tr-editable-fields"),
    );
  })
  .catch(()=>alert("Something went wrong loading editable fields"));
