import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import Results from "./Results";
import { Store } from "./Store";
import {
  exportSepaWithdrawals,
  initializeStore,
  requestFilteredWithdrawals,
} from "./_actions";

export default function Withdrawals({ initialData }) {
  const [exportFileUrl, setExportFileUrl] = useState();

  const didMountRef = useRef(false);
  const {
    state: { filters, totalValue, totalBalance, },
    dispatch
  } = useContext(Store);

  useEffect(() => { initializeStore(dispatch, initialData) }, []);
  useEffect(() => {
    if (didMountRef.current) {
      requestFilteredWithdrawals(dispatch, filters)
    } else {
      didMountRef.current = true;
    }
  }, [filters]);

  const handleExportSepaWithdrawals = async () => {
    const fileUrl = await exportSepaWithdrawals(dispatch);
    setExportFileUrl(fileUrl);
  };

  return (
    <div className="container-fluid">
      {exportFileUrl && <div className="row">
        <div className="col">
          <div className="alert alert-info alert-dismissible fade show">
            <a href={exportFileUrl}>{exportFileUrl}</a>
          </div>
        </div>
      </div>}

      <div className="row mt-4">
        <div className="col d-flex justify-content-between">
          <h2>
            Total balance: EUR {parseFloat(totalBalance).toFixed(2)}
          </h2>
        </div>
        <div className="col d-flex justify-content-between">
          <h2>
            Total uncredited value: EUR {parseFloat(totalValue).toFixed(2)}
          </h2>
        </div>
        <div className="col d-flex justify-content-end">
          <button
            className="btn btn-primary btn-lg float-right"
            onClick={handleExportSepaWithdrawals}
          >
            Export SEPA withdrawals
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <Sidebar className="col-3" />
        <Results className="col-9" />
      </div>
    </div>
  );
}
