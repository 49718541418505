import React from "react";
import { connect } from "react-redux";

export class TableBody extends React.Component {
  render() {
    const {trainingTexts} = this.props;

    return (
      <tbody>
        {trainingTexts.map(({
          languagePair, sourceLanguageCode, targetLanguageCode,
          totalTranslators, training, unreviewed, qualified,
          unqualified, judges, active, customPricing, waitingForJudgment
        }) => {
          const translatorsUrl = `/translators?sourceLanguageCodes[]=${sourceLanguageCode}&page=1&targetLanguageCodes[]=${targetLanguageCode}`;

          return (
            <tr key={`training-text-${sourceLanguageCode}-${targetLanguageCode}`}>
              <td><a href={`/language_pairs/${sourceLanguageCode}-${targetLanguageCode}`}>{languagePair}</a></td>
              <td className="text-center">
                <a href={translatorsUrl}>{totalTranslators}</a>
              </td>
              <td className="text-center">
                <a href={`${translatorsUrl}&training=true`}>{training}</a>
              </td>
              <td className="text-center">
                <a href={`${translatorsUrl}&subset=waiting_for_judgment`}>{waitingForJudgment}</a>
              </td>
              <td className="text-center">
                <a href={`${translatorsUrl}&reviewStatuses[]=unreviewed`}>{unreviewed}</a>
              </td>
              <td className="text-center">
                <a href={`${translatorsUrl}&subset=unqualified`}>{unqualified}</a>
              </td>
              <td className="text-center">
                <a href={`${translatorsUrl}&subset=qualified`}>{qualified}</a>
              </td>
              <td className="text-center">
                <a href={`${translatorsUrl}&subset=judges`}>{judges}</a>
              </td>
              <td className="text-center">{active}</td>
              <td className="text-center">{customPricing}</td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default connect(
  ({ trainingTexts }) => ({trainingTexts})
)(TableBody);
