import { get, patch, urlParams as humpyUrlParams } from "../_utils/humpyAjax";
import gqlClient from "../_utils/graphQlRequests";
import { withdrawalsExportSepaMutation } from "./_gql";

export const INITIALIZE_STORE    = "INITIALIZE_STORE";
export const UPDATE_FILTERS      = "UPDATE_FILTERS";
export const REQUEST_WITHDRAWALS = "REQUEST_WITHDRAWALS";
export const RECEIVE_WITHDRAWALS = "RECEIVE_WITHDRAWALS";
export const UPDATE_WITHDRAWALS  = "UPDATE_WITHDRAWALS";
export const MARK_AS_PAID        = "MARK_AS_PAID";

export const initializeStore = (dispatch, data) =>
  dispatch({ type: INITIALIZE_STORE, payload: data });

export const updateFilters = (dispatch, filters) =>
  dispatch({ type: UPDATE_FILTERS, payload: filters });

export const requestFilteredWithdrawals = async (dispatch, filters) => {
  dispatch({ type: REQUEST_WITHDRAWALS });

  const params = humpyUrlParams(filters);
  const withdrawals = await get(`withdrawals.json?${params}`);

  dispatch({ type: RECEIVE_WITHDRAWALS, payload: withdrawals });

  history.replaceState(null, null, `withdrawals?${params}`);
};

export const markAsPaid = (dispatch, id) => {
  patch(`withdrawals/${id}/mark-as-paid`, {}, (res) =>
    res.ok && dispatch({ type: MARK_AS_PAID, payload: id })
  );
};

export const exportSepaWithdrawals = async (dispatch) => {
  try {
    const {
      withdrawalsExportSepa: { fileUrl, withdrawals }
    } = await gqlClient.request(withdrawalsExportSepaMutation);

    dispatch({ type: UPDATE_WITHDRAWALS, payload: withdrawals });

    return fileUrl;
  } catch (e) {
    alert("Something went wrong. Sorry :(");
  }
};