import React from "react";

import TextWithTags from "./TextWithTags";
import { getFragments, isSameRange, fragmentStyle, segmentHighlightTypes } from "./_utils";
import { QUALITY_HIGHLIGHT_COLOR_CODES } from "../_utils/constants";

export class HighlightedTranslation extends React.Component {
  constructor(props) {
    super(props);

    const {highlights, text} = props;

    this.fragments = getFragments(highlights, text);
  }

  render() {
    const { id, highlights, text, isXliff } = this.props;

    return (
      <React.Fragment>
        {this.fragments.map( (fragment) => {
          let highlightIndex = highlights.findIndex(h => isSameRange(fragment, h)),
              textFragment = text.slice(fragment.rangeStart, fragment.rangeEnd),
              fragmentKey = `fragment-${id}-${fragment.rangeStart}-${fragment.rangeEnd}`;

          if (highlightIndex === -1) return (
            <TextWithTags text={textFragment} isXliff={isXliff} index={fragmentKey} key={fragmentKey} />
          );

          return (
            <span
              key={fragmentKey}
              style={fragmentStyle(fragment, highlights)}
              onMouseOver={() => this.setState({ hoveredRef: fragmentKey })}
              onMouseOut={() => this.setState({ hoveredRef: null })}
            >
              <TextWithTags text={textFragment} isXliff={isXliff} index={fragmentKey} />
            </span>
          );
        })}

        <hr />

        {segmentHighlightTypes(highlights).map(type => {
          return (
            <small className="task__highlight_type" key={`type-${id}-${type}`}>
              <span
                className="task__highlight_type__square"
                style={{ background: QUALITY_HIGHLIGHT_COLOR_CODES[type] }}
              />
              {I18n.t(`metrics.${type}`)}
            </small>
          );
        })}
      </React.Fragment>
    );
  }
}

export default HighlightedTranslation;
