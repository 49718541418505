import React from "react";
import { connect } from "react-redux";

import { languagePairDescriptionFromCodes } from "../_utils/languages";
import Sidebar from "./Sidebar";
import Results from "./Results";

export class WhitelistTranslators extends React.Component {
  render() {
    const { totalCount, languagePairDescription, clientName, clientPath } = this.props;

    return (
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col d-flex justify-content-between">
            <div className="">
              <h1>
                Whitelist translators
                <span className="text-muted"> ({totalCount.toLocaleString()})</span>
              </h1>
              <p>
                Whitelisting
                <strong> {languagePairDescription} </strong>
                <span>translators for </span>
                <strong><a href={clientPath}>{clientName}</a></strong>
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <Sidebar className="col-3" />
          <Results className="col-9" />
        </div>
      </div>
    );
  }
}

export default connect(
  ({filters: {languagePair}, totalCount , staticData: {clientName, clientPath} }) => ({
    languagePairDescription: languagePairDescriptionFromCodes(languagePair),
    clientName,
    clientPath,
    totalCount
  })
)(WhitelistTranslators);
