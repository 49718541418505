import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useGetClientAccountQuery, useGetInvoicesQuery, useCreateInvoiceMutation } from "./_api";
import { updateFilters } from "./_slices";
import InvoicesData from "./InvoicesData";

export default function ClientInvoices() {  
  const clientAccountId = useSelector(s => s.clientAccountId),
        {data: clientAccount} = useGetClientAccountQuery({id: clientAccountId}),
        filters = useSelector(s => s.filters),
        { billingAccountId } = filters,
        {data: invoices} = useGetInvoicesQuery(filters, {skip: !billingAccountId}),
        dispatch = useDispatch(),
        [ createInvoice, result ] = useCreateInvoiceMutation();  

  useEffect(()=>{
    if (clientAccount) dispatch(updateFilters({billingAccountId: clientAccount.billingAccount.id}));
  },[clientAccount]);

  if (!clientAccount || !billingAccountId) return null;

  const onGenerateInvoiceClick = () => createInvoice({
    input: { billingAccountId } 
  });
  
  return (
    <>    
      {result.isError && 
        <div className="alert alert-danger">
          Error: {result.error.data?.map( (e,i) => <p key={i}>{e.message}.</p>)}
        </div>
      }
      {result.isSuccess &&
        <div className="alert alert-success">
          Invoice created succesfully
        </div>
      }
      <div className="d-flex justify-content-between align-items-center mb-3">        
        <h1>
          {clientAccount.name} invoices
          {invoices && <span className="text-muted ml-2">({invoices.totalItems})</span>}        
        </h1>
        <div className="d-flex flex-column justify-content-end">
          <button 
            className="btn btn-success btn-lg" 
            onClick={onGenerateInvoiceClick}
            disabled={clientAccount.paymentMethod == "credit_card" || result.isLoading}
          >
            Generate invoices
          </button>        
          {clientAccount.paymentMethod == "credit_card" && 
            <p className="text-secondary mt-1">
              <small className="ticon-info mr-1" />
              <small>Credit card clients cannot generate invoices</small>
            </p>
          }
        </div>        
      </div>
      <InvoicesData/>
    </>
  );
}