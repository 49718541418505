import PropTypes from "prop-types";
import React from "react";

import Next from "./Next";
import Prev from "./Prev";
import Numbers from "./Numbers";
import FreeSelector from "./FreeSelector";

// Props
// page:          only way to define the current page, the only way to change it is external
// totalPages
// className:     optional, class the ul inside the nav has in addition to *pagination*
// windowSize:    optional, defines the max number of shown clickable pages. If it is not possible to show all pages
//                within the given size ellipsis will be used to skip them
// changePage:    callback to call every time a page change is attempted

const WINDOW_SIZE = 7;

export class Pagination extends React.Component {
  changePage = (page, e) => {
    e.preventDefault();
    this.props.changePage(page);
  }

  render() {
    const { totalPages, page, className } = this.props,
          paginationProps = { totalPages, page, changePage: this.changePage, windowSize: WINDOW_SIZE };

    if (totalPages < 2) return null;

    return (
      <nav>
        <ul className={`pagination ${className || ""}`}>
          <Prev {...paginationProps} />
          <Numbers {...paginationProps} />
          <Next {...paginationProps} />
          <FreeSelector {...{totalPages, page, changePage: this.props.changePage}} />
        </ul>
      </nav>
    );
  }
}

export default Pagination;

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};