// ===============================================================
// IMPORTANT NOTE:
// This file mirror some functionalities of the backend in
// /core/lib/core/models/meta_translation.rb
// please check both stay up to date after any changes
// ===============================================================

export const OFFICIAL_TAGS = ["a", "abbr", "address", "area", "article", "aside", "audio", "b", "base",
  "bdi", "bdo", "blockquote", "body", "br", "button", "canvas", "caption", "cite", "code", "col", "colgroup",
  "command", "data", "datalist", "dd", "del", "details", "dfn", "dialog", "div", "dl", "dt", "em", "embed",
  "fieldset", "figcaption", "figure", "footer", "form", "h1", "h2", "h3", "h4", "h5", "h6", "head", "header",
  "hgroup", "hr", "html", "i", "iframe", "img", "input", "ins", "kbd", "keygen", "label", "legend", "li",
  "link", "map", "mark", "menu", "meta", "meter", "nav", "noscript", "object", "ol", "optgroup", "option",
  "output", "p", "param", "pre", "progress", "q", "rp", "rt", "ruby", "s", "samp", "script", "section",
  "select", "small", "source", "span", "strong", "style", "sub", "summary", "sup", "table", "tbody", "td",
  "textarea", "tfoot", "th", "thead", "time", "title", "tr", "track", "u", "ul", "var", "video", "wbr"];

export const XLIFF_TAGS = [...Array(26).keys()].map(k => String.fromCharCode("a".charCodeAt(0) + k)); // ["a", "b", ..., "z"]

export const openTagsMatchStr = "<[a-z]+(?:-\\d+)?>";
export const openTagsRx = RegExp(openTagsMatchStr);
export const openTagsGlobalRx = RegExp(openTagsMatchStr, "g");
export const openTagsGlobalIgnoreCaseRx = RegExp(openTagsMatchStr, "gi");

export const closeTagsMatchStr = "<\\/[a-z]+(?:-\\d+)?>";
export const closeTagsRx = RegExp(closeTagsMatchStr);
export const closeTagsGlobalRx = RegExp(closeTagsMatchStr, "g");
export const closeTagsGlobalIgnoreCaseRx = RegExp(closeTagsMatchStr, "gi");

export const selfclosedTagsMatchStr = "<[a-z]+(?:-\\d+)?\\/>";
export const selfclosedTagsRx = RegExp(selfclosedTagsMatchStr);
export const selfclosedTagsGlobalRx = RegExp(selfclosedTagsMatchStr, "g");
export const selfclosedTagsGlobalIgnoreCaseRx = RegExp(selfclosedTagsMatchStr, "gi");

export const anyTagMatchStr = "<\\/?[a-z]+(?:-\\d+)?\\/?>";
export const anyTagRx = RegExp(anyTagMatchStr);
export const anyTagGlobalRx = RegExp(anyTagMatchStr, "g");
export const anyTagGlobalIgnoreCaseRx = RegExp(anyTagMatchStr, "gi");

export const allowedTagsMatchStr = (isXliff) => {
  const allowedTags = isXliff ? XLIFF_TAGS : OFFICIAL_TAGS;

  return `<\\/?(?:${allowedTags.join("|")})+(?:-\\d+)?\\/?>`;
};
export const allowedTagsRx = (isXliff) => RegExp(allowedTagsMatchStr(isXliff));
export const allowedTagsGlobalRx = (isXliff) => RegExp(allowedTagsMatchStr(isXliff), "g");
export const allowedTagsGlobalIgnoreCaseRx = (isXliff) => RegExp(allowedTagsMatchStr(isXliff), "gi");

export const textTags = (text, isXliff) => {
  if (!text) return [];
  return [...text.matchAll(allowedTagsGlobalIgnoreCaseRx(isXliff))].map(m => m[0]);
};

export const tagType = (tag) => {
  if (openTagsRx.test(tag)) return "open";
  if (closeTagsRx.test(tag)) return "close";
  if (selfclosedTagsRx.test(tag)) return "selfclosed";
};

export const tagTypeClassMap = {
  open: "translation_ui__form__textarea__tag__open",
  close: "translation_ui__form__textarea__tag__close",
  selfclosed: "translation_ui__form__textarea__tag__selfclosed",
};

export const tagClass = (tag) => tagTypeClassMap[tagType(tag)];

// Mirrors MetaTranslation.check_tags
export const getCrossedTag = (tags) => {
  var stack = [];

  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];

    if (RegExp("</").test(tag)) {
      //The closing tag should be the one on top of the stack
      let matchingTag = stack.pop();
      if (!matchingTag || matchingTag != `<${tag.slice(2)}`) {
        return tag;
      }
    } else if (openTagsRx.test(tag)) {
      stack.push(tag);
    }
  }

  // If it does not return undefined returns an open tag
  // without their corresponding closing tag
  return stack[0];
};