import React from "react";
import { connect } from "react-redux";

import { changePage, changePageAndSize, removeMessage } from "../_common/filtered_lists/_actions";
import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import Loader from "../_common/Loader";
import Alerts from "../_common/Alerts";
import FloatingCornerPopup from "../_common/FloatingCornerPopup";
import Headers from "./Headers";
import TasksList from "./TasksList";
import ProjectNamePopup from "./ProjectNamePopup";
import DeadlinePopup from "./DeadlinePopup";
import { openProjectNamePopup, openDeadlinePopup } from "./_actions";

export class Results extends React.Component {
  render() {
    const {
      totalPages,
      page,
      size,
      changePage,
      changePageAndSize,
      loading,
      className,
      messages,
      count,
      openProjectNamePopup,
      openDeadlinePopup,
      removeMessage,
      projectNamePopupOpen,
      deadlinePopupOpen,
    } = this.props;

    return (
      <div className={className}>
        <Alerts messages={messages} removeCallback={removeMessage} />
        <div className="card">
          <div className="card-body">
            <TopPagination {...{ changePageAndSize, totalPages, page, size }} />
            <div className="loader_wrapper">
              {loading ? <Loader /> : null}
              <table className="table mb-0">
                <Headers />
                <TasksList />
              </table>
            </div>
            <Pagination className="justify-content-end" {...{ changePage, totalPages, page }} />
            <FloatingCornerPopup hidden={count==0}>
              <h3>Selected Tasks: <span className="text-muted mr-2 ml-2">{count}</span></h3>
              <button
                className="btn btn-primary float-right m-2"
                onClick={() => openDeadlinePopup()}
              >
                Change deadline
              </button>
              <button
                className="btn btn-primary float-right m-2"
                onClick={() => openProjectNamePopup()}
              >
                Change project name
              </button>
            </FloatingCornerPopup>
            { projectNamePopupOpen ? <ProjectNamePopup /> : null }
            { deadlinePopupOpen ? <DeadlinePopup /> : null }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ totalPages, filters: { page, size }, loading, messages, allResultsSelected, totalCount, selectedResults, projectNamePopupOpen, deadlinePopupOpen }) => ({
    totalPages,
    page,
    size,
    loading,
    messages,
    projectNamePopupOpen,
    deadlinePopupOpen,
    count: allResultsSelected ? totalCount : selectedResults.length,
  }),
  { changePage, changePageAndSize, removeMessage, openProjectNamePopup, openDeadlinePopup }
)(Results);
