import React from "react";

import { useSendInvoiceReminderMutation } from "./_api";

export default function SendReminder({ id, paid, dueDate }){
  const [sendReminder, { isLoading, error }] = useSendInvoiceReminderMutation();

  const pastDue = () => {
    if (paid) return false
    if (!dueDate) return false

    return new Date > Date.parse(dueDate)
  }

  if (!pastDue()) return null
  if (error) console.error(error);

  if (isLoading) return (
    <span className="text-muted">Loading...</span>
  );

  return (
    <button
      className="btn btn-outline-primary btn-sm"
      onClick={() => sendReminder({input: { invoiceId: parseInt(id)}})}
    >
      Send Reminder
    </button>
  );
}
