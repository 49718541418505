import React from "react";
import { connect } from "react-redux";

export class UsersList extends React.Component {
  render() {
    const { users } = this.props;

    return (
      <tbody>
        {users.map(({ id, fullName, email, organizationName, clientPath, createdAt}) => (
          <tr key={`user-${id}`}>
            <td><a href={`/users/${id}`}>{id}</a></td>
            <td>{fullName}</td>
            <td>{email}</td>
            <td><a href={clientPath}>{organizationName}</a></td>
            <td>{createdAt}</td>
          </tr>
        ))}
      </tbody>
    );
  }
}

export default connect(
  ({ users }) => ({ users })
)(UsersList);
