import { createSlice } from "@reduxjs/toolkit";


export const creatingTemplateSlice = createSlice({
  name: "creatingTemplate",
  initialState: false,
  reducers: {
    setCreatingTemplate: (_, { payload }) => payload,
  },
});

export const { setCreatingTemplate } = creatingTemplateSlice.actions;

export default {
  creatingTemplate: creatingTemplateSlice.reducer,
};
