import { takeLatest, all } from "redux-saga/effects";

import { hocGetHumpedResults } from "../_common/filtered_lists/_sagas";
import { REQUEST_FILTERS_UPDATE } from "../_common/filtered_lists/_actions";

export function* watchRequests(whitelistPath) {
  yield takeLatest(REQUEST_FILTERS_UPDATE, hocGetHumpedResults("translators", whitelistPath));
}

export default function* rootSaga(whitelistPath) {
  yield all([
    watchRequests(whitelistPath)
  ]);
}