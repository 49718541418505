import React from "react";
import { connect } from "react-redux";

import FloatingCornerPopup from "../_common/FloatingCornerPopup";
import { openDeadlinePopup, openConfirmReadyPopup, openSetAsReviewed } from "./_actions";

export class MultiselectionPopup extends React.Component {
  render() {
    const { count, openDeadlinePopup, openConfirmReadyPopup, openSetAsReviewed } = this.props;

    return (
      <FloatingCornerPopup hidden={count == 0}>
        <h3>Selected translation requests: <span className="text-muted mr-2 ml-2">{count}</span></h3>
        <button
          className="btn btn-primary float-right m-2"
          onClick={() => openSetAsReviewed()}
        >
          Accept Ready to Review
        </button>
        <button
          className="btn btn-primary float-right m-2"
          onClick={() => openDeadlinePopup()}
        >
          Manage deadlines
        </button>
        <button
          className="btn btn-primary float-right m-2"
          onClick={() => openConfirmReadyPopup()}
        >
          Set as ready for translation
        </button>
      </FloatingCornerPopup>
    );
  }
}

export default connect(
  ({ allResultsSelected, totalCount, selectedResults }) => ({
    count: allResultsSelected ? totalCount : selectedResults.length,
  }),
  { openDeadlinePopup, openConfirmReadyPopup, openSetAsReviewed }
)(MultiselectionPopup);
