import { gql } from "graphql-request";

const fullOrderData = `
  id
  targetLanguageCode
  quality
  price
  workflowState
  payableAmount
  cancelable
`;


const fullPurchaseOrderData = `
  actualUsdCost
  canceledAt
  cancelable
  clientAccount {
    id,
    name,
  }
  createdAt
  currency
  id
  invoicedAt
  orderCode
  orders {
    ${fullOrderData}
  }
  originalActualUsdCost
  originalPrice
  paidAt
  paymentStatus
  paymentVariant
  payments {
    id
    status
    amountInCents
    paymentIntentId
  }
  price
  quality
  sourceLanguageCode
  updatedAt
`;

export const getPurchaseOrderQuery = gql`
  query getPurchaseOrder($id: ID!) {
    purchaseOrder(id: $id) {
      ${fullPurchaseOrderData}
    }
  }
`;


export const purchaseOrderUpdateMutation = gql`
  mutation purchaseOrderUpdate($input: PurchaseOrderUpdateInput!) {
    purchaseOrderUpdate(input: $input) {
      success
      errors
      purchaseOrder {
        orderCode
      }
    }
  }
`;


export const purchaseOrderCancelMutation = gql`
  mutation purchaseOrderCancel($input: PurchaseOrderCancelInput!) {
    purchaseOrderCancel(input: $input) {
      success
      errors
      purchaseOrder {
        ${fullPurchaseOrderData}
      }
    }
  }
`;

export const orderCancelMutation = gql`
  mutation orderCancel($input: OrderCancelInput!) {
    orderCancel(input: $input) {
      success
      errors
    }
  }
`;
