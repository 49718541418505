import React from "react";
import { connect } from "react-redux";

import { getClientsHash } from "./_selectors";
import { updateWhitelisted } from "./_actions";
import HoverPopover from "../_common/popovers/HoverPopover";
import { ROLE_ICONS, ROLES } from "../_utils/constants";

function equivalentWhitelistedSkills(wls1, wls2) {
  const keys1 = Object.keys(wls1);

  if (keys1.length != Object.keys(wls2).length) return false;

  return keys1.every(key => wls2[key] && wls1[key].role == wls2[key].role);
}

export class Client extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formWhitelistedSkills: props.whitelistedSkills ? {...props.whitelistedSkills} : {},
      saving: false,
    };
  }

  componentDidUpdate({whitelistedSkills: oldWhitelistedSkills}) {
    const { whitelistedSkills } = this.props;

    if (!equivalentWhitelistedSkills(oldWhitelistedSkills, whitelistedSkills)) {
      // whitelistedSkills will only be updated on initialisation and after a batch of changes from updateWhitelisted
      // is finished.
      // By setting saving to "true" before calling updateWhitelisted we avoid further interaction until
      // said requests are done. Which we know because of the updated props.
      this.setState({ formWhitelistedSkills: { ...whitelistedSkills }, saving: false });
    }
  }

  selectRole(e, quality, role){
    const formWhitelistedSkills = {...this.state.formWhitelistedSkills};

    e.preventDefault();

    formWhitelistedSkills[quality] = { role };
    this.setState({formWhitelistedSkills});
  }

  toggleWhitelistedSkill(e, quality) {
    const formWhitelistedSkills = { ...this.state.formWhitelistedSkills };

    e.preventDefault();

    if (formWhitelistedSkills[quality]) {
      delete formWhitelistedSkills[quality];
    } else {
      formWhitelistedSkills[quality] = {role: "both"};
    }

    this.setState({formWhitelistedSkills});
  }

  saveChanges = () => {
    const { whitelistedSkills, updateWhitelisted, client, languagePair, specialButtonFunc } = this.props,
          { formWhitelistedSkills } = this.state,
          equivalentToOriginal = equivalentWhitelistedSkills(whitelistedSkills, formWhitelistedSkills),
          originalQualities = Object.keys(whitelistedSkills),
          finalQualities = Object.keys(formWhitelistedSkills);

    let deleted, added, modified;

    if (specialButtonFunc) specialButtonFunc();

    if (equivalentToOriginal) return;

    this.setState({saving: true});

    // returns [[quality, role], ...]
    added = finalQualities.filter(quality => !whitelistedSkills[quality]).map(quality => ({quality, role: formWhitelistedSkills[quality].role }));
    // both return [whitelistedSkill, ...]
    deleted = originalQualities.filter(quality => !formWhitelistedSkills[quality]).map(quality => whitelistedSkills[quality]);
    modified = finalQualities.filter(quality => whitelistedSkills[quality] && whitelistedSkills[quality].role != formWhitelistedSkills[quality].role)
      .map(quality => ({...whitelistedSkills[quality], role: formWhitelistedSkills[quality].role }) );

    updateWhitelisted(client.id, languagePair, added, deleted, modified);
  }

  cancel = () => {
    const {specialButtonFunc} = this.props;

    if (specialButtonFunc) specialButtonFunc();
    this.setState({formWhitelistedSkills: {...this.props.whitelistedSkills}, saving: false } );
  }

  render() {
    const { client, humanQualities, twoHumansQualities, whitelistedSkills, specialButtonFunc } = this.props,
          { formWhitelistedSkills, saving } = this.state,
          equivalentToOriginal = equivalentWhitelistedSkills(whitelistedSkills, formWhitelistedSkills);

    if (!client) return null;

    return (
      <React.Fragment>
        <tr>
          <td style={{minWidth: "150px"}}>{client.name}</td>
          <td>
            <div className="d-flex flex-wrap">
              {humanQualities.map(quality => {
                const whitelistedSkill = formWhitelistedSkills[quality];

                return (
                  <div key={quality} className="mr-2" >
                    <a
                      href="#"
                      className={`badge badge-pill badge-light ${whitelistedSkill ? "" : "text-muted"}`}
                      style={{width:"100%"}}
                      onClick={e=>this.toggleWhitelistedSkill(e, quality)}
                    >
                      {whitelistedSkill ?
                        <span className="ticon-check mr-2" />
                        :
                        <span className="ticon-cancel mr-2" />
                      }
                      <span
                        style={{
                          textAlign: "left",
                          width: "80px",
                          display: "inline-block"
                        }}
                      >
                        {quality[0].toUpperCase()+quality.slice(1)}
                      </span>
                    </a>
                    {whitelistedSkill && twoHumansQualities.includes(quality) ?
                      <div>
                        {(ROLES.map(role => (
                          <HoverPopover
                            key={role}
                            msg={role}
                            position="bottom"
                          >
                            <a
                              href="#"
                              onClick={e=>this.selectRole(e,quality, role)}
                              className={`badge badge-pill badge-light ${whitelistedSkill.role == role ? "" : "text-muted" }`}
                              style={{position: "relative"}}
                            >
                              <span className={ROLE_ICONS[role]} />
                            </a>
                          </HoverPopover>
                        )))}
                      </div>
                      : null
                    }
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
        {equivalentToOriginal && !saving && !specialButtonFunc ?
          null
          :
          <tr>
            <td colSpan="2">
              <div className="clear">
                <button onClick={this.saveChanges} className="btn btn-sm btn-thin btn-success pull-right">Save</button>
                <button onClick={this.cancel} className="btn btn-sm btn-thin btn-link pull-right">Cancel</button>
              </div>
            </td>
          </tr>
        }
      </React.Fragment>
    );
  }
}

export default connect(
  (state, props) => ({
    client: getClientsHash(state)[props.clientId],
    humanQualities: state.humanQualities,
    twoHumansQualities: state.twoHumansQualities,
  }),
  { updateWhitelisted }
)(Client);
