import useLSAttributesConfiguration from "./useLSAttributesConfiguration";

export default function useConfigureVisibleAttributes(name, keys, hiddenKeys = []) {
  const defaultVisibleAttributes = keys.filter((key) => !hiddenKeys.includes(key))
    .reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});

  return useLSAttributesConfiguration(name, defaultVisibleAttributes);
}