import React from "react";
import Popover, { ArrowContainer } from "react-tiny-popover";

import { defaultArrowProps } from "./_constants";

export class BasicPopover extends React.Component {
  render() {
    const {
      isOpen,
      children,
      msg,
      position
    } = this.props;

    if (!msg) return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );

    return (
      <Popover
        isOpen={isOpen}
        position={position}
        containerStyle={{ overflow: "visible", zIndex: 2000 }}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer {...{ position, targetRect, popoverRect, ...defaultArrowProps }} >
            <div className="tiny_popover">{msg}</div>
          </ArrowContainer>
        )}
      >
        {children}
      </Popover>
    );
  }
}

export default BasicPopover;
