import React from 'react'

import Regenerate from "./Regenerate"
import SendInvoice from './SendInvoice'
import SendReminder from './SendReminder'

export default function Actions(props) {
  return (
    <>
      <Regenerate {...props} />
      &nbsp;
      <SendInvoice {...props} />
      &nbsp;
      <SendReminder {...props} />
    </>
  )
}
