import React from "react";

export class ChartFile extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="nav-item  ml-5">
          <small className="">Chart type:</small>
        </div>

        <li className="nav-item ml-2">
          <a
            href="#"
            className={`badge ${this.props.stacked == true ? "badge-primary" : "badge-light"}`}
            onClick={e => {
              e.preventDefault();
              this.props.changeStacked(true);
            }}
          >
            Stacked
          </a>
        </li>

        <li className="nav-item ml-2">
          <a
            href="#"
            className={`badge ${this.props.stacked == false ? "badge-primary" : "badge-light"}`}
            onClick={e => {
              e.preventDefault();
              this.props.changeStacked(false);
            }}
          >
            Separated
          </a>
        </li>
      </React.Fragment>
    );
  }
}

export default ChartFile;
