import React from "react";
import { connect } from "react-redux";

import FormCalendar from "../_common/FormCalendar";
import FormMultiCheckbox from "../_common/FormMultiCheckbox";
import FormSelect from "../_common/FormSelect";
import SimpleSelect from "../_common/SimpleSelect";
import AugmentedMultiSelect from "../_common/AugmentedMultiSelect";
import { requestFiltersUpdateAndResetPage } from "../_common/filtered_lists/_actions";

export class Sidebar extends React.Component {
  render() {
    const {
      className,
      requestFiltersUpdateAndResetPage,
      filters,
      allHumanLanguages,
      sourceLanguageCodes,
      targetLanguageCodes,
      languageSubsets,
      activities,
      trainings,
      clients,
      qualities,
      reviewStatuses,
      skillAbuseStates,
    } = this.props;

    return (
      <div className={className}>
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Search</label>
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type in your search"
                  name="search"
                  value={filters.search || ""}
                  onChange={(e) => requestFiltersUpdateAndResetPage({ search: e.target.value })}
                />
                <div className="input-group-append"><span className="input-group-text ticon-search"></span></div>
              </div>
              <small id="emailHelp" className="form-text text-muted">Partial matches for ids, emails and names.</small>
            </div>

            <AugmentedMultiSelect
              label="Native languages"
              name="nativeLanguages"
              onChange={selectedOptions => requestFiltersUpdateAndResetPage({ nativeLanguages: selectedOptions })}
              value={filters.nativeLanguages || []}
              options={allHumanLanguages}
            />

            <AugmentedMultiSelect
              label="Source language"
              name="sourceLanguageCodes"
              onChange={selectedOptions => requestFiltersUpdateAndResetPage({sourceLanguageCodes: selectedOptions })}
              value={filters.sourceLanguageCodes || []}
              options={sourceLanguageCodes}
            />

            <AugmentedMultiSelect
              label="Target language"
              name="targetLanguageCodes"
              onChange={selectedOptions => requestFiltersUpdateAndResetPage({ targetLanguageCodes: selectedOptions })}
              value={filters.targetLanguageCodes || []}
              options={targetLanguageCodes}
            />

            <FormSelect
              label="Language pair subset"
              wrapperClassName="form-group mb-0"
              name="subset"
              onChange={e => requestFiltersUpdateAndResetPage({ subset: e.target.value })}
              value={filters.subset || ""}
              options={languageSubsets}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />
            <p><small className="text-muted">(Only works with a language pair selected)</small></p>

            <div className="form-group">
              <label htmlFor={"translator_certificates"}>Translator certificates</label>
              <div className="d-flex">
                <SimpleSelect
                  options={sourceLanguageCodes}
                  name="certificateSourceLanguageCode"
                  onChange={e => requestFiltersUpdateAndResetPage({ certificateSourceLanguageCode: e.target.value })}
                  value={filters.certificateSourceLanguageCode || ""}
                  className="form-control mr-1"
                  includeBlank={true}
                  blankOptionLabel ={"Any"}
                />
                <SimpleSelect
                  options={targetLanguageCodes}
                  name="certificateTargetLanguageCode"
                  onChange={e => requestFiltersUpdateAndResetPage({ certificateTargetLanguageCode: e.target.value })}
                  value={filters.certificateTargetLanguageCode || ""}
                  className="form-control ml-1"
                  includeBlank={true}
                  blankOptionLabel={"Any"}
                />
              </div>
            </div>

            <FormSelect
              label="Activity"
              name="activity"
              onChange={e => requestFiltersUpdateAndResetPage({ activity: e.target.value })}
              value={filters.activity || ""}
              options={activities}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />

            <FormSelect
              label="Training"
              name="training"
              onChange={e => requestFiltersUpdateAndResetPage({ training: e.target.value })}
              value={filters.training || ""}
              options={trainings}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />

            <FormCalendar
              id="calendar"
              label="Created after"
              callback={(date) => requestFiltersUpdateAndResetPage({ createdAfter: date })}
              value={filters.createdAfter || ""}
            />

            <FormSelect
              label="Whitelisted client"
              name="whitelistedClientAccountId"
              onChange={e => requestFiltersUpdateAndResetPage({ whitelistedClientAccountId: e.target.value })}
              value={filters.whitelistedClientAccountId || ""}
              options={clients}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />

            <FormSelect
              label="Suspicious activity"
              name="skillAbuseState"
              onChange={e => requestFiltersUpdateAndResetPage({ skillAbuseState: e.target.value })}
              value={filters.skillAbuseState || ""}
              options={skillAbuseStates}
              includeBlank={true}
              blankOptionLabel={"Any"}
            />

            <AugmentedMultiSelect
              label="Experience"
              name="experienceFields"
              onChange={selectedOptions => requestFiltersUpdateAndResetPage({ experienceFields: selectedOptions })}
              value={filters.experienceFields || ""}
              options={Object.entries(I18n.t("experience_fields")).map(([k, v]) => [v, k])}
            />

            <FormMultiCheckbox
              label="Whitelisted quality"
              name="whitelistedQualities"
              onChange={whitelistedQualities => requestFiltersUpdateAndResetPage({ whitelistedQualities })}
              values={filters.whitelistedQualities}
              options={qualities}
            />

            <FormMultiCheckbox
              label="Review statuses"
              name="reviewStatuses"
              onChange={reviewStatuses => requestFiltersUpdateAndResetPage({ reviewStatuses })}
              values={filters.reviewStatuses}
              options={reviewStatuses}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ filters, staticData: {
    sourceLanguageCodes, targetLanguageCodes, languageSubsets, activities, trainings,
    clients, qualities, reviewStatuses, nativeLanguage, skillAbuseStates, allHumanLanguages
  } }) => ({
    filters,
    allHumanLanguages,
    sourceLanguageCodes,
    targetLanguageCodes,
    languageSubsets,
    activities,
    trainings,
    clients,
    qualities,
    reviewStatuses,
    nativeLanguage,
    skillAbuseStates,
  }),
  { requestFiltersUpdateAndResetPage }
)(Sidebar);
