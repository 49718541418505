import { post, deleteRequest, expectNoContent, get, patch } from "../_utils/humpyAjax";
import { MODES } from "./_constants";

export const UPDATE_QUALITY_REVIEWS = "UPDATE_QUALITY_REVIEWS";
export const TOGGLE_CREATING_REVIEW_TASK = "TOGGLE_CREATING_REVIEW_TASK";
export const UPDATE_TRANSLATOR_ACCOUNT_ID = "UPDATE_TRANSLATOR_ACCOUNT_ID";
export const UPDATE_TASK_ID = "UPDATE_TASK_ID";
export const ADD_QUALITY_REVIEW = "ADD_QUALITY_REVIEW";
export const REMOVE_QUALITY_REVIEW = "REMOVE_QUALITY_REVIEW";
export const UPDATE_ERRORS = "UPDATE_ERRORS";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_TOTAL_PAGES = "UPDATE_TOTAL_PAGES";

export const updateQualityReviews = (qualityReviews) => ({
  type: UPDATE_QUALITY_REVIEWS, qualityReviews
});

export const toggleCreatingReviewTask = () =>({type: TOGGLE_CREATING_REVIEW_TASK});

export const updateTranslatorAccountId = (translatorAccountId) => ({
  type: UPDATE_TRANSLATOR_ACCOUNT_ID,translatorAccountId
});

export const updateTaskId = (taskId) => ({
  type: UPDATE_TASK_ID, taskId
});

export const updateErrors = (errors) => ({
  type: UPDATE_ERRORS, errors
});

export const addQualityReview = () => (dispatch, getState) => {
  const {translatorAccountId, taskId} = getState();

  dispatch(updateErrors([]));

  post("/quality_reviews", { translatorAccountId, taskId } )
    .then( () => {
      dispatch(getPage(1));
      dispatch(toggleCreatingReviewTask());
    })
    .catch( ({json, error}) => json ? dispatch(updateErrors( json.errors)) : dispatch(updateErrors([error.message])) );
};

export const destroyQualityReview = (id) => (dispatch, getState) => {
  deleteRequest(`/quality_reviews/${id}`, expectNoContent)
    .then(() => {
      const { qualityReviews, page } = getState();

      if (qualityReviews.length == 1 && page > 1) {
        dispatch(getPage(page-1));
      } else {
        dispatch(getPage(page));
      }
    })
    .catch(()=>alert("Something went wrong"));
};

// WARNING: Assumes optimistic updates, in other words,
// state changing in the UI before the update is confirmed
export const updateQualityReview = (id, paramsToUpdate) => () => {
  patch(`/quality_reviews/${id}`, paramsToUpdate, expectNoContent)
    .catch(() => alert(`Something went wrong updating quality review ${id}`));
};

export const updatePage = (page) => ({
  type: UPDATE_PAGE, page
});

export const updateTotalPages = (totalPages) => ({
  type: UPDATE_TOTAL_PAGES, totalPages
});

export const getPage = (page) => (dispatch, getState) => {
  const { taskId, translatorAccountId, mode } = getState();

  const handleQualityReviews = ({ qualityReviews, totalPages }) => {
    dispatch(updateQualityReviews(qualityReviews));
    dispatch(updateTotalPages(totalPages));
  };

  dispatch(updatePage(page));

  switch (mode) {
    case MODES.received_quality_reviews:
      get("/quality_reviews", { reviewed_account_id: translatorAccountId, page }).then(handleQualityReviews);
      break;
    case MODES.quality_reviews:
      get("/quality_reviews", { page }).then(handleQualityReviews);
      break;
    case MODES.emitted_quality_reviews:
      get("/quality_reviews", { translatorAccountId, page }).then(handleQualityReviews);
      break;
    case MODES.tasks:
    default:
      get("/quality_reviews", { taskId, page }).then(handleQualityReviews);
      break;
  }
};
