import { createSelector } from "reselect";

import { purchaseOrderSelector } from "./_reducers";

export const paymentsSelector =  createSelector(
  [purchaseOrderSelector],
  (purchaseOrder) => {
    if (!purchaseOrder || !purchaseOrder.payments.length) return null;
    return purchaseOrder.payments
  }
);

export const ordersSelector = createSelector(
  [purchaseOrderSelector],
  (purchaseOrder) => {
    if (!purchaseOrder || !purchaseOrder.orders.length) return null;
    return purchaseOrder.orders;
  }
);
