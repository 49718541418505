import React from "react";
import { connect } from "react-redux";

import FormPopup from "../_common/FormPopup";
import { closeTasksPopup, updateAllTasks } from "./_actions";


export class ProjectNamePopup extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      projectName: this.initialProjectName() || ""
    };
  }

  initialProjectName() {
    let {selectedTask, selectedResults} = this.props;

    if (!selectedTask && selectedResults.length != 1) return "";

    let task = this.taskFromId(selectedTask || selectedResults[0]);

    return task.projectName;
  }

  taskFromId(id){
    return this.props.tasks.find(task => task.id == id);
  }

  taskTitleFromId(id){
    let task = this.taskFromId(id);
    return `"${task.title}" (#${id})`;
  }

  header() {
    const {
      allResultsSelected,
      selectedResults,
      selectedTask,
      totalCount
    } = this.props;

    if (selectedTask !== null || selectedResults.length == 1) {
      return <h3>Change project name in <span className="text-muted">{this.taskTitleFromId(selectedTask || selectedResults[0])}</span></h3>;
    } else if (allResultsSelected) {
      return <h3>Change project name in <span className="text-muted">{totalCount}</span> tasks</h3>;
    } else {
      return <h3>Change project name in <span className="text-muted">{selectedResults.length}</span> tasks</h3>;
    }
  }

  updateProjectName(projectName){
    this.setState({projectName});
  }

  submitForm(e){
    const { projectName } = this.state;

    e.preventDefault();
    this.props.updateAllTasks({ projectName });
  }

  render() {
    const { closeTasksPopup } = this.props;

    const { projectName } = this.state;

    return (
      <FormPopup wrapperCallback={() => closeTasksPopup()}>
        {this.header()}
        <form onSubmit={(e) => this.submitForm(e)}>
          <div className="form-group">
            <label htmlFor="">Project name</label>
            <input
              type="text"
              name="projectName"
              className="form-control"
              value={projectName}
              onChange={(e) => this.updateProjectName(e.target.value)}
            />
          </div>
          <div className="flex">
            <button className="order-2 btn btn-primary float-right">Change project name</button>
            <button className="order-1 btn btn-outline-primary float-left" onClick={(e) => {e.preventDefault();closeTasksPopup();}}>Cancel</button>
          </div>
        </form>
      </FormPopup>
    );
  }
}

export default connect(
  ({
    tasks,
    allResultsSelected,
    selectedResults,
    selectedTask,
    totalCount,
  }) => ({
    tasks,
    allResultsSelected,
    selectedResults,
    selectedTask,
    totalCount,
  }),
  { closeTasksPopup, updateAllTasks }
)(ProjectNamePopup);
