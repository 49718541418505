import React from "react";
import { connect } from "react-redux";
import { cancelMergeSelection, updateMergeData } from "./_actionsAndReducers";

export class Popup extends React.Component {
  constructor() {
    super();
    this.state = {
      confirming: false,
      errorMessage: ""
    };
  }

  componentDidUpdate() {
    let $dialog = $("#merge-dialog"),
        $lastHighlightedRow = $("td.bg-success.text-white").last(),
        $table = $lastHighlightedRow.closest("table"),
        { noneSelected } = this.props;

    if ( noneSelected || !$dialog.length || !$lastHighlightedRow.length) return;

    $dialog.css({
      top: $lastHighlightedRow[0].offsetTop + $table[0].offsetTop + $lastHighlightedRow.height() + 10
    });
  }

  selectedPhrases() {
    let { bothBoundsSelected, selectionSpansDifferentKeys, mergeSelectionBounds: { start, end }, keysData } = this.props;

    if (!bothBoundsSelected || selectionSpansDifferentKeys) return {};

    return keysData[start.x]
      .sourceChunkIds
      .filter((_, index) => index >= start.y && index <= end.y);
  }

  selectedUntranslatables() {
    let { sourceTexts } = this.props;

    return this.selectedPhrases().filter(scid => sourceTexts[scid] === undefined );
  }

  confirmMerge = () => {
    var self = this,
        selectedPhrases = this.selectedPhrases(),
        { orderId, updateMergeData } = this.props;

    if (selectedPhrases === null || selectedPhrases.length === 0) return;

    if (selectedPhrases.indexOf(0) > -1) {
      self.setState({ errorMessage: T("merge_ui.error.paragraph_error") });
      return;
    }

    $.post(`/orders/${orderId}/merge_chunks`, { chunk_ids: this.selectedPhrases() })
      .done(function (mergeData) {
        self.cancelSelection();
        updateMergeData(mergeData);
      }).fail(function (xhr) {
        console.log(xhr)
        if (xhr.responseText && xhr.status == 422) {
          self.setState({ errorMessage: $.parseJSON(xhr.responseText).errors.join(".") });
        } else {
          self.setState({ errorMessage: "These senteces cannot be merged due to an undefined error" });
        }
      });
  }

  goToConfirm = () => {
    this.setState({ confirming: true });
  }

  cancelSelection = (e) => {
    if (e) { e.preventDefault(); }
    this.setState({ confirming: false, errorMessage: "" });
    this.props.cancelMergeSelection();
  }

  render() {
    let { noneSelected, oneSelected, selectionSpansDifferentKeys } = this.props;

    if (noneSelected) return null;

    if (oneSelected) {
      return (
        <div id="merge-dialog" className="merge_ui__popup card">
          <span className="merge_ui__popup__arrow"></span>
          <div className="card-body">
            <h3>Selected 1 sentence</h3>
            <p>Click on the last sentence you want to merge this sentence with, all the sentences in between will be selected too.</p>
            <p>Deselect the sentence by clicking it again.</p>
          </div>
        </div>
      );
    }

    if (this.state.errorMessage != "") {
      return (
        <div id="merge-dialog" className="merge_ui__popup card">
          <span className="merge_ui__popup__arrow"></span>
          <div className="card-body">
            <h3>Cannot be merged</h3>
            <p>The system didn't authorize merging the sentences you selected. The returned error was:</p>
            <p className="alert alert-danger">
              <span className="ticon-warning"></span> {this.state.errorMessage}
            </p>
            <button className="btn btn-primary" onClick={this.cancelSelection}>Understood</button>
          </div>
        </div>
      );
    }

    if (selectionSpansDifferentKeys) {
      return (
        <div id="merge-dialog" className="merge_ui__popup card">
          <span className="merge_ui__popup__arrow"></span>
          <div className="card-body">
            <h3><span className="ticon-warning"></span> Cannot be merged </h3>
            <p>The phrases selected belong to different keys so they cannot be merged.</p>
            <button className="btn btn-primary" onClick={this.cancelSelection}>Understood</button>
          </div>
        </div>
      );
    }

    if (this.state.confirming) {
      return (
        <div id="merge-dialog" className="merge_ui__popup card">
          <span className="merge_ui__popup__arrow"></span>
          <div className="card-body">
            <h3>Are you sure?</h3>
            <p>You won't be able to split back these sentences again.</p>
            <button className="btn btn-success mr-3" onClick={this.confirmMerge}>Yes, confirm merge</button>
            <button className="btn btn-danger" onClick={this.cancelSelection}>No, cancel merge</button>
          </div>
        </div>
      );
    }


    var selectedPhrasesCount = this.selectedPhrases().length,
        untranslatablesCount = this.selectedUntranslatables().length;

    return (
      <div id="merge-dialog" className="merge_ui__popup card">
        <span className="merge_ui__popup__arrow"></span>
        <div className="card-body">
          <h3>Selected {selectedPhrasesCount} sentences</h3>
          <p>
            You are going to merge {selectedPhrasesCount} sentences and {untranslatablesCount} of them is/are untranslatable. Most untranslatables are just spaces, so don't worry if you cannot see them.
          </p>
          <a href="" className="btn btn-link mr-3" onClick={this.cancelSelection}>Cancel</a>
          <button className="btn btn-primary" onClick={this.goToConfirm}>Confirm</button>
        </div>
      </div>
    );
  }
}


export default connect(
  ({ sourceTexts, mergeSelectionBounds, keysData, orderId }) => {
    let bothBoundsSelected = mergeSelectionBounds.start != null && mergeSelectionBounds.end != null;

    return {
      sourceTexts,
      orderId,
      noneSelected: mergeSelectionBounds.start == null && mergeSelectionBounds.end === null,
      oneSelected: mergeSelectionBounds.start != null && mergeSelectionBounds.end === null,
      bothBoundsSelected,
      selectionSpansDifferentKeys: bothBoundsSelected && mergeSelectionBounds.start.x != mergeSelectionBounds.end.x,
      mergeSelectionBounds,
      keysData,
    };
  },
  { cancelMergeSelection, updateMergeData }
)(Popup);