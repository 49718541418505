import React from "react";
import { connect } from "react-redux";

import { changeAttribute } from "./_actionsAndReducers";

export class PostEditingQualities extends React.Component {
  render() {
    const {
      clientAccount: {
        translationQualityViaPostediting,
        localizationQualityViaPostediting,
        expertQualityViaPostediting,
      },
      changeAttribute,
    } = this.props;

    return (
      <>
        <label className="col-4  mb-3 font-weight-bold">Qualities With Postediting Workflow:</label>
        <div className="col-8 mb-3">
          <div className="form-check form-check-inline mr-4">
            <input
              className="form-check-input"
              type="checkbox"
              id="translationQualityViaPostediting"
              checked={translationQualityViaPostediting}
              onChange={() => changeAttribute("translationQualityViaPostediting", !translationQualityViaPostediting)}
            />
            <label className="form-check-label" htmlFor="translationQualityViaPostediting">Translation</label>
          </div>
          <div className="form-check form-check-inline mr-4">
            <input
              className="form-check-input"
              type="checkbox"
              id="localizationQualityViaPostediting"
              checked={localizationQualityViaPostediting}
              onChange={() => changeAttribute("localizationQualityViaPostediting", !localizationQualityViaPostediting)}
            />
            <label className="form-check-label" htmlFor="localizationQualityViaPostediting">Localization</label>
          </div>
          <div className="form-check form-check-inline mr-4">
            <input
              className="form-check-input"
              type="checkbox"
              id="expertQualityViaPostediting"
              checked={expertQualityViaPostediting}
              onChange={() => changeAttribute("expertQualityViaPostediting", !expertQualityViaPostediting)}
            />
            <label className="form-check-label" htmlFor="expertQualityViaPostediting">Expert</label>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  ({clientAccount})=>({clientAccount}),
  { changeAttribute }
)(PostEditingQualities);
