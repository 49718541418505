import { gql } from "graphql-request";

export const createTrainingTextMutation = gql`
  mutation trainingTextCreate($input: TrainingTextCreateInput!) {
    trainingTextCreate(input: $input) {
      success
      errors
    }
  }
`;
