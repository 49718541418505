import React from "react";
import { connect } from "react-redux";

import FormSelect from "../_common/FormSelect";
import { changeTargetLanguage, changeSourceLanguage } from "./_actions";

export class Header extends React.Component {
  render() {
    const { sourceLanguageCode, targetLanguageCode, sourceLanguageList, targetLanguageList, initiallySelectedLanguagePair, editing, changeTargetLanguage, changeSourceLanguage } = this.props;

    if (initiallySelectedLanguagePair && !editing) return (
      <h1>Creating training text for {I18n.t("languages")[sourceLanguageCode]} to {I18n.t("languages")[targetLanguageCode]}</h1>
    );

    if (initiallySelectedLanguagePair && editing) return (
      <h1>Editing training text for {I18n.t("languages")[sourceLanguageCode]} to {I18n.t("languages")[targetLanguageCode]}</h1>
    );

    return (
      <h1 className="form-inline">
        Creating training text for
        <div className="form-group m-3">
          <FormSelect
            className="form-control form-control-lg"
            options={sourceLanguageList}
            name={"sourceLanguageCode"}
            onChange={e => changeSourceLanguage(e.target.value)}
            value={sourceLanguageCode}
          />
        </div>
        to
        <div className="form-group m-3">
          <FormSelect
            className="form-control form-control-lg"
            options={targetLanguageList}
            name={"targetLanguageCode"}
            onChange={e => changeTargetLanguage(e.target.value)}
            value={targetLanguageCode}
          />
        </div>
      </h1>
    );
  }
}

export default connect(
  ({ sourceLanguageCode, targetLanguageCode, staticData: { sourceLanguageList, targetLanguageList, initiallySelectedLanguagePair, editing } }) => ({
    sourceLanguageCode, targetLanguageCode, sourceLanguageList, targetLanguageList, initiallySelectedLanguagePair, editing
  }),
  { changeTargetLanguage, changeSourceLanguage }
)(Header);
