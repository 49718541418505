import React from "react";


export class Translator extends React.Component {
  constructor(props) {
    super();
    this.state = { isJudge: props.translator.isJudge };
  }

  makeJudge(skillId) {
    $.post(`/skills/${skillId}/make-judge`)
     .done( () => this.setState({isJudge: !this.state.isJudge}) )
     .fail( () => alert("There was a server error.") );
  }

  deactivateJudge(skillId) {
    $.post(`/skills/${skillId}/deactivate-judge`)
     .done( () => this.setState({isJudge: !this.state.isJudge}) )
     .fail(() => alert("There was a server error."));
  }

  judgeButton() {
    const { translator: { veteran, hasBillingAcct }, translator} = this.props,
          { isJudge } = this.state;

    if (!veteran) return null;

    if (isJudge) return <span className="btn btn-sm btn-danger" onClick={() => this.deactivateJudge(translator.skillId)}>Undo judge</span>;
    if (!hasBillingAcct) return <small className="text-muted"> <span className="ticon-warning"/> Translator doesn't have billing account</small>;

    return <span className="btn btn-sm btn-primary" onClick={() => this.makeJudge(translator.skillId)}>Make judge</span>;
  }

  render() {
    const { translator } = this.props;

    return (
      <tr>
        <td><strong><a href={`/translators/${translator.id}`}>{translator.email}</a></strong></td>
        <td>{translator.registration}</td>
        <td>{translator.tasks}</td>
        <td>{translator.quality}</td>
        <td>{translator.activity}</td>
        <td>
          <a href={translator.evaluationUrl}>{translator.evaluationStatus}</a>
        </td>
        <td>
          {this.judgeButton()}
        </td>
      </tr>
    );
  }
}

export default Translator;
