export const REQUEST_FILTERS_UPDATE = "REQUEST_FILTERS_UPDATE";
export const UPDATE_TRAINING_TEXTS = "UPDATE_TRAINING_TEXTS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";

export const requestFiltersUpdate = (filters) => (dispatch, getState) => {
  let state = getState();
  dispatch({ type: REQUEST_FILTERS_UPDATE, filters: { ...state.filters, ...filters } });
};

export const updateTrainingTexts = (trainingTexts, withTrainingTotalCount, withoutTrainingTotalCount, totalPages) => ({
  type: UPDATE_TRAINING_TEXTS, trainingTexts, withTrainingTotalCount, withoutTrainingTotalCount, totalPages
});

export const updateFilters = (filters) => ({
  type: UPDATE_FILTERS, filters
});