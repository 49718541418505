import React from "react";
import { connect } from "react-redux";

import { languagePairFromString } from "../_utils/languages";
import { addClient } from "./_actions";
import Client from "./Client";
import AutocompleteInput from "../_common/AutocompleteInput";

export class LanguagePair extends React.Component {
  state = {
    addingClient: false,
    selectedClient: null,
  }

  selectClient(client) {
    const { clients } = this.props;

    if (!clients.find( ({id}) => id == client.id)) {
      this.props.addClient(client);
    }

    this.setState({ selectedClient: client });
  }

  render() {
    const { clientsHash, languagePairString, clients } = this.props,
          { addingClient, selectedClient } = this.state,
          clientIds = Object.keys(clientsHash)
            .map(id => parseInt(id))
            .filter( id => clients.map(c => c.id).includes(id) ) // To avoid problems with deleted clients
            .sort(),
          languagePair = languagePairFromString(languagePairString);

    return (
      <div key={languagePairString} className="mb-5">
        <h5 className="mb-4">
          {languagePair.toHuman}
          {addingClient ?
            null :
            <button className="btn btn-sm btn-primary pull-right" onClick={()=>this.setState({addingClient: true})}>Add client</button>
          }
        </h5>

        {addingClient && !selectedClient ?
          <AutocompleteInput
            placeholder="Type the name of the client here"
            className="mb-3"
            url="/clients"
            searchParamKey="name_like"
            responseConverter={response => response.filter( ({id}) => !clientIds.includes(id) )}
            selectionCallback={client => this.selectClient(client)}
          />
          : null
        }

        <table className="table clear">
          <tbody>
            {selectedClient && clients.find(({ id }) => id == selectedClient.id ) ?
              <Client
                clientId={selectedClient.id}
                whitelistedSkills={{}}
                languagePair={languagePair}
                specialButtonFunc={()=>this.setState({addingClient: false, selectedClient: null})}
              />
              : null
            }

            {clientIds.map((clientId) => (
              <Client
                key={clientId}
                clientId={clientId}
                whitelistedSkills={clientsHash[clientId]}
                languagePair={languagePair}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    clients: state.clients,
  }),
  { addClient }
)(LanguagePair);
