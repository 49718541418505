import React from "react";
import { connect } from "react-redux";

import { requestFiltersUpdateAndResetPage } from "../_common/filtered_lists/_actions";
import FormSelect from "../_common/FormSelect";
import AugmentedMultiSelect from "../_common/AugmentedMultiSelect";
import SimpleSelect from "../_common/SimpleSelect";
import FormMultiCheckbox from "../_common/FormMultiCheckbox";
import FormCalendar from "../_common/FormCalendar";
import LanguagePairSelector from "./LanguagePairSelector";

export class Sidebar extends React.Component {
  render() {
    const {
      className,
      filters,
      allHumanLanguages,
      qualities,
      yesNo,
      requestFiltersUpdateAndResetPage,
      experienceFields,
      perceivedQualities,
      activityValues,
    } = this.props;

    return (
      <div className={className}>
        <div className="card">
          <div className="card-body">
            <AugmentedMultiSelect
              label="Native language"
              name="nativeLanguages"
              onChange={selectedOptions => requestFiltersUpdateAndResetPage({ nativeLanguages: selectedOptions })}
              value={filters.nativeLanguages || []}
              options={allHumanLanguages}
            />

            <LanguagePairSelector />

            <div className="form-group">
              <label className="m-0" htmlFor="whitelisted">Whitelisted</label>
              <p className="text-muted m-0 mb-1"><small>Select "Yes" to access whitelisted subsets</small></p>
              <SimpleSelect
                name="whitelisted"
                onChange={e => requestFiltersUpdateAndResetPage({ whitelisted: e.target.value })}
                value={filters.whitelisted || ""}
                options={yesNo}
                includeBlank={true}
                blankOptionLabel="Any"
              />
            </div>

            <div className="form-group">
              <label className="m-0" htmlFor="whitelisted">Activity</label>              
              <SimpleSelect
                name="activity"
                onChange={e => requestFiltersUpdateAndResetPage({ activity: e.target.value })}
                value={filters.activity || ""}
                options={activityValues}
                includeBlank={true}
                blankOptionLabel="Any"
              />
            </div>

            {filters.whitelisted == "yes" ?
              <div className="alert alert-info">
                <FormMultiCheckbox
                  label="Whitelisted qualities"
                  columns={2}
                  name="whitelistedQualities"
                  onChange={whitelistedQualities => requestFiltersUpdateAndResetPage({ whitelistedQualities })}
                  values={filters.whitelistedQualities}
                  options={qualities}
                />
              </div>
              : null
            }

            <AugmentedMultiSelect
              label="Experience"
              name="experienceFields"
              onChange={selectedOptions => requestFiltersUpdateAndResetPage({ experienceFields: selectedOptions })}
              value={filters.experienceFields || ""}
              options={experienceFields}
            />

            <FormSelect
              label="Has any CV"
              name="hasAnyCv"
              onChange={e => requestFiltersUpdateAndResetPage({ hasAnyCv: e.target.value })}
              value={filters.hasAnyCv || ""}
              options={yesNo}
              includeBlank={true}
              blankOptionLabel="All"
            />

            <FormMultiCheckbox
              label="Review status"
              columns={2}
              name="qualityLevel"
              onChange={qualityLevel => requestFiltersUpdateAndResetPage({ qualityLevel })}
              values={filters.qualityLevel}
              options={perceivedQualities}
            />

            <FormCalendar
              id="calendar"
              label="Created after"
              callback={(date) => requestFiltersUpdateAndResetPage({ createdAt: date })}
              value={filters.createdAt || ""}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ filters, staticData: { allHumanLanguages, qualities, yesNo, experienceFields, perceivedQualities, activityValues, } }) => ({
    filters,
    allHumanLanguages,
    qualities,
    yesNo,
    experienceFields,
    perceivedQualities,
    activityValues,
  }),
  { requestFiltersUpdateAndResetPage}
)(Sidebar);
