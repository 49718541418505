import React from "react";
import { connect } from "react-redux";

import FormCalendar from "../_common/FormCalendar";
import FormSelect from "../_common/FormSelect";
import FormMultiCheckbox from "../_common/FormMultiCheckbox";
import SimpleSelect from "../_common/SimpleSelect";
import { requestFiltersUpdateAndResetPage } from "../_common/filtered_lists/_actions";

export class Sidebar extends React.Component {
  render() {
    const {
      className,
      filters,
      requestFiltersUpdateAndResetPage,
      orderTypes,
      sourceLanguageCodes,
      targetLanguageCodes,
      clients,
      qualities,
      excludeBlankOrderType,
    } = this.props;

    const undefinedOrderTypeValue = excludeBlankOrderType ? "need_check" : "";

    return (
      <div className={className}>
        <div className="card">
          <div className="card-body">

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Search</label>
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type in your search"
                  name="search"
                  value={filters.search || ""}
                  onChange={(e) => requestFiltersUpdateAndResetPage({ search: e.target.value })}
                />
                <div className="input-group-append"><span className="input-group-text ticon-search"></span></div>
              </div>
              <small id="emailHelp" className="form-text text-muted">Exact matches for order ids and TR versions, partial matches for names.</small>
            </div>

            <FormSelect
              label="Translation request type"
              name="orderType"
              onChange={ e => requestFiltersUpdateAndResetPage({orderType: e.target.value })}
              value={filters.orderType || undefinedOrderTypeValue}
              options={orderTypes}
              includeBlank={!excludeBlankOrderType}
              wrapperClassName={`form-group ${excludeBlankOrderType ? "mb-0": ""}`}
            />
            {excludeBlankOrderType &&
              <small className="form-text text-danger mb-2">
                <span className="ticon-warning mr-1" />
                You need to select at least one more major filter <em>(languages, search, quality or client)</em> to be able to exclude this filter
              </small>
            }

            <div className="form-group">
              <label htmlFor="">Language pair</label>
              <div className="container-fluid">
                <div className="row">
                  <SimpleSelect
                    className="col form-control"
                    name="sourceLanguageCode"
                    onChange={e => requestFiltersUpdateAndResetPage({ sourceLanguageCode: e.target.value })}
                    value={filters.sourceLanguageCode || ""}
                    options={sourceLanguageCodes}
                    includeBlank={true}
                  />
                  <SimpleSelect
                    className="col form-control"
                    name="targetLanguageCode"
                    onChange={e => requestFiltersUpdateAndResetPage({ targetLanguageCode: e.target.value })}
                    value={filters.targetLanguageCode || ""}
                    options={targetLanguageCodes}
                    includeBlank={true}
                  />
                </div>
              </div>
            </div>

            <FormCalendar
              id="calendar"
              label="Created after"
              callback={(date) => requestFiltersUpdateAndResetPage({ createdAfter: date })}
              value={filters.createdAfter || ""}
            />

            <FormSelect
              label="Client"
              name="clientAccountId"
              onChange={e => requestFiltersUpdateAndResetPage({ clientAccountId: e.target.value })}
              value={filters.clientAccountId || ""}
              options={clients}
              includeBlank={true}
            />

            <FormMultiCheckbox
              label="Quality"
              name="qualities"
              onChange={ qualities => requestFiltersUpdateAndResetPage({qualities})}
              values={filters.qualities}
              options={qualities}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ filters, staticData: { orderTypes, sourceLanguageCodes, targetLanguageCodes, clients, qualities} }) => ({
    filters,
    orderTypes,
    sourceLanguageCodes,
    targetLanguageCodes,
    clients,
    qualities,
    // matches no_filters? in orders_presenter.rb
    excludeBlankOrderType: !filters.search && !filters.sourceLanguageCode &&
               !filters.targetLanguageCode && !filters.clientAccountId && !filters.qualities.length,
  }),
  { requestFiltersUpdateAndResetPage }
)(Sidebar);
