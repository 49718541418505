import React from "react";
import { connect } from "react-redux";

import Sidebar from "./Sidebar";
import Results from "./Results";

export class TranslationRequests extends React.Component {
  render() {
    const { totalCount, clientAccountName } = this.props;

    return (
      <div className="container-fluid mb-5">
        <div className="row mt-4">
          <div className="col d-flex justify-content-between">
            <div className="">
              <h1>
                {clientAccountName ? `${clientAccountName}'s translation requests ` : "Translation Requests "}
                <span className="text-muted">({totalCount.toLocaleString()})</span>
              </h1>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <Sidebar className="col-3" />
          <Results className="col-9" />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ totalCount, clientAccountName }) => ({ totalCount, clientAccountName })
)(TranslationRequests);
