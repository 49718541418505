import React from "react";
import { connect } from "react-redux";

import { toggleCreatingReviewTask, getPage } from "./_actions";
import List from "./List";
import TableList from "./TableList";
import Creator from "./Creator";
import { MODES } from "./_constants";
import Pagination from "../_common/pagination/Pagination";


export class QualityReviews extends React.Component {
  componentDidMount(){
    this.props.getPage(1);
  }

  render() {
    const { creatingReviewTask, mode, page, totalPages, getPage } = this.props;

    return (
      <div>
        <h3 className="mt-3 mb-3 hide_for_printers">
          {mode == MODES.received_quality_reviews ?
            "Received Quality Reviews"
            :
            "Quality Reviews"
          }
          {!creatingReviewTask && mode != MODES.received_quality_reviews &&
            <button className="btn btn-primary btn-sm pull-right" onClick={()=>this.props.toggleCreatingReviewTask()}>Create Review</button>
          }
        </h3>
        <Creator />
        {mode == MODES.tasks ?
          <List />
          :
          <TableList />
        }
        <Pagination
          totalPages={totalPages}
          page={page}
          changePage={page => getPage(page) }
        />
      </div>
    );
  }
}

export default connect(
  ({ mode, taskId, translatorAccountId, creatingReviewTask, totalPages, page }) => ({ mode, taskId, translatorAccountId, creatingReviewTask, totalPages, page }),
  ({ toggleCreatingReviewTask, getPage  })
)(QualityReviews);
