import React, {useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorsAlert from "../_common/ErrorsAlert";
import { loadPurchaseOrder } from "./_actions";
import gqlClient from "../_utils/graphQlRequests";
import { orderCancelMutation } from "./_gql";
import { purchaseOrderSelector } from "./_reducers";

export default function CancelOrder({order}){
  const [errors, setErrors] = useState([]);
  const [cancelling, setCancelling] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const purchaseOrder = useSelector(purchaseOrderSelector);
  const dispatch = useDispatch();

  const cancelOrder = () => {
    setCancelling(true);
    gqlClient.request(orderCancelMutation, {input: {orderId: order.id}})
      .then(({orderCancel: {errors}}) => {
        setErrors(errors);
        setCancelled(true);
        if (!errors.length) dispatch(loadPurchaseOrder(purchaseOrder.id));
      })
      .catch(() => setErrors(["Unexpected server error"]))
      .finally(() => setCancelling(false));
  };

  if ((order && !order.cancelable) || cancelled) return null;

  return (
    <>
      <button
        className="btn btn-danger"
        onClick={cancelOrder}
        disabled={cancelling}
      >
        Cancel order
      </button>
      <ErrorsAlert errors={errors} />
    </>
  );
}
