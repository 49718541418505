import { Socket } from "phoenix";

class TranslationLiveMap {
  constructor() {
    this.map = this.createMap();
    this.translators = {
      // translator_id: {
      //   added: true,
      //   location: google.maps.LatLang
      // }
    }

    this.subscribeToTranslationsLive();
    this.cleanTranslators();
  }

  createMap() {
    return  new google.maps.Map(document.getElementById('translations-live-map'), {
      zoom: 4,
      center: new google.maps.LatLng(46.550835, -10.969864),
      styles: TranslationLiveMapStyle
    })
  }

  subscribeToTranslationsLive() {
    const realtimeToken = document.querySelector("meta[name='realtime-token']").getAttribute("content");
    const realtimeUserId = document.querySelector("meta[name='realtime-user-id']").getAttribute("content");

    const socket = new Socket(`${REALTIME_SOCKET_URL}/socket`, { params: { token: realtimeToken }});
    socket.connect();

    const channel = socket.channel("translations", {})

    channel.join()
      .receive("ok", resp => { console.log("Joined successfully", resp) })
      .receive("error", resp => { console.log("Unable to join", resp) });

    channel.on("new:translation", data => {
      let translator = this.translators[data.translator_account_id],
          type =  data.autocompletion ? 'autocomplete' : 'translation';

      if (!translator || !translator.added) {
        this.addTranslator(data.translator_account_id);
        this.locateTranslatorAndPulse(data.translator_account_id, data.address, type);
      }

      if (translator && translator.location) this.addPulse(type, data.translator_account_id);
    });
  }

  // reduces inactive translators size and opacity over time
  // after 10 minutes inactive the translator is deleted
  cleanTranslators() {
    const HALF_LIFE = 1000 * 60 * 2; //the translator will be removed after 5 HALF_LIFE (10 minutes inactive)

    setInterval( () => {
      var now = new Date().getTime(),
          translatorsIds = Object.keys(this.translators);

      translatorsIds.forEach( id => {
        var scale = HALF_LIFE / ( now - this.translators[id].lastActionTime );

        if (scale > 1) scale = 1;

        if (scale > 0.2) {
          $(`#tl-marker-${id}`).css({ transform: `scale(${scale})`, opacity: scale * 1.2 });
        } else {
          delete this.translators[id];
        }
      });
    }, 10000 )
  }

  addTranslator(id) {
    this.translators[id] = { added: true, location: null, lastActionTime: new Date().getTime() };
  }

  addPulse(type, id) {
    var $pulse = $(`<span class='tl_marker__${type}_pulse' />`).appendTo(`#tl-marker-${id}`);

    this.translators[id].lastActionTime = new Date().getTime();
    $pulse.on('animationend', e => $pulse.remove() )
  }

  locateTranslatorAndPulse(translator_id, address, type) {
    this.latlangFrom(address)
        .then( location => {
          this.translators[translator_id].location = location;
          new TranslatorMarker(location, this.map, {id: translator_id });
          setTimeout( () => this.addPulse(type, translator_id) , 100);
        })
        .catch( e => console.error(e, address) );
  }

  latlangFrom(address) {
    var geocoder = new google.maps.Geocoder();

    // recursive search using broader terms each time => [country, city, address] =>  [country, city] =>  [country]
    function getGeoCode(address, resolve, reject) {
      geocoder.geocode( { 'address': address.join(", ")}, (results, status) => {
        if (status == 'OK') {
          resolve( results[0].geometry.location );
        } else if (address.length <= 1) {
          reject('Address: '+ address + "not found,  " + status);
        } else {
          address.pop();
          getGeoCode(address, resolve, reject);
        }
      });
    }

    return new Promise( (resolve,reject) => getGeoCode(address, resolve, reject) )
  }
}


if ($("#translations-live-map").length) {
  var translationLiveMap;
  $(window).on("translator_marker_available", () => translationLiveMap = new TranslationLiveMap() );
}
