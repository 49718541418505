import React from "react";
import { connect } from "react-redux";

import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";
import { selectedResultsHashSelector } from "../_common/filtered_lists/_selectors";
import { toggleResultSelection } from "../_common/filtered_lists/_actions";
import { openProjectNamePopup, openDeadlinePopup, updateSelectedTask } from "./_actions";

export class TasksList extends React.Component {
  openProjectNamePopup(e, id) {
    e.preventDefault();
    this.props.updateSelectedTask(id);
    this.props.openProjectNamePopup();
  }

  openDeadlinePopup(e, id) {
    e.preventDefault();
    this.props.updateSelectedTask(id);
    this.props.openDeadlinePopup();
  }

  render() {
    const { tasks, toggleResultSelection, selectedResultsHash, search,  } = this.props;

    return (
      <tbody>
        {tasks.map(({
          id,
          taskPath,
          title,
          clientPath,
          clientName,
          words,
          quality,
          languagePair,
          translatorPath,
          translatorEmail,
          proofreaderPath,
          proofreaderEmail,
          lockedTranslatorPath,
          lockedTranslatorEmail,
          reservedTranslatorPath,
          reservedTranslatorEmail,
          projectName,
          status,
          completedAt,
          progress,
          createdAt,
          orderedAt,
          deadline,
          inactiveFor,
          tooInactive,
          proposedClientDeadline,
        })=>{          
          return (
            <tr key={`task-${id}`}>
              <td>
                <input type="checkbox" checked={selectedResultsHash[id] || false} onChange={() => toggleResultSelection(id)} />
              </td>
              <td><a href={taskPath} className={parseInt(search) == id ? "bg-warning p-1" : ""}>{id}</a></td>
              <td>
                <p className="mb-2">
                  {!!search && !parseInt(search) && RegExp(search, "gi").test(title) ?
                    <span
                      dangerouslySetInnerHTML={{
                        __html: title.replace(RegExp(search, "gi"), "<span class=\"bg-warning\">$&</span>")
                      }}
                    />
                    :
                    title
                  } <small className="text-muted">by</small> <a href={clientPath}>{clientName}</a>
                </p>

                <p className="m-0">
                  <strong>Words:</strong> {words.toLocaleString()}
                  <strong className="ml-3">{quality}</strong> {languagePair}
                </p>

                <p className="m-0">
                  {translatorEmail ?
                    <React.Fragment>
                      <strong className="mr-1">Translator:</strong> <a href={translatorPath}>{translatorEmail}</a>
                    </React.Fragment>
                    : null
                  }
                  {proofreaderEmail ?
                    <React.Fragment>
                      <strong className="ml-3 mr-1">Proofreader:</strong> <a href={proofreaderPath}>{proofreaderEmail}</a>
                    </React.Fragment>
                    : null
                  }
                </p>

                {lockedTranslatorEmail ?
                  <p className="m-0">
                    <strong className="mr-1">Locked:</strong> <a href={lockedTranslatorPath}>{lockedTranslatorEmail}</a>
                  </p>
                  : null
                }

                {reservedTranslatorEmail ?
                  <p className="m-0">
                    <strong className="mr-1">Reserved:</strong> <a href={reservedTranslatorPath}>{reservedTranslatorEmail}</a>
                  </p>
                  : null
                }

                <p className="m-0">
                  <strong>Project name: </strong>
                  {projectName ?
                    <React.Fragment>
                      <span>{projectName} </span>
                      <a href="#" className="ticon-edit" onClick={(e) => this.openProjectNamePopup(e, id)}></a>
                    </React.Fragment>
                    :
                    <span className="text-muted" onClick={(e)=>this.openProjectNamePopup(e,id)}>Click here to set it</span>
                  }
                </p>

                {proposedClientDeadline ?
                  <p className="m-0">
                    <strong>Originally proposed deadline: </strong> <PrettyLocalDateTime date={proposedClientDeadline} />
                  </p>
                  : null
                }
              </td>
              <td>
                {status}

                {completedAt ?
                  <p className="m-0 text-muted">
                    <small>
                      <PrettyLocalDateTime date={completedAt} />
                    </small>
                  </p>
                  : null
                }

                {progress ? <p className="m-0 text-muted"><small>{progress}%</small></p> : null }
              </td>
              <td>
                <PrettyLocalDateTime date={createdAt} />
              </td>
              <td>
                <PrettyLocalDateTime date={orderedAt} />
              </td>              
              <td>
                {deadline ?
                  <PrettyLocalDateTime date={deadline} />
                  :
                  <span className="text-muted">No deadline</span>
                }
                <a href="#" className="ml-2 ticon-edit" onClick={(e)=>this.openDeadlinePopup(e,id)}></a>
              </td>
              <td>
                {status == "finished" ?
                  <span>N/A</span>
                  :
                  <span className={tooInactive ? "text-danger" : ""}>{inactiveFor}</span>
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }
}

export default connect(
  ({ tasks, selectedResults, filters: {search} }) => ({
    tasks,
    selectedResultsHash: selectedResultsHashSelector({ selectedResults }),
    search,
  }),
  { toggleResultSelection, openProjectNamePopup, openDeadlinePopup, updateSelectedTask }
)(TasksList);
