import React, { useCallback } from "react";

const MIN = 0;
const MAX = 9;

export default function PriceField(props) {
  const min = props.min || MIN;
  const max = props.max || MAX;

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (value < min) value = min;
    if (value > max) value = max;

    props.onChange({ [name]: value });
  };

  return (
    <input
      className="text-center form-control"
      min={MIN}
      max={MAX}
      step="0.001"
      type="number"
      name={props.name}
      value={props.value}
      onChange={handleChange}
    />
  );
}
