// Props summary
// Select input with optional label
// label (optional): Label that would appear over the input
// name: MANDATORY unique input name and input id
// onChange: callback to call on change
// value: current selected options
// className: name of the class used in the Select
// wrapperClassName: name of the class used in the input wrapper
// options: array of options with 2 possible formats:
//    [optionValue]: values and labels are the same
//    [[optionLabel, optionValue]]: what is shown (label) is different to waht is handled/sent

import React from "react";

import { onClickOutside } from "../_utils/customClickEvents";

export class AugmentedMultiSelect extends React.Component {
  constructor(props){
    super(props);

    this.multiselectInput = React.createRef();

    this.optionsHaveLabels = props.options.length && Array.isArray(props.options[0]);

    this.state = {
      selectedOptions: props.value || [],
      searching: false,
      searchTerm: "",
    };
  }


  componentDidMount() {
    this.clickOusideListener = onClickOutside(`#${this.props.name}`, () => this.setState({ searching: false }) );
  }

  componentWillUnmount() {
    this.clickOusideListener.stop();
  }

  componentDidUpdate({value: oldValue}){
    if (oldValue !== this.props.value) {
      this.setState({ selectedOptions: this.props.value || []});
    }
  }

  addOption(option){
    this.setState({
      selectedOptions: [...this.state.selectedOptions.filter(o => option !== o), option],
      searching: false,
      searchTerm: "",
    }, () => this.props.onChange(this.state.selectedOptions) );
  }

  removeOption(e, option) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      selectedOptions: this.state.selectedOptions.filter(o => option !== o),
      searching: false,
    }, () => this.props.onChange(this.state.selectedOptions) );
  }

  handleMultiselect() {
    this.multiselectInput.current.focus();
    this.setState({ searching: true });
  }

  inputWidth() {
    const inputLength = this.state.searchTerm.length + 2;
    if (inputLength < 10) return `${inputLength}rem`;
    return "100%";
  }

  render() {
    const { selectedOptions, searching, searchTerm } = this.state,
          { options, wrapperClassName, label, name } = this.props,
          rx = RegExp(`^${searchTerm}`, "i"),
          optionKeys = options.map(o => this.optionsHaveLabels ? o[1] : o);

    const optionLabels = options.reduce((a, o) => {
      if (this.optionsHaveLabels) {
        const [label, key] = o;
        a[key] = label;
      } else {
        a[o] = o;
      }
      return a;
    }, {});

    const filteredOptionKeys = optionKeys.filter(o => !selectedOptions.includes(o))
                                         .filter(o => rx.test(optionLabels[o]));

    return (
      <div>
        <div className={ wrapperClassName || "form-group"}>
          {label ? <label htmlFor={name}>{label}</label> : null}
          <div
            className="multiselect form-control"
            id={name}
            onClick={()=>this.handleMultiselect()}
          >
            <ul className="multiselect__selected_options">
              {selectedOptions.map( optionKey => {
                return (
                  <li
                    key={`selected-option-${name}-${optionKey}`}
                    onClick={(e)=>this.removeOption(e, optionKey)}
                  >
                    <a href="#" className="badge badge-primary">
                      {optionLabels[optionKey]} | <small className="ticon-cancel"></small>
                    </a>
                  </li>
                );
              })}
            </ul>
            <input
              type="text"
              className="multiselect__input"
              value={searchTerm}
              ref={this.multiselectInput}
              onChange={ e => this.setState({ searchTerm: e.target.value, searching: true })}
              style={{width: this.inputWidth()}}
            />
            {searching ?
              <ul className="multiselect__options list-group">
                {filteredOptionKeys.map(optionKey => {
                  const matchedPart = rx.exec(optionLabels[optionKey])[0],
                        nonMatchedPart = optionLabels[optionKey].slice(matchedPart.length);

                  return (
                    <li
                      className="list-group-item list-group-item-action"
                      key={`select-option-${name}-${optionKey}`}
                      onClick={()=>this.addOption(optionKey)}
                    >
                      <strong>{matchedPart}</strong>
                      {nonMatchedPart}
                    </li>
                  );
                })}
              </ul>
              :
              null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default AugmentedMultiSelect;
