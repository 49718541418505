import React from "react";
import PropTypes from "prop-types";

export class Next extends React.Component {
  render() {
    const { totalPages, page, changePage } = this.props;

    if (totalPages == 1) return null;

    return (
      <li className={`page-item ${page == totalPages ? "disabled" : ""}`}>
        <span className="page-link" onClick={e => changePage(page + 1, e)}>&raquo;</span>
      </li>
    );
  }
}

export default Next;

Next.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};