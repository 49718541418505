import { combineReducers } from "redux";

import {
  OPEN_DEADLINE_CHANGER,
  CLOSE_DEADLINE_CHANGER,
  SET_NEW_DEADLINE,
} from "./_actions";

export const changeDeadlineOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_DEADLINE_CHANGER:
      return true;
    case CLOSE_DEADLINE_CHANGER:
      return false;
    default:
      return state;
  }
};

export const deadline = (state= null, action) => {
  if (action.type == SET_NEW_DEADLINE) return action.deadline;
  return state;
};

// Static
export const proposedClientDeadline = (state = null) => state;
export const offcialClientDeadline = (state = null) => state;
export const acceptDeadlinePath = (state = "") => state;
export const changeDeadlinePath = (state = "") => state;

export default combineReducers({
  proposedClientDeadline,
  offcialClientDeadline,
  acceptDeadlinePath,
  changeDeadlinePath,
  changeDeadlineOpen,
  deadline,
});