import {
  SET_USER,
  UPDATE_USER
} from "./_actions";

import { combineReducers } from "redux";

export const user = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user;
    case UPDATE_USER:
      return { ...state, ...action.changes };
    default:
      return state;
  }
};

export const userSelector = (state) => state.user;

export default combineReducers({
  user
});
