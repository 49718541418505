import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from 'use-debounce';

import Card from "../_common/Card";
import Field from "../_common/Field";

import { formFields } from "./_constants";
import {
  useGetPromptTemplateQuery,
  useCreateMachineTranslatorPromptMutation,
  useUpdateMachineTranslatorPromptMutation,
  usePreviewMachineTranslatorPromptMutation,
} from './_api';

const initialState = {
  type: 'chatgpt',
  keepHtmlIntact: false,
  properNamesTitles: false,
};

const AiTemplateFormHeader = ({ isEditing }) => {
  return (
    <h2>{ isEditing ? 'Update' : 'New'} AI template</h2>
  )
}

const AiTemplateForm = ({ isEditing, machineTranslatorPromptId }) => {
  const dispatch = useDispatch();

  const [template, setTemplate] = useState(initialState);
  const { data: machineTranslatorPrompt } = useGetPromptTemplateQuery({promptId: machineTranslatorPromptId});

  const [createMachineTranslatorPromptTemplate] = useCreateMachineTranslatorPromptMutation();
  const [updateMachineTranslatorPromptTemplate] = useUpdateMachineTranslatorPromptMutation();
  const [previewMachineTranslatorPromptTemplate] = usePreviewMachineTranslatorPromptMutation();

  useEffect(() => {
    if (machineTranslatorPrompt) {
      setTemplate(machineTranslatorPrompt);
    }
  }, [machineTranslatorPrompt]);

  useEffect(() => {
    if (isEditing) return;

    previewMachineTranslatorPromptTemplate({ input: { promptAttributes: template }}).then((response) => {
      const prompt = response?.data?.machineTranslatorPromptPreview?.prompt;

      if (prompt) setTemplate(prompt);
    });
  }, [isEditing]);

  const previewTemplateCallback = useDebouncedCallback((prompt) => {
    previewMachineTranslatorPromptTemplate({input: { promptAttributes: cleanAttributes(prompt) }}).then((response) => {
      const prompt = response?.data?.machineTranslatorPromptPreview?.prompt;

      if (prompt) setTemplate(prompt);
    });
  }, 1000);

  const cleanAttributes = (template) => {
    const promptAttributes = { ...template };

    // cleanup attributes
    delete promptAttributes['id'];
    promptAttributes['context'] = promptAttributes['translationContext'];
    delete promptAttributes['translationContext'];
    delete promptAttributes['defaultPrompt'];
    delete promptAttributes['defaultRefinementPrompt'];

    promptAttributes['type'] = 'chatgpt';

    return promptAttributes;
  }

  const onSaveChanges = () => {
    if (isEditing) {
      updateMachineTranslatorPromptTemplate({ input: { promptId: machineTranslatorPromptId, promptAttributes: cleanAttributes(template) }});
    } else {
      createMachineTranslatorPromptTemplate({input: { promptAttributes: cleanAttributes(template) }}).then(({ data }) => {
        console.log('response', data);
        if (data?.machineTranslatorPromptCreate?.success) {
          window.location.href = '/ai';
        }
      });
    }
  }

  const onChangeAttribute = (attribute, value) => {
    const previewTemplate = { ...template, [attribute]: value };

    setTemplate(previewTemplate);
    previewTemplateCallback(previewTemplate);
  };

  return (
    <>
      <AiTemplateFormHeader isEditing={isEditing} />
      <Card className="mb-4">
        <div className="row">
          <Field
            key="name"
            label="Name"
            attribute="name"
            type="text"
            value={template['name']}
            width="3"
            onChange={(value) => onChangeAttribute('name', value)}
          />
        </div>
        <div className="row">
          { formFields.map(field =>(
              <Field
                key={field.attribute}
                {...{
                  ...field,
                  value: template[field.attribute],
                  onChange: value => onChangeAttribute(field.attribute, value)
                }}
              />
            ))}
        </div>
        <div className="row">
          <Field
            key="general"
            label="General"
            attribute="general"
            type="textarea"
            value={template['general']}
            width="8"
            onChange={(value) => onChangeAttribute('general', value)}
          />
        </div>
        <hr />
        <div className="row">
          <Field
            key="prompt"
            label="Prompt"
            attribute="prompt"
            type="textarea"
            value={template['prompt']}
            placeholder={template['defaultPrompt']}
            width="8"
            rows="10"
            onChange={(value) => onChangeAttribute('prompt', value)}
          />
        </div>
        <div className="row">
          <Field
            key="refinementPrompt"
            label="Refinement prompt"
            attribute="refinementPrompt"
            type="textarea"
            value={template['refinementPrompt']}
            placeholder={template['defaultRefinementPrompt']}
            width="8"
            rows="10"
            onChange={(value) => onChangeAttribute('refinementPrompt', value)}
          />
        </div>
        <div className="row">
          <Field
            key="proofreadingPrompt"
            label="Proofreading prompt (required)"
            attribute="proofreadingPrompt"
            type="textarea"
            value={template['proofreadingPrompt']}
            width="8"
            rows="10"
            onChange={(value) => onChangeAttribute('proofreadingPrompt', value)}
          />
        </div>
        <div className="row">
          <input
            type="submit"
            className="btn btn-sm btn-success ml-1"
            value="Save"
            onClick={() => onSaveChanges()}
          />
        </div>
      </Card>
    </>
  )
}

export default AiTemplateForm;
