import React from "react";
import { debounce } from "underscore";

import gqlClient from "../_utils/graphQlRequests";
import { updateTaskMutation } from "./_gql";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";

export class BadVerdict extends React.Component {
  constructor(props){
    super(props);

    const {
      badVerdict: {
        proposedAction, actionTaken, translatorAnswered, opsHandled
      }
    } = props;

    this.state = {
      translatorAnswered,
      opsHandled,
      proposedAction,
      actionTaken,
      error: false,
      errors: [],
    };
  }

  debouncedSave = debounce(()=>{
    const { translatorAnswered, opsHandled, proposedAction, actionTaken, } = this.state;

    const variables = {
      input: {
        taskId: this.props.badVerdict.id,
        taskInput: {
          translatorAnswered, opsHandled, proposedAction, actionTaken
        }
      }
    };

    gqlClient.request(updateTaskMutation,variables)
      .then(({taskUpdate:{errors}}) => {
        if (errors.length) this.setState({errors});
      })
      .catch( error => {
        console.error(error);
        this.setState({error});
      } );
  }, 1000)

  updateBadVerdict(changes) {
    this.setState({...changes, error: null, errors: [] }, ()=> this.debouncedSave() );
  }

  render() {
    const {
      badVerdict: {
        id,
        date,
        proofreaderVerdictText,
        translator,
        proofreader
      }
    } = this.props;

    const emailStyle = {
      maxWidth: "150px",
      overflow: "hidden",
      display: "inline-block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    };

    const {
      translatorAnswered,
      opsHandled,
      proposedAction,
      actionTaken,
      errors,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <tr>
          <td><a href={`/tasks/${id}`} target="_blank" rel="noreferrer" >#{id}</a></td>
          <td>
            <small>Translator</small>
            <br/>
            {translator ?
              <a style={emailStyle} title={translator.email} href={`/translators/${translator.id}`} target="_blank" rel="noreferrer" >
                {translator.email}
              </a>
              :
              <span>N/A <small className="text-muted">machine translation or deleted</small></span>
            }
            <br />
            <small>Proofreader</small>
            <br />
            {proofreader ?
              <a style={emailStyle} title={proofreader.email} href={`/translators/${proofreader.id}`} target="_blank" rel="noreferrer" >
                {proofreader.email}
              </a>
              :
              <span>N/A <small className="text-muted">probably deleted</small></span>
            }
          </td>
          <td>
            {proofreaderVerdictText}
          </td>
          <td>
            <small>
              <PrettyLocalDateTime date={date} />
            </small>
          </td>
          <td className="text-center">
            <a
              href="#"
              className={translatorAnswered ? "ticon-check text-success" : "ticon-cancel text-danger"}
              onClick={e => { e.preventDefault(); this.updateBadVerdict({ translatorAnswered: !translatorAnswered }); }}
            />
          </td>
          <td className="text-center">
            <a
              href="#"
              className={opsHandled ? "ticon-check text-success" : "ticon-cancel text-danger"}
              onClick={e => { e.preventDefault(); this.updateBadVerdict({ opsHandled: !opsHandled }); }}
            />
          </td>
          <td>
            <textarea
              className="form-control"
              onChange={e => this.updateBadVerdict({ proposedAction: e.target.value })}
              value={proposedAction || ""}
            />
          </td>
          <td>
            <textarea
              className="form-control"
              onChange={e => this.updateBadVerdict({ actionTaken: e.target.value })}
              value={actionTaken || ""}
            />
          </td>
        </tr>
        {errors.length > 0 || error ?
          <tr className=" bg-danger">
            <td colSpan="8" className="text-center bg-danger pt-2 pb-3">
              <p className="text-white">
                <strong>
                  <span className="ticon-warning mr-3" /> Something went wrong while saving the changes in Task #{id}
                </strong>
              </p>
              {errors.map((error, i) => <p className="text-white m-0" key={i}>{error}</p>)}
            </td>
          </tr>
          : null
        }
      </React.Fragment>
    );
  }
}

export default BadVerdict;
