import React from "react";
import { connect } from "react-redux";

import Sidebar from "./Sidebar";
import { isPotentiallyMergeable, areCoordsWithinBoundaries } from "./_utils";
import { potentialMergeablesList } from "./_selectors";
import { selectMergeSentence } from "./_actionsAndReducers";
import Popup from "./Popup";
import NoMergePossible from "./NoMergePossible";

export class MergeUI extends React.Component {
  phraseClass(name, chunkId, keyIndex, index) {
    let { sourceTexts, highlightedMergeable, mergeSelectionBounds } = this.props;

    if (areCoordsWithinBoundaries({ x: keyIndex, y: index }, mergeSelectionBounds)) return "bg-success text-white";

    if (!sourceTexts[chunkId]) return "";

    if (highlightedMergeable.name == name &&
        highlightedMergeable.chunkId == chunkId &&
        highlightedMergeable.index == index) return "bg-primary text-white";

    if( isPotentiallyMergeable(sourceTexts[chunkId])) return "bg-warning text-white";
  }

  componentDidUpdate({ keysData: oldKeysData }) {
    let { coordsToScrollTo: { x, y }, keysData } = this.props;

    if (oldKeysData != keysData) {
      let name = keysData[x].name,
          chunkId = keysData[x].sourceChunkIds[y],
          element = document.getElementById(`${name}-${chunkId}-${y}`);

      if (element) element.scrollIntoView();
    }
  }

  render() {
    let { keysData, sourceTexts, translatedInTM, selectMergeSentence, postprocessingReady } = this.props;

    if (!postprocessingReady) return <NoMergePossible />;

    return (
      <div className="row">
        <div className="col-8" style={{ position: "relative" }} >
          <Popup />
          {keysData.map((keyData, keyIndex) => (
            <div key={keyData.name}>
              <h6>{keyData.name}</h6>
              <table className="table table-sm table-borderless table-hover mb-4" >
                <thead className="thead-dark">
                  <tr>
                    <th>Source text</th>
                    <th width="120px">Info</th>
                  </tr>
                </thead>
                <tbody>
                  {keyData.sourceChunkIds.map( (chunkId, index) => (
                    <tr
                      key={`${keyData.name}-${chunkId}-${index}`}
                      id={`${keyData.name}-${chunkId}-${index}`}
                      onClick={ () => selectMergeSentence({x:keyIndex, y:index}) }
                    >
                      <td
                        className={this.phraseClass(keyData.name, chunkId, keyIndex, index)}
                      >
                        {sourceTexts[chunkId]}
                      </td>
                      <td>{translatedInTM[chunkId] ? "Already translated" : ""}</td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          ))}
        </div>
        <Sidebar />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    keysData: state.keysData,
    sourceTexts: state.sourceTexts,
    translatedInTM: state.translatedInTM,
    highlightedMergeable: potentialMergeablesList(state)[state.currentMergeableIndex] || {},
    mergeSelectionBounds: state.mergeSelectionBounds,
    postprocessingReady: state.postprocessingReady,
    coordsToScrollTo: state.coordsToScrollTo
  }),
  { selectMergeSentence }
)(MergeUI);
