import React from "react";
import { connect } from "react-redux";

import { requestFiltersUpdate } from "./_actions";

export class Tabs extends React.Component {
  changeTab = (e, tab) => {
    e.preventDefault();
    this.props.requestFiltersUpdate({ page:1, tab });
  }

  render() {
    const { withTrainingTotalCount, withoutTrainingTotalCount, trainingTextSelected } = this.props;

    return (
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className={`nav-link ${trainingTextSelected ? "active" : ""}`}
            href="#"
            onClick={e => this.changeTab(e, "")}
          >
            With Training Text ({withTrainingTotalCount})
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${trainingTextSelected ? "" : "active"}`}
            href="#"
            onClick={e => this.changeTab(e, "without_training_text")}
          >
            Without Training Text ({withoutTrainingTotalCount})
          </a>
        </li>
      </ul>
    );
  }
}

export default connect(
  ({ filters: { tab }, withTrainingTotalCount, withoutTrainingTotalCount,  } ) => {
    let trainingTextSelected = tab != "without_training_text";

    return {
      trainingTextSelected,
      withTrainingTotalCount,
      withoutTrainingTotalCount,
    };
  },
  { requestFiltersUpdate }
)(Tabs);
