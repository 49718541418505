import React, { useState } from "react";

export default function EditableField({ name, value, onSave }) {
  const [newValue, setNewValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    const { success, errors } = await onSave(newValue);

    if (success) {
      setEditMode(false);
      setError(null);
    } else {
      setError(errors.join(", "));
    }
  };

  return (
    <dl className="inline-dl">
      <dt>{name}:</dt>
      {editMode ?
        <dd>
          <form onSubmit={onSubmit}>
            <input
              type="text"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
            <input
              type="submit"
              className="btn btn-sm btn-success ml-1"
              value="Save"
            />
            {error && <div className="alert alert-danger">{error}</div>}
          </form>
        </dd>
        :
        <dd>
          {value}
          <a
            href="#"
            className="my-0 mx-1 ticon-edit"
            onClick={() => setEditMode(true)}
          />
        </dd>}
    </dl>
  );
}
