import { combineReducers } from "redux";

import { CHANGE_TARGET_LANGUAGE, CHANGE_SOURCE_LANGUAGE, CHANGE_TEXT } from "./_actions";

export const staticData = (state = {}) => state;

export const sourceLanguageCode = (state = "", action) => {
  if (action.type == CHANGE_SOURCE_LANGUAGE) return action.sourceLanguageCode;
  return state;
};

export const targetLanguageCode = (state = "", action) => {
  if (action.type == CHANGE_TARGET_LANGUAGE) return action.targetLanguageCode;
  return state;
};

export const text = (state = "", action) => {
  if (action.type == CHANGE_TEXT) return action.text;
  return state;
};


export default combineReducers({
  staticData,
  sourceLanguageCode,
  targetLanguageCode,
  text,
});