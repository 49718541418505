import React from "react";

import { useSendInvoiceMutation } from "./_api";

export default function SendInvoice({ id, paid }){
  const [sendInvoice, { isLoading, error }] = useSendInvoiceMutation();


  if (paid) return null
  if (error) console.error(error);

  if (isLoading) return (
    <span className="text-muted">Loading...</span>
  );

  return (
    <button
      className="btn btn-outline-primary btn-sm"
      onClick={() => sendInvoice({input: { invoiceId: parseInt(id)}})}
    >
      Send Invoice
    </button>
  );
}
