import { combineReducers } from "redux";

// Constants
// ======================================
export const CHANGE_MERGEABLE_INDEX = "CHANGE_MERGEABLE_INDEX";
export const SELECT_MERGE = "SELECT_MERGE";
export const SELECT_MERGE_START = "SELECT_MERGE_START";
export const SELECT_MERGE_END = "SELECT_MERGE_END";
export const CANCEL_MERGE_SELECTION = "CANCEL_MERGE_SELECTION";
export const UPDATE_MERGE_DATA = "UPDATE_MERGE_DATA";

// Actions
// ======================================
export const changeMergeableIndex = (currentMergeableIndex) => ({
  type: CHANGE_MERGEABLE_INDEX,
  currentMergeableIndex
});

export const selectMergeSentence = (coords) => (dispatch, getState) => {
  let { mergeSelectionBounds: { start, end } } = getState();

  // If boundaries selected do nothing
  if (start !== null && end !== null) return;
  // If no boundaries set start
  if (start === null) return dispatch({ type: SELECT_MERGE_START, coords });
  // If clicking first sentence twice
  if (end === null && start.x == coords.x && start.y == coords.y) return dispatch({ type: CANCEL_MERGE_SELECTION });
  // If selected a second phrase and appears before the second
  if ((start.x > coords.x) || (start.x == coords.x && start.y > coords.y)) {
    return dispatch({ type: SELECT_MERGE, mergeSelectionBounds: { start: coords, end: { ...start } } });
  }
  // If selected a second phrase
  dispatch({ type: SELECT_MERGE_END, coords });
};

export const cancelMergeSelection = () => ({ type: CANCEL_MERGE_SELECTION });

export const updateMergeData = ({ keysData, sourceTexts, translatedInTM }) => ({
  type: UPDATE_MERGE_DATA,
  keysData, sourceTexts, translatedInTM
});

// Reducers
// ======================================

export const orderId = (state = null) => state;
export const postprocessingReady = (state = true) => state;

export const keysData = (state = [], action) => {
  switch (action.type) {
    case UPDATE_MERGE_DATA:
      return action.keysData;
    default:
      return state;
  }
};

export const sourceTexts = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MERGE_DATA:
      return action.sourceTexts;
    default:
      return state;
  }
};

export const translatedInTM = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MERGE_DATA:
      return action.translatedInTM;
    default:
      return state;
  }
};

export const currentMergeableIndex = (state = null, action) => {
  switch (action.type) {
    case CHANGE_MERGEABLE_INDEX:
      return action.currentMergeableIndex;
    case UPDATE_MERGE_DATA:
      return null;
    default:
      return state;
  }
};

export const mergeSelectionBounds = (state = { start: null, end: null }, action) => {
  switch (action.type) {
    case SELECT_MERGE:
      return action.mergeSelectionBounds;
    case SELECT_MERGE_START:
      return { ...state, start: action.coords };
    case SELECT_MERGE_END:
      return { ...state, end: action.coords };
    case CANCEL_MERGE_SELECTION:
      return { start: null, end: null };
    default:
      return state;
  }
};

export const coordsToScrollTo = ( state = {x: 0, y: 0}, action) => {
  switch (action.type) {
    case SELECT_MERGE_START: {
      return {...action.coords};
    }
    default:
      return state;
  }
};

export const reducers = combineReducers({
  orderId,
  keysData,
  sourceTexts,
  translatedInTM,
  currentMergeableIndex,
  mergeSelectionBounds,
  postprocessingReady,
  coordsToScrollTo
});