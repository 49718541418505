import { gql } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/query/react";

import { graphqlBaseQuery } from "../_utils/graphqlBaseQuery";

export const gqlApi = createApi({
  reducerPath: "gql",
  baseQuery:   graphqlBaseQuery(),
  tagTypes:    [ "ClientAccount", "Invoices" ],
  endpoints:   (builder) => ({
    getClientAccount: builder.query({
      transformResponse: response => response?.clientAccount,
      query:             input => ({
        input,
        body: gql`
          query ClientAccount($id: ID!) {
            clientAccount(id: $id){
              name
              paymentMethod
              billingAccount {
                id
              }
            }
          }
        `
      })
    }),
    getInvoices: builder.query({
      providesTags:      ["Invoices"],
      transformResponse: response => {
        if (!response?.invoices) return;

        return {
          items:      response.invoices.nodes,
          totalItems: response.invoices.nodesCount,
          totalPages: response.invoices.pagesCount,
        };
      },
      query: (input) => ({
        input,
        body: gql`
        query Invoices(
            $sortAttribute: InvoiceSortAttributeEnum,
            $sortOrder: SortOrderEnum,
            $page: Int,
            $perPage: Int,
            $billingAccountId: ID,
            $paid: Boolean,
            $paymentMethod: PaymentMethodEnum,
          ){
          invoices(
            sortAttribute: $sortAttribute,
            sortOrder: $sortOrder,
            page: $page,
            perPage: $perPage,
            billingAccountId: $billingAccountId,
            paid: $paid,
            paymentMethod: $paymentMethod
          ){
            nodes {
              id
              amount
              billingAccount {
                clientAccount {
                  id
                  name
                }
              }
              chamberOfCommerce
              createdAt
              issuedAt
              currency
              dueDate
              reminderSentAt
              reminderResentAt
              fromAddress
              netAmount
              paid
              poCode
              taxAmount
              taxId
              toAddress
            }
            nodesCount
            pagesCount
          }
        }
        `
      })
    }),
    createInvoice: builder.mutation({
      invalidatesTags: ["Invoices"],
      query:           input => ({
        input,
        body: gql`
          mutation InvoiceCreate($input: InvoiceCreateInput!) {
            invoiceCreate(input: $input) {
              errors
              success
            }
          }
        `
      })
    }),
    markInvoiceAsPaid: builder.mutation({
      invalidatesTags: ["Invoices"],
      query:           input => ({
        input,
        body: gql`
          mutation InvoiceMarkAsPaid($input: InvoiceMarkAsPaidInput!) {
            invoiceMarkAsPaid(input: $input) {
              errors,
              success
            }
          }
        `
      })
    }),
    recreateInvoice: builder.mutation({
      invalidatesTags: ["Invoices"],
      query:           input => ({
        input,
        body: gql`
          mutation InvoiceRecreate($input: InvoiceRecreateInput!) {
            invoiceRecreate(input: $input) {
              errors,
              success
            }
          }
        `
      })
    }),
    sendInvoiceReminder: builder.mutation({
      invalidatesTags: ["Invoices"],
      query:           input => ({
        input,
        body: gql`
          mutation InvoiceSendReminder($input: InvoiceSendReminderInput!) {
            invoiceSendReminder(input: $input) {
              errors,
              success
            }
          }
        `
      })
    }),
    sendInvoice: builder.mutation({
      invalidatesTags: ["Invoices"],
      query:           input => ({
        input,
        body: gql`
          mutation InvoiceSend($input: InvoiceSendInput!) {
            invoiceSend(input: $input) {
              errors,
              success
            }
          }
        `
      })
    }),
    sendInvoiceAll: builder.mutation({
      invalidatesTags: ["Invoices"],
      query:           input => ({
        input,
        body: gql`
          mutation InvoiceSendAll {
            invoiceSendAll(input: {}) {
              errors,
              success
            }
          }
        `
      })
    }),
  })
});

export const {
  useGetInvoicesQuery,
  useGetClientAccountQuery,
  useCreateInvoiceMutation,
  useMarkInvoiceAsPaidMutation,
  useRecreateInvoiceMutation,
  useSendInvoiceReminderMutation,
  useSendInvoiceMutation,
  useSendInvoiceAllMutation,
} = gqlApi;
