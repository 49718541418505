import { combineReducers } from "redux";

import { UPDATE_EDITABLE_FIELDS, RESET_FIELDS, REMOVE_GLOSSARY } from "./_actions";

export const editableFields = (state = {}, action) => {
  if (action.type == UPDATE_EDITABLE_FIELDS) {
    if (action.field === 'assignedGlossariesIds') {
      const assignedGlossaries = [Number(action.value), ...state.assignedGlossariesIds]
      return {
        ...state,
        [action.field]: [...new Set(assignedGlossaries)],
      };
    };
    return {
      ...state,
      [action.field]: action.value
    };
  }
  if (action.type == RESET_FIELDS) {
    return action.editableFields;
  }
  if (action.type === REMOVE_GLOSSARY) {
    const assignedGlossaries = state.assignedGlossariesIds.filter((g) => g != action.glossaryId)
    return {
      ...state,
      assignedGlossariesIds: [...new Set(assignedGlossaries)],
    };
  }
  return state;
};

export const staticData = (state = {} )  => state;

export default combineReducers({
  editableFields,
  staticData,
});
