import React from "react";

import { allowedTagsGlobalIgnoreCaseRx, tagClass } from "../_utils/metaTranslation";
import PlainTextFragment from "./PlainTextFragment";


export class TextWithTags extends React.Component {
  render() {
    if (!this.props.text) return "";

    const { text, isXliff, index } = this.props,
          rx = allowedTagsGlobalIgnoreCaseRx(isXliff),
          tagList = [...text.matchAll(rx)].map(m => m[0]),
          textList = text.split(rx);

    return (
      <React.Fragment>
        {textList.map((t, i) => {
          const tag = tagList[i];

          return (
            <React.Fragment key={`original-segment-${index}-${i}`}>
              <PlainTextFragment text={t} />
              {tag ?
                <span className={tagClass(tag)}>{tag}</span>
                : null
              }
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

export default TextWithTags;
