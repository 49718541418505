import React from "react";

export class Whitelisted extends React.Component {
  render() {
    const {clientLanguages, id} = this.props;

    if (!clientLanguages) return <span className="text-muted">No languages</span>;

    return (
      <div>
        {Object.keys(clientLanguages).map(languagePair => {
          const qualities = Object.keys(clientLanguages[languagePair]);

          return (
            <div className="row mb-1" key={`client-${id}-lp-${languagePair}`}>
              <div className="col-5" style={{whiteSpace: "nowrap"}}>{languagePair}:</div>
              <div className="col-7" style={{fontSize: "small"}}>
                {qualities.length ?
                  <React.Fragment>
                    {qualities.map(quality => (
                      <a
                        key={`client-${id}-lp-${languagePair}-quality-${quality}`}
                        href={`/clients/${id}/whitelisting/?language_pair=${languagePair.toLowerCase()}&whitelisted=yes`}
                        className="d-block text-capitalize"
                      >
                        { quality } { clientLanguages[languagePair][quality] }
                      </a>
                    ))}
                  </React.Fragment>
                  :
                  <a
                    href={`/clients/${id}/whitelisting/?language_pair=${languagePair.toLowerCase()}`}
                    className="d-block text-danger"
                  >
                    No whitelisted translators for this pair
                  </a>
                }
              </div >
            </div >
          );
        })}
      </div>
    );
  }
}

export default Whitelisted;