import React from "react";
import { connect } from "react-redux";

import BadVerdict from "./BadVerdict";

export class TableList extends React.Component {
  render() {
    const {badVerdicts} = this.props;

    return (
      <table className="table thead-dark table-hover">
        <thead>
          <tr>
            <th>Task</th>
            <th>Translators</th>
            <th style={{ minWidth: "250px", width: "20%" }}>Verdict</th>
            <th>Date</th>
            <th className="text-centered">Translator response</th>
            <th className="text-centered">Handled by Ops</th>
            <th style={{minWidth: "250px", width: "20%"}}>Proposed action</th>
            <th style={{ minWidth: "250px", width: "20%" }}>Action taken</th>
          </tr>
        </thead>

        <tbody>
          {badVerdicts.nodes.map(badVerdict => <BadVerdict key={badVerdict.id} badVerdict={badVerdict} />)}
        </tbody>
      </table>
    );
  }
}

export default connect(
  ({ badVerdicts }) => ({ badVerdicts })
)(TableList);
