import React from "react";
import { useDispatch } from "react-redux";

import { Popup } from "../_common/Popup";
import ManualOperationForm from "./ManualOperationForm";
import { useCreateManualOperationMutation, useGetStaticDataQuery } from "./_api";
import {closePopup} from "./_slices";

export default function CreateManualOperation(){
  const [ createManualOperation, { isLoading: isCreating, isError, error }] = useCreateManualOperationMutation(),
        {data: billingAccount} = useGetStaticDataQuery(),
        dispatch = useDispatch();

  if (!billingAccount) return;

  const onSubmit = manualOperationInput => createManualOperation({
    input: {
      manualOperationInput, 
      billingAccountId: billingAccount.id
    }
  });

  return (
    <Popup>
      <div className="card">
        <div className="card-body">
          <h1>New manual operation</h1>
          {isError && 
            <div className="alert alert-danger">
              <strong>Error:</strong> {error.data?.map( (e,i) => <p key={i}>{e.message}.</p>)}
            </div>
          }
          <ManualOperationForm
            creating={true} 
            onSubmit={onSubmit} 
            buttons={
              <div className="d-flex flex-row-reverse mt-4 mb-3">
                <button className="btn btn-primary" disabled={isCreating}>Create</button>
                <button type="button" className="btn btn-link mr-3" onClick={()=>dispatch(closePopup())}>Cancel</button>
              </div>
            }
          />           
        </div>
      </div>
    </Popup>
  );
}