import { SET_PURCHASE_ORDER, UPDATE_PURCHASE_ORDER } from "./_actions";

import { combineReducers  } from "redux";

export const purchaseOrder = (state = null, action) => {
  if (action.type == SET_PURCHASE_ORDER) return action.purchaseOrder;
  if (action.type == UPDATE_PURCHASE_ORDER) return {...state, ...action.changes};
  return state;
};

export const purchaseOrderSelector = (state) => state.purchaseOrder;

export default combineReducers({
  purchaseOrder,
});