import React from "react";
import { connect } from "react-redux";

import { MODES } from "./_constants";
import { updateTranslatorAccountId, updateTaskId, toggleCreatingReviewTask, addQualityReview } from "./_actions";

export class Creator extends React.Component {
  render() {
    const {
      creatingReviewTask, translatorAccountId, taskId, mode, addQualityReview,
      updateTranslatorAccountId, updateTaskId, toggleCreatingReviewTask, errors
    } = this.props;

    if (!creatingReviewTask) return null;

    if (mode == MODES.tasks) return (
      <div className="mb-4 mt-3">
        <div className="form-inline">
          <label className="mr-3"><strong>Translator Account ID:</strong></label>
          <input
            className="form-control mb-2"
            min={1}
            type="number"
            value={translatorAccountId}
            onChange={e => updateTranslatorAccountId(e.target.value)}
            placeholder="Translator ID"
          />
          <div style={{display: "inline-block"}}>
            <button className="ml-1 btn btn-sm btn-link" onClick={() => toggleCreatingReviewTask()}>Cancel</button>
            <button className="ml-1 btn btn-sm btn-primary" onClick={() => addQualityReview() }>Create review task</button>
          </div>
        </div>
        <small className="text-muted"><em>(The translator needs no whitelisting nor matching skills)</em></small>
        {errors.length > 0 &&
          <ul className="list-unstyled">
            {errors.map((error, i) => <li key={i} className="text-danger">{error}</li>)}
          </ul>
        }
      </div>
    );

    if (mode == MODES.emitted_quality_reviews) return (
      <div className="mb-4 mt-3">
        <div className="form-inline">
          <label className="mr-3"><strong>Task ID:</strong></label>
          <input
            className="form-control mb-2"
            min={1}
            type="number"
            value={taskId}
            onChange={e => updateTaskId(e.target.value)}
            placeholder="Task ID"
          />
          <div style={{ display: "inline-block" }}>
            <button className="ml-1 btn btn-sm btn-link" onClick={() => toggleCreatingReviewTask()}>Cancel</button>
            <button className="ml-1 btn btn-sm btn-primary" onClick={() => addQualityReview()}>Create review task</button>
          </div>
        </div>
        {errors.length > 0 &&
          <ul className="list-unstyled">
            {errors.map((error, i) => <li key={i} className="text-danger">{error}</li>)}
          </ul>
        }
      </div>
    );


    if (mode == MODES.quality_reviews) return (
      <div className="mb-4 mt-3">
        <div className="form-inline">
          <label className="mr-3"><strong>Translator Account ID:</strong></label>
          <input
            className="form-control mb-2"
            min={1}
            type="number"
            value={translatorAccountId}
            onChange={e => updateTranslatorAccountId(e.target.value)}
            placeholder="Translator ID"
          />
          <label className="ml-4 mr-3"><strong>Task ID:</strong></label>
          <input
            className="form-control mb-2"
            min={1}
            type="number"
            value={taskId}
            onChange={e => updateTaskId(e.target.value)}
            placeholder="Task ID"
          />
          <div style={{ display: "inline-block" }}>
            <button className="ml-1 btn btn-sm btn-link" onClick={() => toggleCreatingReviewTask()}>Cancel</button>
            <button className="ml-1 btn btn-sm btn-primary" onClick={() => addQualityReview()}>Create review task</button>
          </div>
        </div>
        {errors.length > 0 &&
          <ul className="list-unstyled">
            {errors.map((error, i) => <li key={i} className="text-danger">{error}</li>)}
          </ul>
        }
      </div>
    );

    return null;
  }
}

export default connect(
  ({ creatingReviewTask, translatorAccountId, taskId, mode, errors }) => ({ creatingReviewTask, translatorAccountId, taskId, mode, errors }),
  { updateTranslatorAccountId, updateTaskId, toggleCreatingReviewTask, addQualityReview }
)(Creator);
