import { takeLatest, all } from "redux-saga/effects";

import { hocGetResults } from "../_common/filtered_lists/_sagas";
import { REQUEST_FILTERS_UPDATE } from "../_common/filtered_lists/_actions";

const getResults = hocGetResults("translators", "translators");

export function* watchRequests() {
  yield takeLatest(REQUEST_FILTERS_UPDATE, getResults);
}

export default function* rootSaga() {
  yield all([
    watchRequests()
  ]);
}