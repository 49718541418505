import { combineReducers } from "redux";

import {
  totalCount,
  totalPages,
  filters,
  results,
  loading,
  staticData
} from "../_common/filtered_lists/_reducers";

export default combineReducers({
  totalCount,
  totalPages,
  filters,
  clients: results,
  loading,
  staticData
});