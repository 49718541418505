import { createFilterSlice } from "../_utils/createFilterSlice";
import { createSlice } from "@reduxjs/toolkit";

export const filtersSlice = createFilterSlice({
  page:             1,
  perPage:          20,
  query:            null,
  sortAttribute:    "id",
  sortOrder:        "desc",
  billingAccountId: null,
  paid:             null,
  paymentMethod:    null,
});

export const { updatePage, updateSize, updateFilters, rewriteFilters } = filtersSlice.actions;

export const clientAccountIdSlice = createSlice({
  name:         "clientAccountId",
  initialState: null,
  reducers:     {
    setClient: (_, {payload}) => payload
  }
});

export const { setClientId } = clientAccountIdSlice.actions;

export default {
  filters:         filtersSlice.reducer,
  clientAccountId: clientAccountIdSlice.reducer,
};
