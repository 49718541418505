import {
  UPDATE_RESULTS,
  UPDATE_FILTERS,
  LOADING,
  NOT_LOADING,
  SELECT_ALL_RESULTS,
  DESELECT_ALL_RESULTS,
  TOGGLE_RESULT_SELECTION,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
} from "./_actions";

export const totalCount = (state = 0, action) => {
  if (action.type == UPDATE_RESULTS) return action.totalCount;
  return state;
};

export const totalPages = (state = 0, action) => {
  if (action.type == UPDATE_RESULTS) return action.totalPages;
  return state;
};

export const filters = (state = {}, action) => {
  if (action.type == UPDATE_FILTERS) return action.filters;
  return state;
};

export const results = (state = [], action) => {
  if (action.type == UPDATE_RESULTS) return action.results;
  return state;
};

export const loading = (state = false, action) => {
  switch (action.type) {
    case LOADING:
      return true;
    case NOT_LOADING:
      return false;
    default:
      return state;
  }
};

export const allResultsSelected = (state = false, action) => {
  switch (action.type) {
    case SELECT_ALL_RESULTS:
      return true;
    case DESELECT_ALL_RESULTS:
    case TOGGLE_RESULT_SELECTION:
      return false;
    default:
      return state;
  }
};

export const selectedResults = (state = [], action) => {
  switch (action.type) {
    case SELECT_ALL_RESULTS:
    case TOGGLE_RESULT_SELECTION:
      return action.selectedResults;
    case DESELECT_ALL_RESULTS:
      return [];
    default:
      return state;
  }
};

// message = {type, message}
export const messages = (state=[], action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return [action.message, ...state];
    case REMOVE_MESSAGE:
      return state.filter( (_, index)=> index != action.index );
    default:
      return state;
  }
};

export const staticData = (state = {}) => state;