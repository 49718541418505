import React from "react";
import { connect } from "react-redux";

import TableSortableHeader from "../_common/TableSortableHeader";
import { requestFiltersUpdate } from "../_common/filtered_lists/_actions";

const headers = [
  { attribute: "id", sortable: true },
  { attribute: "first_name", sortable: true, label: "Name" },
  { attribute: "email", sortable: true},
  { attribute: "organization", sortable: false },
  { attribute: "created_at", sortable: true, label: "Created at" },
];

export class Headers extends React.Component {
  render() {
    const { requestResults, sortAttribute, sortOrder } = this.props;

    return (
      <TableSortableHeader {...{ sortAttribute, sortOrder, headers, requestResults }} />
    );
  }
}

export default connect(
  ({ filters: { sortAttribute, sortOrder } }) => ({ sortAttribute, sortOrder }),
  {
    requestResults: (sortOrder, sortAttribute) => requestFiltersUpdate({ page: 1, sortOrder, sortAttribute })
  }
)(Headers);
