import { createSelector } from "reselect";

const getSelectedResults = ({ selectedResults }) => selectedResults;

export const selectedResultsHashSelector = createSelector(
  [getSelectedResults],
  (selectedResults) => {
    return selectedResults.reduce((acc, selectedResult) => {
      acc[selectedResult] = true;
      return acc;
    }, {});
  }
);