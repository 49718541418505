import React from 'react';
import { openEdit, openRefund } from "./_slices";
import { useDispatch } from "react-redux";

export default function Actions(manualOperation) {
  const dispatch = useDispatch()

  return (
    <div>
      <button className="btn btn-outline-primary btn-sm" onClick={()=>dispatch(openEdit(manualOperation))}>Edit</button>
      { manualOperation.payment?.refundable &&
        <button className="btn btn-outline-danger btn-sm ml-2" onClick={()=>dispatch(openRefund(manualOperation))}>Refund</button> }
    </div>
  )
}
