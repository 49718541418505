import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createFilterSlice } from "../_utils/createFilterSlice";
import { gqlApi } from "./_api";

const succesfulManualOperation = isAnyOf(
  gqlApi.endpoints.createManualOperation.matchFulfilled,
  gqlApi.endpoints.updateManualOperation.matchFulfilled,
  gqlApi.endpoints.refundManualOperation.matchFulfilled,
);

// Slices
// ============================================================
export const filtersSlice = createFilterSlice({
  page:          1,
  perPage:       20,
  query:         null,
  sortAttribute: "id",
  sortOrder:     "desc",
});

export const { updatePage, updateSize, updateFilters, rewriteFilters } = filtersSlice.actions;

export const currentPopupSlice = createSlice({
  name:         "currentPopup",
  initialState: "none",
  reducers:     {
    closePopup: ()=>"none",
    openCreate: ()=>"create",
    openEdit:   ()=>"edit",
    openRefund: ()=>"refund",
  },
  extraReducers: builder => builder.addMatcher(
    succesfulManualOperation, () => "none"
  )
});

export const {closePopup, openCreate, openEdit, openRefund} = currentPopupSlice.actions;


export const currentManualOperationSlice = createSlice({
  name:          "currentManualOperation",
  initialState:  null,
  extraReducers: builder => builder
    .addCase( openEdit, (s, {payload}) => payload )
    .addCase( openRefund, (s, {payload}) => payload )
});

export const { updateCurrentManualOperation} = currentManualOperationSlice.actions;


// Reducers export
// ============================================================
export default {
  currentManualOperation: currentManualOperationSlice.reducer,
  filters:                filtersSlice.reducer,
  currentPopup:           currentPopupSlice.reducer,
};
