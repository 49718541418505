import React from "react";
import { connect } from "react-redux";

import FormPopup from "../_common/FormPopup";
import {
  closeEmailPopup,
  updateEmailSubject,
  updateEmailBody,
  sendEmails,
} from "./_actions";

export class EmailFormPopup extends React.Component {
  emailFromId(id){
    return this.props.translators.find( translator => id == translator.id ).email;
  }

  header(){
    const {
      allResultsSelected,
      selectedResults,
      translatorToEmailTo,
      totalCount
    } = this.props;

    if (translatorToEmailTo !== null || selectedResults.length == 1) {
      return <h3>Send email to <span className="text-muted">{this.emailFromId(translatorToEmailTo || selectedResults[0])}</span></h3>;
    } else if (allResultsSelected) {
      return <h3>Send email to <span className="text-muted">{totalCount}</span> translators</h3>;
    } else {
      return <h3>Send email to <span className="text-muted">{selectedResults.length}</span> translators</h3>;
    }

  }

  render() {
    const {
      emailPopupOpen,
      closeEmailPopup,
      emailSubject,
      emailBody,
      updateEmailSubject,
      updateEmailBody,
      sendEmails,
    } = this.props;

    if (!emailPopupOpen) return null;

    return (
      <FormPopup wrapperCallback={() => closeEmailPopup()}>
        {this.header()}
        <div className="form-group">
          <label htmlFor="">Subject</label>
          <input
            type="text"
            name="subject"
            className="form-control"
            value={emailSubject}
            onChange={e => updateEmailSubject(e.target.value)}
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="">Body</label>
          <textarea
            className="form-control"
            name="body"
            rows="5"
            value={emailBody}
            onChange={e => updateEmailBody(e.target.value)}
          ></textarea>
        </div>
        <button className="btn btn-outline-primary float-left" onClick={() => closeEmailPopup()}>Cancel</button>
        <button className="btn btn-primary float-right" onClick={() => sendEmails() }>Send emails</button>
      </FormPopup>
    );
  }
}

export default connect(
  ({
    emailPopupOpen,
    emailSubject,
    emailBody,
    allResultsSelected,
    selectedResults,
    translatorToEmailTo,
    translators,
    totalCount
  }) => ({
    emailPopupOpen,
    emailSubject,
    emailBody,
    allResultsSelected,
    selectedResults,
    translatorToEmailTo,
    translators,
    totalCount
  }),
  {
    closeEmailPopup,
    updateEmailSubject,
    updateEmailBody,
    sendEmails
  }
)(EmailFormPopup);
