import { gql } from "graphql-request";

export const clientAccountQuery = gql`
  query clientAccount($id: ID!) {
    clientAccount(id: $id) {
      orderMachineAllowed
      orderPosteditingAllowed
      translationQualityViaPostediting
      localizationQualityViaPostediting
      expertQualityViaPostediting
      id
      autoClientReview
      machineTranslationEngine
      posteditingTranslationEngine
      accountManagerEmail
      currencyCode
      name
      apiCallbackUrl
      translationRequestVersion
      admin
      lateConfirmation
      paymentMethod
      testing
      payingCustomer
      allowedToHaveEditors
      billingAccount {
        id
        invoiceDueDays
      }
      machineTranslatorPrompt {
        id
      }
    }
  }
`;

export const clientAccountMutation = gql`
  mutation clientAccountUpdate($input: ClientAccountUpdateInput!) {
    clientAccountUpdate(input: $input) {
      clientAccount{
        orderMachineAllowed
        orderPosteditingAllowed
        translationQualityViaPostediting
        localizationQualityViaPostediting
        expertQualityViaPostediting
        id
      }
      errors
      success
    }
  }
`;


export const clientAccountInviteMutation = gql`
  mutation clientAccountInvite($input: ClientAccountInviteInput!) {
    clientAccountInvite(input: $input) {
      clientInvitation{
        acceptedAt
        message
      }
      errors
      success
    }
  }
`;


export const billingAccountUpdateMutation = gql`
  mutation billingAccountUpdate($input: BillingAccountUpdateInput!) {
    billingAccountUpdate(input: $input) {
      errors
      success
    }
  }
`;

export const machineTranslatorPromptsQuery = gql`
  query promptTemplates {
    machineTranslatorPrompts {
      id
    }
  }
`;
