import React from "react";
import { connect } from "react-redux";

import FormPopup from "../_common/FormPopup";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";
import { closeTasksPopup, updateAllTasks } from "./_actions";
import Calendar from "react-calendar";

function isToday(date) {
  return moment(date).isSame(moment(), "day");
}

function isTomorrow(date) {
  let tomorrow = moment().add(1, "day");

  return moment(date).isSame(tomorrow, "day");
}

function isNextWeek(date) {
  let nextWeek = moment().add(7, "days");

  return moment(date).isSame(nextWeek, "day");
}

function isCustom(date) {
  return !isToday(date) && !isTomorrow(date) && !isNextWeek(date);
}

export class DeadlinePopup extends React.Component {
  constructor(props){
    super(props);
    let deadline = moment().add(1, "day").startOf("hour").toDate();

    this.calendar = React.createRef();

    this.state = {
      deadline,
      hour: deadline.getHours(),
      minutes: deadline.getMinutes(),
      showCalendar: false
    };
  }

  updateDeadline(deadline) {
    this.setState({ deadline });
  }

  sendDeadline(e) {
    const { deadline } = this.state;

    e.preventDefault();
    this.props.updateAllTasks({ deadline });
  }

  removeDeadline(e) {
    e.preventDefault();
    this.props.updateAllTasks({ deadline: null });
  }

  taskTitleFromId(id) {
    let task = this.taskFromId(id);
    return `"${task.title}" (#${id})`;
  }

  taskFromId(id) {
    return this.props.tasks.find(task => task.id == id);
  }

  header() {
    const {
      allResultsSelected,
      selectedResults,
      selectedTask,
      totalCount,
    } = this.props;

    if (selectedTask !== null || selectedResults.length == 1) {
      return <h3>Change deadline in <span className="text-muted">{this.taskTitleFromId(selectedTask || selectedResults[0])}</span></h3>;
    } else if (allResultsSelected) {
      return <h3>Change deadline in <span className="text-muted">{totalCount}</span> tasks</h3>;
    } else {
      return <h3>Change deadline in <span className="text-muted">{selectedResults.length}</span> tasks</h3>;
    }
  }

  changeDeadlineToDaysFromToday(days){
    let momentDeadline = moment(this.state.deadline),
        newDeadline = moment().add(days, "days").startOf("day");

    newDeadline.minutes(momentDeadline.minutes())
               .hours(momentDeadline.hours());

    this.setState({deadline: newDeadline.toDate() });
  }

  changeDateAndCloseCalendar(date) {
    let momentDeadline = moment(this.state.deadline),
        newDeadline = moment(date);

    newDeadline.minutes(momentDeadline.minutes())
              .hours(momentDeadline.hours());

    this.setState({ deadline: newDeadline.toDate(), showCalendar: false });
  }

  changeHour(hour){
    let momentDeadline = moment(this.state.deadline);

    momentDeadline.hour(hour);
    this.setState({ deadline: momentDeadline.toDate(), hour });
  }

  changeMinutes(minutes){
    let momentDeadline = moment(this.state.deadline);

    momentDeadline.minutes(minutes);
    this.setState({deadline: momentDeadline.toDate(), minutes});
  }

  selectTomorrow(e) {
    e.preventDefault();
    this.changeDeadlineToDaysFromToday(1);
  }

  selectToday(e) {
    e.preventDefault();
    this.changeDeadlineToDaysFromToday(0);
  }

  selectNextWeek(e){
    e.preventDefault();
    this.changeDeadlineToDaysFromToday(7);
  }

  toggleCalendar(e) {
    if (e) e.preventDefault();
    this.setState({ showCalendar: !this.state.showCalendar });
  }

  render() {
    const { closeTasksPopup } = this.props;

    var {
      deadline,
      hour,
      minutes,
      showCalendar,
    } = this.state;

    return (
      <FormPopup className="popup--with-visible-overflow" wrapperCallback={() => closeTasksPopup()}>
        {this.header()}
        <p className="mt-4">
          <strong>Deadline: </strong>
          <PrettyLocalDateTime date={deadline} className="text-muted" />
        </p>
        <ul className="nav nav-pills nav-fill mt-3 mb-5">
          <li className="nav-item">
            <a
              href="#"
              className={`nav-link ${isToday(deadline) ? "active" : ""}`}
              onClick={e => this.selectToday(e)}
            >
              Today
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#"
              className={`nav-link ${isTomorrow(deadline) ? "active" : ""}`}
              onClick={e => this.selectTomorrow(e)}
            >
              Tomorrow
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#"
              className={`nav-link ${isNextWeek(deadline) ? "active" : ""}`}
              onClick={e => this.selectNextWeek(e)}
            >
              Next week
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#"
              className={`nav-link ${isCustom(deadline) ? "active" : ""}`}
              onClick={() => this.toggleCalendar()}
            >
              <span className="ticon-calendar"></span> Custom
            </a>
            {showCalendar ?
              <Calendar
                className="calendar"
                activeStartDate={deadline}
                value={deadline}
                onClickDay={(date) => this.changeDateAndCloseCalendar(date)}
                minDate={new Date()}
              /> : null
            }
          </li>
          <li className="nav-item">
            <div className="form-inline justify-content-center">
              <select name="" id="" className="form-control" onChange={(e) => this.changeHour(e.target.value)} value={hour}>
                {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map( hour => (
                  <option key={`hour-option-${hour}`} value={hour}>{hour}</option>
                ))}
              </select>
              <span className="ml-2 mr-2">:</span>
              <select name="" id="" className="form-control" onChange={(e) => this.changeMinutes(e.target.value)} value={minutes}>
                <option value={0}>00</option>
                <option value={15}>15</option>
                <option value={30}>30</option>
                <option value={45}>45</option>
              </select>
            </div>
          </li>
        </ul>

        <div className="flex">
          <button className="order-2 btn btn-primary float-right" onClick={e => this.sendDeadline(e)}>Change deadline</button>
          <button className="order-3 btn btn-danger float-right mr-3" onClick={e => this.removeDeadline(e)}>Remove deadline</button>
          <button className="order-1 btn btn-outline-primary float-left" onClick={(e) => { e.preventDefault(); closeTasksPopup(); }}>Cancel</button>
        </div>
      </FormPopup>
    );
  }
}

export default connect(
  ({
    tasks,
    allResultsSelected,
    selectedResults,
    selectedTask,
    totalCount
  }) => ({
    tasks,
    allResultsSelected,
    selectedResults,
    selectedTask,
    totalCount
  }),
  { closeTasksPopup, updateAllTasks }
)(DeadlinePopup);
