import React, { useCallback } from "react";

const MIN = 0;
const MAX = 100;

export default function SalesMarginField(props) {
  const handleChange = (e) => {
    let { name, value } = e.target;

    if (value < MIN) value = MIN;
    if (value > MAX) value = MAX;

    props.onChange({ [name]: value });
  };

  return (
    <input
      className="text-center form-control"
      min={MIN}
      max={MAX}
      step="0.01"
      type="number"
      name={props.name}
      value={props.value}
      onChange={handleChange}
    />
  );
}
