import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useGetManualOperationsQuery, useGetStaticDataQuery } from "./_api";
import { updateFilters, updatePage, openCreate } from "./_slices";
import {
  DataTable,
  PrettyCurrency,
  PrettyLocalDateTime,
} from "../_common/all";
import Actions from "./Actions";
import Popup from "./Popup";

export default function ManualOperations() {
  const {data: billingAccount} = useGetStaticDataQuery(),
        filters = useSelector(s=>s.filters),
        { sortAttribute, sortOrder } = filters,
        dispatch = useDispatch();

  const {data: manualOperations} = useGetManualOperationsQuery({
    ...filters,
    billingAccountId: billingAccount?.id
  }, {
    skip: !billingAccount
  });

  const keys = [
    "id",
    "title",
    "amount",
    "description",
    "creator",
    "createdAt",
    "rateUsed",
    "template",
    "type",
    "updatedAt",
    "actions"
  ];

  const  sortableKeys = [ "id","amount", "created_at", "currency", "description", "rate_used", "template", "title", "type", "updated_at", ];

  const creator = ({creator}) => {
    if (!creator) return <span className="text-muted">N/A</span>;

    return <a href={`/users/${creator.id}`}>{creator.name}</a>;
  };

  const components = {
    creator,
    amount:    ({ currency, amount, type }) => (
      <span className={type === "debit" ? "text-danger" : "text-success"}>
        <PrettyCurrency currency={currency} amount={amount} />
      </span>
    ),
    id:        ({id})          => <a href={`/billing-accounts/${billingAccount?.id}/manual-operations/${id}`}>#{id}</a>,
    createdAt: ({ createdAt }) => <PrettyLocalDateTime date={createdAt} hideTime={true} />,
    updatedAt: ({ updatedAt }) => <PrettyLocalDateTime date={updatedAt} hideTime={true} />,
    actions:   Actions,
  };

  if (!billingAccount) return null;


  let { id: billingAccountId, clientAccount, translatorAccount } = billingAccount,
      headingName,
      id;

  if (clientAccount) {
    const {name, id: accountId } = clientAccount;

    headingName =  name || `#${id}`;
    id = accountId;

  } else {
    const { firstName, lastName, email } = translatorAccount.user,
            accountId = translatorAccount.id,
            fullName = firstName && lastName ? `${firstName} ${lastName}` : null;

    headingName =  fullName || email || `#${id}`;
    id = accountId;
  }

  return (
    <>
      <Popup />
      <div className="d-flex mt-4 mb-4 justify-content-between align-items-center">
        <h1>
          {billingAccount.clientAccount ?
            <a href={`/clients/${id}`}>{headingName}</a>
            :
            <a href={`/translators/${id}`}>{headingName}</a>
          }
          <span className="ml-1">manual operations</span>
          {manualOperations && <span className="ml-1 text-muted">({manualOperations?.totalItems})</span>}
        </h1>

        <div className="d-flex flex-column">
          <button className="btn btn-primary btn-lg" onClick={()=>dispatch(openCreate())}>
            Create manual operation
          </button>
        </div>
      </div>

      <div className="card">
        <div className="card-body">

          <DataTable
            {...{
              filterUrlSync: true,
              getQuery:      params=>useGetManualOperationsQuery({...params, billingAccountId}),
              name:          "invoices",
              components,
              keys,
              sortableKeys,
              sortAttribute,
              sortOrder,
              updateFilters,
              updatePage,
            }}
          />
        </div>
      </div>
    </>
  );
}
