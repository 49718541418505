import { combineReducers } from "redux";

import { clientAccountQuery, clientAccountMutation, billingAccountUpdateMutation, machineTranslatorPromptsQuery } from "./_gql";
import gqlClient from "../_utils/graphQlRequests";
import { post } from "../_utils/humpyAjax";
import { convertToBackendDialects } from "./_utils";

// ACTIONS
// ================================================================================================
export const SET_CLIENT_ACCOUNT = "SET_CLIENT_ACCOUNT";
export const SET_MACHINE_TRANSLATOR_PROMPTS = "SET_MACHINE_TRANSLATOR_PROMPTS";
export const UPDATE_CLIENT_ACCOUNT = "UPDATE_CLIENT_ACCOUNT";
export const UPDATE_CLIENT_DIALECTS = "UPDATE_CLIENT_DIALECTS";

export const setClientAccount = (clientAccount) => ({
  type: SET_CLIENT_ACCOUNT, clientAccount
});

export const setMachineTranslatorPrompts = (machineTranslatorPrompts) => ({
  type: SET_MACHINE_TRANSLATOR_PROMPTS, machineTranslatorPrompts
})

export const updateClientAccount = (changes) => ({
  type: UPDATE_CLIENT_ACCOUNT, changes
});

export const getClientAccount = (id) => (dispatch) => {
  gqlClient.request(clientAccountQuery, {id})
    .then(({ clientAccount }) => dispatch( setClientAccount(clientAccount) ) )
    .catch(error => {
      alert("Something went wrong and we could not get the client account");
      console.error(error);
    });
};

export const getMachineTranslatorPrompts = () => (dispatch) => {
  gqlClient.request(machineTranslatorPromptsQuery)
    .then(({ machineTranslatorPrompts }) => dispatch(setMachineTranslatorPrompts(machineTranslatorPrompts)))
    .catch(error => {
      alert("Something went wrong and we could not get the client account");
      console.error(error);
    });
}

const postCurrencyUpdate = (id, currency) => {
  post(`/clients/${id}/update_currency`, { currency })
    .catch(errors => {
      alert("There was an error updating the currency");
      console.error(errors);
    });
};


export const changeAttribute = (attribute, newValue) => (dispatch, getState) => {
  const {clientAccount} = getState(),
        changes = { [attribute]: newValue },
        mutationInput = {
          input: {
            clientAccountId: clientAccount.id,
            clientAccountInput: { [attribute]: newValue }
          }
        };

  //Assume success
  dispatch(updateClientAccount(changes));

  if (attribute == "currencyCode") return postCurrencyUpdate(clientAccount.id, newValue);

  gqlClient.request(clientAccountMutation, mutationInput)
    .catch( error => {
      //Cancel changes when not succesful
      // dispatch(updateClientAccount({ [qualityName]: clientAccount[qualityName] }));
      alert("Something went wrong and we could not update the client account");
      console.error(error);
    });
};

export const changeDueDate = (invoiceDueDays) => (dispatch, getState) => {
  const {clientAccount} = getState(),
        {billingAccount: {id: billingAccountId}} = clientAccount,
        changes = { billingAccount: {
          ...clientAccount.billingAccount,
          invoiceDueDays,
        }};

  dispatch(updateClientAccount(changes));

  gqlClient.request(billingAccountUpdateMutation,{
    input: {
      billingAccountId,
      billingAccountInput: { invoiceDueDays }
    }
  }).catch( e => {
    alert("Something went wrong and we could not update the due date");
    console.error(e);
  });
}

export const updateClientDialects = clientDialects => ({
  type: UPDATE_CLIENT_DIALECTS, clientDialects
});

export const postDialectsUpdate = (clientDialects) => (dispatch, getState) => {
  const { clientAccount: { id }, clientDialects: oldClientDialects} = getState();

  dispatch(updateClientDialects(clientDialects));

  const dialects = convertToBackendDialects(clientDialects);

  post(`/clients/${id}/update_dialects`, { dialects })
    .catch(errors => {
      dispatch(updateClientDialects(oldClientDialects));
      alert("Something went wrong");
      console.error(errors);
    });

};

// REDUCERS
// ================================================================================================
export const clientAccount = (state = {}, action) => {
  if (action.type == SET_CLIENT_ACCOUNT) return action.clientAccount;
  if (action.type == UPDATE_CLIENT_ACCOUNT) return {...state, ...action.changes};
  return state;
};

export const machineTranslatorPrompts = (state = [], action) => {
  if (action.type == SET_MACHINE_TRANSLATOR_PROMPTS) return action.machineTranslatorPrompts;
  return state;
}

export const clientDialects = (state = {}, {type, clientDialects}) => {
  if (type === UPDATE_CLIENT_DIALECTS) return clientDialects;
  return state;
};

export const staticData = (state={})=>state;

export default combineReducers({
  staticData,
  clientAccount,
  clientDialects,
  machineTranslatorPrompts,
});
