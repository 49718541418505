import { combineReducers } from "redux";

import {
  totalCount,
  totalPages,
  filters,
  results,
  loading,
  staticData,
  allResultsSelected,
  selectedResults,
  messages
} from "../_common/filtered_lists/_reducers";

import {
  UPDATE_TRANSLATOR_TO_EMAIL_TO,
  OPEN_EMAIL_POPUP,
  CLOSE_EMAIL_POPUP,
  UPDATE_EMAIL_SUBJECT,
  UPDATE_EMAIL_BODY,
  REQUEST_EMAIL_SUBMISSION,
  EMAIL_REQUEST_SUCCESS
} from "./_actions";


export const emailPopupOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_EMAIL_POPUP:
      return true;
    case CLOSE_EMAIL_POPUP:
      return false;
    default:
      return state;
  }
};

export const translatorToEmailTo = (state = null, action) => {
  switch (action.type) {
    case UPDATE_TRANSLATOR_TO_EMAIL_TO:
      return action.translatorToEmailTo;
    case CLOSE_EMAIL_POPUP:
      return null;
    default:
      return state;
  }
};

export const emailSubject = (state = "", action) => {
  switch (action.type) {
    case UPDATE_EMAIL_SUBJECT:
      return action.emailSubject;
    default:
      return state;
  }
};

export const emailBody = (state = "", action) => {
  switch (action.type) {
    case UPDATE_EMAIL_BODY:
      return action.emailBody;
    default:
      return state;
  }
};

export const requestingEmailSubmission = (state = false, action) => {
  switch (action.type) {
    case REQUEST_EMAIL_SUBMISSION:
      return true;
    case EMAIL_REQUEST_SUCCESS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  totalCount,
  totalPages,
  filters,
  translators: results,
  loading,
  staticData,
  allResultsSelected,
  selectedResults,
  emailPopupOpen,
  translatorToEmailTo,
  emailSubject,
  emailBody,
  requestingEmailSubmission,
  messages,
});