import { createSelector } from "reselect";

import { isPotentiallyMergeable } from "./_utils";

const getData = ({ keysData, sourceTexts }) => ({ keysData, sourceTexts });

export const potentialMergeablesList = createSelector(
  [getData],
  ({keysData, sourceTexts}) => {
    let mergeablesList = [];

    keysData.forEach(keyData => {
      keyData.sourceChunkIds.forEach( (chunkId, index) => {
        if (sourceTexts[chunkId] &&
          isPotentiallyMergeable(sourceTexts[chunkId])
        ) mergeablesList.push({name: keyData.name, chunkId, index});
      });
    });

    return mergeablesList;
  }
);
