import { post } from "../_utils/humpyAjax";
import { addMessage, refreshResults } from "../_common/filtered_lists/_actions";

export const OPEN_PROJECT_NAME_POPUP = "OPEN_PROJECT_NAME_POPUP";
export const OPEN_DEADLINE_POPUP = "OPEN_DEADLINE_POPUP";
export const CLOSE_TASKS_POPUP = "CLOSE_TASKS_POPUP";
export const UPDATE_SELECTED_TASK = "UPDATE_SELECTED_TASK";


export const openProjectNamePopup = () => ({ type: OPEN_PROJECT_NAME_POPUP });

export const openDeadlinePopup = () => ({ type: OPEN_DEADLINE_POPUP });

export const closeTasksPopup = () => ({type: CLOSE_TASKS_POPUP });

export const updateSelectedTask = (selectedTask) => ({type: UPDATE_SELECTED_TASK, selectedTask});

export const updateAllTasks = (attributesToUpdate) => (dispatch, getState) => {
  const { selectedTask, selectedResults, allResultsSelected, filters } = getState(),
        ids = getIds(selectedTask, allResultsSelected, selectedResults);

  updateTasks(filters, ids, attributesToUpdate, allResultsSelected, dispatch);
};

function updateTasks(filters, ids, attrToUpdate, allResultsSelected, dispatch){
  post("/tasks/update_all", { ...filters, ids, ...attrToUpdate })
    .then(({ tasksCount }) => {
      let message = allResultsSelected ? `${tasksCount} tasks updated` : `Tasks with ids: ${ids.join(", ")} updated`;
      dispatch(addMessage({ message, type: "primary" }));
      dispatch(closeTasksPopup());
      dispatch(refreshResults());
    }).catch(({ error, response, json }) => {
      if (json) {
        json.errors.forEach(error => dispatch(addMessage({ message: error, type: "danger" })));
      } else if (!response.ok) {
        dispatch(addMessage({ message: response.statusText, type: "danger" }));
      } else {
        dispatch(addMessage({ message: error.message, type: "danger" }));
      }
      dispatch(closeTasksPopup());
    });
}

function getIds(selectedTask, allResultsSelected, selectedResults) {
  if (selectedTask != null) {
    return [selectedTask];
  } else if (allResultsSelected) {
    return "all";
  } else {
    return selectedResults;
  }
}

