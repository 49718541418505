import React from "react";

import BasicPopover from "./BasicPopover";

export class HoverPopover extends React.Component {
  state = {
    hovering: false,
  }

  render() {
    const { children, msg, position } = this.props;

    const { hovering } = this.state;

    return (
      <BasicPopover isOpen={hovering} msg={msg} position={position}>
        <span
          onMouseEnter={() => this.setState({ hovering: true })}
          onMouseLeave={() => this.setState({ hovering: false })}
        >
          {children}
        </span>
      </BasicPopover>
    );
  }
}

export default HoverPopover;
