export const TIME_RANGES = [
  "week",
  "month",
  "year"
];

export const QUALITY_DIMENSIONS_COLORS = {
  proofreadingEdits: "#2E99CC",
  spellingAutomated: "#642ECC",
  spellingHuman: "#E74C3C",
  punctuation: "#B22ECC",
  grammar: "#f39c12",
  terminology: "#4A90E2",
  fluency: "#7DE0E3",
  accuracy: "#27AE60",
  wrong: "#2ecc71",
};

