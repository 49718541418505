import React from "react";

export class Headers extends React.Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Translator</th>
          <th style={{width:"30%"}}>CV</th>
          <th>Skills</th>
          <th>Tasks</th>
          <th style={{width:"230px"}}>Whitelisted</th>
        </tr>
      </thead>
    );
  }
}

export default Headers;
