import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducers from "./_reducers";
import DeadlineHandler from "./DeadlineHandler";

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  reducers,
  DEADLINE_HANDLING_INITIAL_STATE, //defined in _deadline_handling.erb
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <DeadlineHandler />
  </Provider>,
  document.getElementById("deadline-handler"),
);
