import React from "react";
import { connect } from "react-redux";

import Sidebar from "./Sidebar";
import Headers from "./Headers";
import ClientsList from "./ClientsList";
import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import { changePage, changePageAndSize } from "../_common/filtered_lists/_actions";
import Loader from "../_common/Loader";
import ClientCreationPopup from "./ClientCreationPopup";

export class Clients extends React.Component {
  render() {
    const { totalCount, totalPages, page, size, changePage, changePageAndSize, loading } = this.props;

    return (
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col d-flex justify-content-between">
            <h1>
              {"Clients "}
              <span className="text-muted">({totalCount.toLocaleString()})</span>
            </h1>
            <ClientCreationPopup />
          </div>
        </div>

        <div className="row mt-4">
          <Sidebar className="col-3" />
          <div className="col-9">
            <div className="card">
              <div className="card-body">
                <TopPagination {...{ changePageAndSize, totalPages, page, size }} />
                <div className="loader_wrapper">
                  {loading ? <Loader/> : null}
                  <table className="table">
                    <Headers />
                    <ClientsList />
                  </table>
                </div>
                <Pagination className="justify-content-end" {...{ changePage, totalPages, page }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ totalCount, totalPages, filters: { page, size }, loading }) => ({
    totalCount,
    totalPages,
    page,
    size,
    loading
  }),
  { changePage, changePageAndSize }
)(Clients);
