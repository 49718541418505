// =============================================================================================
// =============================================================================================
// Usage:
//
//    Wrap the div over which the loader must appear with "loader_wrapper"
//
// Example:
//
// <div className="loader_wrapper">
//   {loading ? <Loader /> : null}
//   <ListOfSomething />
//   <Pagination />
// </div>
// =============================================================================================
// =============================================================================================

import React from "react";

export class Loader extends React.Component {
  render() {
    return (
      <div className="loader_whitespace">
        <div className="lds_roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    );
  }
}

export default Loader;
