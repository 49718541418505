import React from "react";
import { connect } from "react-redux";

import FormPopup from "../_common/FormPopup";
import { clientAccountCreateMutation } from "./_gql";
import gqlClient from "../_utils/graphQlRequests";

const textInputs = [
  { name: "Company name", input: "clientAccountInput", attribute: "name" },
  { name: "Email", input: "userInput", attribute: "email" },
  { name: "First name", input: "userInput", attribute: "firstName" },
  { name: "Last name", input: "userInput", attribute: "lastName" },
];

export class ClientCreationPopup extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showPopup: false,
      clientAccountInput: {
        name: "",
        paymentMethod: "credit_card",
      },
      userInput: {
        email: "",
        firstName: "",
        lastName: "",
      },
      errors: [],
      sending: false,
    };
  }

  setTextInputValue(input, attribute, value) {
    this.setState({ [input]: { ...this.state[input], [attribute]: value } });
  }

  setPaymentMethod(paymentMethod){
    this.setState({ clientAccountInput: {
      ...this.state.clientAccountInput,
      paymentMethod
    } });
  }

  onSubmit(e){
    const { clientAccountInput, userInput} = this.state,
          input = { clientAccountInput, userInput };

    e.preventDefault();

    this.setState({ sending: true });

    gqlClient.request(clientAccountCreateMutation, {input})
      .then( ({clientAccountCreate:{clientAccount, errors}}) =>{
        if (errors?.length) return this.setState({ errors });
        document.location = `/clients/${clientAccount.id}`;
      })
      .catch(errors => {
        console.error(errors)
        if (errors?.response?.status === 500) {
          return alert("Unexpected error creating the client");
        }
      })
      .finally(() => this.setState({ sending: false }));
  }

  render() {
    const {
      showPopup,
      clientAccountInput,
      errors,
      sending,
    } = this.state;

    if (!showPopup) return(
      <button
        className="btn btn-primary btn-lg float-right"
        onClick={()=>this.setState({showPopup: true})}
      >
        Create client account
      </button>
    );

    return (
      <FormPopup wrapperCallback={()=>this.setState({showPopup: false})}>
        <form className="container-fluid" onSubmit={(e)=>this.onSubmit(e)}>
          <h2 className="mb-4">Create client account</h2>

          {textInputs.map(({ name, input, attribute }) => (
            <div className="form-group row" key={`${input}-${attribute}`}>
              <label htmlFor={`${input}-${attribute}`} className="col-4 font-weight-bold">{name}</label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id={`${input}-${attribute}`}
                  onChange={e=>this.setTextInputValue(input, attribute, e.target.value)}
                  required
                />
              </div>
            </div>
          ))}

          <div className="form-group row">
            <label htmlFor="paymentMethod" className="col-4 font-weight-bold">Payment type</label>
            <div className="col-8">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="paymentMethod"
                  id="paymentMethod1"
                  value={true}
                  checked={clientAccountInput.paymentMethod === "bank_transfer"}
                  onChange={() =>this.setPaymentMethod("bank_transfer")}
                />
              <label htmlFor="paymentMethod1" className="form-check-label">Transfer</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="paymentMethod"
                  id="paymentMethod2"
                  value={false}
                  checked={clientAccountInput.paymentMethod === "credit_card"}
                  onChange={() =>this.setPaymentMethod("credit_card")}
                />
                <label htmlFor="paymentMethod2" className="form-check-label">Credit card</label>
              </div>
            </div>
          </div>

          {errors.length > 0 &&
            <div className="alert alert-danger">
              <ul className="m-0">
                {errors.map(error => <li key={error}>{error}</li>)}
              </ul>
            </div>
          }

          <div className="float-right mb-3 mt-3">
            <button
              className="btn btn-link mr-3"
              onClick={() => this.setState({ showPopup: false })}
              type="button"
              disabled={sending}
            >
              Cancel
            </button>
            <button className="btn btn-primary" tyep="submit" disabled={sending}>Create</button>
          </div>
        </form>
      </FormPopup>
    );
  }
}

export default connect()(ClientCreationPopup);
