// Props summary
// Select input with optional label
// name: input name and input id
// onChange: callback to call on change
// value: current selected value
// className: obvious
// multiple: enable multiple selection
// includeBlank (optional): adds an empty option with an empty string value
// blankOptionLabel (optional): copy to be shown inside the blank option, it will be blank otherwise
// options: array of options with 2 possible formats:
//    [optionValue]: values and labels are the same
//    [[optionLabel, optionValue]]: what is shown (label) is different to waht is handled/sent

import PropTypes from "prop-types";
import React from "react";

export class SimpleSelect extends React.Component {
  render() {
    const { options, name, onChange, value, className, multiple, includeBlank, blankOptionLabel } = this.props;

    return (
      <select
        name={name}
        id={name}
        className={className || "form-control"}
        value={value}
        onChange={onChange}
        multiple={false || multiple}
      >
        {includeBlank ? <option value="">{blankOptionLabel || ""}</option> : null}
        {options.map(option => {
          let optionLabel, optionValue;

          if (Array.isArray(option)) {
            [optionLabel, optionValue] = option;
          } else {
            optionLabel = optionValue = option;
          }

          return <option value={optionValue} key={`${name}-option-${optionValue}`}>{optionLabel}</option>;
        })}
      </select>
    );
  }
}

export default SimpleSelect;

SimpleSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};