import React, { useState } from "react";

import { Popup } from "../all";
import ConfigureSorting from "./ConfigureSorting";
import ConfigureVisible from "./ConfigureVisible";


export default function ConfigureAttributes({
  filters,
  keys,
  labels,
  setShowConfigurator,
  setVisibleAttributes,
  sortableKeys,
  sortableKeysLabels,
  updateFilters,
  visibleAttributes,
}) {
  const [ renderKeys, setRenderKeys ] = useState(visibleAttributes),
        [ sorting, setSorting ] = useState(filters);

  const send = (e) => {
    e.preventDefault();
    close();
    setVisibleAttributes(renderKeys);
    if (sortableKeys) updateFilters(sorting);
  };

  const close = (e) => {
    if (e) e.preventDefault();
    setShowConfigurator();
  };

  return (
    <Popup wrapperCallback={close}>
      <div className="card">
        <div className="card-body">
          <form onSubmit={send}>
            <div>
              <ConfigureSorting {...{ sorting, setSorting, sortableKeys, sortableKeysLabels }} />
              <ConfigureVisible {...{ keys, renderKeys, setRenderKeys, labels }} />

              <div className="d-flex justify-content-end">
                <button className="btn btn-link mr-3" type="button" onClick={close}>Cancel</button>
                <button className="btn btn-primary">Apply</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Popup>
  );
}