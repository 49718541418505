import React from "react";
import { connect } from "react-redux";

import { changePage, changePageAndSize, removeMessage } from "../_common/filtered_lists/_actions";
import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import FloatingSelectorPopupOpener from "../_common/FloatingSelectorPopupOpener";
import Loader from "../_common/Loader";
import Alerts from "../_common/Alerts";
import { openEmailPopup } from "./_actions";
import EmailFormPopup from "./EmailFormPopup";
import Headers from "./Headers";
import TranslatorsList from "./TranslatorsList";

export class Results extends React.Component {
  render() {
    const {
      totalPages,
      page,
      size,
      changePage,
      changePageAndSize,
      loading,
      className,
      count,
      openEmailPopup,
      messages,
      removeMessage
    } = this.props;

    return (
      <div className={className}>
        <Alerts messages={messages} removeCallback={removeMessage} />
        <div className="card">
          <div className="card-body">
            <TopPagination {...{ changePageAndSize, totalPages, page, size }} />
            <div className="loader_wrapper">
              {loading ? <Loader /> : null}
              <Headers />
              <TranslatorsList />
            </div>
            <Pagination className="justify-content-end" {...{ changePage, totalPages, page }} />
            <FloatingSelectorPopupOpener
              title="Selected translators"
              count={count}
              buttonCopy="Compose email"
              callback={() => openEmailPopup()}
            />
            <EmailFormPopup />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ totalPages, filters: { page, size }, loading, allResultsSelected, totalCount, selectedResults, messages }) => ({
    totalPages,
    page,
    size,
    loading,
    count: allResultsSelected ? totalCount : selectedResults.length,
    messages
  }),
  { changePage, changePageAndSize, openEmailPopup, removeMessage }
)(Results);
