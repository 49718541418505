import React from "react";

import { TIME_RANGES } from "./_constants";

export class TimeRangeSelector extends React.Component {
  handleCallback(e, timeRange) {
    e.preventDefault();
    this.props.callback(timeRange);
  }

  render() {
    const { callback, currentTimeRange } = this.props;

    return (
      <React.Fragment>
        <div className="nav-item  ml-5">
          <small className="">Date ranges:</small>
        </div>
        {TIME_RANGES.map(timeRange => (
          <li className="nav-item ml-2" key={`verdicts-${timeRange}`}>
            <a
              href="#"
              className={`badge ${timeRange == currentTimeRange ? "badge-primary" : "badge-light"}`}
              onClick={e => this.handleCallback(e, timeRange)}
            >
              {timeRange}
            </a>
          </li>
        ))}
      </React.Fragment>
    );
  }
}

export default TimeRangeSelector;