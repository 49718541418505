import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { camelizeKeys } from "humps";

import reducers from "./_reducers";
import rootSaga from "./_sagas";
import WhitelistTranslators from "./WhitelistTranslators";


let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let sagaMiddleware = createSagaMiddleware(),
    initialData = camelizeKeys(WHITELIST_TRANSLATORS_DATA);

let store = createStore(
  reducers,
  initialData,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

sagaMiddleware.run(rootSaga, initialData.staticData.whitelistPath);

ReactDOM.render(
  <Provider store={store}>
    <WhitelistTranslators />
  </Provider>,
  document.getElementById("whitelisting"),
);
