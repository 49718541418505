document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("bad-verdicts")) import("./src/bad_verdicts/App");
  if (document.getElementById("client-account-editable-fields")) import("./src/client_account/App");
  if (document.getElementById("client-language-language-pair-pricings")) import("./src/client_language_pair_pricings/App");
  if (document.getElementById("clients")) import("./src/clients/App");
  if (document.getElementById("deadline-handler")) import("./src/deadline_handler/App");
  if (document.getElementById("invoices")) import("./src/invoices/App");
  if (document.getElementById("language-pairs")) import("./src/language_pairs/App");
  if (document.getElementById("manual-operations")) import("./src/manual_operations/App");
  if (document.getElementById("merge-ui")) import("./src/merge_ui/App");
  if (document.getElementById("translation-requests")) import("./src/translation_requests/App");
  if (document.getElementById("population")) import("./src/population/App");
  if (document.getElementById("purchase-order")) import("./src/purchase_order/App");
  if (document.getElementById("quality-reviews")) import("./src/quality_reviews/App");
  if (document.getElementById("task-translations")) import("./src/task_translations/App");
  if (document.getElementById("tasks")) import("./src/tasks/App");
  if (document.getElementById("tr-editable-fields")) import("./src/tr_editable_fields/App");
  if (document.getElementById("training-text-form")) import("./src/training_text_form/App");
  if (document.getElementById("transactions")) import("./src/transactions/App");
  if (document.getElementById("translations-live-display-area")) import("./src/translation-live");
  if (document.getElementById("translations-live-map")) import("./src/translations-live-map");
  if (document.getElementById("translator-metrics")) import("./src/translator_metrics/App");
  if (document.getElementById("translator-whitelisted-skills")) import("./src/translator_whitelisted_skills/App");
  if (document.getElementById("translators")) import("./src/translators/App");
  if (document.getElementById("user")) import("./src/user/App");
  if (document.getElementById("users")) import("./src/users/App");
  if (document.getElementById("whitelisting")) import("./src/whitelist_translators/App");
  if (document.getElementById("withdrawals")) import("./src/withdrawals/App");
  if (document.getElementById("chatgpt_history")) import("./src/chatgpt_history/App");
  if (document.getElementById("ai_templates")) import("./src/ai_templates/App");
  if (document.getElementById("ai_template_form")) import("./src/ai_template_form/App");
});
