import React from 'react'
import { useDispatch } from "react-redux";

import { PrettyCurrency } from "../_common/all";
import { Popup } from "../_common/Popup";
import { closePopup } from './_slices';
import { useRefundManualOperationMutation } from "./_api";

export default function RefundManualOperation({manualOperation}) {
  const [refundManualOperation, { isLoading, isError, error }] = useRefundManualOperationMutation()
  const dispatch = useDispatch()

  const onSubmit = () => refundManualOperation({
    input: {
      manualOperationId: manualOperation.id
    }
  })

  return (
    <Popup>
      <div className="card">
        <div className="card-body">
          <h1>Refund Manual Operation</h1>
          {isError &&
            <div className="alert alert-danger">
              Error: {error.data?.map( (e,i) => <p key={i}>{e.message}.</p>)}
            </div>
          }
          <form>
            Are you sure you wish to refund <PrettyCurrency currency={manualOperation.currency} amount={manualOperation.amount} /> to <strong>{manualOperation.billingAccount?.clientAccount?.name}</strong>?
            <div className="d-flex flex-row-reverse mt-4 mb-3">
              <button type="submit" className="btn btn-danger" disabled={isLoading} onClick={()=>onSubmit()}>Refund</button>
              <button type="button" className="btn btn-link mr-3" onClick={()=>dispatch(closePopup())}>Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </Popup>
  )
}
