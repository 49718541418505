import React from "react";
import { connect } from "react-redux";

import { getInitialData } from "./_actions";
import { getGroupedWhitelistedSkills, getTranslatorSkillsLpStrings } from "./_selectors";
import LanguagePair from "./LanguagePair";

export class TranslatorWhitelistedSkills extends React.Component {
  componentDidMount(){
    this.props.getInitialData();
  }

  render() {
    const { groupedWhitelistedSkills, translatorSkillsLpStrings } = this.props;

    return (
      <div className="mb-4">
        <h3 className="mb-3">Whitelisted for</h3>
        {translatorSkillsLpStrings.map(languagePairString => {
          const clientsHash = groupedWhitelistedSkills[languagePairString] || {};

          return (
            <LanguagePair key={languagePairString} clientsHash={clientsHash} languagePairString={languagePairString} />
          );
        })}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    groupedWhitelistedSkills: getGroupedWhitelistedSkills(state),
    translatorSkillsLpStrings: getTranslatorSkillsLpStrings(state),
  }),
  { getInitialData }
)(TranslatorWhitelistedSkills);
