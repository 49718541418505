import React from "react";
import { useDispatch } from "react-redux";

import { Popup } from "../_common/Popup";
import ManualOperationForm from "./ManualOperationForm";
import { useUpdateManualOperationMutation } from "./_api";
import { closePopup } from "./_slices";

export default function UpdateManualOperation({manualOperation}){
  const [ updateManualOperation, { isLoading: isEditing, isError, error }] = useUpdateManualOperationMutation(),
        dispatch = useDispatch();

  const onSubmit = manualOperationInput => updateManualOperation({
    input: {
      manualOperationInput, 
      manualOperationId: manualOperation.id
    }
  });

  return (
    <Popup>
      <div className="card">
        <div className="card-body">
          <h1>New manual operation</h1>
          {isError && 
            <div className="alert alert-danger">
              Error: {error.data?.map( (e,i) => <p key={i}>{e.message}.</p>)}
            </div>
          }
          <ManualOperationForm 
            initialManualOperation={manualOperation}
            onSubmit={onSubmit} 
            buttons={
              <div className="d-flex flex-row-reverse mt-4 mb-3">
                <button className="btn btn-primary" disabled={isEditing}>Save changes</button>
                <button type="button" className="btn btn-link mr-3" onClick={()=>dispatch(closePopup())}>Cancel</button>
              </div>
            }
          />           
        </div>
      </div>
    </Popup>
  );
}