import { get, post, patch, deleteRequest } from "../_utils/humpyAjax";
import { uniq } from "../_utils/filterCallbacks";
import { languagePairFromModel } from "../_utils/languages";

export const UPDATE_WHITELISTED_SKILLS = "UPDATE_WHITELISTED_SKILLS";
export const ADD_WHITELISTED_SKILL = "ADD_WHITELISTED_SKILL";
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_SKILLS = "UPDATE_SKILLS";
export const UPDATE_CONSTANT_DATA = "UPDATE_CONSTANT_DATA";

export const addClient = client => ({type: ADD_CLIENT, client});

export const getInitialData = () => async (dispatch, getState) => {
  const { translatorAccountId } = getState();

  get("/skills", {translatorAccountId}).then( skills => dispatch({type: UPDATE_SKILLS, skills}) );

  get("/constant_data", { include: ["human_qualities", "two_humans_qualities"] }).then(
    ({ humanQualities, twoHumansQualities }) => dispatch({ type: UPDATE_CONSTANT_DATA, humanQualities, twoHumansQualities})
  );

  const whitelistedSkills = await get("/whitelisted_skills", { translatorAccountId }),
        clientIds = whitelistedSkills.map(({clientAccountId})=>clientAccountId).filter(uniq);

  let clients = [];

  if (clientIds.length) clients = await get("/clients", {ids: clientIds});

  dispatch({type: UPDATE_CLIENTS, clients});
  dispatch({type: UPDATE_WHITELISTED_SKILLS, whitelistedSkills});
};

export const updateWhitelisted = (clientAccountId, languagePair, added, deleted, modified) => (dispatch, getState) => {
  const requests = [],
        errors = [],
        { translatorAccountId, skills, whitelistedSkills: currentWhitelistedSkills } = getState(),
        skill = skills.find(s => languagePairFromModel(s).toString == languagePair.toString),
        baseWhitelistedSkill = { clientAccountId, translatorAccountId, skillId: skill.id, ...languagePair.toHash };

  let whitelistedSkills = [...currentWhitelistedSkills];

  added.forEach( ({quality, role}) => requests.push(
    post("/whitelisted_skills", {whitelistedSkill: {...baseWhitelistedSkill, quality, role } } )
      .then(whitelistedSkill => whitelistedSkills.push(whitelistedSkill))
      .catch( () => errors.push(`Could not add quality ${quality}.`) )
  ));

  deleted.forEach((whitelistedSkill) => requests.push(
    deleteRequest(`/whitelisted_skills/${whitelistedSkill.id}`)
      .then( () => whitelistedSkills = whitelistedSkills.filter(wl => wl.id != whitelistedSkill.id) )
      .catch(() => errors.push(`Could not delete ${whitelistedSkill.quality}.`) )
  ));

  modified.forEach((whitelistedSkill) => requests.push(
    patch(`/whitelisted_skills/${whitelistedSkill.id}`, {role: whitelistedSkill.role })
      .then(updatedWlSk => whitelistedSkills = whitelistedSkills.map(wl => wl.id == updatedWlSk.id ? updatedWlSk : wl))
      .catch(() => errors.push(`Could not modify role for ${whitelistedSkill.quality}.`))
  ));

  Promise.all(requests)
    .then( () => {
      dispatch({ type: UPDATE_WHITELISTED_SKILLS, whitelistedSkills });
      if (errors.length) alert(errors.join("\n"));
    });
};