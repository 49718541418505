import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { reducers } from "./_actionsAndReducers";
import MergeUI from "./MergeUI";


let persistedState = {
  keysData: MUI_DATA.keysData,
  sourceTexts: MUI_DATA.sourceTexts,
  translatedInTM: MUI_DATA.translatedInTM,
  orderId: MUI_DATA.orderId,
  postprocessingReady: MUI_DATA.postprocessingReady
};

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <MergeUI />
  </Provider>,
  document.getElementById("merge-ui"),
);
