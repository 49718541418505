import { combineReducers } from "redux";

import { UPDATE_TRANSLATORS, UPDATE_PAGE, UPDATE_TOTAL_PAGES, UPDATE_ORDER } from "./_actions";

export const translators = (state = [], action) => {
  switch (action.type) {
    case UPDATE_TRANSLATORS:
      return action.translators;
    default:
      return state;
  }
};

export const totalPages = (state = 1, action) => {
  if (action.type == UPDATE_TOTAL_PAGES) return action.totalPages;
  return state;
};

export const page = (state = 1, action) => {
  if (action.type == UPDATE_PAGE) return action.page;
  return state;
};

export const order = (state = { type: "quality", order: "desc" }, action) => {
  if (action.type == UPDATE_ORDER) return action.order;
  return state;
};

export const staticData = (state = {url: ""}) => state;

export default combineReducers({
  staticData,
  translators,
  totalPages,
  page,
  order
});