import { combineReducers } from "redux";
import gqlClient from "../_utils/graphQlRequests";
import { paginationDefaults, invitationsQuery, tasksQuery } from "./_gql";

// Actions
// ====================================
const UPDATE_BAD_VERDICTS = "UPDATE_BAD_VERDICTS";
const UPDATE_LOADING = "UPDATE_LOADING";
const UPDATE_ERROR = "UPDATE_ERROR";
const UPDATE_PAGE = "UPDATE_PAGE";

export const updateBadVerdicts = (badVerdicts) => ({ type: UPDATE_BAD_VERDICTS, badVerdicts });
export const updateLoading = (loading) => ({ type: UPDATE_LOADING, loading });
export const updateError = (error) => ({ type: UPDATE_ERROR, error });
export const updatePage = page => ({ type: UPDATE_PAGE, page });

export const getBadVerdicts = (page = paginationDefaults.page, perPage = paginationDefaults.perPage) => (dispatch, getState) => {
  const { page: oldPage } = getState();

  dispatch(updateLoading(true));
  dispatch(updateError(null));
  dispatch(updatePage(page));

  gqlClient.request(tasksQuery, { page, perPage })
    .then(data => dispatch(
      updateBadVerdicts(gqlTasksToBadVerdictsNormalizer(data))
    ))
    .catch(error => {
      console.error(error);
      dispatch(updateError(error));
      dispatch(updatePage(oldPage));
    })
    .finally(() => dispatch(updateLoading(false)));
};

// Normalizer
// ====================================
const gqlTaskToBadVerdictNormalizer = (gqlTask) => ({
  id: gqlTask.id,
  proofreaderVerdict: gqlTask.proofreaderVerdict,
  proofreaderVerdictText: gqlTask.proofreaderVerdictText,
  translatorAnswered: gqlTask.translatorAnswered,
  opsHandled: gqlTask.opsHandled,
  proposedAction: gqlTask.proposedAction,
  actionTaken: gqlTask.actionTaken,
  translator: gqlTask.translatorAccount ? {
    id: gqlTask.translatorAccount.id,
    email: gqlTask.translatorAccount.user.email,
  } : null,
  proofreader: gqlTask.proofreaderAccount ? {
    id: gqlTask.proofreaderAccount.id,
    email: gqlTask.proofreaderAccount.user.email,
  } : null,
  date: gqlTask.invitations[0].confirmedAt,
});

const gqlTasksToBadVerdictsNormalizer = ({tasks: {nodes, nodesCount, pagesCount}}) => {
  return {
    nodes: nodes.map(gqlTaskToBadVerdictNormalizer),
    nodesCount,
    pagesCount,
  };
};


// Reducers
// ====================================
export const NO_VERDICTS = { nodes: [] };

export const badVerdicts = (state = NO_VERDICTS, action) => {
  if (action.type == UPDATE_BAD_VERDICTS) return action.badVerdicts;
  return state;
};

export const error = (state = null, action) => {
  if (action.type == UPDATE_ERROR) return action.error;
  return state;
};

export const loading = (state = true, action) => {
  if (action.type == UPDATE_LOADING) return action.loading;
  return state;
};

export const page = (state = paginationDefaults.page, action) => {
  if (action.type == UPDATE_PAGE) return action.page;
  return state;
};

export default combineReducers({
  badVerdicts,
  error,
  loading,
  page,
});
