import { gql } from "graphql-request";
import { createApi } from "@reduxjs/toolkit/query/react";

import { graphqlBaseQuery } from "../_utils/graphqlBaseQuery";

export const gqlApi = createApi({
  reducerPath: "gql",
  baseQuery:   graphqlBaseQuery(),
  tagTypes:    [ "ClientAccount", "ChatGPTHistory" ],
  endpoints:   (builder) => ({
    getClientAccount: builder.query({
      transformResponse: response => response?.clientAccount,
      query:             input => ({
        input,
        body: gql`
          query ClientAccount($id: ID!, $type: String!, $page: Int, $perPage: Int) {
            clientAccount(id: $id) {
              id
              machineTranslatorPromptHistories(type: $type, page: $page, perPage: $perPage) {
                nodes {
                  amount
                  slug
                  source
                  createdAt
                  translationRequests {
                    id
                    title
                  }
                }
                nodesCount
                pagesCount
              }
              machineTranslatorPromptHistoriesTotal(type: $type)
              machineTranslatorPromptHistoriesAiProofreaderStats {
                totalSegments
                totalSegmentsApprovedByAi
              }
            }
          }
        `
      })
    }),
  })})

export const { useGetClientAccountQuery } = gqlApi;
