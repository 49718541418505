import React from "react";
import { connect } from "react-redux";
import { patch } from "../_utils/humpyAjax";

import { updateEditableFields, resetFields, removeGlossary } from "./_actions";

export class TrEditableFields extends React.Component {
  submit(e){
    const { staticData: { translationRequestPath}, editableFields} = this.props;
    e.preventDefault();

    patch(translationRequestPath, editableFields, () => {
      location.reload();
      window.scrollTo(0, 0);
    });
  }

  render() {
    const {
      editableFields: {
        comment,
        name,
        callbackUrl,
        contextUrl,
        styleGuideReferenceId,
        userId,
        assignedGlossariesIds,
      },
      staticData: {
        styleGuides,
        users,
        canceled,
        glossaries,
      },
      updateEditableFields,
      resetFields,
      removeGlossary,
    } = this.props;

    const noChanges = this.props.editableFields === EDITABLE_FIELDS_INITIAL_STATE;

    const glossaryName = (glossaryId) => glossaries.find((g) => g.id === glossaryId)?.name;

    return (
      <div className="mt-5">
        <h3>Editable fields</h3>
        <form onSubmit={e => this.submit(e)} >
          <div className="form-group row">
            <label className="col-4">Assigned editor</label>
            <div className="col-8">
              <select name="userId" value={userId} className="form-control" onChange={e => updateEditableFields("userId", e.target.value)} disabled={canceled}>
                {users.map(user => (<option key={`editor-${user.id}`} value={user.id}>{user.name}</option>))}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4">Style guide</label>
            <div className="col-8">
              <select name="styleGuideReferenceId" value={styleGuideReferenceId || 1} className="form-control" onChange={e => updateEditableFields("styleGuideReferenceId", e.target.value)} disabled={canceled}>
                {styleGuides.map(styleGuide => (<option key={`sg-${styleGuide.referenceId}`} value={styleGuide.referenceId}>{styleGuide.name}</option>))}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4">Glossaries</label>
            <div className="col-8">
              <select name="assignedGlossariesIds" className="form-control" onChange={e => updateEditableFields("assignedGlossariesIds", e.target.value)} disabled={canceled}>
                {glossaries.map(glossary => (<option key={`g-${glossary.id}`} value={glossary.id}>{glossary.name}</option>))}
              </select>
            </div>
          </div>
            <div className="form-group row">
              <label className="col-4">Assigned glossaries</label>
              <div className="col-8">
                { assignedGlossariesIds.map(glossaryId => (
                <li key={glossaryId} className="ordering_form__excluder__excluded__range">
                  { glossaryName(glossaryId) }
                  <span
                    className="ticon-cancel ordering_form__excluder__excluded__remove"
                    onClick={() => removeGlossary(glossaryId)}
                  />
                </li>
              ))}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4">Name</label>
            <div className="col-8">
              <input className="form-control" type="text" value={name} onChange={e => updateEditableFields("name", e.target.value)} disabled={canceled} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4">Callback Url</label>
            <div className="col-8">
              <input className="form-control" type="text" value={callbackUrl} onChange={e => updateEditableFields("callbackUrl", e.target.value)} disabled={canceled} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4">Context Url</label>
            <div className="col-8">
              <input className="form-control" type="text" value={contextUrl} onChange={e => updateEditableFields("contextUrl", e.target.value)} disabled={canceled} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-4">Comment for translator</label>
            <div className="col-8">
              <textarea className="form-control" value={comment} onChange={e => updateEditableFields("comment", e.target.value)} disabled={canceled} />
            </div>
          </div>

          {!canceled && <button
            className="btn btn-primary"
            disabled={noChanges}
          >
            Save
          </button>}

          {noChanges ?
            null :
            <button
              className="btn btn-link"
              onClick={e => { e.preventDefault(); resetFields(EDITABLE_FIELDS_INITIAL_STATE); }}
            >
              Cancel
            </button>
          }
        </form>

      </div>
    );
  }
}

export default connect(
  ({editableFields, staticData}) => ({
    editableFields,
    staticData
  }),
  { updateEditableFields, resetFields, removeGlossary }
)(TrEditableFields);
