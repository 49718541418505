import { gql } from "graphql-request";

export const withdrawalsExportSepaMutation = gql`
  mutation {
    withdrawalsExportSepa(input: {}) {
      fileUrl
      withdrawals {
        id
        paid
      }
      success
    }
  }
`;