import { combineReducers } from "redux";

import { UPDATE_RESULTS } from "../_common/filtered_lists/_actions";
import {
  totalCount,
  totalPages,
  filters,
  results,
  loading,
  allResultsSelected,
  selectedResults,
  staticData
} from "../_common/filtered_lists/_reducers";
import {
  CLOSE_DEADLINE_POPUP,
  OPEN_DEADLINE_POPUP,
  CLOSE_CONFIRM_READY_POPUP,
  OPEN_CONFIRM_READY_POPUP,
  CLOSE_SET_AS_REVIEWED,
  OPEN_SET_AS_REVIEWED,
} from "./_actions";

export const clientAccountName = (state=null, action) => {
  switch (action.type) {
    case UPDATE_RESULTS:
      return action.otherChanges.clientAccountName;
    default:
      return state;
  }
};

export const deadlinePopupOpen = (state = false, action) => {
  switch (action.type) {
    case CLOSE_DEADLINE_POPUP:
      return false;
    case OPEN_DEADLINE_POPUP:
      return true;
    default:
      return state;
  }
};

export const confirmReadyPopupOpen = (state = false, action) => {
  switch (action.type) {
    case CLOSE_CONFIRM_READY_POPUP:
      return false;
    case OPEN_CONFIRM_READY_POPUP:
      return true;
    default:
      return state;
  }
};

export const setAsReviewedPopupOpen = (state = false, action) => {
  switch (action.type) {
    case CLOSE_SET_AS_REVIEWED:
      return false;
    case OPEN_SET_AS_REVIEWED:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  staticData,
  allResultsSelected,
  clientAccountName,
  confirmReadyPopupOpen,
  deadlinePopupOpen,
  filters,
  loading,
  orders: results,
  selectedResults,
  setAsReviewedPopupOpen,
  totalCount,
  totalPages,
});