import { get, post, patch } from "../_utils/humpyAjax";
import { deleteRequest } from "../_utils/ajax";
import gqlClient from "../_utils/graphQlRequests";

import {
  getClientDefaultPricingQuery,
  updateClientDefaultPricingMutation,
} from "./_gql";

export const UPDATE_NEW_CLIENT_LANGUAGE_PAIR_PRICING      = "UPDATE_NEW_CLIENT_LANGUAGE_PAIR_PRICING";
export const CLEAN_NEW_CLIENT_LANGUAGE_PAIR_PRICING       = "CLEAN_NEW_CLIENT_LANGUAGE_PAIR_PRICING";

export const ADD_CLIENT_LANGUAGE_PAIR_PRICING             = "ADD_CLIENT_LANGUAGE_PAIR_PRICING";
export const UPDATE_CLIENT_LANGUAGE_PAIR_PRICING          = "UPDATE_CLIENT_LANGUAGE_PAIR_PRICING";
export const UPDATE_CLIENT_LANGUAGE_PAIR_PRICINGS          = "UPDATE_CLIENT_LANGUAGE_PAIR_PRICINGS";
export const REMOVE_CLIENT_LANGUAGE_PAIR_PRICING          = "REMOVE_CLIENT_LANGUAGE_PAIR_PRICING";

export const ADD_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING    = "ADD_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING";
export const UPDATE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING = "UPDATE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING";
export const REMOVE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING = "REMOVE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING";

export const SET_CLIENT_DEFAULT_PRICING                   = "SET_CLIENT_DEFAULT_PRICING";
export const UPDATE_CLIENT_DEFAULT_PRICING                = "UPDATE_CLIENT_DEFAULT_PRICING";

export const CHANGE_CLIENT_LANGUAGE_PAIR_PRICING_SCOPE    = "CHANGE_CLIENT_LANGUAGE_PAIR_PRICING_SCOPE";

export const getClientLanguagePairPricings = (baseUrl) => (dispatch, getState) =>  {
  const state = getState();
  const {
    clientLanguagePairPricingsScope: {
      page,
      sourceLanguageCode,
      targetLanguageCode
    }
  } = state;

  let params = new URLSearchParams(window.location.search)

  params.set('page', page)

  if (sourceLanguageCode) {
    params.set('source_language_code', sourceLanguageCode)
  } else {
    params.delete('source_language_code')
  }
  if (targetLanguageCode) {
    params.set('target_language_code', targetLanguageCode)
  } else {
    params.delete('target_language_code')
  }

  get(`${baseUrl}.json?${params.toString()}`)
    .then( response =>{
      dispatch(updateClientLanguagePairPricings(response.clientLanguagePairPricings))
      dispatch(changeClientLanguagePairPricingsScope({ page: response.page, totalPages: response.totalPages }))

      history.replaceState(null, null, `${document.location.pathname}?${params.toString()}`);
    })
    .catch( e => console.error(e));

    return {
      ...state
    }
}

export const changeClientLanguagePairPricingsScope = (changes) => ({
  type: CHANGE_CLIENT_LANGUAGE_PAIR_PRICING_SCOPE,
  changes
})

export const updateNewClientLanguagePairPricing = (changes) => ({
  type: UPDATE_NEW_CLIENT_LANGUAGE_PAIR_PRICING,
  changes,
});

export const saveNewClientLanguagePairPricing = () => async (dispatch, getState) => {
  const state = getState();
  const {
    clientLanguagePairPricings: pricings,
    newClientLanguagePairPricing: pricing,
    clientAccount: { id: clientAccountId },
  } = state;

  const usedPairs = pricings.map(cPricing => `${cPricing.sourceLanguageCode}-${cPricing.targetLanguageCode}`);
  const pairUsed  = usedPairs.includes(`${pricing.sourceLanguageCode}-${pricing.targetLanguageCode}`);
  const sameCodes = pricing.sourceLanguageCode === pricing.targetLanguageCode;

  // TODO: redux-form would fit better here
  if (pricing.sourceLanguageCode && pricing.targetLanguageCode && !sameCodes && !pairUsed) {
    const createdPricing = await post(`/clients/${clientAccountId}/custom-pricing`, {
      clientLanguagePairPricing: {
        sourceLanguageCode:            pricing.sourceLanguageCode,
        targetLanguageCode:            pricing.targetLanguageCode,
        pricePerWordPosteditingUsd:    pricing.pricePerWordPosteditingUsd,
        pricePerWordPosteditingEur:    pricing.pricePerWordPosteditingEur,
        pricePerWordPosteditingAiUsd:  pricing.pricePerWordPosteditingAiUsd,
        pricePerWordPosteditingAiEur:  pricing.pricePerWordPosteditingAiEur,
        pricePerWordExpertUsd:         pricing.pricePerWordExpertUsd,
        pricePerWordExpertEur:         pricing.pricePerWordExpertEur,
        pricePerWordLocalizationUsd:   pricing.pricePerWordLocalizationUsd,
        pricePerWordLocalizationEur:   pricing.pricePerWordLocalizationEur,
        pricePerWordTranslationUsd:    pricing.pricePerWordTranslationUsd,
        pricePerWordTranslationEur:    pricing.pricePerWordTranslationEur,
        pricePerWordMachineUsd:        pricing.pricePerWordMachineUsd,
        pricePerWordMachineEur:        pricing.pricePerWordMachineEur,
        salesMargin:                   pricing.salesMargin ? pricing.salesMargin / 100 : null,
      }
    });

    dispatch(addClientLanguagePairPricing(createdPricing));
    dispatch({ type: CLEAN_NEW_CLIENT_LANGUAGE_PAIR_PRICING });
  } else {
    dispatch(updateNewClientLanguagePairPricing({
      validSourceLanguageCode: !!pricing.sourceLanguageCode && !sameCodes && !pairUsed,
      validTargetLanguageCode: !!pricing.targetLanguageCode && !sameCodes && !pairUsed,
    }));
  }
};

export const addClientLanguagePairPricing = (clientLanguagePairPricing) => ({
  type: ADD_CLIENT_LANGUAGE_PAIR_PRICING,
  clientLanguagePairPricing,
});

export const updateClientLanguagePairPricing = (clientLanguagePairPricingId, changes) => ({
  type: UPDATE_CLIENT_LANGUAGE_PAIR_PRICING,
  clientLanguagePairPricingId,
  changes,
});

export const updateClientLanguagePairPricings = (clientLanguagePairPricings) => ({
  type: UPDATE_CLIENT_LANGUAGE_PAIR_PRICINGS,
  clientLanguagePairPricings
});


export const saveClientLanguagePairPricing = (pricing) => async (dispatch) => {
  const updatedPricing = await patch(`/custom-pricing/${pricing.id}`, {
    clientLanguagePairPricing: {
      pricePerWordPosteditingUsd:    pricing.pricePerWordPosteditingUsd,
      pricePerWordPosteditingEur:    pricing.pricePerWordPosteditingEur,
      pricePerWordPosteditingAiUsd:  pricing.pricePerWordPosteditingAiUsd,
      pricePerWordPosteditingAiEur:  pricing.pricePerWordPosteditingAiEur,
      pricePerWordExpertUsd:         pricing.pricePerWordExpertUsd,
      pricePerWordExpertEur:         pricing.pricePerWordExpertEur,
      pricePerWordLocalizationUsd:   pricing.pricePerWordLocalizationUsd,
      pricePerWordLocalizationEur:   pricing.pricePerWordLocalizationEur,
      pricePerWordTranslationUsd:    pricing.pricePerWordTranslationUsd,
      pricePerWordTranslationEur:    pricing.pricePerWordTranslationEur,
      pricePerWordMachineUsd:        pricing.pricePerWordMachineUsd,
      pricePerWordMachineEur:        pricing.pricePerWordMachineEur,
      salesMargin:                   pricing.salesMargin ? pricing.salesMargin / 100 : null,
    }
  });

  dispatch(updateClientLanguagePairPricing(pricing.id, updatedPricing));
};

export const removeClientLanguagePairPricing = (clientLanguagePairPricingId) => async (dispatch) => {
  await deleteRequest(`/custom-pricing/${clientLanguagePairPricingId}`);

  dispatch({
    type: REMOVE_CLIENT_LANGUAGE_PAIR_PRICING,
    clientLanguagePairPricingId,
  });
};

export const addClientWorkflowLanguagePairPricing = (clientLanguagePairPricingId) => async (dispatch, getState) => {
  const state = getState();
  const { clientAccount: { id: clientAccountId } } = state;

  const clientWorkflowLanguagePairPricing = await post(`/clients/${clientAccountId}/custom-pricing`, {
    clientLanguagePairPricing: { parentId: clientLanguagePairPricingId, type: "workflow" }
  });

  dispatch({
    type: ADD_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING,
    clientLanguagePairPricingId,
    clientWorkflowLanguagePairPricing
  });
};

export const updateClientWorkflowLanguagePairPricing = (clientLanguagePairPricingId, changes) => ({
  type: UPDATE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING,
  clientLanguagePairPricingId,
  changes,
});

export const saveClientWorkflowLanguagePairPricing = (clientLanguagePairPricingId, pricing) => async (dispatch) => {
  const updatedWorkflowPricing = await patch(`/custom-pricing/${pricing.id}`, {
    clientLanguagePairPricing: {
      salesMarginExpert:       pricing.salesMarginExpert ? pricing.salesMarginExpert / 100 : null,
      salesMarginLocalization: pricing.salesMarginLocalization ? pricing.salesMarginLocalization / 100 : null,
      salesMarginTranslation:  pricing.salesMarginTranslation ? pricing.salesMarginTranslation / 100 : null,
    }
  });

  dispatch(updateClientWorkflowLanguagePairPricing(clientLanguagePairPricingId, updatedWorkflowPricing));
};

export const removeClientWorkflowLanguagePairPricing = (clientLanguagePairPricingId, clientWorkflowLanguagePairPricingId) => async (dispatch) => {
  await deleteRequest(`/custom-pricing/${clientWorkflowLanguagePairPricingId}`);

  dispatch({
    type: REMOVE_CLIENT_WORKFLOW_LANGUAGE_PAIR_PRICING,
    clientLanguagePairPricingId,
  });
};

export const setClientDefaultPricing = (clientDefaultPricing) =>
  ({ type: SET_CLIENT_DEFAULT_PRICING, clientDefaultPricing });

export const getClientDefaultPricing = (clientAccountId) => async (dispatch) => {
  const {
    clientAccount: {
      clientDefaultPricing,
    },
  } = await gqlClient.request(getClientDefaultPricingQuery, {
    clientAccountId: clientAccountId.toString()
  });

  dispatch(setClientDefaultPricing(clientDefaultPricing));
};

export const updateClientDefaultPricing = (changes) => ({
  type: UPDATE_CLIENT_DEFAULT_PRICING, changes,
});

export const saveClientDefaultPricing = (clientAccountId, clientDefaultPricing) => async (dispatch) => {
  const {
    clientDefaultPricingUpdate: {
      success,
      errors,
      clientDefaultPricing: updatedClientDefaultPricing,
    }
  } = await gqlClient.request(updateClientDefaultPricingMutation, {
    input: {
      clientAccountId,
      clientDefaultPricingInput: Object.fromEntries(Object.entries(clientDefaultPricing).map(([k,v]) => [k, parseFloat(v) || null])),
    }
  });

  if (success) dispatch(updateClientDefaultPricing(updatedClientDefaultPricing));

  return { success, errors };
};
