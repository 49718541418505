import { combineReducers } from "redux";

// import { } from "./_actions";

export const verdictTimeFrame = (state = "month", action) => state;
export const qualityDimensionTimeFrame = (state = "month", action) => state;
export const qualityDimensionExtrapolation = (state = "words", action) => state;
export const proofreadingTimeFrame = (state = "month", action) => state;
export const proofreadingExtrapolation = (state = "words", action) => state;

export const staticData = (state = {}) => state;
export const machineTranslatorPromptId = (state = null) => state;

export default combineReducers({
  verdictTimeFrame,
  qualityDimensionTimeFrame,
  qualityDimensionExtrapolation,
  proofreadingTimeFrame,
  proofreadingExtrapolation,
  staticData,
  machineTranslatorPromptId,
});
