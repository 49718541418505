import { GraphQLClient } from "graphql-request";
import { getCSRFToken } from "./ajax";

export const createGqlClient = () => new GraphQLClient("/graphql", {
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-Token": getCSRFToken(),
  },
  credentials: "same-origin",
});

const gqlClient = createGqlClient();

export default gqlClient;
