import { takeLatest, all } from "redux-saga/effects";

import { hocGetHumpedResults } from "../_common/filtered_lists/_sagas";
import { REQUEST_FILTERS_UPDATE } from "../_common/filtered_lists/_actions";

// TODO: Inconsistency here - TRs controller uses Orders presenter - TRs displayed (like on dashboard are actually orders...)
const getResults = hocGetHumpedResults("orders", "/translation-requests");

export function* watchRequests() {
  yield takeLatest(REQUEST_FILTERS_UPDATE, getResults);
}

export default function* rootSaga() {
  yield all([
    watchRequests()
  ]);
}
