import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Breadcrumbs from "../_common/Breadcrumbs";
import { purchaseOrderSelector } from "./_reducers";
import { loadPurchaseOrder, updatePurchaseOrder } from "./_actions";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";
import ErrorsAlert from "../_common/ErrorsAlert";
import Orders from "./Orders";
import CreditCardPayments from "./CreditCardPayments";
import EditableShortText from "../_common/editable_fields/EditableShortText";
import { purchaseOrderUpdateMutation, purchaseOrderCancelMutation } from "./_gql";
import gqlClient from "../_utils/graphQlRequests";

export default function PurchaseOrder(){
  const [errors, setErrors] = useState([]);
  const [cancelling, setCancelling] = useState(false);
  const purchaseOrder = useSelector(purchaseOrderSelector);
  const dispatch = useDispatch();
  const id = parseInt(document.location.pathname.split("/")[2]);

  const updatePurchaseOrderCode = (orderCode) => {
    gqlClient.request(purchaseOrderUpdateMutation, { input: {purchaseOrderId: id,  purchaseOrderInput: {orderCode}} })
      .then( ({purchaseOrderUpdate: {errors, purchaseOrder}}) => {
        setErrors(errors);
        dispatch(updatePurchaseOrder(purchaseOrder));
      })
      .catch( () => setErrors(["Unexpected server error"]));
  };

  const cancelPurchaseOrder = (e) => {
    e.preventDefault();

    if (!window.confirm(`Are you sure you want to cancel purchase order #${id}`)) return;

    setCancelling(true);
    gqlClient.request(purchaseOrderCancelMutation, {input: {purchaseOrderId: id}})
      .then( ({ purchaseOrderCancel: {errors, purchaseOrder}, }) => {
        setErrors(errors);
        dispatch(updatePurchaseOrder(purchaseOrder));
      })
      .catch(() => setErrors(["Unexpected server error"]))
      .finally(()=>setCancelling(false));
  };

  useEffect(() => {
    dispatch(loadPurchaseOrder(id));
  }, []);

  if (!purchaseOrder) return null;

  const {
    orderCode,
    clientAccount,
    createdAt,
    quality,
    sourceLanguageCode,
    paymentStatus,
    paymentVariant,
    paidAt,
    price,
    originalPrice,
    invoicedAt,
    currency,
    cancelable,
    canceledAt,
  } = purchaseOrder;

  return (
    <div>
      <Breadcrumbs
        links={[{ name: `Purchase Order ${id}`, url: `/purchase-orders/${id}` }]}
      />

      <div className="container-fluid">
        <div className="row">
          <h1 className="col my-3">
            {cancelable &&
              <button
                className="btn btn-danger btn-lg float-right mb-3"
                onClick={cancelPurchaseOrder}
                disabled={cancelling}
              >
                Cancel purchase order
              </button>
            }
            Purchase Order <span className="text-muted">#{id}</span>
          </h1>
        </div>

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">

                <p className="text-muted font-italic">
                  Ordered by
                  <a className="mx-1" href={`/clients/${clientAccount.id}`}>{clientAccount.name || clientAccount.id}</a>
                  at
                  <PrettyLocalDateTime className="mx-1" date={createdAt} />
                </p>

                <ErrorsAlert errors={errors} />

                <div className="row">
                  <div className="col-3">
                    <dl>
                      <EditableShortText
                        name={"Purchase order code"}
                        value={orderCode}
                        onSave={updatePurchaseOrderCode}
                      />
                      <dt>Quality</dt>
                      <dd>{quality}</dd>
                      <dt>Source language</dt>
                      <dd>{I18n.t(`languages.${sourceLanguageCode}`)}</dd>
                    </dl>
                  </div>
                  <div className="col-3">
                    <dl>
                      <dt>ID</dt>
                      <dd> {id}</dd>
                      <dt>Invoiced at</dt>
                      <dd><PrettyLocalDateTime date={invoicedAt} /></dd>
                      <dt>Payment status</dt>
                      <dd>{paymentStatus}</dd>
                    </dl>
                  </div>
                  <div className="col-3">
                    <dl>
                      <dt>Price</dt>
                      <dd>{price.toLocaleString(navigator.language, { currency, style: "currency" })}</dd>
                      <dt>Original price <span className="text-muted">(before refunds)</span></dt>
                      <dd className="text-muted">{originalPrice.toLocaleString(navigator.language, { currency, style: "currency" })}</dd>
                      <dt>Payment variant</dt>
                      <dd>{paymentVariant}</dd>
                    </dl>
                  </div>
                  <div className="col-3">
                    <dl>
                      <dt>Paid at</dt>
                      <dd><PrettyLocalDateTime date={paidAt} /></dd>
                      {canceledAt &&
                        <>
                          <dt className="text-danger"><span className="ticon-warning mr-2"></span>Cancelled at</dt>
                          <dd><PrettyLocalDateTime date={canceledAt} /> </dd>
                        </>
                      }
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CreditCardPayments/>
          <Orders/>
        </div>
      </div>
    </div>
  );
}
