import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import sliceReducers from "./_slices";
import { gqlApi } from "./_api";
import Invoices from "./Invoices";
import ClientInvoices from "./ClientInvoices";


let idMatch = window.location.pathname.match(/^\/clients\/(\d+)/),
    clientAccountId = idMatch && parseInt(idMatch[1]);  

export const store = configureStore({
  reducer: {
    ...sliceReducers,
    [gqlApi.reducerPath]: gqlApi.reducer,
  },
  preloadedState: { clientAccountId },
  middleware:     (getDefaultMiddleware) => getDefaultMiddleware().concat(gqlApi.middleware)
});

ReactDOM.render(
  <Provider store={store}>
    <div className="mt-4">
      {clientAccountId ? <ClientInvoices/> : <Invoices />}
    </div>
  </Provider>,
  document.getElementById("invoices")
);
