import React, { useContext } from "react";
import { Store } from './Store';
import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import Loader from "../_common/Loader";
import Alerts from "../_common/Alerts";
import FloatingCornerPopup from "../_common/FloatingCornerPopup";
import Headers from "./Headers";
import WithdrawalsList from "./WithdrawalsList";
import { updateFilters } from "./_actions";

export default function Results(props) {
  const {
    state: {
      filters: { page, size },
      totalPages,
      loading,
    },
    dispatch
  } = useContext(Store);

  const changePage = (page) => updateFilters(dispatch, { page });
  const changePageAndSize = (page, size) => updateFilters(dispatch, { page, size });

  return (
    <div className="col-9">
      <div className="card">
        <div className="card-body">
          <TopPagination {...{ changePageAndSize, totalPages, page, size }} />
          <div className="loader_wrapper">
            {loading && <Loader />}
            <table className="table mb-0">
              <Headers />
              <WithdrawalsList />
            </table>
          </div>
          <Pagination className="justify-content-end" {...{ changePage, totalPages, page }} />
        </div>
      </div>
    </div>
  );
}
