// Frontend format:
// {
//   en: { allowed: ["en_CA", "en"], default: false },
//   nl: { allowed: ["nl_NL", "nl"], default: "nl" },
//   es: { allowed: ["es_MX"], default: "es_MX" },
// }
export function convertToFrontendDialects(backendDialects) {
  return Object.entries(backendDialects).reduce((frontendDialects, [masterCode, dialect])=>{
    let defaultDialect = false;

    if (dialect.default === null) {
      defaultDialect = masterCode;
    } else if (dialect.default) {
      defaultDialect = `${masterCode}_${dialect.default}`;
    }

    frontendDialects[masterCode] = {
      allowed: dialect.allowed.map(code => code ? `${masterCode}_${code}` : masterCode),
      default: defaultDialect
    };

    return frontendDialects;
  }, {});
}

// Backend format (receive):
// (This is the format that we receive and therefore the one used by convertToFrontendDialects)
// {
//   en: { allowed: ["CA", null], default: false },
//   nl: { allowed: ["NL", null], default: null },
//   es: { allowed: ["MX"], default: "MX" },
// }

// Backend format (send):
// (Rails thinks sending null values on your array is a crime against humanity and ignores them, so we need to circumvent it somehow)
// {
//   en: { allowed: ["CA", "null"], default: false },
//   nl: { allowed: ["NL", "null"], default: null },
//   es: { allowed: ["MX"], default: "MX" },
// }
export function convertToBackendDialects(frontendDialects){
  return Object.entries(frontendDialects).reduce((backendDialects, [masterCode, dialect]) => {
    let defaultDialect;

    if (dialect.default) defaultDialect = dialect.default.split("_")[1] || null;

    backendDialects[masterCode] = {
      allowed: dialect.allowed.map(code => code.split("_")[1] || "null"),
      default: dialect.default ? defaultDialect : false,
    };

    return backendDialects;
  }, {});
}