import React from "react";

export default function ConfigureVisible({
  keys,
  renderKeys,
  setRenderKeys,
  labels,
}) {
  return (
    <>
      <h2>Configure visible attributes</h2>
      <p>Select which attributes you wish to display in the table</p>

      <div className="grid row-gap-1 mb-5">
        {keys.map(key => (
          <div key={key} className="gcol-4 flex align-items-start">
            <input
              id={`config-show-${key}`}
              type="checkbox"
              checked={!!renderKeys[key]}
              className="tcheckbox mr-1 mt-1 dont_resize"
              onChange={() => setRenderKeys({ ...renderKeys, [key]: !renderKeys[key] })}
            />
            <label htmlFor={`config-show-${key}`}>{labels(key)}</label>
          </div>
        ))}
      </div>
    </>
  );
}