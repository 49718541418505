import { createSlice } from "@reduxjs/toolkit";
import { urlSearchToInitialState } from "../_utils/humpyAjax";

export const defaultFilterSliceData = (initialStateData = {
  page: 1,
  perPage: 20,
}) => ({
  name: "filters",
  initialState: urlSearchToInitialState(initialStateData),
  reducers: {
    updatePage: (state, { payload }) => { state.page = payload; },
    updatePerPage: (state, { payload }) => { state.perPage = payload; },
    updateFilters: (state, { payload }) => ({ ...state, ...payload }),
    rewriteFilters: (state, { payload }) => payload,
  }
});

export const createFilterSlice = (initialStateData) => createSlice(defaultFilterSliceData(initialStateData));

export default createFilterSlice;