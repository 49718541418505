import React from "react";
import { connect } from "react-redux";

import FormCalendar from "../_common/FormCalendar";
import { requestFiltersUpdateAndResetPage } from "../_common/filtered_lists/_actions";

export class Sidebar extends React.Component {
  render() {
    const { className, filters, requestFiltersUpdateAndResetPage} = this.props;

    return (
      <div className={className}>
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Search</label>
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type in your search"
                  name="search"
                  value={filters.search || ""}
                  onChange={(e) => requestFiltersUpdateAndResetPage({ search: e.target.value })}
                />
                <div className="input-group-append"><span className="input-group-text ticon-search"></span></div>
              </div>
              <small id="emailHelp" className="form-text text-muted">Partial matches for ids and names.</small>
            </div>

            <FormCalendar
              id="calendar"
              label="Created after"
              callback={(date) => requestFiltersUpdateAndResetPage({ createdAfter: date })}
              value={filters.createdAfter || ""}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ filters }) => ({ filters }),
  { requestFiltersUpdateAndResetPage }
)(Sidebar);
