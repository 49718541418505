export const onClickOutside = (elementSelector, callback) => {
  const outsideClickListener = event => {
    if (!event.target.closest(elementSelector)) {
      callback();
    }
  };

  const removeClickListener = () => {
    document.removeEventListener("click", outsideClickListener);
  };

  document.addEventListener("click", outsideClickListener);

  return { stop: removeClickListener };
};