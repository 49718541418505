import { delay } from "redux-saga";
import { takeLatest, all, call, put  } from "redux-saga/effects";
import { get, urlParams } from "../_utils/ajax";

import { REQUEST_FILTERS_UPDATE, updateTrainingTexts, updateFilters } from "./_actions";

// Sagas
function* getTrainingTexts({filters}) {
  yield put(updateFilters(filters));
  let params = urlParams(filters);
  yield call(delay, 200);
  let response = yield call(get,`/language_pairs.json?${params}`);
  let { trainingTexts, withTrainingTotalCount, withoutTrainingTotalCount, totalPages } = yield call([response, "json"]);
  yield put(updateTrainingTexts(trainingTexts, withTrainingTotalCount, withoutTrainingTotalCount, totalPages));
  history.replaceState(null, null, `/language_pairs?${params}`);
}

// Watchers
function* watchRequests(){
  yield takeLatest(REQUEST_FILTERS_UPDATE, getTrainingTexts);
}

// ROOTSAGA
export default function* rootSaga() {
  yield all([
    watchRequests()
  ]);
}