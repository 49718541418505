import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { get } from "../_utils/humpyAjax";
import { TIME_RANGES } from "./_constants";
import Card from "../_common/Card";
import LineChart from "./LineChart";
import VerdictsList from "./VerdictsList";
import TimeRangeSelector from "./TimeRangeSelector";

export class Verdicts extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      values: [{
        color: "#2ECC71",
        data: []
      }],
      timeRange: TIME_RANGES[0],
    };

    this.ticks = {
      min: 0,
      max: 4,
      stepSize: 1,
      callback: v => I18n.t(`qualities.${props.possibleVerdicts[v]}`)
    };
  }

  componentDidMount(){
    this.getData();
  }

  getData() {
    const { machineTranslatorPromptId } = this.props;
    const { timeRange } = this.state;

    get(this.props.paths.verdictsLineChart, { timeRange, machineTranslatorPromptId } )
      .then( data => {
        const values = [...this.state.values];

        values[0].data = data;
        this.setState({values});
      });
  }

  changeTimeRange(timeRange){
    this.setState({timeRange}, () => this.getData() );
  }

  render() {
    const { timeRange, values } = this.state;

    return (
      <div className="col-12">
        <div className="row">
          <Card className="col-8">

            <ul className="nav nav-pills justify-content-end mb-3">
              <TimeRangeSelector callback={(timeRange) => this.changeTimeRange(timeRange)} currentTimeRange={timeRange} />
            </ul>

            <LineChart
              values={values}
              ticks={this.ticks}
              timeRange={timeRange}
            />
          </Card>

          <Card className="col-4 chart__line_chart__wraper__companion_card--with-legend">
            <VerdictsList />
          </Card>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ staticData: { possibleVerdicts, paths }, machineTranslatorPromptId }) => ({ possibleVerdicts, paths, machineTranslatorPromptId })
)(Verdicts);
