import React from "react";

export class FreeSelector extends React.Component {
  render() {
    const { totalPages, page, changePage, windowSize } = this.props;

    if (totalPages <= windowSize) return null;

    return (
      <li className="ml-3">        
        <input
          type="number"
          value={page}
          onChange={e => changePage(parseInt(e.target.value))}
          max={totalPages}
          min={1}
          className="form-control"
        />        
      </li>
    );
  }
}
export default FreeSelector;