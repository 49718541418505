// Props summary
// Select input with optional label
// label (optional): Label that would appear over the input
// columns (optional): number of columns to split checkboxes, default 2
// name: master name used to form subnames with optionValues
// onChange: callback to call on change, calls with an array of values instead of the target
// values: array of currently selected values
// className: obvious
// options: array of options with 2 possible formats:
//    [optionValue]: values and labels are the same
//    [[optionLabel, optionValue]]: what is shown (label) is different to waht is handled/sent

import PropTypes from "prop-types";
import React from "react";

export class FormMultiCheckbox extends React.Component {
  handleChange(value){
    const {values, onChange} = this.props;

    if (values.includes(value)) {
      onChange(values.filter( v => v != value ));
    } else {
      onChange([...values, value]);
    }
  }

  render() {
    const { label, options, name, className, values, columns } = this.props;

    return (
      <div className={className || "form-group"}>
        {label ? <label htmlFor={name}>{label}</label> : null}

        <div style={{ columns: columns || 2 }}>
          {options.map(option => {
            let optionLabel, optionValue;

            if (Array.isArray(option)) {
              [optionLabel, optionValue] = option;
            } else {
              optionLabel = optionValue = option;
            }

            return (
              <div className="form-group form-check mb-1" key={`input-wrapper-${name}-${optionValue}`} >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`input-${name}-${optionValue}`}
                  checked={values.includes(optionValue)}
                  onChange={()=>this.handleChange(optionValue)}
                />
                <label className="form-check-label text-capitalize" htmlFor={`input-${name}-${optionValue}`}>{optionLabel}</label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default FormMultiCheckbox;

FormMultiCheckbox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.any.isRequired
};