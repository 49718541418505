import React, { useContext } from "react";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";
import { Store } from "./Store";

const amountClass = (amount) => {
  if (amount == 0)
    return "text-secondary";
  else if (amount < 0)
    return "text-danger";
  else
    return "text-success";
};

export default function TransactionsList(props) {
  const { state: { transactions }, dispatch } = useContext(Store);

  const sourcePath = (sourceId, sourceType) => {
    switch (sourceType) {
      case "Payments::ManualOperation":
        return <a href={`/billing-accounts/${TRANSACTIONS_DATA.account.id}/manual-operations/${sourceId}`}>{`Manual Operation #${sourceId}`}</a>;
        break;
      case "Payout":
        return `Payout #${sourceId}`;
        break;
      case "PurchaseOrder":
        return <a href={`/purchase-orders/${sourceId}`}>{`Purchase Order #${sourceId}`}</a>;
        break;
      case "TrainingCredit":
        return `Training Credit #${sourceId}`;
        break;
      case "Withdrawal":
        return <a href={`/withdrawals/${sourceId}`}>{`Withdrawal# ${sourceId}`}</a>;
        break;
    }
  };

  return (
    <tbody>
      {transactions.map((transaction) =>
        <tr key={`transaction-${transaction.id}`}>
          <td><a href={`/transactions/${transaction.id}`}>{transaction.id}</a></td>
          <td>{transaction.type}</td>
          <td><PrettyLocalDateTime date={transaction.createdAt} /></td>
          <td className={amountClass(transaction.amount)}>{transaction.amount}</td>
          <td>{transaction.currency}</td>
          <td>{transaction.sourceId ? sourcePath(transaction.sourceId, transaction.sourceType) : "N/A"}</td>
          <td>{transaction.invoiceable ? transaction.invoiceId : "N/A"}</td>
        </tr>
      )}
    </tbody>
  );
}
