import { takeLatest, call, all, put } from "redux-saga/effects";
import { get } from "../_utils/ajax";

import { REQUEST_TRANSLATORS_PAGE, updateTranslators, updatePage, updateOrder, updateTotalPages } from "./_actions";

function* getTranslatorsPage({page, order, url}){
  yield put(updatePage(page));
  yield put(updateOrder(order));
  let response = yield call(get, `${url}?page=${page}&order=${order.order}&type=${order.type}`);
  let {translators, totalPages} = yield call([response, "json"]);
  yield put(updateTranslators(translators));
  yield put(updateTotalPages(totalPages));
}

function* watchRequests() {
  yield takeLatest(REQUEST_TRANSLATORS_PAGE, getTranslatorsPage);
}

// Root Saga
export default function* rootSaga() {
  yield all([
    watchRequests()
  ]);
}