import React from "react";
import { useSelector } from "react-redux";

import { useGetInvoicesQuery } from "./_api";
import { updateFilters, updatePage, regenerate } from "./_slices";
import {
  DataTable,
  PrettyCurrency,
  PrettyLocalDateTime,
} from "../_common/all";
import Paid from "./Paid";
import Actions from "./Actions";

export default function InvoicesData() {
  const { sortAttribute, sortOrder } = useSelector(s=>s.filters);

  const keys = [ "id", "amount", "netAmount", "taxAmount", "billingAccount", "clientAccount", "chamberOfCommerce", "createdAt", "issuedAt", "currency", "dueDate", "reminderSentAt", "reminderResentAt", "fromAddress", "toAddress", "paid", "poCode", "taxId", "actions" ],
        hiddenKeys = [ "chamberOfCommerce", "taxId", "fromAddress", "toAddress", "currency", "taxAmount", "billingAccount", ],
        sortableKeys = [
          "amount",
          "chamber_of_commerce",
          "created_at",
          "currency",
          "due_date",
          "from_address",
          "id",
          "net_amount",
          "paid",
          "po_code",
          "tax_amount",
          "tax_id",
          "to_address",
          "updated_at",
          "reminder_sent_at",
          "reminder_resent_at"
        ];

  const labels = (label) =>{
    return {
      id:                "ID",
      amount:            "Amount",
      netAmount:         "Net amount",
      taxAmount:         "Tax",
      billingAccount:    "Billing account",
      clientAccount:     "Client account",
      chamberOfCommerce: "Chamber of commerce",
      createdAt:         "Created at",
      issuedAt:          "Issued at",
      currency:          "Currency",
      dueDate:           "Due date",
      fromAddress:       "From address",
      paid:              "Paid",
      poCode:            "PO Code",
      taxId:             "Tax ID",
      toAddress:         "To address",
      actions:           "Actions",
      reminderSentAt:    "Reminder sent at",
      reminderResentAt:  "Reminder resent at"
    }[label];
  };

  const components = {
    id:               ({id}) => <a href={`/invoices/${id}.pdf`} target="_blank" rel="noreferrer">#{id}</a>,
    amount:           PrettyCurrency,
    netAmount:        ({netAmount: amount, currency}) => <PrettyCurrency {...{amount,currency}} />,
    taxAmount:        ({taxAmount: amount, currency}) => <PrettyCurrency {...{amount,currency}} />,
    createdAt:        ({ createdAt }) => <PrettyLocalDateTime date={createdAt} />,
    issuedAt:         ({ issuedAt }) => <PrettyLocalDateTime date={issuedAt} />,
    dueDate:          ({ dueDate }) => <PrettyLocalDateTime date={dueDate} hideTime={true} />,
    reminderSentAt:   ({reminderSentAt}) => <PrettyLocalDateTime date={reminderSentAt} />,
    reminderResentAt: ({reminderResentAt}) => <PrettyLocalDateTime date={reminderResentAt} />,
    paid:             Paid,
    clientAccount:    ({billingAccount: {clientAccount}}) => clientAccount ? <a href={`/clients/${clientAccount.id}`}>{clientAccount.name}</a> : "N/A",
    actions:          Actions,
  };

  return (
    <div className="card">
      <div className="card-body">
        <DataTable
          {...{
            name:          "invoices",
            keys,
            getQuery:      useGetInvoicesQuery,
            updateFilters,
            updatePage,
            components,
            sortableKeys,
            sortAttribute,
            sortOrder,
            hiddenKeys,
            labels,
            filterUrlSync: true,
          }}
        />
      </div>
    </div>
  );
}
