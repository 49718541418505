import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import reducers from "./_reducers";
import rootSaga from "./_sagas";
import Population from "./Population";

let url = document.location.href + "/population";
let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let sagaMiddleware = createSagaMiddleware();

let store = createStore(
  reducers,
  {staticData: {url} },
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <Population />
  </Provider>,
  document.getElementById("population"),
);
