// Props summary
// title: What the header containing the count is going to say
// count: count of selected items
// buttonCopy: copy of the button
// callback: what happens on button click

import React from "react";

import FloatingCornerPopup from "./FloatingCornerPopup";

export class FloatingSelectorPopupOpener extends React.Component {
  render() {
    const {title, buttonCopy, callback, count} = this.props;

    return (
      <FloatingCornerPopup hidden={count==0}>
        <h3>{title}: <span className="text-muted mr-2 ml-2">{count}</span></h3>
        <button
          className="btn btn-primary float-right m-2"
          onClick={callback}
        >
          {buttonCopy}
        </button>
      </FloatingCornerPopup>
    );
  }
}

export default FloatingSelectorPopupOpener;
