export const isPotentiallyMergeable = (phrase) => /^[a-z]/.test(phrase);

export const areCoordsWithinBoundaries = ({ x, y }, { start, end }) => {
  // if no selection
  if (!start) return false;

  // if only the start of the range is selected or is start occurrence
  if (start.x == x && start.y == y) return true;

  // if previous is false and boundaries not closed
  if (!end) return false;

  // if inside the boundaries
  if (start.x <= x && start.y <= y && end.x >= x && end.y >= y) return true;

  return false;
}