import { gql } from "graphql-request";

export const getUserQuery = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      email
      gravatarEmailHash
      name
      firstName
      lastName
      confirmedAt
      unconfirmedEmail
      maxLoginAttemptsReached
    }
  }
`;

export const userConfirmEmailMutation = gql`
  mutation userConfirmEmail($input: UserConfirmEmailInput!) {
    userConfirmEmail(input: $input) {
      success
      errors
      user {
        confirmedAt,
        email,
        unconfirmedEmail,
      }
    }
  }
`;

export const userDeleteMutation = gql`
  mutation userDelete($input: UserDeleteInput!) {
    userDelete(input: $input) {
      success
      errors
    }
  }
`;

export const userResetOtpLimitMutation = gql`
  mutation userResetOtpLimit($input: UserResetOtpLimitInput!) {
    userResetOtpLimit(input: $input) {
      success
      errors
    }
  }
`;

export const userResetPasswordMutation = gql`
  mutation userResetPassword($input: UserResetPasswordInput!) {
    userResetPassword(input: $input) {
      success
      errors
      password
    }
  }
`;

export const userUpdateMutation = gql`
  mutation userUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      success
      errors
    }
  }
`;
