import PropTypes from "prop-types";
import React from "react";

export class TopPagination extends React.Component {
  changePageAndSize = (page, size, e) => {
    e.preventDefault();
    this.props.changePageAndSize(page, size);
  }

  render() {
    let { totalPages, page, size } = this.props;

    return (
      <div className="d-flex justify-content-between mb-3">
        <form className="form-inline">
          <select
            className="form-control mb-2 mr-sm-2 mb-sm-0" id="inlineFormCustomSelect"
            value={size}
            onChange={e => this.changePageAndSize(1, parseInt(e.target.value), e)}
          >
            <option value="10">Show 10 items per page</option>
            <option value="25">Show 25 items per page</option>
            <option value="50">Show 50 items per page</option>
            <option value="100">Show 100 items per page</option>
          </select>
        </form>

        <ul className="nav">
          <li className="nav-item"><span className="nav-link">Page {page} of {totalPages}</span></li>
          <li className="nav-item">
            {page <= 1 ?
              <span className="nav-link ticon-left text-muted"></span>
              :
              <a className="nav-link ticon-left" rel="prev" href="#" onClick={(e) => this.changePageAndSize(page - 1, size, e)} ></a>
            }
          </li>
          <li className="nav-item">
            {page >= totalPages ?
              <span className="nav-link ticon-right text-muted"></span>
              :
              <a className="nav-link ticon-right" rel="next" href="#" onClick={(e) => this.changePageAndSize(page + 1, size, e)}></a>
            }
          </li>
        </ul>
      </div>
    );
  }
}

export default TopPagination;

TopPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  changePageAndSize: PropTypes.func.isRequired,
};