export const OPEN_DEADLINE_POPUP = "OPEN_DEADLINE_POPUP";
export const CLOSE_DEADLINE_POPUP = "CLOSE_DEADLINE_POPUP";
export const OPEN_CONFIRM_READY_POPUP = "OPEN_CONFIRM_READY_POPUP";
export const CLOSE_CONFIRM_READY_POPUP = "CLOSE_CONFIRM_READY_POPUP";
export const OPEN_SET_AS_REVIEWED = "OPEN_SET_AS_REVIEWED";
export const CLOSE_SET_AS_REVIEWED = "CLOSE_SET_AS_REVIEWED";

export const openDeadlinePopup = () => ({
  type: OPEN_DEADLINE_POPUP
});

export const closeDeadlinePopup = () => ({
  type: CLOSE_DEADLINE_POPUP
});

export const openConfirmReadyPopup = () => ({ type: OPEN_CONFIRM_READY_POPUP });

export const closeConfirmReadyPopup = () => ({ type: CLOSE_CONFIRM_READY_POPUP });

export const openSetAsReviewed = () => ({ type: OPEN_SET_AS_REVIEWED });

export const closeSetAsReviewed = () => ({ type: CLOSE_SET_AS_REVIEWED });