export const KEYCODES = {
  ctrlAltP: "ctrlAltP",
  altf1: "altf1",
  altf2: "altf2",
  altf3: "altf3",
  enter: "enter",
  up: "up",
  down: "down",
  shiftf1: "shiftf1",
  shiftf2: "shiftf2",
  tab: "tab",
};

export const getKeyCodeName = function(e) {
  let code = e.keyCode || e.which;

  // Ctrl + Alt
  if (code == 80  && e.altKey && e.ctrlKey)   return KEYCODES.ctrlAltP;
  // Alt
  if (code == 112 && e.altKey)                return KEYCODES.altf1;
  if (code == 113 && e.altKey)                return KEYCODES.altf2;
  if (code == 114 && e.altKey)                return KEYCODES.altf3;
  // Shift
  if (code == 112 && e.shiftKey)              return KEYCODES.shiftf1;
  if (code == 113 && e.shiftKey)              return KEYCODES.shiftf2;
  // Single code
  if (code == 13)                             return KEYCODES.enter;
  if (code == 38)                             return KEYCODES.up;
  if (code == 40)                             return KEYCODES.down;
  if (code == 9)                              return KEYCODES.tab;
};