import React from "react";
import { useSelector } from "react-redux";

import { ordersSelector } from "./_selectors";
import { purchaseOrderSelector } from "./_reducers";
import CancelOrder from "./CancelOrder";

export default function Orders() {
  const orders = useSelector(ordersSelector);

  if (!orders) return null;

  const { sourceLanguageCode, currency } = useSelector(purchaseOrderSelector);

  return (
    <div className="col-12">
      <h2 className="mt-4 mb-3">Orders <span className="text-muted">({orders.length})</span></h2>
      <div className="card">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Workflow state</th>
                <th>Language pair</th>
                <th>Quality</th>
                <th>Price</th>
                <th>Credit card Payment</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order)=> {
                const {
                  id,
                  targetLanguageCode,
                  quality,
                  price,
                  workflowState,
                  payableAmount,
                } = order;

                return (
                  <tr key={id}>
                    <td>
                      <strong> <a href={`/orders/${id}`}>#{id}</a> </strong>
                    </td>
                    <td
                      className={["payment_failed", "canceled"].includes(workflowState) ? "text-danger" : "" }
                    >
                      {workflowState}
                    </td>
                    <td>
                      {I18n.t(`languages.${sourceLanguageCode}`)} - {I18n.t(`languages.${targetLanguageCode}`)}
                    </td>
                    <td>{quality}</td>
                    <td>
                      {price.toLocaleString(navigator.language, { currency, style: "currency" })}
                    </td>
                    <td>
                      {payableAmount ?
                        payableAmount.toLocaleString(navigator.language, { currency, style: "currency" })
                        :
                        <span className="text-muted">N/A</span>
                      }
                    </td>
                    <td>
                      <CancelOrder order={order} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
