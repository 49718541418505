import React, { useState } from "react";
import { useSelector } from "react-redux";
import gqlClient from "../_utils/graphQlRequests";

import { userResetPasswordMutation } from "./_gql";
import { userSelector } from "./_reducers";

export default function ResetPassword() {
  const user = useSelector(userSelector);
  const [password, setPassword] = useState(null);
  const [errors, setErrors] = useState([]);

  const resetPassword = () => {
    if (confirm(`Are you sure you want to reset the password for ${user.email} from the database?`)) {
      gqlClient.request(userResetPasswordMutation, { input: { userId: user.id } })
        .then(({userResetPassword: {password, errors}})=>{
          setPassword(password);
          setErrors(errors);
        });
    }
  };

  return (
    <div className="row mb-4">
      <div className="col">
        <div className="alert alert-danger">
          <a
            className="text-error btn btn-danger pull-right mt-4"
            onClick={resetPassword}
          >
            reset password
          </a>

          <h3 className="alert-heading">Reset password</h3>

          <p>Use the button to reset the password. The new password will appear on this page.</p>
        </div>

        {password &&
          <div className="alert alert-info">
            The new password is: <strong>{password}</strong>
          </div>
        }

        {errors.length > 0 &&
          <div className="alert alert-warning">
            <h5>There were some errors resetting the password:</h5>
            <ul>
              {errors.map( error => <li key={error}>{error}</li>)}
            </ul>
          </div>
        }
      </div>
    </div>
  );
}