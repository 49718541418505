import React from "react";

export class Card extends React.Component {
  render() {
    let { className, children, h100 } = this.props;

    return (
      <div className={className}>
        <div className={`card ${h100 ? "h-100" : ""}`}>
          <div className="card-body">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
