import React from "react";

import Sidebar from "./Sidebar";
import Results from "./Results";

export class LanguagePairs extends React.Component {
  render() {
    return (
      <div>
        <div className="row mt-4">
          <div className="col d-flex justify-content-between">
            <div className="">
              <h1>Language pairs</h1>
            </div>
            <div className="">
              <a href="/training-texts/new" className="btn btn-primary btn-lg">Create Training Text</a>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <Sidebar />
          <Results />
        </div>
      </div>
    );
  }
}

export default LanguagePairs;
