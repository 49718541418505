import React from "react";

export class TaskGrouping extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="nav-item ">
          <small className="">Task grouping:</small>
        </div>

        {[1, 2, 5, 10, 20, 50].map(size => (
          <li className="nav-item ml-1" key={`size-${size}`}>
            <a
              href="#"
              className={`badge ${this.props.groupSize == size ? "badge-primary" : "badge-light"}`}
              onClick={e => {
                e.preventDefault();
                this.props.changeSize(size);
              }}
            >
              {size == 1 ? "None" : size}
            </a>
          </li>
        ))}
      </React.Fragment>
    );
  }
}

export default TaskGrouping;
