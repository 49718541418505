import React from "react";

function createChart(values, context) {
  let chart = new Chart(context, {
    type: "doughnut",
    data: valuesToData(values),
    options: {
      legend: { display: false },
    }
  });

  return chart;
}

function valuesToData(values) {
  return {
    labels: values.map(v => v.label),
    datasets: [{
      data: values.map(v => v.data),
      backgroundColor: values.map(v => v.color),
    }]
  };
}

export class PieChart extends React.Component {
  constructor(props){
    super(props);
    this.canvas = React.createRef();

    this.state = {
      excludedLabels: []
    };
  }

  componentDidMount() {
    let {values} = this.props;

    if (values.length) this.chart = createChart(values, this.canvas.current);
  }

  componentDidUpdate({ values: oldValues, excluded: oldExcluded }, { excludedLabels: oldExcludedLabels}) {
    let { values, excluded } = this.props,
        { excludedLabels } = this.state,
        shouldUpdateChart = oldValues != values || excludedLabels != oldExcludedLabels || excluded != oldExcluded,
        valuesToShow = values.filter( v => !excludedLabels.includes(v.label) );

    if (excluded) valuesToShow = values.filter(v => !excluded.includes(v.label));

    if (shouldUpdateChart && !this.chart) {
      this.chart = createChart(valuesToShow, this.canvas.current);
    } else if (shouldUpdateChart) {
      this.chart.data = valuesToData(valuesToShow);
      this.chart.update();
    }
  }

  componentWillUnmount(){
    if (this.chart) this.chart.destroy();
  }

  toggleLabel(label) {
    let { excludedLabels } = this.state;

    if (excludedLabels.includes(label)) {
      excludedLabels = excludedLabels.filter( l => l!=label);
    } else {
      excludedLabels = [...excludedLabels, label];
    }

    this.setState({excludedLabels});
  }

  render() {
    const { values, id, title, count} = this.props;
    const { excludedLabels } = this.state;

    return (
      <div className="chart__pie__card" id={id}>
        <div className="chart__pie__wrapper">
          <div className="chart__pie">
            <canvas ref={this.canvas} ></canvas>
          </div>
        </div>

        <div className="chart__pie__legends__wrapper">
          <div>
            <h6>
              {title}
              {count ? <small className="text-muted"> ({count.toLocaleString()})</small> : null}
            </h6>
            <ul className="chart__legends">
              {values.map(({ label, color, data }, index ) => (
                <li
                  className="chart__legend"
                  key={`chart-legend-${id}-${index}`}
                  onClick={()=>this.toggleLabel(label)}
                  style={{ textDecoration: excludedLabels.includes(label) ? "line-through" : "none" }}
                >
                  <div className="chart__legend__box" style={{backgroundColor: color}}></div>
                  {label}: <strong>{data}</strong>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default PieChart;
