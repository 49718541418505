import React, { useCallback } from "react";

export default function LanguageField(props) {
  return (
    <select
      className={props.invalid ? "form-control is-invalid" : "form-control"}
      name={props.name}
      value={props.value}
      onChange={(e) => props.onChange({ [e.target.name]: e.target.value })}
    >
      <option value=""></option>
      {props.options.map(option => {
        const [optionLabel, optionValue] = option;
        return <option value={optionValue} key={`${name}-option-${optionValue}`}>{optionLabel}</option>;
      })}
    </select>
  );
}
