import { ClientError } from "graphql-request";
import gqlClient from "../_utils/graphQlRequests";

export const graphqlBaseQuery = () => async ({ body, input }) => {
  try {
    const data = await gqlClient.request(body, input);
    return { data };
  } catch (error) {
    if (error instanceof ClientError) {
      return { error: { status: error.response.status, data: error.response.errors } };
    }
    return { error: { status: 500, data: error } };
  }
};

export default graphqlBaseQuery;