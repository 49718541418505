import React from "react";
import { connect } from "react-redux";

import { requestFiltersUpdateAndResetPage } from "../_common/filtered_lists/_actions";

export class LanguagePairSelector extends React.Component {
  changeLanguagePair(sourceCode, targetCode) {
    this.props.requestFiltersUpdateAndResetPage({languagePair: `${sourceCode}-${targetCode}`});
  }

  render() {
    const {
      activeSourceLanguages,
      activeTargetLanguages,
      inactiveSourceLanguages,
      inactiveTargetLanguages,
      selectedSourceLanguageCode,
      selectedTargetLanguageCode
    } = this.props;

    return (
      <div className="form-group">
        <label htmlFor="languagePair">Language pair</label>
        <div className="container-fluid">
          <div className="row">
            <select
              name="sourceLanguage"
              className="col form-control"
              value={selectedSourceLanguageCode}
              onChange={ e => this.changeLanguagePair(e.target.value, selectedTargetLanguageCode) }
            >
              <option value="any">Any</option>
              <option disabled>Client's source languages</option>
              <option disabled>-----------------------------------</option>
              {activeSourceLanguages.map(([name, code]) => (
                <option key={`source-language-filter-${code}`} value={code}>{name}</option>
              ))}
              <option disabled></option>
              <option value="" disabled>Other languages</option>
              <option disabled>-----------------------------------</option>
              {inactiveSourceLanguages.map(([name, code]) => (
                <option key={`source-language-filter-${code}`} value={code}>{name}</option>
              ))}
            </select>

            <select
              name="targetLanguage"
              className="col form-control"
              value={selectedTargetLanguageCode}
              onChange={e => this.changeLanguagePair(selectedSourceLanguageCode, e.target.value) }
            >
              <option value="any">Any</option>
              <option disabled>Client's target languages</option>
              <option disabled>-----------------------------------</option>
              {activeTargetLanguages.map(([name, code]) => (
                <option key={`source-language-filter-${code}`} value={code}>{name}</option>
              ))}
              <option disabled></option>
              <option value="" disabled>Other languages</option>
              <option disabled>-----------------------------------</option>
              {inactiveTargetLanguages.map(([name, code]) => (
                <option key={`source-language-filter-${code}`} value={code}>{name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

const nonActiveCode = (code, languages) => {
  return !languages.find( ([,currentCode]) => currentCode == code );
};

export default connect(
  ({ staticData: { allHumanLanguages, activeSourceLanguages, activeTargetLanguages }, filters: {languagePair} }) => {
    let inactiveSourceLanguages = allHumanLanguages.filter( ([,code]) => nonActiveCode(code, activeSourceLanguages)  ),
        inactiveTargetLanguages = allHumanLanguages.filter(([, code]) => nonActiveCode(code, activeTargetLanguages));

    return {
      activeSourceLanguages,
      activeTargetLanguages,
      inactiveSourceLanguages,
      inactiveTargetLanguages,
      selectedSourceLanguageCode: languagePair.split("-")[0],
      selectedTargetLanguageCode: languagePair.split("-")[1]
    };
  },
  { requestFiltersUpdateAndResetPage }
)(LanguagePairSelector);
