import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientLanguagePairPricings, changeClientLanguagePairPricingsScope } from "./_actions";

import LanguageField from "./LanguageField";

export default function Search({ sourceLanguageCodes, targetLanguageCodes }) {
  const dispatch = useDispatch()

  const { sourceLanguageCode, targetLanguageCode } = useSelector(state => state.clientLanguagePairPricingsScope);

  const setSourceLanguageCode = (sourceLanguageCode) => dispatch(changeClientLanguagePairPricingsScope({ sourceLanguageCode, }))
  const setTargetLanguageCode = (targetLanguageCode) => dispatch(changeClientLanguagePairPricingsScope({ targetLanguageCode, }))

  const handleSubmit = () => {
    dispatch(getClientLanguagePairPricings(document.location.pathname));
  }

  return (
    <div className="row">
      <div className="col">
        <h5>Search custom pricings:</h5>
      </div>
      <div className="col">
        <LanguageField
          name="sourceLanguageCode"
          value={sourceLanguageCode}
          options={sourceLanguageCodes}
          onChange={(e) => setSourceLanguageCode(e.sourceLanguageCode)}
        />
      </div>
      <div className="col">
        <LanguageField
          name="targetLanguageCode"
          value={targetLanguageCode}
          options={targetLanguageCodes}
          onChange={(e) => setTargetLanguageCode(e.targetLanguageCode)}
        />
      </div>
      <div className="col">
        <input
          type="submit"
          name="commit"
          value="Search"
          className="btn btn-primary"
          data-disable-with="Create"
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}
