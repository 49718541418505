import React from "react";
import { connect } from "react-redux";
import PieChart from "./PieChart";

const colors = {
  localization: "#4A90E2",
  expert: "#2ECC71",
  translation: "#FF9119",
  postediting: "#4AE2D5",
};

export class QualityDistributionChart extends React.Component {
  render() {
    const { qualityDistribution, tasksCount } = this.props;

    return (
      <PieChart
        id="tasks"
        title="Task quality distribution"
        count={tasksCount}
        values={Object.keys(qualityDistribution).map(key => ({
          label: key,
          data: qualityDistribution[key],
          color: colors[key]
        }))}
      />
    );
  }
}

export default connect(
  ({ staticData: {qualityDistribution, tasksCount } }) => ({ qualityDistribution, tasksCount  })
)(QualityDistributionChart);
