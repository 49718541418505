import React, { useContext } from "react";
import { Store } from './Store';
import TableSortableHeader from "../_common/TableSortableHeader";
import { updateFilters } from "./_actions";

const headers = [
  { attribute: "id",         sortable: true, label: "ID" },
  { attribute: "type",       sortable: true },
  { attribute: "created_at", sortable: true, label: "Created At" },
  { attribute: "amount",     sortable: true },
  { attribute: "currency",   sortable: true },
  { attribute: "source",     sortable: true },
  { attribute: "invoice_id", sortable: true, label: "Invoice ID" },
];

export default function Headers(props) {
  const {
    state: {
      filters: { sortAttribute, sortOrder }
    },
    dispatch
  } = useContext(Store);

  const requestResults = (sortOrder, sortAttribute) =>
    updateFilters(dispatch, { page: 1, sortOrder, sortAttribute });

  return (
    <TableSortableHeader  {...{ sortAttribute, sortOrder, headers, requestResults }} />
  );
}
