import React from "react";
import { connect } from "react-redux";

import { selectedResultsHashSelector } from "../_common/filtered_lists/_selectors";
import { toggleResultSelection } from "../_common/filtered_lists/_actions";
import { openEmailPopup, updateTranslatorToEmailTo } from "./_actions";

export class TranslatorsList extends React.Component {
  openEmailPopup(e, id) {
    e.preventDefault();
    this.props.updateTranslatorToEmailTo(id);
    this.props.openEmailPopup();
  }

  render() {
    const { translators, toggleResultSelection, selectedResultsHash } = this.props;

    return (
      <table className="table">
        <tbody>
          {translators.map(({
            id,
            fullName,
            email,
            createdAt,
            nativeLanguageCode,
            activityName,
            languagePairs,
          }) => {
            return (
              <tr key={`translator-row-${id}`}>
                <td>
                  <input type="checkbox" checked={selectedResultsHash[id] || false} onChange={() => toggleResultSelection(id)} />
                </td>
                <td>
                  <p className="table__title m-0">
                    <a href={`/translators/${id}`}>
                      <strong className="r2-2">{fullName} </strong> {email}
                    </a>
                  </p>
                  <dl className="inline-dl mb-2">
                    <dt>Id</dt>
                    <dd>{id}</dd>
                    <dt>Created at:</dt>
                    <dd>{createdAt}</dd>
                    <dd>Native:</dd>
                    <dt>{nativeLanguageCode ? I18n.t(`languages.${nativeLanguageCode}`) : "Unknown" }</dt>
                    <dd>Activity:</dd>
                    <dd>{activityName}</dd>
                  </dl>

                  <div className="d-flex flex-wrap">
                    {languagePairs.map(({
                      name,
                      qualityLevel,
                      training,
                      whitelistCount,
                      translatedTasksCount,
                      proofreadTasksCount,
                      isJudge
                    })=>{
                      return (
                        <div className="translator__language_pair" key={`translator-${id}-${name.replace(/\W/g, "-")}`}>
                          <dl className="inline-dl mb-0">
                            <dt>{name}:</dt>
                            <dd>{qualityLevel}</dd>
                          </dl>
                          <ul className="inline p-0 text-small">
                            <li>
                              { training ? <span className="text-muted">Not trained</span> : "Trained" }
                            </li>
                            <li>
                              {whitelistCount > 0 ? <span>Whitelist count: {whitelistCount}</span> : null}
                              {whitelistCount == 0 && !training ? <span className="text-danger">Not whitelisted</span> : null}
                            </li>
                            {translatedTasksCount > 0 ?
                              <li>Translated tasks: {translatedTasksCount}</li>
                              : null
                            }
                            {proofreadTasksCount > 0 ?
                              <li>Translated tasks: {proofreadTasksCount}</li>
                              : null
                            }
                            {isJudge ? <li>Judge</li> : null}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td>
                  <a href="#" className="table__actionable ticon-email" onClick={e => this.openEmailPopup(e, id)}>
                    <span className="table__actionable__label">Email</span>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default connect(
  ({ translators, selectedResults }) => ({
    translators,
    selectedResultsHash: selectedResultsHashSelector({ selectedResults })
  }),
  { toggleResultSelection, openEmailPopup, updateTranslatorToEmailTo }
)(TranslatorsList);
