import React, { useState } from "react";
import { useSendInvoiceAllMutation } from "./_api";
import FormPopup from "../_common/FormPopup";

const ConfirmSendAllInvoicesPopup = ({ isVisible, setIsVisible, onSendAllInvoices }) => {
  if (!isVisible) return (<></>)

  return (
    <FormPopup>
      <h3>Are you sure you want to send this months invoices to customers?</h3>
      <button className="btn btn-primary" tyep="submit" onClick={() => onSendAllInvoices()} >Yes</button>
      <button className="btn btn-cancel" tyep="submit" onClick={() => setIsVisible(false)}>Cancel</button>
    </FormPopup>
  )
}

const BulkActions = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [sendInvoices, { isLoading, error}] = useSendInvoiceAllMutation()


  const onSendAllInvoices = () => {
    sendInvoices().then(() => setIsVisible(false));
  }

  return (
    <>
      <h5>Bulk Actions</h5>
      <hr />
      <button className="btn btn-primary" tyep="submit" disabled={false} onClick={() => setIsVisible(true)}>
        Send all invoices
      </button>

      {/* Popup elements */}
      <ConfirmSendAllInvoicesPopup isVisible={isVisible} setIsVisible={setIsVisible} onSendAllInvoices={onSendAllInvoices} />
    </>
  )
}

export default BulkActions;
