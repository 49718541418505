// Props summary
// Select input with optional label
// label (optional): Label that would appear over the input
// name: input name and input id
// onChange: callback to call on change
// value: current selected value
// className: name of the class used in the Select
// multiple: enable multiple selection
// wrapperClassName: name of the class used in the wrapper
// includeBlank (optional): adds an empty option with an empty string value
// blankOptionLabel (optional): copy to be shown inside the blank option, it will be blank otherwise
// options: array of options with 2 possible formats:
//    [optionValue]: values and labels are the same
//    [[optionLabel, optionValue]]: what is shown (label) is different to waht is handled/sent

import PropTypes from "prop-types";
import React from "react";

import SimpleSelect from "./SimpleSelect";

export class FormSelect extends React.Component {
  render() {
    const { label, options, name, onChange, value, className, wrapperClassName, multiple, includeBlank, blankOptionLabel } = this.props;

    return (
      <div className={wrapperClassName || "form-group"}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        <SimpleSelect {...{ options, name, onChange, value, className, multiple, includeBlank, blankOptionLabel }} />
      </div>
    );
  }
}

export default FormSelect;

FormSelect.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};