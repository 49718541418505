import React from "react";

export class InactiveFor extends React.Component {
  render() {
    const { any, timeAgoInWords, waitingTooLong } = this.props;

    if (any) return <span className={waitingTooLong ? "text-danger" : ""}>{timeAgoInWords}</span>;
    return <span>N/A</span>
  }
}

export default InactiveFor;