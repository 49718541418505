import React from "react";
import { connect } from "react-redux";

import { requestTranslatorsPage } from "./_actions";
import Pagination from "../_common/pagination/Pagination";
import TableSortableHeader from "../_common/TableSortableHeader";
import Translator from "./Translator";

export class Population extends React.Component {
  componentDidMount(){
    const { order, requestTranslatorsPage } = this.props;
    requestTranslatorsPage(1, order);
  }

  render() {
    const { translators, requestTranslatorsPage, totalPages, page, order } = this.props,
          { order: sortOrder, type: sortAttribute} = order,
          headers = [
            {sortable: true, attribute: "email"},
            {sortable: true, attribute: "registration"},
            {sortable: false, attribute: "tasks"},
            {sortable: true, attribute: "quality"},
            {sortable: true, attribute: "activity"},
            {sortable: true, attribute: "evaluation"},
            {sortable: true, attribute: "judge"}
          ],
          requestResults = (order, type) => requestTranslatorsPage(1, { order, type });

    return (
      <React.Fragment>
        <table className="table table-sm">
          <TableSortableHeader {...{ sortAttribute, sortOrder, headers, requestResults}} />
          <tbody>
            {translators.map(translator => (
              <Translator key={`translator-${translator.id}`} translator={translator} />
            ))}
          </tbody>
        </table>

        {totalPages > 1 ?
          <Pagination
            className="pagination-sm justify-content-end"
            totalPages={totalPages}
            page={page}
            changePage={newPage => requestTranslatorsPage(newPage, order)}
          />
          : null
        }
      </React.Fragment>
    );
  }
}

export default connect(
  ({ translators, totalPages, page, order }) => ({ translators, totalPages, page, order }),
  { requestTranslatorsPage }
)(Population);
