import { combineReducers } from "redux";

import { UPDATE_RESULTS } from "../_common/filtered_lists/_actions";
import {
  totalCount,
  totalPages,
  filters,
  results,
  loading,
  staticData,
  allResultsSelected,
  selectedResults,
  messages,
} from "../_common/filtered_lists/_reducers";

import {
  OPEN_PROJECT_NAME_POPUP,
  OPEN_DEADLINE_POPUP,
  CLOSE_TASKS_POPUP,
  UPDATE_SELECTED_TASK,
} from "./_actions";

export const wordCount = (state = null, action) => {
  switch (action.type) {
    case UPDATE_RESULTS:
      return action.otherChanges.wordCount;
    default:
      return state;
  }
};

export const deadlinePopupOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_DEADLINE_POPUP:
      return true;
    case CLOSE_TASKS_POPUP:
      return false;
    default:
      return state;
  }
};

export const projectNamePopupOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_PROJECT_NAME_POPUP:
      return true;
    case CLOSE_TASKS_POPUP:
      return false;
    default:
      return state;
  }
};

export const selectedTask = (state = null, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_TASK:
      return action.selectedTask;
    case CLOSE_TASKS_POPUP:
      return null;
    default:
      return state;
  }
};


export default combineReducers({
  totalCount,
  totalPages,
  filters,
  tasks: results,
  wordCount,
  loading,
  staticData,
  allResultsSelected,
  selectedResults,
  messages,
  deadlinePopupOpen,
  projectNamePopupOpen,
  selectedTask,
});