// Uses iso8601 string dates on value

// Props summary
// id: MANDATORY Id of the calendar
// label: optional
// callback: function to execute on day selection
// value: selected input value

import React from "react";
import Calendar from "react-calendar";
import moment from "moment";

import SimpleSelect from "./SimpleSelect";
import { onClickOutside } from "../_utils/customClickEvents";

export class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    const { date } = props;

    this.state = {
      showCalendar: false,
      hours: date ? moment(date).format("HH") : "00",
      minutes: date ? moment(date).format("mm") : "00",
    };
  }

  componentDidMount() {
    this.onClickOutsideCalendar = onClickOutside(`#${this.props.id}`, () => this.hideCalendar());
  }

  componentWillUnmount() {
    this.onClickOutsideCalendar.stop();
  }

  showCalendar() {
    this.setState({ showCalendar: true });
  }

  hideCalendar() {
    this.setState({ showCalendar: false });
  }

  selectDate(date) {
    const { hours, minutes } = this.state;

    this.props.callback(moment(date).hours(hours).minutes(minutes).format());
    this.hideCalendar();
  }

  selectHours(hours){
    const {date, callback} = this.props;

    if (date) callback( moment(date).hours(hours).format() );

    this.setState({hours});
  }

  selectMinutes(minutes) {
    const {date, callback} = this.props;

    if (date) callback( moment(date).minutes(minutes).format() );

    this.setState({minutes});
  }

  render() {
    const { id, label, date, minDate } = this.props,
          { showCalendar, hours, minutes } = this.state;

    return (
      <div id={id}>
        <div className="form-group">
          {label ? <label htmlFor="exampleInputEmail1">{label}</label> : null}

          <div className="form-inline">
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <input
                type="text"
                className="form-control"
                placeholder="Select your date"
                name="search"
                value={date ? moment(date).format("LL") : ""}
                readOnly={true}
                onFocus={() => this.showCalendar()}
                autoComplete="off"
              />
              <div className="input-group-append">
                <span className="input-group-text ticon-calendar" onClick={() => this.showCalendar()} ></span>
              </div>
            </div>

            <SimpleSelect
              className="form-control"
              name="sourceLanguageCode"
              onChange={e => this.selectHours(e.target.value)}
              value={hours}
              options={["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]}
            />
            <span className="m-1"> : </span>
            <SimpleSelect
              className="form-control"
              name="targetLanguageCode"
              onChange={e => this.selectMinutes(e.target.value)}
              value={minutes}
              options={["00", "10", "20", "30", "40", "50"]}
            />

          </div>
        </div>
        {showCalendar ?
          <Calendar
            className="calendar"
            activeStartDate={date ? new Date(date) : null}
            value={date ? new Date(date) : null}
            onClickDay={(date) => this.selectDate(date)}
            minDate={minDate}
          />
          : null
        }
      </div>
    );
  }
}

export default DateTimePicker;
