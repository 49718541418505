import React from "react";

import { Loader } from "../all";
import TComponent from "./TComponent";

export default function TBody({
  items,
  keys,
  components,
  Actions,
  children,
  isLoading,
  error
}) {
  if (!keys) return null;

  if (error) {
    console.error("Unexpected error in TBody", error);
    return (
      <tbody>
        <tr>
          <td
            colSpan={keys.length}
            className="text-center p-5"
          >
            <p className="text-danger">
              <span className="ticon-warning" /> Unexpected error
            </p>
            {Array.isArray(error?.data) && (
              <ul>
                {error.data.map(({ message }, i) => (<li key={i}>{message}</li>))}
              </ul>
            )}
          </td>
        </tr>
      </tbody>
    );
  }
  
  if (!items) return (
    <tbody>
      <tr>
        <td
          colSpan={keys.length}
          className="text-center p-5"
        >
          <div className="loader_wrapper">
            <Loader />
          </div>
          <p className="text-center text-muted">
            Loading ...
          </p>
        </td>
      </tr>
    </tbody>
  );

  return (
    <tbody className="loader_ellipsis__container">
      {isLoading &&
        <tr>
          <td
            colSpan={keys.length}
            className="p-5 loader_ellipsis__background"
          >
            <Loader />
          </td>
        </tr>
      }
      {items.map((item) => (
        <tr key={`tbody-tr-${item.id}`}>
          {keys.map((key) => <TComponent key={key} componentFn={components[key]} attribute={item[key]} item={item} />)}
          {Actions && <Actions {...item} />}
        </tr>
      ))}
      {children}
    </tbody>
  );
}