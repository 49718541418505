import CurrencyList from 'currency-list'

const currencyConfig = Object.values(CurrencyList.getAll('en_US')).map(c => c.name);

export const formFields = [
  { label: "Keep html intact", attribute: "keepHtmlIntact", type: "checkbox", },
  { label: "Context", attribute: "translationContext", type: "input", },
  { label: "Target audience", attribute: "targetAudience", type: "text", },
  { label: "Formality", attribute: "formality", type: "text", },
  { label: "Tone of voice", attribute: "toneOfVoice", type: "text", },
  { label: "Unit of measure", attribute: "unitOfMeasure", type: "text", },
  { label: "Punctuation", attribute: "punctuation", type: "text", },
  { label: "Hyphens and quotes", attribute: "hyphensAndQuotes", type: "text", },
  { label: "Currency", attribute: "currencyString", type: "select", options: currencyConfig},
  { label: 'Do not translate brand name', attribute: 'properNamesTitles', type: "checkbox" }
];
