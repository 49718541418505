import React, { useState } from "react";

import SimpleSelect from "../_common/SimpleSelect";
import { useGetStaticDataQuery } from "./_api";

function symbolCurrency(currency) {
  if (!currency) return "N/A";

  const curr = currency.toUpperCase(),
        currencyMap = {
          "EUR": "€",
          "USD": "$",
        };

  if (["EUR", "USD"].includes(curr)) return currencyMap[curr];

  return curr;
}

function manualOperationTypes(billingAccount) {
  const clientAccount     = billingAccount?.clientAccount
  const translatorAccount = billingAccount?.translatorAccount

  if (translatorAccount) return [ "order", "bonus", "penalty" ]
  if (clientAccount?.paymentMethod === "credit_card") {
    return [ "order" ]
  } else {
    return [ "discount", "order" ]
  }
}

export default function ManualOperationForm({initialManualOperation, onSubmit, buttons, creating }){
  const {data: billingAccount} = useGetStaticDataQuery(),
        templateOptions = manualOperationTypes(billingAccount);

  const [ manualOperation, setManualOperation ] = useState(initialManualOperation || {
    title:       "",
    amount:      0,
    template:    templateOptions[0],
    description: ""
  });

  const { title, amount, template, description, } = manualOperation;

  const handleOnSubmit = e =>{
    e.preventDefault();
    if (creating) return onSubmit(manualOperation);
    onSubmit({title, description});
  };

  const setManualOperationValue = key => e => {
    e.preventDefault();

    let value = e.target.value;

    if (key == "amount") {
      value = parseFloat(value) || 0;
    }

    setManualOperation({...manualOperation, [key]: value });
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group">
        <label htmlFor="title">Title:</label>
        <input type="text" className="form-control" value={title} onChange={setManualOperationValue("title")} required />
      </div>

      {creating &&
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="amount">Amount:</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  value={amount}
                  onChange={setManualOperationValue("amount")}
                  min={0.01}
                  step={0.01}
                />
                <div className="input-group-append">
                  <strong className="input-group-text">{symbolCurrency(billingAccount?.currency)}</strong>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label htmlFor="template">Template:</label>
              <SimpleSelect
                name="template"
                options={templateOptions}
                value={template}
                onChange={setManualOperationValue("template")}
              />
            </div>
          </div>
        </div>
      }

      <div className="form-group">
        <label htmlFor="description">Description:</label>
        <textarea type="number" className="form-control" value={description} onChange={setManualOperationValue("description")} required />
      </div>

      {buttons || null}
    </form>
  );
}
