import React from "react";
import { connect } from "react-redux";

import { destroyQualityReview } from "./_actions";

export class RemoveQualityReview extends React.Component {
  deleteAfterConfirmation(id) {
    if (window.confirm(`Are you sure you want to delete quality review #${id} ?`)) {
      this.props.destroyQualityReview(id);
    }
  }

  render() {
    return (
      <button
        className="ticon-bin btn btn-sm btn-outline-danger pull-right"
        onClick={() => this.deleteAfterConfirmation(this.props.id)}
      />
    );
  }
}

export default connect(
  null,
  { destroyQualityReview }
)(RemoveQualityReview);
