import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducers from "./_reducers";
import QualityReviews from "./QualityReviews";
import { MODES } from "./_constants";

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const splitPathname = window.location.pathname.split("/"),
      section = splitPathname[1],
      id = splitPathname[2] || "",
      subSection = splitPathname[3],
      mode = MODES[subSection] || MODES[section] || MODES.tasks;

let translatorAccountId = "",
    taskId = "";

if (mode == MODES.tasks) taskId = id;
if ([MODES.emitted_quality_reviews, MODES.received_quality_reviews].includes(mode)) translatorAccountId = id;

let store = createStore(
  reducers,
  { mode, translatorAccountId, taskId },
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <QualityReviews />
  </Provider>,
  document.getElementById("quality-reviews"),
);
