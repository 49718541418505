import { combineReducers } from "redux";

import {
  UPDATE_QUALITY_REVIEWS,
  TOGGLE_CREATING_REVIEW_TASK,
  UPDATE_TRANSLATOR_ACCOUNT_ID,
  UPDATE_TASK_ID,
  ADD_QUALITY_REVIEW,
  REMOVE_QUALITY_REVIEW,
  UPDATE_ERRORS,
  UPDATE_PAGE,
  UPDATE_TOTAL_PAGES,
} from "./_actions";

export const translatorAccountId = (state = "", action) => {
  if (action.type == UPDATE_TRANSLATOR_ACCOUNT_ID) return action.translatorAccountId;
  return state;
};

export const taskId = (state = "", action) => {
  if (action.type == UPDATE_TASK_ID) return action.taskId;
  return state;
};

export const qualityReviews = (state =[], action) => {
  switch (action.type) {
    case UPDATE_QUALITY_REVIEWS:
      return action.qualityReviews;
    case ADD_QUALITY_REVIEW:
      return [...state, action.qualityReview];
    case REMOVE_QUALITY_REVIEW:
      return state.filter( q => q.id != action.id );
    default:
      return state;
  }
};

export const errors = (state = [], action) => {
  if (action.type == UPDATE_ERRORS) return action.errors;
  return state;
};

export const mode = (state = null, action) => {
  return state;
};

export const creatingReviewTask = (state = false, action) => {
  if (action.type == TOGGLE_CREATING_REVIEW_TASK) return !state;
  return state;
};

export const page = (state =1, action) => {
  if (action.type == UPDATE_PAGE) return action.page;
  return state;
};

export const totalPages = (state=1, action) => {
  if (action.type == UPDATE_TOTAL_PAGES) return action.totalPages;
  return state;
};

export default combineReducers({
  translatorAccountId,
  taskId,
  qualityReviews,
  errors,
  mode,
  creatingReviewTask,
  page,
  totalPages
});