import React from "react";
import { connect } from "react-redux";

import { post } from "../_utils/humpyAjax";
import { refreshResults, addMessage } from "../_common/filtered_lists/_actions";
import { ROLE_ICONS } from "../_utils/constants";
import HoverPopover from "../_common/popovers/HoverPopover";

const badgeClass = (isWhitelisted) => {
  if (isWhitelisted) return "badge badge-pill badge-light";
  return "badge badge-pill badge-light text-muted";
};

function qualityRoleString({quality, role}) {
  return `${quality}-${role}`;
}

function stringifyWlQualities(qualities) {
  return qualities.map(qualityRoleString).sort().join("-");
}

export class WhitelistedQualities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whitelistedQualities: [...props.initialWhitelistedQualities],
    };
  }

  areDefaultWhitelisted() {
    let originalWhitelisted = stringifyWlQualities(this.props.initialWhitelistedQualities),
        newWhitelisted = stringifyWlQualities(this.state.whitelistedQualities);

    return originalWhitelisted == newWhitelisted;
  }

  // We require to set at least a quality and its role, there is no need to create a full one
  toggleWhitelistedQuality(e, quality){
    let { whitelistedQualities } = this.state,
        newWhitelistedQualities;

    e.preventDefault();

    if (whitelistedQualities.map(wq=>wq.quality).includes(quality)) {
      newWhitelistedQualities = whitelistedQualities.filter(wq => wq.quality != quality);
    } else {
      newWhitelistedQualities = [...whitelistedQualities, {quality, role: "both"}];
    }
    this.setState({whitelistedQualities: newWhitelistedQualities});
  }

  selectRole(e, quality, role) {
    const whitelistedQualities = this.state.whitelistedQualities.map(whitelistedQuality => {
      if (whitelistedQuality.quality == quality) return {...whitelistedQuality, role};
      return whitelistedQuality;
    });

    e.preventDefault();

    this.setState({ whitelistedQualities });
  }

  updateWhitelisted(e){
    e.preventDefault();

    const { id, email, languagePair, updateWhitelistedPath, refreshResults, addMessage } = this.props,
          { whitelistedQualities} = this.state,
          params = {
            whitelistedQualities: whitelistedQualities.map(({ quality, role }) => ({ quality, role }) ),
            languagePair
          };

    post(`${updateWhitelistedPath}?translator_id=${id}`, params)
      .then( ({whitelisted}) => {
        refreshResults();
        if (whitelisted.length) {
          addMessage({ type: "success", message: `${email} got whitelisted for ${whitelisted.join(", ")}`});
        } else {
          addMessage({ type: "warning", message: `${email} is not whitelisted anymore` });
        }
      } )
      .catch( () => alert("unexpected error") );
  }

  render() {
    const { id, qualities, roles, twoHumansQualities } = this.props,
          { whitelistedQualities } = this.state;

    return (
      <React.Fragment>
        <div className="d-flex flex-wrap">
          {qualities.map(quality => {
            const key = `translator-row-${id}-whitelisted-quality-${quality}`,
                  whitelistedQuality = whitelistedQualities.find(w=> w.quality == quality),
                  isWhitelisted = !!whitelistedQuality;

            return (
              <div key={key} className="mr-2 mb-2">
                <a href="#" className={badgeClass(isWhitelisted)} onClick={e => this.toggleWhitelistedQuality(e, quality)}>
                  <span className={badgeClass(isWhitelisted)}>
                    <span className={isWhitelisted ? "ticon-check" : "ticon-cancel"}></span>
                  </span>
                  {quality}
                </a>
                {isWhitelisted && twoHumansQualities.includes(quality) ?
                  <div>
                    {(roles.map(role => (
                      <HoverPopover
                        key={role}
                        msg={role}
                        position="bottom"
                      >
                        <a
                          href="#"
                          onClick={e => this.selectRole(e, quality, role)}
                          className={`badge badge-pill badge-light ${whitelistedQuality.role == role ? "" : "text-muted"}`}
                          style={{ position: "relative" }}
                        >
                          <span className={ROLE_ICONS[role]} />
                        </a>
                      </HoverPopover>
                    )))}
                  </div>
                  : null
                }
              </div>
            );
          })}
        </div>
        <button
          className="mt-3 btn btn-sm btn-primary float-right"
          disabled={this.areDefaultWhitelisted()}
          onClick={e => this.updateWhitelisted(e)}
        >
          Update whitelisted
        </button>
      </React.Fragment>
    );
  }
}

export default connect(
  ({ staticData: { qualities, updateWhitelistedPath, roles, twoHumansQualities }, filters: { languagePair } }) => ({
    qualities,
    languagePair,
    updateWhitelistedPath,
    roles,
    twoHumansQualities
  }),
  { refreshResults, addMessage }
)(WhitelistedQualities);
