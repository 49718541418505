import { createSelector } from "reselect";

import { languagePairFromModel } from "../_utils/languages";

export const getWhitelistedSkills = state => state.whitelistedSkills;
export const getWranslatorAccountId = state => state.translatorAccountId;
export const getClients = state => state.clients;
export const getSkills = state => state.skills;

// returns whitelisted grouped by clients and language pairs
export const getGroupedWhitelistedSkills = createSelector(
  [getWhitelistedSkills],
  (whitelistedSkills) => whitelistedSkills.reduce(
    (acc, whitelistedSkill) => {
      const {clientAccountId } = whitelistedSkill,
            languagePair = languagePairFromModel(whitelistedSkill).toString;

      if (!acc[languagePair]) acc[languagePair] = {};
      if (!acc[languagePair][clientAccountId]) acc[languagePair][clientAccountId] = {};

      acc[languagePair][clientAccountId][whitelistedSkill.quality] = whitelistedSkill;

      return acc;
    }, {}
  )
);

export const getClientsHash = createSelector(
  [getClients],
  clients => clients.reduce((acc, client) =>{
    acc[client.id] = client;
    return acc;
  },{})
);

export const getTranslatorSkillsLpStrings = createSelector(
  [getSkills],
  (skills) => skills.map(s => languagePairFromModel(s).toString ).sort()
);