import React from "react";
import { connect } from "react-redux";

import { changeAttribute } from "./_actionsAndReducers";

export class AllowQualities extends React.Component {
  render() {
    const {
      clientAccount: {
        orderMachineAllowed,
        orderPosteditingAllowed,
      },
      changeAttribute,
    } = this.props;

    return (
      <>
        <label className="col-4 mb-3 font-weight-bold">Allowed special qualities:</label>
        <div className="col-8 mb-3">
          <div className="form-check form-check-inline mr-4">
            <input
              className="form-check-input"
              type="checkbox"
              id="orderMachineAllowed"
              checked={orderMachineAllowed}
              onChange={() => changeAttribute("orderMachineAllowed", !orderMachineAllowed)}
            />
            <label className="form-check-label" htmlFor="orderMachineAllowed">Machine</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="orderPosteditingAllowed"
              checked={orderPosteditingAllowed}
              onChange={() => changeAttribute("orderPosteditingAllowed", !orderPosteditingAllowed)}
            />
            <label className="form-check-label" htmlFor="orderPosteditingAllowed">Postediting</label>
          </div>
        </div>
      </>
    );
  }
}


export default connect(
  ({ clientAccount }) => ({ clientAccount }),
  { changeAttribute }
)(AllowQualities);