import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getClientLanguagePairPricings, updateClientLanguagePairPricings, changeClientLanguagePairPricingsScope } from "./_actions";
import NewClientLanguagePairPricing from "./NewClientLanguagePairPricing";
import ClientLanguagePairPricing from "./ClientLanguagePairPricing";
import ClientWorkflowLanguagePairPricing from "./ClientWorkflowLanguagePairPricing";
import Search from "./Search";
import { get } from "../_utils/humpyAjax";
import Pagination from "../_common/pagination/Pagination";

export default function ClientLanguagePairPricingList(props) {
  const clientLanguagePairPricings = useSelector(state => state.clientLanguagePairPricings);
  const { page, totalPages } = useSelector(state => state.clientLanguagePairPricingsScope);
  const anyPricings = Array.isArray(clientLanguagePairPricings) && clientLanguagePairPricings.length > 0;
  const dispatch = useDispatch();

  const changePage = (page)=> {
    dispatch(changeClientLanguagePairPricingsScope({ page }));
    dispatch(getClientLanguagePairPricings(document.location.pathname));
  };

  useEffect(()=>{
    dispatch(getClientLanguagePairPricings(document.location.pathname))
  }, []);

  return (
    <div className="row">
      <div className="col d-flex flex-column">
        <NewClientLanguagePairPricing
          sourceLanguageCodes={props.sourceLanguageCodes}
          targetLanguageCodes={props.targetLanguageCodes}
        />
        <h3 className="flex-dont-grow">Language pair pricings</h3>
        <Search
          sourceLanguageCodes={props.sourceLanguageCodes}
          targetLanguageCodes={props.targetLanguageCodes}
        />
        <hr/>
        {anyPricings ? <React.Fragment>
          <div className="card flex-grow mb-5">
            <div className="card-body">
              {clientLanguagePairPricings.map(clientLanguagePairPricing =>
                <React.Fragment key={clientLanguagePairPricing.id}>
                  <ClientLanguagePairPricing
                    clientLanguagePairPricing={clientLanguagePairPricing}
                  />
                  {clientLanguagePairPricing.workflowPricing &&
                    <ClientWorkflowLanguagePairPricing
                      clientLanguagePairPricing={clientLanguagePairPricing}
                      workflowLanguagePairPricing={clientLanguagePairPricing.workflowPricing}
                    />
                  }
                </React.Fragment>
              )}

              {
                totalPages && <Pagination {...{page, totalPages, changePage}} />
              }
            </div>
          </div>
        </React.Fragment> : <h6 className="text-center">No results</h6>}
      </div>
    </div>
  );
}
