import promise from "es6-promise";
promise.polyfill();
import param from "jquery-param";

function handleHttpErrors(response) {
  if (!response.ok) throw Error(response.statusText);

  return response;
}

export const handleJsonResponse = (response) => {
  return response.json()
      .catch(error => { throw {error, response}; })
      .then(json => ({ json, ok: response.ok }) )
      .then(({ json, ok }) => {
        if (!ok) throw {json};
        return json;
      });
};

export const getCSRFToken = () => {
  let csrftoken = document.querySelector("meta[name=csrf-token]");
  return csrftoken ? csrftoken.content : "no-csrf-token";
};

export const get = (url, handleResponse = handleHttpErrors) => {
  return fetch(url, { credentials: "same-origin" }).then(handleResponse);
};

export const post = (url, params, handleResponse = handleHttpErrors) => {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getCSRFToken(),
    },
    credentials: "same-origin"
  }).then(handleResponse);
};

export const patch = (url, params, handleResponse = handleHttpErrors) => {
  return fetch(url, {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getCSRFToken(),
    },
    credentials: "same-origin"
  }).then(handleResponse);
};

export const deleteRequest = (url, handleResponse = handleHttpErrors) => {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getCSRFToken(),
    },
    credentials: "same-origin"
  }).then(handleResponse);
};

export const cleanParams = (params) => Object.keys(params).filter(key => params[key]).reduce((acc, key) => {
  acc[key] = params[key];
  return acc;
}, {});

export const urlParams = (params) => {
  return param(cleanParams(params));
};
