import React from "react";
import { connect } from "react-redux";

import FormSelect from "../_common/FormSelect";
import FormCalendar from "../_common/FormCalendar";
import { requestFiltersUpdateAndResetPage } from "../_common/filtered_lists/_actions";
import {CLIENT_MODES, CLIENT_MODES_NAMES} from "./_constants";

const past12Months = Array(12).fill("")
    .map((_, i) => moment().subtract(i + 1, "months").startOf("month"))
    .map( m => [m.format("MMM YYYY"), m.format("YYYY-MM-DD")] );


export class SideBar extends React.Component {
  render() {
    const { className, requestFiltersUpdateAndResetPage, filters } = this.props;

    return (
      <div className={className}>
        <div className="card">
          <div className="card-body">

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Search</label>
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type in your search"
                  name="search"
                  value={ filters.search || ""}
                  onChange={(e) => requestFiltersUpdateAndResetPage({ search: e.target.value })}
                />
                <div className="input-group-append"><span className="input-group-text ticon-search"></span></div>
              </div>
              <small id="emailHelp" className="form-text text-muted">Partial matches for ids and names.</small>
            </div>

            <FormSelect
              label="With orders in progress ?"
              name="progress"
              onChange={(e) => requestFiltersUpdateAndResetPage({progress: e.target.value})}
              value={filters.progress || ""}
              options={[ ["Indifferent", ""], ["Yes", "yes"], ["No", "no"] ]}
            />

            <FormCalendar
              id="calendar"
              label="Created after"
              callback={ (date) => requestFiltersUpdateAndResetPage({createdAfter: date}) }
              value={filters.createdAfter || ""}
            />

            <FormSelect
              label="With problems ?"
              name="problems"
              onChange={(e) => requestFiltersUpdateAndResetPage({ problems: e.target.value })}
              value={filters.problems || ""}
              options={[
                ["Indifferent", ""],
                ["Inactive tasks for more than 24h", "inactive_day"],
                ["Inactive tasks for more than 1 week", "inactive_month"],
                ["No whitelisted translators", "no_whitelisted"]
              ]}
            />

            <FormSelect
              label="With orders during month"
              name="ordersMonth"
              onChange={(e) => requestFiltersUpdateAndResetPage({ ordersMonth: e.target.value })}
              value={filters.ordersMonth || ""}
              options={[
                ["Indifferent", ""],
                ...past12Months
              ]}
            />

            <FormSelect
              label="Mode"
              name="mode"
              onChange={(e) => requestFiltersUpdateAndResetPage({ mode: e.target.value })}
              value={filters.mode || ""}
              options={[
                ["Indifferent", ""],
                ...CLIENT_MODES.map( mode => [CLIENT_MODES_NAMES[mode], mode] )                
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ filters }) => ({ filters }),
  { requestFiltersUpdateAndResetPage }
)(SideBar);
