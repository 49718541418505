import React from "react";
import { connect } from "react-redux";

import Sidebar from "./Sidebar";
import Results from "./Results";

export class Tasks extends React.Component {
  render() {
    const { totalCount, clientName, wordCount } = this.props;

    return (
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col d-flex justify-content-between">
            <div className="">
              <h1>
                {clientName ? `${clientName} tasks` : "Tasks"}
                <span className="text-muted">({totalCount.toLocaleString()})</span>
              </h1>
              <h5>Word count: <span className="text-muted"> {wordCount.toLocaleString()} <small>words</small></span> </h5>
              <p>Here you can set soft deadlines for your translators. Translators will see their tasks ordered by deadline from the closest to the furthest having those without at the end.</p>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <Sidebar className="col-3" />
          <Results className="col-9" />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ totalCount, wordCount, filters: { clientId }, staticData: {clientsWithOrders} }) => {
    var clientName;

    if (clientId) clientsWithOrders.forEach( ([name, id]) => {
      if (id == clientId) clientName = name;
    });

    return {
      totalCount,
      wordCount,
      clientName
    };
  }
)(Tasks);