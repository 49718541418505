import React from "react";

export class TableHead extends React.Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Language pair</th>
          <th className="text-center">Total translators</th>
          <th className="text-center">Training</th>
          <th className="text-center">Waiting for judgment</th>
          <th className="text-center">Unreviewed</th>
          <th className="text-center">Unqualified</th>
          <th className="text-center">Qualified</th>
          <th className="text-center">Judges</th>
          <th className="text-center">Training enabled</th>
          <th className="text-center">Custom pricing</th>
        </tr>
      </thead>
    );
  }
}

export default TableHead;
