import { gql } from "graphql-request";

export const getClientDefaultPricingQuery = gql`
  query getClientDefaultPricing($clientAccountId: ID!) {
    clientAccount(id: $clientAccountId) {
      clientDefaultPricing {
        salesMargin
        pricePerWordPosteditingUsd
        pricePerWordPosteditingEur
        pricePerWordPosteditingAiUsd
        pricePerWordPosteditingAiEur
        pricePerWordTranslationUsd
        pricePerWordTranslationEur
        pricePerWordLocalizationUsd
        pricePerWordLocalizationEur
        pricePerWordExpertUsd
        pricePerWordExpertEur
        pricePerWordMachineUsd
        pricePerWordMachineEur
      }
    }
  }
`;

export const updateClientDefaultPricingMutation = gql`
  mutation clientDefaultPricingUpdate($input: ClientDefaultPricingUpdateInput!) {
    clientDefaultPricingUpdate(input: $input) {
      success
      errors
      clientDefaultPricing {
        salesMargin
        pricePerWordPosteditingUsd
        pricePerWordPosteditingEur
        pricePerWordPosteditingAiUsd
        pricePerWordPosteditingAiEur
        pricePerWordTranslationUsd
        pricePerWordTranslationEur
        pricePerWordLocalizationUsd
        pricePerWordLocalizationEur
        pricePerWordExpertUsd
        pricePerWordExpertEur
        pricePerWordMachineUsd
        pricePerWordMachineEur
      }
    }
  }
`;
