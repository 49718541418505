// Props:
// removeCallback: callback that uses array index to remove a message
// messages: array of messages to display
//        message: Text to display
//        type: string corresponding to bootstrap style

import React from "react";

export class Alerts extends React.Component {
  render() {
    const {messages, removeCallback} = this.props;

    return (
      <React.Fragment>
        {messages.map(({type, message}, index)=>(
          <div className={`alert alert-${type}`} key={`message-${index}-${message.replace(/\W/g, "-")}`}>
            {message}
            <button className="close" onClick={()=>removeCallback(index)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default Alerts;
