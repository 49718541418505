import React from "react";
import { connect } from "react-redux";
import { post } from "../_utils/humpyAjax";

import moment from "moment";
import ChangeOfficialDeadline from "./ChangeOfficialDeadline";
import { openDeadlineChanger } from "./_actions";

export class DeadlineHandler extends React.Component {
  acceptDeadline(e){
    e.preventDefault();
    post(this.props.acceptDeadlinePath, {})
      .then( () => location.reload() );
  }

  render() {
    const {
      proposedClientDeadline,
      offcialClientDeadline,
      acceptDeadlinePath,
      changeDeadlineOpen,
      openDeadlineChanger
    } = this.props;

    return (
      <div>
        {changeDeadlineOpen ?
          null
          : <button className="btn btn-primary pull-right js-set-custom-deadline" onClick={openDeadlineChanger}>Set custom deadline</button>
        }

        <h3>Deadline management</h3>

        <p>
          <strong className="mr-1">Proposed deadline:</strong>
          {proposedClientDeadline ? moment(proposedClientDeadline).format("LLL") : "Not set" }
        </p>

        {proposedClientDeadline ?
          <p>
            <a href={acceptDeadlinePath} onClick={e=>this.acceptDeadline(e)}>Accept deadline</a>
          </p>
          : null
        }

        <p>
          <strong className="mr-1">Official deadline:</strong>
          {offcialClientDeadline ? moment(offcialClientDeadline).format("LLL") : "Not set" }
        </p>

        <ChangeOfficialDeadline />
      </div>
    );
  }
}

export default connect(
  ({
    proposedClientDeadline,
    offcialClientDeadline,
    acceptDeadlinePath,
    changeDeadlineOpen,
  }) => ({
    proposedClientDeadline,
    offcialClientDeadline,
    acceptDeadlinePath,
    changeDeadlineOpen,
  }),
  { openDeadlineChanger }
)(DeadlineHandler);
