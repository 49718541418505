import React from "react";
import { connect } from "react-redux";

import { clientsUrl } from "../_utils/otherAppsLinks";
import { selectedResultsHashSelector } from "../_common/filtered_lists/_selectors";
import { toggleResultSelection } from "../_common/filtered_lists/_actions";

export class OrdersList extends React.Component {
  render() {
    const {
      orders,
      selectedResultsHash,
      toggleResultSelection,
      search,
    } = this.props;

    return (
      <table className="table">
        <tbody>
          {orders.map(({
            id,
            orderUrl,
            title,
            clientUrl,
            clientAccountName,
            createdAt,
            quality,
            languagePair,
            becomeLink,
            version,
            postprocessState,
            paymentStatus,
            translationStatus,
            orderedWords,
            currency,
            price,
            purchaseOrderId,
            purchaseOrderUrl,
            waitingForReview,
            proposedClientDeadline,
            officialClientDeadline,
            downloadLink,
          }) => (
            <tr key={`order-${id}`}>
              <td>
                <input type="checkbox" checked={selectedResultsHash[id] || false} onChange={() => toggleResultSelection(id)} />
              </td>
              <td><a href={orderUrl} className={parseInt(search) == id ? "bg-warning p-1" : ""}>{id}</a></td>
              <td>
                <p className="table__title mb-2">
                  <a href={orderUrl}>
                    {!!search && !parseInt(search) && RegExp(search, "gi").test(title) ?
                      <span
                        dangerouslySetInnerHTML={{
                          __html: title.replace(RegExp(search, "gi"), "<span class=\"bg-warning\">$&</span>")
                        }}
                      />
                      :
                      title
                    }
                  </a>
                </p>
                <ul className="table__compact_list">
                  <li className="table__compact_list__item">
                    <a href={clientUrl}>{clientAccountName}</a>
                  </li>
                  <li className="table__compact_list__item">
                    <strong>Created: </strong> {createdAt}
                  </li>
                  <li className="table__compact_list__item">
                    <strong>Quality: </strong> {quality}
                  </li>
                  <li className="table__compact_list__item">
                    <strong>Lang. pair</strong> {languagePair}
                  </li>
                  {becomeLink ?
                    <React.Fragment>
                      <li className="table__compact_list__item">
                        <a href={`${clientsUrl()}${becomeLink}`}>Become client and see TR</a>
                      </li>
                      <li className={`table__compact_list__item ${parseInt(search) == version ? "bg-warning" : ""}`}>
                        <strong>TR Version :</strong>  {version}
                      </li>
                    </React.Fragment>
                    : null
                  }
                  <li className="table__compact_list__item">
                    <strong>Processing status: </strong> {postprocessState}
                  </li>
                  <li className="table__compact_list__item">
                    <strong>Payment: </strong> {paymentStatus}
                  </li>
                  <li className="table__compact_list__item">
                    <strong>Translation status: </strong> {translationStatus}
                  </li>
                  <li>
                    <strong>Ordered words: </strong> {orderedWords.toLocaleString()}
                  </li>
                  <li>
                    <strong>Price: </strong> {price.toLocaleString(navigator.language, {currency, style: "currency"})}
                  </li>
                  <li>
                    <strong>Purchase order: </strong> <a href={purchaseOrderUrl}>{purchaseOrderId}</a>
                  </li>
                  { waitingForReview ?
                    <li>
                      <strong>Waiting for review: </strong> {waitingForReview}
                    </li>
                    : null
                  }
                  {proposedClientDeadline ?
                    <li>
                      <strong>Proposed deadline:</strong> {moment(proposedClientDeadline).format("LLL")}
                    </li>
                    : null
                  }
                  {officialClientDeadline ?
                    <li>
                      <strong>Oficial deadline:</strong> {moment(officialClientDeadline).format("LLL")}
                    </li>
                    : null
                  }
                  {downloadLink ?
                    <li>
                      <strong>Download original:</strong> <a href={`/translation-requests/${id}/download`} target="_blank">link</a>
                    </li>
                    : null
                  }
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default connect(
  ({ orders, selectedResults, filters: {search} }) => ({
    orders,
    selectedResultsHash: selectedResultsHashSelector({selectedResults}),
    search,
  }),
  { toggleResultSelection }
)(OrdersList);
