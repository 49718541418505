import React from "react";

import TextWithTags from "./TextWithTags";
import PrettyLocalDateTime from "../_common/PrettyLocalDateTime";

export default function History({
  pretranslation: {
    history,
    sourceChunkId,
    isXliff,
  },
  translatorPathById,
  emailById,
}){
  if (!history?.length) return null;

  return (history.map( ({
    date,
    phase,
    translation,
    translator,
  }) => (
    <tr key={`pretranslation-msg-${sourceChunkId}-history-${date}`}>
      <td></td>
      <td></td>
      <td className="text-muted">
        <small>
          {phase && <>
            {phase}
            <br />
          </>}
          <PrettyLocalDateTime date={date} />
        </small>
      </td>
      <td>
        <small className="text-secondary">
          <TextWithTags text={translation} isXliff={isXliff} index={date} />
        </small>
      </td>
      <td>
        {translator &&
          <a
            className="hide_for_printers"
            href={translatorPathById[translator]}
          >
            {emailById[translator]}
          </a>
        }
      </td>
      <td></td>
    </tr>
  )) );
}
