import React, { useContext, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import Results from "./Results";
import { Store } from './Store';
import { initializeStore, requestFilteredTransactions } from './_actions';

export default function Transactions({ initialData }) {
  const didMountRef = useRef(false);
  const {
    state: { account, transactionsCount, filters },
    dispatch
  } = useContext(Store);

  useEffect(() => { initializeStore(dispatch, initialData) }, []);
  useEffect(() => {
    if (didMountRef.current) {
      requestFilteredTransactions(dispatch, filters)
    } else {
      didMountRef.current = true;
    }
  }, [filters]);

  const currentBalance = account &&
    Object.entries(
      account.currentBalance
    ).map(([currency, balance]) =>
      `${balance} ${currency}`
    ).join(' | ');

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col d-flex justify-content-between">
          {account && <>
            <div>
              <h1>
                {account.ownerName} Transactions <span className="text-muted">({ transactionsCount.toLocaleString() })</span>
              </h1>
              <h5>
                Current balance: <span className="text-muted">{currentBalance.toLocaleString()}</span>
              </h5>
            </div>
          </>}
        </div>
      </div>

      <div className="row mt-4">
        <Sidebar className="col-3" />
        <Results className="col-9" />
      </div>
    </div>
  );
}
