import React, { useContext } from "react";
import FormCalendar from "../_common/FormCalendar";
import FormSelect from "../_common/FormSelect";
import { Store } from "./Store";
import { updateFilters } from "./_actions";

const paymentStatuses = [
  ["Paid", true],
  ["Unpaid", false],
];

const withdrawalMethods = [
  ["Bank transfer", false],
  ["PayPal", true],
];

export default function Sidebar() {
  const { state: { filters, currencies }, dispatch } = useContext(Store);

  return (
    <div className="col-3">
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Search</label>
            <div className="input-group mb-2 mr-sm-2 mb-sm-0">
              <input
                type="text"
                className="form-control"
                placeholder="Type in your search"
                name="search"
                value={filters.search || ""}
                onChange={e => updateFilters(dispatch, { page: 1, search: e.target.value })}
              />
              <div className="input-group-append"><span className="input-group-text ticon-search"></span></div>
            </div>
            <small id="emailHelp" className="form-text text-muted">Partial matches for emails.</small>
          </div>

          <FormSelect
            label="Payment status"
            name="paid"
            onChange={e => updateFilters(dispatch, { page: 1, paid: e.target.value })}
            value={filters.paid || ""}
            options={paymentStatuses}
            includeBlank={true}
            blankOptionLabel="All"
          />

          <FormSelect
            label="Currency"
            name="currency"
            onChange={e => updateFilters(dispatch, { page: 1, currency: e.target.value })}
            value={filters.currency || ""}
            options={currencies}
            includeBlank={true}
            blankOptionLabel="All"
          />

          <FormSelect
            label="Withdrawal method"
            name="paypalPreferred"
            onChange={e => updateFilters(dispatch, { page: 1, paypalPreferred: e.target.value })}
            value={filters.paypalPreferred || ""}
            options={withdrawalMethods}
            includeBlank={true}
            blankOptionLabel="All"
          />

          <FormSelect
            label="SEPA supported"
            name="sepaSupported"
            onChange={e => updateFilters(dispatch, { page: 1, sepaSupported: e.target.value })}
            value={filters.sepaSupported || ""}
            options={[["Yes", true], ["No", false]]}
            includeBlank={true}
            blankOptionLabel="All"
          />

          <FormCalendar
            id="calendar"
            label="Completed after"
            callback={(date) => updateFilters(dispatch, { page: 1, createdAfter: date })}
            value={filters.createdAfter || ""}
          />

          <FormCalendar
            id="calendar"
            label="Completed before"
            callback={(date) => updateFilters(dispatch, { page: 1, createdBefore: date })}
            value={filters.createdBefore || ""}
          />
        </div>
      </div>
    </div>
  );
}
